import CheckIcon from "@mui/icons-material/Check";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Chip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { RichText } from "components";
import { formatDistanceToNow } from "date-fns";
import {
  CreateJournalEntryComment,
  JournalEntry,
  JournalEntryComment as Comment,
} from "generated/openapi";
import { FC, useState } from "react";
import {
  createJournalEntryComment,
  selectUser,
  updateJournalEntryComment,
  useAppDispatch,
  useAppSelector,
} from "store";

export interface Props {
  journal: JournalEntry;
  comment: Comment;
  indent?: number;
}

export const JournalEntryComment: FC<Props> = ({
  journal,
  comment,
  indent,
}) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser)!;

  const [editing, setEditing] = useState<string | undefined>(undefined);
  const [newComment, setNewComment] = useState<
    CreateJournalEntryComment | undefined
  >(undefined);

  return (
    <Stack gap={1} sx={{ marginBlock: 1, marginLeft: indent ?? 8 }}>
      <Stack direction="row" gap={2}>
        <Typography>
          {comment.user
            ? `${comment.user.firstName} ${comment.user.lastName}`
            : "Deleted User"}
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          {formatDistanceToNow(new Date(comment.created)) + " ago"}
        </Typography>
      </Stack>

      <RichText
        value={
          editing ??
          (comment.comment === "" ? "<p>Deleted Comment<p/>" : comment.comment)
        }
        editable={editing !== undefined}
        onChange={(v) => editing && setEditing(v)}
      />

      {editing ? (
        <Stack direction="row" gap={2} justifyContent="end">
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => setEditing(undefined)}
          >
            Cancel
          </Button>
          <Button
            color="success"
            variant="contained"
            disabled={journal.entry === editing}
            startIcon={<CheckIcon />}
            onClick={() => {
              setEditing(undefined);
              dispatch(
                updateJournalEntryComment({
                  entryId: journal.id,
                  comment: { ...comment, comment: editing },
                }),
              );
            }}
          >
            Save
          </Button>
        </Stack>
      ) : (
        <Stack direction="row" spacing={1}>
          {user.id === comment.user?.id && (
            <Chip
              disabled={!!comment.deleted}
              icon={<EditIcon />}
              label="Edit"
              onClick={() => setEditing(comment.comment)}
            />
          )}
          <Chip
            disabled={!!comment.deleted}
            icon={<CommentIcon />}
            label="Reply"
            onClick={() => setNewComment({ comment: "", replyTo: comment.id })}
          />
        </Stack>
      )}

      {newComment && (
        <Stack>
          <RichText
            editable
            value=""
            onChange={(v) => setNewComment({ ...newComment, comment: v })}
          />
          <Stack direction="row" justifyContent="right">
            <Button color="inherit" onClick={() => setNewComment(undefined)}>
              Cancel
            </Button>
            <Button
              color="info"
              disabled={newComment.comment === ""}
              onClick={() => {
                dispatch(
                  createJournalEntryComment({
                    entryId: journal.id,
                    comment: newComment,
                  }),
                ).then(() => setNewComment(undefined));
              }}
            >
              Reply
            </Button>
          </Stack>
        </Stack>
      )}

      {comment.comments.map((c, i) => (
        <JournalEntryComment key={i} journal={journal} comment={c} />
      ))}
    </Stack>
  );
};
