import { TextField } from "@mui/material";
import { Keybind } from "generated/openapi";
import { FC, useEffect, useState } from "react";
import { keybindStr } from "utils";

export interface Props {
  value?: Keybind | null;
  size?: "small" | "medium";
  onChange?: (_?: Keybind) => void;
  onBlur?: (_?: Keybind) => void;
}

export const KeybindInput: FC<Props> = ({
  value: _value,
  size,
  onChange,
  onBlur,
}) => {
  const [value, setValue] = useState(_value);

  useEffect(() => setValue(_value), [_value]);

  const handleChange = (v?: Keybind) => {
    setValue(v);
    onChange?.(v);
  };

  return (
    <TextField
      focused
      size={size}
      label="Keybind"
      variant="standard"
      sx={{
        minWidth: "6.75rem",
        "& .MuiInputBase-input": {
          textTransform: "capitalize",
        },
      }}
      value={value ? keybindStr(value) : ""}
      inputProps={{ style: { textAlign: "left", fontSize: "1rem" } }}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        if (e.key === "Backspace" || e.key === "Delete") {
          e.preventDefault();
          handleChange(undefined);
        } else if (e.key.length === 1 && e.key.charCodeAt(0) <= 127) {
          e.preventDefault();
          handleChange({ char: e.key, ctrl: e.ctrlKey });
        }
      }}
      onBlur={() => onBlur?.(value ?? undefined)}
    />
  );
};
