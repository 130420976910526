import ImageIcon from "@mui/icons-material/Image";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import theme from "app/theme";
import { ChipList } from "components";
import { customBadge } from "containers/Playlist/PlaylistSegments";
import { PlaylistSegment } from "generated/openapi";
import { FC } from "react";
import {
  selectForzaTeamsMap,
  selectPlayersMap,
  selectSegmentTagsFlattenedMap,
  useAppSelector,
} from "store";
import { minutesSeconds } from "utils";

interface Props {
  segment: PlaylistSegment;
}

export const SegmentItem: FC<Props> = (props) => {
  const { segment } = props;
  const tagsMap = useAppSelector(selectSegmentTagsFlattenedMap) ?? {};
  const playersMap = useAppSelector(selectPlayersMap) ?? {};
  const teamsMap = useAppSelector(selectForzaTeamsMap);

  return (
    <Box
      sx={{ display: "flex", bgcolor: "primary.main", height: "100%", p: 2 }}
    >
      <Stack
        direction="column"
        spacing={2}
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Box>
          <Typography variant="h5">{segment.title}</Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              py: 1,
              px: 0.5,
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ mr: 0.5 }}
            >
              {minutesSeconds(segment.toTimestamp - segment.fromTimestamp)}
            </Typography>
            <Divider
              sx={{ bgcolor: "primary.light" }}
              orientation="vertical"
              flexItem
            />
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ mx: 0.5 }}
            >
              {`${teamsMap[segment.homeTeamId].name} vs. ${teamsMap[segment.visitingTeamId].name}`}
            </Typography>
            {!!Object.values(segment.drawings).length &&
              customBadge(Object.values(segment.drawings).length, ImageIcon)}
          </Box>
        </Box>
        <Stack gap={1}>
          <Grid container columnSpacing={1} rowSpacing={0} alignItems="center">
            <Grid item xs={2}>
              <Typography variant="caption" color="text.secondary">
                Tags:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <ChipList
                value={segment.tags.map((t) => tagsMap[t].displayName)}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={1} rowSpacing={0} alignItems="center">
            <Grid item xs={2}>
              <Typography variant="caption" color="text.secondary">
                Players:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <ChipList
                value={segment.playerTags.map(
                  (pt) =>
                    `${playersMap[pt].firstName} ${playersMap[pt].lastName}`,
                )}
                color={theme.palette.grey.A700}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={1} rowSpacing={0} alignItems="center">
            <Grid item xs={2}>
              <Typography variant="caption" color="text.secondary">
                Teams:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <ChipList
                value={segment.forzaTeamTags.map((tt) => teamsMap[tt].name)}
                color={theme.palette.grey.A400}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
};
