import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { JournalEntryComment } from "generated/openapi";
import { AppState } from "store/state";
import {
  createJournalEntry,
  createJournalEntryComment,
  deleteJournalEntry,
  deleteJournalEntryComment,
  getJournalEntries,
  getJournalEntry,
  updateJournalEntry,
  updateJournalEntryComment,
} from "./actions";

export const journalBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(getJournalEntry.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(getJournalEntry.fulfilled, (state, action) => {
      if (state.journalEntries) {
        state.journalEntries.unshift(action.payload);
      } else {
        state.journalEntries = [action.payload];
      }
    })
    .addCase(getJournalEntries.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
      state.journalEntries = undefined;
      state.journalEntriesLoading = true;
    })
    .addCase(getJournalEntries.fulfilled, (state, action) => {
      state.journalEntries = action.payload;
      state.journalEntriesLoading = false;
    })
    .addCase(getJournalEntries.rejected, (state, _action) => {
      state.journalEntriesLoading = false;
    })
    .addCase(createJournalEntry.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(createJournalEntry.fulfilled, (state, action) => {
      if (state.journalEntries) {
        state.journalEntries.unshift(action.payload);
      } else {
        state.journalEntries = [action.payload];
      }
    })
    .addCase(createJournalEntryComment.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(createJournalEntryComment.fulfilled, (state, action) => {
      const idx = state.journalEntries?.findIndex(
        (v) => v.id === action.meta.arg.entryId,
      );
      if (idx !== undefined && idx >= 0) {
        if (action.meta.arg.comment.replyTo) {
          const id = action.meta.arg.comment.replyTo;
          const comment = findComment(id, state.journalEntries![idx].comments);
          if (comment) {
            comment.comments.unshift(action.payload);
          }
        } else {
          state.journalEntries![idx].comments.unshift(action.payload);
        }
      }
    })
    .addCase(updateJournalEntry.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updateJournalEntry.fulfilled, (state, action) => {
      const idx = state.journalEntries?.findIndex(
        (v) => v.id === action.meta.arg.entry.id,
      );
      if (idx !== undefined && idx >= 0) {
        Object.assign(state.journalEntries![idx], action.meta.arg.entry, {
          updated: new Date().toISOString(),
        });
      }
    })
    .addCase(updateJournalEntryComment.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updateJournalEntryComment.fulfilled, (state, action) => {
      const idx = state.journalEntries?.findIndex(
        (v) => v.id === action.meta.arg.entryId,
      );
      if (idx !== undefined && idx >= 0) {
        const id = action.meta.arg.comment.id;
        const comment = findComment(id, state.journalEntries![idx].comments);
        if (comment) {
          Object.assign(comment, action.meta.arg.comment, {
            updated: new Date().toISOString(),
          });
        }
      }
    })
    .addCase(deleteJournalEntry.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(deleteJournalEntry.fulfilled, (state, action) => {
      const idx = state.journalEntries?.findIndex(
        (v) => v.id === action.meta.arg.entryId,
      );
      if (idx !== undefined && idx >= 0) {
        state.journalEntries?.splice(idx, 1);
      }
    })
    .addCase(deleteJournalEntryComment.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(deleteJournalEntryComment.fulfilled, (state, action) => {
      const idx = state.journalEntries?.findIndex(
        (v) => v.id === action.meta.arg.entryId,
      );
      if (idx !== undefined && idx >= 0) {
        const id = action.meta.arg.commentId;
        const comment = findComment(id, state.journalEntries![idx].comments);
        if (comment) {
          Object.assign(comment, {
            comment: "",
            deleted: new Date().toISOString(),
          });
        }
      }
    });

function findComment(
  commentId: number,
  comments: JournalEntryComment[],
): JournalEntryComment | undefined {
  for (const c of comments) {
    if (c.id === commentId) return c;
    const comment = findComment(commentId, c.comments);
    if (comment) return comment;
  }
  return undefined;
}
