import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";
import { TeamDetailed } from "generated/openapi";

export const createTeam = createAsyncThunk("team/createTeam", Api.createTeam);

export const updateTeam = createAsyncThunk("team/updateTeam", Api.updateTeam);

export const deleteTeam = createAsyncThunk("team/deleteTeam", Api.deleteTeam);

export const kickFromTeam = createAsyncThunk(
  "team/kickFromTeam",
  Api.kickFromTeam,
);

export const setSelectedTeam = createAction<TeamDetailed>(
  "team/setSelectedTeam",
);
