import { PlaylistSegmentTag } from "generated/openapi";

export interface SegmentTagState {
  segmentTags?: PlaylistSegmentTag[];
  segmentTagsLoading: boolean;
}

export const initialSegmentTagState: SegmentTagState = {
  segmentTags: undefined,
  segmentTagsLoading: false,
};
