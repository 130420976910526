import { Arrow, Shape, ShapeDiscriminants } from "generated/openapi";
import Konva from "konva";
import { FC, ForwardedRef, forwardRef, useRef } from "react";
import * as ReactKonva from "react-konva";

export interface Props {
  arrow: Arrow;
  selected: boolean;
  interactive: boolean;
  onSelect: () => void;
  onChange: (_: Shape) => void;
}

export const ArrowComponent: FC<Props> = ({
  arrow,
  selected,
  interactive,
  onSelect,
  onChange: _onChange,
}) => {
  const shapeRef = useRef<Konva.Arrow>(null);
  const trRef1 = useRef<Konva.Rect>(null);
  const trRef2 = useRef<Konva.Rect>(null);

  const changeCursor = (cursor: string) => {
    const stage = shapeRef.current!.getStage();
    if (stage) {
      stage.container().style.cursor = cursor;
    }
  };

  const onChange = (value: Partial<Arrow>) =>
    _onChange({
      type: ShapeDiscriminants.Arrow,
      value: { ...arrow, ...value },
    });

  const Anchor = forwardRef(
    ({ x, y }: { x: number; y: number }, ref: ForwardedRef<Konva.Rect>) => (
      <ReactKonva.Rect
        draggable={interactive}
        visible={selected}
        ref={ref}
        x={x}
        y={y}
        width={10}
        height={10}
        offsetX={5}
        offsetY={5}
        fill={"white"}
        stroke={"blue"}
        strokeWidth={1}
        onMouseEnter={() => changeCursor("move")}
        onMouseLeave={() => changeCursor("default")}
        onDragMove={(_e) => {
          const p1 = trRef1.current!.getPosition();
          const p2 = trRef2.current!.getPosition();
          shapeRef.current!.points([p1.x, p1.y, p2.x, p2.y]);
        }}
      />
    ),
  );

  return (
    <ReactKonva.Group
      draggable={interactive}
      onDragEnd={(event) => {
        const p1 = trRef1.current!.getAbsolutePosition();
        const p2 = trRef2.current!.getAbsolutePosition();
        event.target.setAttrs({ x: 0, y: 0 });
        onChange({
          x1: p1.x,
          y1: p1.y,
          x2: p2.x,
          y2: p2.y,
        });
      }}
    >
      <ReactKonva.Arrow
        {...arrow}
        points={[arrow.x1, arrow.y1, arrow.x2, arrow.y2]}
        strokeScaleEnabled={false}
        ref={shapeRef}
        onClick={() => interactive && onSelect()}
      />
      <Anchor x={arrow.x1} y={arrow.y1} ref={trRef1} />
      <Anchor x={arrow.x2} y={arrow.y2} ref={trRef2} />
    </ReactKonva.Group>
  );
};
