import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";

export const getOrg = createAsyncThunk("org/getOrg", Api.getOrg);

export const createOrg = createAsyncThunk("org/createOrg", Api.createOrg);

export const updateOrg = createAsyncThunk("org/updateOrg", Api.updateOrg);

export const deleteOrg = createAsyncThunk("org/deleteOrg", Api.deleteOrg);

export const leaveOrg = createAsyncThunk("team/leaveOrg", Api.leaveOrg);

export const kickFromOrg = createAsyncThunk(
  "team/kickFromOrg",
  Api.kickFromOrg,
);
