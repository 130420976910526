import { apiConfiguration, apiGet } from "api";
import {
  CreateJournalEntry,
  CreateJournalEntryComment,
  JournalEntry,
  JournalEntryComment,
  V1journalApi,
} from "generated/openapi";

const api = new V1journalApi(apiConfiguration);

export interface GetJournalEntryArgs {
  entryId: number;
  token?: string;
}

export interface GetJournalEntriesArgs {
  playerId: string;
  token?: string;
}

export interface CreateJournalEntryArgs {
  playerId: string;
  entry: CreateJournalEntry;
  token?: string;
}

export interface CreateJournalEntryCommentArgs {
  entryId: number;
  comment: CreateJournalEntryComment;
  token?: string;
}

export interface UpdateJournalEntryArgs {
  entry: JournalEntry;
  token?: string;
}

export interface UpdateJournalEntryCommentArgs {
  entryId: number;
  comment: JournalEntryComment;
  token?: string;
}

export interface DeleteJournalEntryArgs {
  entryId: number;
  token?: string;
}

export interface DeleteJournalEntryCommentArgs {
  entryId: number;
  commentId: number;
  token?: string;
}

export const getJournalEntries = async (query: GetJournalEntriesArgs) =>
  apiGet(async () =>
    api.getJournalEntries(
      { playerId: query.playerId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const getJournalEntry = async (query: GetJournalEntryArgs) =>
  apiGet(async () =>
    api.getJournalEntry(
      { journalEntryId: query.entryId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const createJournalEntry = async (query: CreateJournalEntryArgs) =>
  apiGet(async () =>
    api.createJournalEntry(
      { playerId: query.playerId, createJournalEntry: query.entry },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const createJournalEntryComment = async (
  query: CreateJournalEntryCommentArgs,
) =>
  apiGet(async () =>
    api.createJournalEntryComment(
      {
        journalEntryId: query.entryId,
        createJournalEntryComment: query.comment,
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const updateJournalEntry = async (query: UpdateJournalEntryArgs) =>
  apiGet(async () =>
    api.updateJournalEntry(
      {
        journalEntryId: query.entry.id,
        updateJournalEntry: {
          entry: query.entry.entry,
        },
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const updateJournalEntryComment = async (
  query: UpdateJournalEntryCommentArgs,
) =>
  apiGet(async () =>
    api.updateJournalEntryComment(
      {
        journalEntryCommentId: query.comment.id,
        updateJournalEntryComment: {
          comment: query.comment.comment,
        },
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const deleteJournalEntry = async (query: DeleteJournalEntryArgs) =>
  apiGet(async () =>
    api.deleteJournalEntry(
      { journalEntryId: query.entryId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const deleteJournalEntryComment = async (
  query: DeleteJournalEntryCommentArgs,
) =>
  apiGet(async () =>
    api.deleteJournalEntryComment(
      { journalEntryCommentId: query.commentId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );
