import { apiConfiguration, apiGet } from "api";
import {
  CreatePlaylist,
  CreatePlaylistExport,
  FootballFieldGridIdx,
  Playlist,
  PlaylistTag,
  V1playlistApi,
} from "generated/openapi";
import { TagItem } from "models";

export const MAX_PLAYLIST_SEGMENTS = 250;

const api = new V1playlistApi(apiConfiguration);

export interface GetPlaylistArgs {
  playlistId: number;
  token?: string;
}

export interface GetPlaylistsArgs {
  teamId: number;
  tags?: PlaylistTag[];
  players?: string[];
  teams?: number[];
  limit?: number;
  offset?: number;
  token?: string;
}

export interface GetPlaylistSegmentsArgs {
  teamId: number;
  tags?: TagItem[];
  players?: string[];
  teams?: number[];
  leftTeamId?: number;
  rightTeamId?: number;
  grids?: FootballFieldGridIdx[];
  limit?: number;
  offset?: number;
  token?: string;
}

export interface ComposePlaylistArgs {
  playlist: CreatePlaylist;
  token?: string;
}

export interface CreatePlaylistArgs {
  playlist: CreatePlaylist;
  token?: string;
}

export interface UpdatePlaylistArgs {
  playlist: Playlist;
  token?: string;
}

export interface DeletePlaylistArgs {
  playlistId: number;
  token?: string;
}

export interface StartPlaylistExportArgs extends CreatePlaylistExport {
  playlistId: number;
  token?: string;
}

export interface GeneratePlaylistHomographyArgs {
  playlistId: number;
  segments: number[];
  token?: string;
}

export const getPlaylists = async (query: GetPlaylistsArgs) =>
  apiGet(async () =>
    api.getPlaylists(
      {
        teamId: query.teamId,
        tags: query.tags?.map((t) => t.id),
        playerTags: query.players,
        forzaTeamTags: query.teams,
        limit: query.limit,
        offset: query.offset,
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const getPlaylistSegments = async (query: GetPlaylistSegmentsArgs) =>
  apiGet(async () =>
    api.getPlaylistSegments(
      {
        teamId: query.teamId,
        tags: query.tags?.flatMap((t) =>
          [t.id].concat(t.subTags?.map((t) => t.id) ?? []),
        ),
        playerTags: query.players,
        forzaTeamTags: query.teams,
        footballFieldLeftTeamId: query.leftTeamId,
        footballFieldRightTeamId: query.rightTeamId,
        footballFieldGrids: query.grids?.map((g) => `${g.x}:${g.y}`),
        limit: query.limit,
        offset: query.offset,
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const getPlaylist = async (query: GetPlaylistArgs) =>
  apiGet(async () =>
    api.getPlaylist(
      { playlistId: query.playlistId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const composePlaylist = async (query: ComposePlaylistArgs) =>
  apiGet(async () =>
    api.createPlaylist(
      {
        createPlaylist: {
          ...query.playlist,
          segments: query.playlist.segments?.map((s) => ({
            ...s,
            id: undefined,
          })),
        },
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const createPlaylist = async (query: CreatePlaylistArgs) =>
  apiGet(async () =>
    api.createPlaylist(
      {
        createPlaylist: {
          ...query.playlist,
          segments: query.playlist.segments?.map((s) => ({
            ...s,
            id: undefined,
          })),
          forzaTeamTags: [],
        },
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const updatePlaylist = async (query: UpdatePlaylistArgs) =>
  apiGet(async () =>
    api.updatePlaylist(
      {
        playlistId: query.playlist.id,
        updatePlaylist: {
          name: query.playlist.name,
          teamId: query.playlist.teamId,
          forzaTeamTags: query.playlist.forzaTeamTags,
          segments: query.playlist.segments.map((s) => ({
            ...s,
            id: s.id < 0 ? undefined : s.id,
            userId: s.creator?.id,
            tags: s.tags,
          })),
          tags: query.playlist.tags,
          playerTags: query.playlist.playerTags,
          sharedWith: query.playlist.sharedWith.map((s) => s.id),
        },
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const deletePlaylist = async (query: DeletePlaylistArgs) =>
  apiGet(async () =>
    api.deletePlaylist(
      { playlistId: query.playlistId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const startPlaylistExport = async ({
  token,
  playlistId,
  ...query
}: StartPlaylistExportArgs) =>
  apiGet(async () =>
    api.startPlaylistExport(
      { playlistId, createPlaylistExport: query },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const generatePlaylistHomography = async (
  query: GeneratePlaylistHomographyArgs,
) =>
  apiGet(async () =>
    api.playlistGenerateHomography(
      {
        playlistId: query.playlistId,
        playlistSegmentHomography: {
          segmentIds: query.segments,
        },
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );
