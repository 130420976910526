import { ActionReducerMapBuilder, createReducer } from "@reduxjs/toolkit";
import { setAlert, setAuthState, setError } from "./actions";
import { forzaEventsBuilder } from "./forzaEvents";
import { forzaGamesBuilder } from "./forzaGame";
import { forzaPlayersBuilder } from "./forzaPlayer";
import { forzaTeamsBuilder } from "./forzaTeam";
import { groupBuilder } from "./group";
import { journalBuilder } from "./journal";
import { liveStreamBuilder } from "./liveStream";
import { getOrg, orgBuilder } from "./org";
import { playlistBuilder } from "./playlist";
import { playlistTagBuilder } from "./playlistTag";
import { segmentTagBuilder } from "./segmentTag";
import { AppState, initialAppState } from "./state";
import { teamBuilder } from "./team";
import { getUser, userBuilder } from "./user";

class AppActionReducerMapBuilder<State> {
  builder: ActionReducerMapBuilder<State>;

  constructor(builder: ActionReducerMapBuilder<State>) {
    this.builder = builder;
  }

  extendBuilder(
    extender: (
      _: ActionReducerMapBuilder<State>,
    ) => ActionReducerMapBuilder<State>,
  ) {
    extender(this.builder);
    return this;
  }

  finish = () => this.builder;
}

const baseBuilder = (builder: ActionReducerMapBuilder<AppState>) =>
  builder
    .addCase(setError, (state, action) => {
      state.error = action.payload;
    })
    .addCase(setAuthState, (state, action) => {
      state.auth0User = action.payload.user;
      state.accessToken = action.payload.token;
      if (!state.user) {
        (action as any).asyncDispatch(getUser({}));
        (action as any).asyncDispatch(getOrg({}));
      }
    })
    .addCase(setAlert, (state, action) => {
      state.alert = action.payload;
    });

export const appReducer = createReducer(initialAppState, (builder) =>
  new AppActionReducerMapBuilder(builder)
    .extendBuilder(baseBuilder)
    .extendBuilder(userBuilder)
    .extendBuilder(orgBuilder)
    .extendBuilder(teamBuilder)
    .extendBuilder(groupBuilder)
    .extendBuilder(segmentTagBuilder)
    .extendBuilder(playlistTagBuilder)
    .extendBuilder(playlistBuilder)
    .extendBuilder(journalBuilder)
    .extendBuilder(forzaPlayersBuilder)
    .extendBuilder(forzaTeamsBuilder)
    .extendBuilder(forzaGamesBuilder)
    .extendBuilder(forzaEventsBuilder)
    .extendBuilder(liveStreamBuilder)
    .finish(),
);
