import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";

export const LoadingScreen: FC = () => {
  return (
    <Box sx={{ width: "100%", height: "100vh", bgcolor: "primary.dark" }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <CircularProgress color="secondary" size={80} />
      </Box>
    </Box>
  );
};
