import EditNoteIcon from "@mui/icons-material/EditNote";
import PanoramaIcon from "@mui/icons-material/Panorama";
import {
  Avatar,
  Badge,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ForzaGame, UserRole } from "generated/openapi";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  selectCurrentUserRole,
  selectForzaTeamsMap,
  selectIsOrcaAdmin,
  useAppSelector,
} from "store";
import { dateFormat } from "utils/utils";
import { Thumbnail } from "./Thumbnail";

export interface Props {
  game: ForzaGame;
  width?: number | string;
  height?: number | string;
}

export const GameThumbnail: FC<Props> = ({ game, width, height }) => {
  const navigate = useNavigate();

  const teams = useAppSelector(selectForzaTeamsMap);
  const isOrcaAdmin = useAppSelector(selectIsOrcaAdmin);
  const userRole = useAppSelector(selectCurrentUserRole)!;

  // A game's first event may be of an unknown type, with a thumbnail_url to the norkring domain.
  // These urls are not valid, so we avoid them.
  const event =
    game.events[
      game.events.findIndex((e) => !e.thumbnailUrl.includes("norkring"))
    ];

  const team1 = teams[game.homeTeamId];
  const team2 = teams[game.visitingTeamId];

  return (
    <Thumbnail
      url={event?.thumbnailUrl}
      title={`${team1.name} vs. ${team2.name}`}
      subtitle={dateFormat(game.date, "dd.MM.yyyy")}
      width={width}
      height={height}
    >
      {game.playlistCreators.length > 0 && userRole !== UserRole.Player && (
        <Tooltip
          sx={{ bgcolor: "primary.dark" }}
          title={
            <Stack>
              {game.playlistCreators.map((c) => (
                <Typography key={c.id} variant="caption">
                  {c.firstName} {c.lastName}
                </Typography>
              ))}
            </Stack>
          }
        >
          <Badge
            sx={{
              position: "absolute",
              bottom: 10,
              right: 5,
            }}
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            badgeContent={
              <Avatar
                sx={{
                  width: 18,
                  height: 18,
                  bgcolor: "white",
                  border: "1px solid black",
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {game.playlistCreators.length}
                </Typography>
              </Avatar>
            }
          >
            <Avatar sx={{ bgcolor: "fourth.main", width: 34, height: 34 }}>
              <EditNoteIcon sx={{ fontSize: "1.5rem", color: "white" }} />
            </Avatar>
          </Badge>
        </Tooltip>
      )}
      {isOrcaAdmin && (
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0 }}
          onClick={(e) => {
            e.preventDefault();
            navigate(`/panorama/${game.id}`);
          }}
        >
          <PanoramaIcon />
        </IconButton>
      )}
    </Thumbnail>
  );
};
