import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import {
  Avatar,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import theme from "app/theme";
import { Confirm, ConfirmModal } from "components";
import { CreateInvite, User, UserRole } from "generated/openapi";
import { FC, useMemo, useState } from "react";
import {
  kickFromOrg,
  selectAccessToken,
  selectOrg,
  selectUser,
  useAppDispatch,
  useAppSelector,
} from "store";
import { InviteUser } from "./InviteUser";
import { UpdatePlayer } from "./UpdatePlayer";
import { UpdateUser } from "./UpdateUser";

interface RowMenuProps {
  user: User | undefined;
  anchorEl: HTMLButtonElement | null;
}

export const StyledTableCell = styled(TableCell)(() => ({
  borderLeft: "none",
  borderRight: "none",
  "&:first-of-type": {
    textAlign: "center",
    width: 80,
  },

  [`&.${tableCellClasses.head}`]: {
    border: "none",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontSize: "0.8rem",
    backgroundColor: "#1A1A1A",

    "&:last-of-type": { textAlign: "right" },
  },
  [`&.${tableCellClasses.body}`]: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: "white",
    fontSize: "0.95rem",

    "&:last-of-type": {
      textAlign: "right",
    },
  },
}));

export const StyledTableCellSub = styled(TableCell)(() => ({
  borderLeft: "none",
  borderRight: "none",
  border: "none",
  textTransform: "uppercase",
  color: theme.palette.text.secondary,
  fontSize: "0.8rem",
  backgroundColor: theme.palette.primary.main,
  textAlign: "left",
  paddingTop: 8,
  paddingBottom: 8,
}));

export const Users: FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser)!;
  const user = useAppSelector(selectUser)!;
  const org = useAppSelector(selectOrg)!;
  const token = useAppSelector(selectAccessToken);

  const [confirm, setConfirm] = useState<Confirm | undefined>(undefined);
  const [editUser, setEditUser] = useState<User | undefined>(undefined);
  const [inviteUser, setInviteUser] = useState<CreateInvite | undefined>(
    undefined,
  );
  const [rowMenuProps, setRowMenuProps] = useState<RowMenuProps>({
    user: undefined,
    anchorEl: null,
  });

  const isPlayer = user.userRole === UserRole.Player;
  const isAdmin = user.userRole === UserRole.Admin;

  const [admins, coaches, players] = useMemo(() => {
    const admins: Record<string, User> = {};
    const coaches: Record<string, User> = {};
    const players: Record<string, User> = {};

    for (const t of org.teams) {
      for (const u of t.admins) admins[u.id] = u;
      for (const u of t.coaches) coaches[u.id] = u;
      for (const u of t.players) players[u.id] = u;
    }
    for (const u of org.otherUsers) {
      if (u.userRole === UserRole.Admin) admins[u.id] = u;
      else if (u.userRole === UserRole.Coach) coaches[u.id] = u;
      else players[u.id] = u;
    }

    return [
      Object.values(admins),
      Object.values(coaches),
      Object.values(players),
    ];
  }, [org]);

  const handleOpenActionMenu = (
    user: User,
    anchorTarget: HTMLButtonElement,
  ) => {
    setRowMenuProps({
      user,
      anchorEl: anchorTarget,
    });
  };

  const handleCloseMenu = () => {
    setRowMenuProps({
      user: undefined,
      anchorEl: null,
    });
  };

  const handleEditUser = () => {
    setEditUser(rowMenuProps.user);
    handleCloseMenu();
  };

  const handleKickUser = () => {
    setConfirm({
      message: `Are you sure you want to remove '${rowMenuProps.user!.firstName} ${rowMenuProps.user!.lastName}' from ${org.name}?`,
      onConfirm: () => {
        dispatch(kickFromOrg({ userId: rowMenuProps.user!.id }));
      },
    });
    handleCloseMenu();
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Users</Typography>
      {!isPlayer && (
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            color="third"
            disabled={org.teams.length >= org.maxTeams}
            sx={{ color: "text.secondary" }}
            startIcon={<PersonAddIcon sx={{ color: "third.main" }} />}
            onClick={() =>
              setInviteUser({ email: "", userRole: UserRole.Player })
            }
          >
            Add user
          </Button>
        </Stack>
      )}
      <TableContainer
        sx={{ border: `1px solid ${theme.palette.primary.main}` }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>First name</StyledTableCell>
              <StyledTableCell>Last name</StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          {admins.length && (
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCellSub colSpan={5}>Admins</StyledTableCellSub>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins.map((u, i) => (
                  <TableRow key={i}>
                    <StyledTableCell>
                      <Avatar
                        sx={{ width: 26, height: 26 }}
                        src={`${process.env.REACT_APP_API_URL}/v1.0/users/${u.id}/profile_image?authorization=${token}`}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{u.firstName}</StyledTableCell>
                    <StyledTableCell>{u.lastName}</StyledTableCell>
                    <StyledTableCell>{u.userRole}</StyledTableCell>
                    <StyledTableCell>
                      {isAdmin && (
                        <IconButton
                          disabled={!isAdmin}
                          onClick={(e) =>
                            handleOpenActionMenu(u, e.currentTarget)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
          {coaches.length && (
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCellSub colSpan={5}>Coaches</StyledTableCellSub>
                </TableRow>
              </TableHead>
              <TableBody>
                {coaches.map((u, i) => (
                  <TableRow key={i}>
                    <StyledTableCell>
                      <Avatar
                        sx={{ width: 26, height: 26 }}
                        src={`${process.env.REACT_APP_API_URL}/v1.0/users/${u.id}/profile_image?authorization=${token}`}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{u.firstName}</StyledTableCell>
                    <StyledTableCell>{u.lastName}</StyledTableCell>
                    <StyledTableCell>{u.userRole}</StyledTableCell>
                    <StyledTableCell>
                      {isAdmin && (
                        <IconButton
                          disabled={!isAdmin}
                          onClick={(e) =>
                            handleOpenActionMenu(u, e.currentTarget)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
          {players.length && (
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCellSub colSpan={5}>Players</StyledTableCellSub>
                </TableRow>
              </TableHead>
              <TableBody>
                {players.map((u, i) => (
                  <TableRow key={i}>
                    <StyledTableCell>
                      <Avatar
                        sx={{ width: 26, height: 26 }}
                        src={`${process.env.REACT_APP_API_URL}/v1.0/users/${u.id}/profile_image?authorization=${token}`}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{u.firstName}</StyledTableCell>
                    <StyledTableCell>{u.lastName}</StyledTableCell>
                    <StyledTableCell>{u.userRole}</StyledTableCell>
                    <StyledTableCell>
                      {isAdmin && (
                        <IconButton
                          onClick={(e) =>
                            handleOpenActionMenu(u, e.currentTarget)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
      {confirm && (
        <ConfirmModal {...confirm} open onClose={() => setConfirm(undefined)} />
      )}
      {editUser &&
        (currentUser.id !== editUser.id &&
        editUser.userRole === UserRole.Player ? (
          <UpdatePlayer
            user={editUser}
            onClose={() => setEditUser(undefined)}
          />
        ) : (
          <UpdateUser user={editUser} onClose={() => setEditUser(undefined)} />
        ))}
      {inviteUser && (
        <InviteUser
          invite={inviteUser}
          onClose={() => setInviteUser(undefined)}
        />
      )}
      <Menu
        disableScrollLock
        anchorEl={rowMenuProps.anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(rowMenuProps.anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleEditUser}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit user
        </MenuItem>
        <MenuItem
          onClick={handleKickUser}
          disabled={currentUser.id === rowMenuProps.user?.id}
        >
          <ListItemIcon>
            <PersonRemoveIcon fontSize="small" />
          </ListItemIcon>
          Remove from organization
        </MenuItem>
      </Menu>
    </Stack>
  );
};
