import { apiConfiguration, apiGet } from "api";
import {
  PanoramaUpdate,
  StitchPanorama,
  V1forzaApi,
  V1panoramaApi,
} from "generated/openapi";

const api = new V1panoramaApi(apiConfiguration);
const forza = new V1forzaApi(apiConfiguration);

export const stitchPanorama = async (
  forzaVideoAssetId: number,
  query: StitchPanorama,
  token: string,
) =>
  apiGet(async () =>
    api.stitchPanorama(
      { forzaVideoAssetId, stitchPanorama: query },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    ),
  );

export const setGamePanorama = async (
  id: number,
  panorama: PanoramaUpdate | undefined,
  token: string,
) =>
  apiGet(async () =>
    forza.updateForzaGame(
      {
        forzaGameId: id,
        updateForzaGame: { panorama },
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    ),
  );
