import { TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";

export interface Props {
  value?: number | null;
  size?: "small" | "medium";
  label?: string;
  onBlur?: (_?: number) => void;
}

export const OffsetInput: FC<Props> = ({
  value: _value,
  size,
  label,
  onBlur,
}) => {
  const secs =
    _value === undefined || _value === null
      ? undefined
      : Math.floor(_value / 1_000);

  const [value, setValue] = useState(secs);

  useEffect(() => setValue(secs), [secs]);

  return (
    <TextField
      focused
      size={size}
      label={label}
      variant="standard"
      sx={{
        minWidth: "4rem",
        "& .MuiInputBase-input": {
          textTransform: "capitalize",
        },
      }}
      value={value ?? ""}
      onClick={(e) => e.stopPropagation()}
      inputProps={{ style: { textAlign: "center", fontSize: "1rem" } }}
      onChange={(e) => {
        if (e.target.value) {
          const num = +e.target.value;
          if (Number.isFinite(num)) {
            setValue(num);
          }
        } else {
          setValue(undefined);
        }
      }}
      onBlur={() => onBlur?.(value === undefined ? undefined : value * 1_000)}
    />
  );
};
