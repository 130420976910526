import { apiConfiguration, apiGet } from "api";
import {
  CreateUser,
  UpdatePlayer,
  UpdateUser,
  V1playerApi,
  V1userApi,
} from "generated/openapi";

const api = new V1userApi(apiConfiguration);
const playerApi = new V1playerApi(apiConfiguration);

export interface UpdateUserArgs {
  user: UpdateUser;
  token?: string;
}

export interface UpdatePlayerArgs {
  userId: string;
  user: UpdatePlayer;
  token?: string;
}

export const getUser = async ({ token }: { token?: string }) =>
  apiGet(async () =>
    api.getUser({ headers: { Authorization: `Bearer ${token}` } }),
  );

export const createUser = async (createUser: CreateUser, token: string) =>
  apiGet(async () =>
    api.createUser(
      { createUser },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const updateUser = async (query: UpdateUserArgs) =>
  apiGet(async () =>
    api.updateUser(
      {
        updateUser: {
          ...query.user,
          birthday: query.user.birthday?.substring(
            0,
            query.user.birthday.indexOf("T"),
          ),
        },
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const deleteUser = async ({ token }: { token?: string }) =>
  apiGet(async () =>
    api.deleteUser({ headers: { Authorization: `Bearer ${token}` } }),
  );

export const updatePlayer = async (query: UpdatePlayerArgs) =>
  apiGet(async () =>
    playerApi.updatePlayer(
      {
        userId: query.userId,
        updatePlayer: {
          ...query.user,
          birthday: query.user.birthday?.substring(
            0,
            query.user.birthday.indexOf("T"),
          ),
        },
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );
