import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SettingsIcon from "@mui/icons-material/Settings";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "app/theme";
import { FC, ReactNode, useEffect, useState } from "react";
import screenfull from "screenfull";
import { hms } from "utils";

interface Props {
  duration: number;
  playing: boolean;
  played: number;
  loaded: number;
  mute: boolean;
  volume: number;
  playerDetectionLoading?: boolean;
  augmentControls?: ReactNode;
  onPlayPause: () => void;
  onFullScreen: () => void;
  onSeek: (value: number) => void;
  onSeekEnd: () => void;
  onSeekStart: () => void;
  onMute: () => void;
  onVolumeChange: (value: number) => void;
  onPlaybackRateChange: (value: number) => void;
  onPlayerDetection?: () => void;
}

const playbackSpeedOptions = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

export const Controls: FC<Props> = ({
  duration,
  playing,
  played,
  loaded,
  mute,
  volume,
  playerDetectionLoading,
  augmentControls,
  onPlayPause,
  onFullScreen,
  onSeek,
  onSeekStart,
  onSeekEnd,
  onMute,
  onVolumeChange,
  onPlaybackRateChange,
  onPlayerDetection,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [playbackSpeedIdx, setPlaybackSpeedIdx] = useState(3);
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up(600));
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  // Trigger component re-render on fullscreen change
  useEffect(() => {
    const onChange = () => setIsFullscreen(screenfull.isFullscreen);
    screenfull.on?.("change", onChange);
    return () => screenfull.off?.("change", onChange);
  });

  const handlePlaybackSpeedChange = (speed: number, idx: number) => {
    setAnchorEl(null);
    setPlaybackSpeedIdx(idx);
    onPlaybackRateChange(speed);
  };

  // Trigger loading bar change
  useEffect(() => {
    const bar = document.getElementById("progress-bar");
    const val = loaded * 100 + "%";
    bar?.style.setProperty("--w", val);
  }, [loaded]);

  return (
    <Box
      className="controls"
      sx={{
        background: mobileBreakpoint
          ? "linear-gradient(0deg, rgba(47,47,47,0.8050072079613095) 0%, rgba(47,47,47,0) 75%)"
          : "none",
        ".MuiSlider-thumb": {
          height: 15,
          width: 15,
          "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit",
          },
        },
        "& .MuiSlider-root": {
          p: 0,
        },
        "& .MuiIconButton-root": {
          width: mobileBreakpoint ? 40 : 30,
          height: mobileBreakpoint ? 40 : 30,
          "& .MuiSvgIcon-root": {
            fontSize: mobileBreakpoint ? "1.5rem" : "1.25rem",
          },
        },
        "& .MuiButtonBase-root": {
          "&:focus": {
            bgcolor: "transparent",
          },
        },
      }}
    >
      <Box
        sx={{
          px: isFullscreen ? 3 : !mobileBreakpoint ? 1 : 2,
          pb: isFullscreen ? 1 : 0,
        }}
      >
        <Box sx={{ display: "flex", alignContent: "center" }} id="progress-bar">
          <Slider
            sx={{
              alignSelf: "center",
              color: "inherit",
            }}
            min={0}
            max={duration}
            value={played}
            onChange={(e, value) => {
              if (e instanceof MouseEvent || e instanceof TouchEvent) {
                onSeek(value as number);
              }
            }}
            onKeyDown={(e) => {
              switch (e.key) {
                case "ArrowLeft":
                case "ArrowDown":
                case "PageDown":
                  onSeek(played - 5);
                  return;
                case "ArrowRight":
                case "ArrowUp":
                case "PageUp":
                  onSeek(played + 5);
                  return;
                case "Home":
                  onSeek(0);
                  return;
                case "End":
                  onSeek(duration);
              }
            }}
            onMouseDown={onSeekStart}
            onChangeCommitted={onSeekEnd}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <IconButton
              size="small"
              onClick={() => onPlayPause()}
              title={playing ? "Pause" : "Play"}
              onKeyDown={(e) => e.preventDefault()}
            >
              {playing ? <PauseIcon /> : <PlayArrowIcon fontSize="small" />}
            </IconButton>

            <IconButton
              onClick={onMute}
              title={mute ? "Unmute" : "Mute"}
              onKeyDown={(e) => e.preventDefault()}
            >
              {mute ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
            {mobileBreakpoint && (
              <>
                <Slider
                  sx={{
                    width: 100,
                    alignSelf: "center",
                    ml: 2,
                    color: "inherit",
                  }}
                  step={10}
                  value={mute ? 0 : volume * 100}
                  onChange={(_event, value, _) =>
                    onVolumeChange(value as number)
                  }
                />
              </>
            )}
            <Typography
              variant="subtitle2"
              sx={{
                // width: duration >= 3600 ? 120 : 110,
                alignSelf: "center",
                whiteSpace: "nowrap",
                textAlign: "center",
                mx: mobileBreakpoint ? 2 : 1,
                fontSize: mobileBreakpoint ? "0.875rem" : "0.75rem",
              }}
            >
              {hms(played)} / {hms(duration)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            {augmentControls}
            <IconButton
              title="Player detection"
              disabled={
                playerDetectionLoading === true || !onPlayerDetection || playing
              }
              onClick={onPlayerDetection}
              onKeyDown={(e) => e.preventDefault()}
            >
              {playerDetectionLoading === true ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <DirectionsRunIcon
                  sx={{
                    color: (playing ?? !onPlayerDetection) ? "grey " : "white",
                  }}
                />
              )}
            </IconButton>
            <IconButton
              title="Playback rate"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              onKeyDown={(e) => e.preventDefault()}
            >
              <SettingsIcon />
            </IconButton>
            <IconButton
              title="Fullscreen"
              onClick={() => onFullScreen()}
              onKeyDown={(e) => e.preventDefault()}
            >
              <FullscreenIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              disableScrollLock
              disablePortal
              MenuListProps={{ disablePadding: true }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {playbackSpeedOptions.map((speed, i) => (
                <MenuItem
                  sx={{
                    "&.Mui-selected": {
                      bgcolor: "primary.main",
                    },
                  }}
                  dense
                  key={i}
                  selected={i === playbackSpeedIdx}
                  onClick={() => handlePlaybackSpeedChange(speed, i)}
                >
                  {speed === 1 ? speed + "x (Normal)" : speed + "x"}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
