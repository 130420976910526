import {
  Playlist,
  ValuesTotalMinimalPlaylist,
  ValuesTotalPlaylistSegment,
} from "generated/openapi";

export interface PlaylistState {
  segments?: ValuesTotalPlaylistSegment;
  playlist?: Playlist;
  playlists?: ValuesTotalMinimalPlaylist;
  segmentsLoading: boolean;
  playlistsLoading: boolean;
}

export const initialPlaylistState: PlaylistState = {
  segments: undefined,
  playlist: undefined,
  playlists: undefined,
  segmentsLoading: false,
  playlistsLoading: false,
};
