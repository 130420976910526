import { getPlaylist, getPlaylistSegments, GetPlaylistSegmentsArgs } from "api";
import { FC } from "react";
import {
  createPlaylist,
  selectAccessToken,
  updatePlaylist,
  useAppDispatch,
  useAppSelector,
} from "store";
import { AddToPlaylistModal } from "./AddToPlaylistModal";

interface Props {
  open: boolean;
  total: number;
  query: GetPlaylistSegmentsArgs;
  onClose: (message?: string) => void;
}

export const AddSegmentsQueryToPlaylistModal: FC<Props> = ({
  open,
  total,
  query,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const token = useAppSelector(selectAccessToken);

  const getSegments = async () =>
    getPlaylistSegments({ ...query, limit: 250, offset: 0, token });

  return (
    <AddToPlaylistModal
      open={open}
      numToAdd={total}
      onClose={onClose}
      onCreate={(p) => {
        getSegments().then(({ values }) => {
          dispatch(
            createPlaylist({
              playlist: {
                ...p,
                segments: p.segments?.concat(
                  values.map((s) => ({
                    ...s,
                    tags: s.tags,
                  })),
                ),
              },
            }),
          ).then(() => onClose("Playlist Created"));
        });
      }}
      onUpdate={(p) => {
        Promise.all([
          getPlaylist({ playlistId: p.id, token }),
          getSegments(),
        ]).then(([p, { values }]) => {
          dispatch(
            updatePlaylist({
              playlist: {
                ...p,
                segments: values.concat(p.segments),
              },
            }),
          ).then(() => onClose("Added To Playlist"));
        });
      }}
    />
  );
};
