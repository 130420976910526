import { Tabs } from "components";
import { UserRole } from "generated/openapi";
import { FC } from "react";
import { selectCurrentUserRole, useAppSelector } from "store";
import { Playlists } from "./Playlists";
import { Segments } from "./Segments";

const TABS = [
  {
    key: "Playlists",
    Element: Playlists,
  },
  {
    key: "Segments",
    Element: Segments,
  },
];

export const PlaylistsView: FC = () => {
  const userRole = useAppSelector(selectCurrentUserRole)!;

  if (userRole === UserRole.Player) {
    return <Playlists />;
  }
  return <Tabs tabs={TABS} />;
};
