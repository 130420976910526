import { Button, TextField, Typography } from "@mui/material";
import { Modal } from "components";
import { CreateTeam as Team } from "generated/openapi";
import { FC } from "react";
import { Form, useForm } from "react-hook-form";
import { createTeam, useAppDispatch } from "store";

export interface Props {
  onClose: () => void;
}

export const CreateTeam: FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const {
    control,
    register,
    formState: { errors },
  } = useForm<Team>();

  return (
    <Modal open={true} onClose={onClose} sx={{ p: 3 }}>
      <Form
        control={control}
        style={{ display: "flex", flexDirection: "column", gap: 8, width: 300 }}
        onSubmit={({ data }) => {
          dispatch(createTeam({ team: data }));
          onClose();
        }}
      >
        <Typography sx={{ pb: 1 }} variant="h6">
          Create Team
        </Typography>

        <TextField
          autoFocus
          spellCheck={false}
          color="third"
          label="Name"
          variant="outlined"
          error={!!errors.name}
          {...register("name", { required: true })}
        />

        <Button color="third" type="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
