import {
  Box,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  MenuProps,
} from "@mui/material";
import { HomographyResult, PlayerTeam } from "generated/openapi";
import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "konva/lib/types";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Circle, Line, Rect } from "react-konva";
import { Html } from "react-konva-utils";
import { useImmer } from "use-immer";
import {
  FootballField,
  HEIGHT_FACTOR,
  Props as BaseProps,
} from "./FootballField";

export interface Props extends Partial<BaseProps> {
  homography: HomographyResult;
  onChange?: (_: HomographyResult) => void;
}

export const FootballFieldHomography: FC<Props> = ({
  homography: _homography,
  onChange,
  ...baseProps
}) => {
  const boxRef = useRef<HTMLElement | null>(null);

  const [width, setWidth] = useState<number>(1);
  const [homography, setHomography] = useImmer<HomographyResult>(_homography);

  useEffect(() => setHomography(_homography), [_homography]);

  useEffect(() => {
    if (!boxRef.current) return;
    const fn = () => setWidth(boxRef.current!.offsetWidth);
    fn();
    window.addEventListener("resize", fn);
    return () => {
      window.removeEventListener("resize", fn);
    };
  }, [boxRef.current]);

  const height = width * HEIGHT_FACTOR;

  const players = useMemo(
    () =>
      homography.players.map((p) => ({
        x: p.xPercentage * width,
        y: p.yPercentage * height,
        team: p.team ?? undefined,
      })),
    [homography.players, width],
  );

  const convertPlayers = (players: Player[]) =>
    players.map((p) => ({
      ...p,
      xPercentage: p.x / width,
      yPercentage: p.y / height,
    }));

  return (
    <Box
      ref={boxRef}
      sx={{
        position: "relative",
        width: "100%",
        height,
      }}
    >
      <Box sx={{ position: "absolute", inset: 0 }}>
        <FootballFieldPlayersInner
          {...baseProps}
          width={width}
          players={players}
          ballIdx={homography.ballIdx ?? undefined}
          onChange={(_players, ballIdx) => {
            const players = convertPlayers(_players);
            setHomography((draft) => {
              draft.players = players;
              draft.ballIdx = ballIdx;
            });
            onChange?.({ ...homography, players, ballIdx });
          }}
          onPlayersChange={(_players) => {
            const players = convertPlayers(_players);
            setHomography((draft) => {
              draft.players = players;
            });
            onChange?.({ ...homography, players });
          }}
          onBallIdxChange={(ballIdx) => {
            setHomography((draft) => {
              draft.ballIdx = ballIdx;
            });
            onChange?.({ ...homography, ballIdx });
          }}
        />
      </Box>
    </Box>
  );
};

interface AnchorState {
  x: number;
  y: number;
  playerIdx?: number;
  regionIdx?: number;
}

interface Region {
  playerIdxes: number[];
  pos?: Vector2d;
}

interface RegionsState {
  drawing: boolean;
  regions: Region[];
}

interface Player {
  x: number;
  y: number;
  team?: PlayerTeam;
}

interface PassLine {
  points: number[];
  opacity: number;
}

interface PropsInner extends BaseProps {
  players: Player[];
  ballIdx?: number;
  onChange: (players: Player[], ballIdx?: number) => void;
  onPlayersChange: (_: Player[]) => void;
  onBallIdxChange: (_?: number) => void;
}

const FootballFieldPlayersInner: FC<PropsInner> = ({
  players,
  ballIdx,
  onChange,
  onPlayersChange,
  onBallIdxChange,
  ...baseProps
}) => {
  const width = baseProps.width;
  const height = width * HEIGHT_FACTOR;

  const anchorRef = useRef(null);

  const [anchor, setAnchor] = useState<AnchorState | undefined>(undefined);
  const [regionsState, setRegionsState] = useImmer<RegionsState>({
    drawing: false,
    regions: [],
  });

  const { drawing: drawingRegion, regions } = regionsState;

  const lines = useMemo(() => {
    if (ballIdx === undefined) return;

    const { x, y, team } = players[ballIdx];

    const teammates = players.filter((p) => p.team === team);
    const opponents = players.filter((p) => p.team !== team);

    const lines: PassLine[] = [];

    outer: for (const p of teammates) {
      const points = [x, y, p.x, p.y];
      const passDist = distance(x, y, p.x, p.y);
      let opacity = 1;

      for (const o of opponents) {
        const interceptDist = point2LineDist(points, o.x, o.y);
        if (passDist > interceptDist * 3) {
          continue outer;
        }
        opacity = Math.min(opacity, interceptDist / passDist);
      }

      lines.push({ points, opacity });
    }

    return lines;
  }, [players, ballIdx]);

  const updatePlayer = (
    idx: number,
    update: Partial<Player> | ((_: Player) => Partial<Player>),
  ) => {
    Object.assign(
      players[idx],
      typeof update === "function" ? update(players[idx]) : update,
    );
    onPlayersChange(players);
  };

  const addPlayer = (player: Player) => onPlayersChange([...players, player]);

  const deletePlayer = (idx: number) => {
    setRegionsState((draft) => {
      draft.regions = draft.regions.map((r) => ({
        ...r,
        playerIdxes: r.playerIdxes
          .filter((i) => i !== idx)
          .map((i) => (i > idx ? i - 1 : i)),
      }));
    });

    if (ballIdx !== undefined) {
      if (ballIdx === idx) {
        ballIdx = undefined;
      } else if (ballIdx > idx) {
        ballIdx -= 1;
      }
    }

    players.splice(idx, 1);
    onChange(players, ballIdx);
  };

  const onEndRegion = () =>
    setRegionsState((draft) => {
      draft.drawing = false;
      draft.regions.last()!.pos = undefined;
    });

  const onMenuClose = () => setAnchor(undefined);

  const Menu = ({ children, ...props }: Partial<MenuProps>) => (
    <MuiMenu
      open
      disableScrollLock
      anchorEl={anchorRef.current}
      onClose={onMenuClose}
      {...props}
    >
      {children}
    </MuiMenu>
  );

  const MenuItem = ({ onClick, children, ...props }: MenuItemProps) => (
    <MuiMenuItem
      onClick={(e) => {
        onClick?.(e);
        onMenuClose();
      }}
      {...props}
    >
      {children}
    </MuiMenuItem>
  );

  const Lines = useMemo(
    () =>
      lines?.map(({ points, opacity }, i) => (
        <Line
          key={i}
          points={points}
          stroke="#ffffff"
          opacity={opacity}
          strokeWidth={2}
        />
      )),
    [lines],
  );

  const Regions = useMemo(
    () =>
      regions.map(({ playerIdxes, pos }, i) => {
        const points = playerIdxes.flatMap((i) => [players[i].x, players[i].y]);
        if (pos) {
          points.push(pos.x);
          points.push(pos.y);
        }
        return (
          <Line
            closed
            key={i}
            points={points}
            fill="#ffffff44"
            stroke="#ffffff44"
            strokeWidth={2}
            onContextMenu={(e) => {
              e.evt.preventDefault();
              setAnchor({ x: e.evt.offsetX, y: e.evt.offsetY, regionIdx: i });
            }}
          />
        );
      }),
    [regions, players],
  );

  return (
    <FootballField
      {...baseProps}
      backgroundColor="#00000000"
      width={width}
      onMouseMove={(e) => {
        if (drawingRegion && regions.length) {
          setRegionsState((draft) => {
            draft.regions.last()!.pos = { x: e.evt.offsetX, y: e.evt.offsetY };
          });
        }
      }}
    >
      <Rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill="#00000088"
        onContextMenu={(e) => {
          e.evt.preventDefault();
          setAnchor({ x: e.evt.offsetX, y: e.evt.offsetY });
        }}
        onDblTap={(e: KonvaEventObject<TouchEvent>) => {
          e.evt.preventDefault();
          const pos = e.target.getStage()?.getPointerPosition();
          if (pos) {
            setAnchor(pos);
          }
        }}
      />
      {Lines}
      {Regions}
      {players.map((p, i) => (
        <Circle
          draggable
          key={i}
          x={p.x}
          y={p.y}
          radius={width * 0.01 + 2}
          fill={p.team === PlayerTeam.A ? "#00f" : "#0f0"}
          stroke="red"
          strokeWidth={i === ballIdx ? width * 0.002 + 1 : 0}
          onDragMove={(e) => updatePlayer(i, e.target.position())}
          onTap={() => {
            if (drawingRegion) {
              setRegionsState((draft) => {
                if (draft.regions.length) {
                  draft.regions.last()!.playerIdxes.push(i);
                } else {
                  draft.regions.push({ playerIdxes: [i] });
                }
              });
            } else {
              onBallIdxChange(i !== ballIdx ? i : undefined);
            }
          }}
          onClick={(e) => {
            if (e.evt.button !== 0) return;

            if (drawingRegion) {
              setRegionsState((draft) => {
                if (draft.regions.length) {
                  draft.regions.last()!.playerIdxes.push(i);
                } else {
                  draft.regions.push({ playerIdxes: [i] });
                }
              });
            } else {
              onBallIdxChange(i !== ballIdx ? i : undefined);
            }
          }}
          onContextMenu={(e) => {
            e.evt.preventDefault();
            setAnchor({ ...e.target.position(), playerIdx: i });
          }}
          onDblTap={(e: KonvaEventObject<TouchEvent>) => {
            e.evt.preventDefault();
            setAnchor({ ...e.target.position(), playerIdx: i });
          }}
        />
      ))}
      <Html>
        <Box
          ref={anchorRef}
          sx={{
            position: "absolute",
            visibility: "hidden",
            top: anchor?.y ?? 0,
            left: anchor?.x ?? 0,
          }}
        />
        {anchorRef.current &&
          anchor &&
          (anchor.playerIdx !== undefined ? (
            <Menu>
              {drawingRegion ? (
                <MenuItem onClick={onEndRegion}>End Region</MenuItem>
              ) : (
                <MenuItem
                  onClick={() =>
                    setRegionsState((draft) => {
                      draft.drawing = true;
                      draft.regions.push({ playerIdxes: [anchor.playerIdx!] });
                    })
                  }
                >
                  Start Region
                </MenuItem>
              )}
              <MenuItem
                onClick={() =>
                  updatePlayer(anchor.playerIdx!, (p) => ({
                    team: p.team === PlayerTeam.A ? PlayerTeam.B : PlayerTeam.A,
                  }))
                }
              >
                Switch Team
              </MenuItem>
              <MenuItem onClick={() => deletePlayer(anchor.playerIdx!)}>
                Delete Player
              </MenuItem>
            </Menu>
          ) : (
            <Menu>
              {drawingRegion && (
                <MenuItem onClick={onEndRegion}>End Region</MenuItem>
              )}
              {anchor.regionIdx !== undefined && (
                <MenuItem
                  onClick={() =>
                    setRegionsState((draft) => {
                      draft.regions.splice(anchor.regionIdx!, 1);
                    })
                  }
                >
                  Delete Region
                </MenuItem>
              )}
              <MenuItem
                onClick={() => addPlayer({ ...anchor, team: PlayerTeam.A })}
              >
                Add Player A
              </MenuItem>
              <MenuItem
                onClick={() => addPlayer({ ...anchor, team: PlayerTeam.B })}
              >
                Add Player B
              </MenuItem>
            </Menu>
          ))}
      </Html>
    </FootballField>
  );
};

const distance = (x1: number, y1: number, x2: number, y2: number): number => {
  const dx = x2 - x1;
  const dy = y2 - y1;
  return Math.sqrt(dx * dx + dy * dy);
};

// Source: https://stackoverflow.com/a/6853926/14517591
const point2LineDist = (line: number[], x: number, y: number) => {
  const a = x - line[0];
  const b = y - line[1];
  const c = line[2] - line[0];
  const d = line[3] - line[1];

  const dot = a * c + b * d;
  const lenSq = c * c + d * d;
  const param = lenSq === 0 ? -1 : dot / lenSq;

  let xx, yy;

  if (param < 0) {
    xx = line[0];
    yy = line[1];
  } else if (param > 1) {
    xx = line[2];
    yy = line[3];
  } else {
    xx = line[0] + param * c;
    yy = line[1] + param * d;
  }

  return distance(xx, yy, x, y);
};
