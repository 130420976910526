import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import {
  alpha,
  Autocomplete as MuiAutocomplete,
  autocompleteClasses,
  Box,
  Checkbox,
  Popper,
  styled,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" sx={{ color: "white" }} />;

interface Props<T> {
  value: T[];
  options: T[];
  placeholder: string;
  disabled?: boolean;
  disablePortal?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
  variant?: "filled" | "outlined" | "standard";
  getOptionLabel: (_: T) => string;
  getOptionDisabled?: (_: T) => boolean;
  onChange?: (_: T[]) => void;
}

export function ChipAutocomplete<T>({
  value,
  options,
  placeholder,
  disabled,
  disablePortal,
  sx,
  color,
  variant,
  getOptionLabel,
  getOptionDisabled,
  onChange,
}: Props<T>) {
  const readOnlyStyle: SxProps<Theme> | undefined =
    onChange !== undefined
      ? undefined
      : {
          "& .MuiInputBase-root": {
            ":before": {
              content: "none",
            },
          },
          "& .MuiInput-root": {
            cursor: "default",
          },
          input: {
            cursor: "auto",
          },
        };

  return (
    <Box
      sx={{
        width: "100%",
        "& .MuiChip-filled": {
          color: "white",
          bgcolor: color ?? "third.dark",
        },

        "& .MuiAutocomplete-root": {
          "& .MuiInput-root": {
            "&.MuiInputBase-sizeSmall": {
              "& .MuiInput-input": {
                pl: 0.5,
              },
            },
          },
        },
        ...sx,
        ...readOnlyStyle,
      }}
    >
      <MuiAutocomplete
        multiple
        disabled={disabled}
        disablePortal={disablePortal ?? true}
        disableListWrap
        disableCloseOnSelect
        readOnly={!onChange}
        size="small"
        limitTags={3}
        PopperComponent={StyledPopper}
        ChipProps={{ deleteIcon: <DisabledByDefaultIcon /> }}
        value={value}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={onChange ? (_, value) => onChange(value) : undefined}
        onKeyDown={(e) => e.stopPropagation()}
        getOptionDisabled={getOptionDisabled}
        renderOption={({ key, ...props }, option, { selected }) => (
          <li key={key} {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{
                margin: "0px 4px 0px 4px",
              }}
              checked={selected}
            />
            {getOptionLabel(option)}
          </li>
        )}
        renderInput={(params: any) => (
          <TextField
            variant={variant}
            {...params}
            placeholder={placeholder}
            InputProps={
              onChange
                ? params.InputProps
                : { ...params.InputProps, endAdornment: undefined }
            }
          />
        )}
      />
    </Box>
  );
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    "& .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: alpha(theme.palette.primary.main, 0.6),
      "&.Mui-focused": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    "& .MuiAutocomplete-option": {
      paddingLeft: 0,
      paddingRight: "4px",
    },
  },
  [`& .${autocompleteClasses.option}`]: {
    borderBottom: "1px solid",
    borderColor: theme.palette.primary.main,
    color: "white",
    "& .MuiTypography-caption": { color: "grey" },
    paddingRight: "4px",
  },
  [`& .${autocompleteClasses.paper}`]: {
    borderRadius: 0,
    marginRight: "1px",
    marginLeft: "1px",
  },
}));
