import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  SxProps,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "app/theme";
import { LiveMessage } from "generated/openapi";
import { FC, useEffect, useState } from "react";
import { createLiveMessage, useAppDispatch } from "store";
import { dateFormat } from "utils";

export interface Props {
  liveStreamId: number;
  disabled: boolean;
  messages: LiveMessage[];
  colorMap: Record<string, string>;
  sx?: SxProps;
}

export const LiveMessages: FC<Props> = ({
  liveStreamId,
  disabled,
  messages,
  colorMap,
  sx,
}) => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState("");
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up("xl"));

  const onSend = () => {
    if (message) {
      dispatch(createLiveMessage({ liveStreamId, message: { message } }));
      setMessage("");
    }
  };
  // Scroll to bottom of chat when new message appears
  useEffect(() => {
    const chat = document.getElementById("chat-box");
    if (chat) {
      chat.scrollTo(0, chat.scrollHeight);
    }
  }, [messages]);

  // Scroll to bottom of chat on window size change
  useEffect(() => {
    const handleWindowSizeChange = () => {
      const chat = document.getElementById("chat-box");
      if (chat) {
        chat.scrollTo(0, chat.scrollHeight);
      }
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <Stack
      gap={2}
      sx={{
        ...sx,
        p: 2,
        border: "1px solid #5B5B5B",
      }}
    >
      <Stack
        id="chat-box"
        gap={1}
        sx={{ overflowY: "auto", height: xlBreakpoint ? "69vh" : 500 }}
      >
        {messages.map((m) => {
          return (
            <Box key={m.id}>
              <Typography
                sx={{ pr: 1 }}
                variant="caption"
                color="text.secondary"
                component="span"
              >
                {dateFormat(m.timestamp, "HH:mm")}
              </Typography>
              <Typography
                component="span"
                sx={{
                  color: m.user ? colorMap[m.user.id] : "#CBCBCB",
                  pr: 1,
                }}
              >
                {(m.user ? m.user.firstName : "Unknown") + ":"}
              </Typography>
              <Typography sx={{ whiteSpace: "pre-line" }} component="span">
                {m.message}
              </Typography>
            </Box>
          );
        })}
      </Stack>
      {!disabled && (
        <>
          <Divider sx={{ bgcolor: "primary.light" }} />
          <Stack direction="row" alignItems={"center"}>
            <TextField
              autoFocus
              multiline
              spellCheck={false}
              color="info"
              placeholder="Send a message"
              variant="outlined"
              sx={{ width: "100%" }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (!e.shiftKey && e.key === "Enter") {
                  onSend();
                  e.preventDefault();
                }
              }}
            />
            <IconButton
              sx={{ ml: 1 }}
              size="large"
              color="inherit"
              onClick={onSend}
              disabled={!message}
            >
              <SendIcon />
            </IconButton>
          </Stack>
        </>
      )}
    </Stack>
  );
};
