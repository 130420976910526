import { apiConfiguration, apiGet } from "api";
import { V1playerDetectionApi, VideoOffset } from "generated/openapi";

const api = new V1playerDetectionApi(apiConfiguration);

export const getPlayerDetection = async (image: string) =>
  apiGet(async () => api.playerDetection({ playerDetection: { image } }));

export const getPlayerHomography = async (
  gameId: number,
  image: string,
  videoOffset?: VideoOffset,
) =>
  apiGet(async () =>
    api.homography({ gameId, homography: { image, videoOffset } }),
  );
