import { createSelector } from "@reduxjs/toolkit";
import { UserRole } from "generated/openapi";
import { selectOrg } from "store/org";
import { selectAppState } from "store/selectAppState";
import { selectUser } from "store/user";

export const selectTeams = createSelector(selectOrg, (state) => state?.teams);

export const selectSelectedTeam = createSelector(
  selectAppState,
  (state) => state.selectedTeam,
);

export const selectTeamsSorted = createSelector(selectTeams, (state) =>
  state ? [...state].sort((a, b) => a.name.localeCompare(b.name)) : undefined,
);

export const selectTeamsMap = createSelector(selectTeams, (state) =>
  state ? Object.fromEntries(state.map((t) => [t.id, t])) : undefined,
);

export const selectTeam = (id: number) =>
  createSelector(selectTeamsMap, (state) => state?.[id]);

export const selectTeamPlayers = (id: number) =>
  createSelector(selectTeam(id), (state) => state?.players);

export const selectTeamPlayersMap = (id: number) =>
  createSelector(selectTeamPlayers(id), (state) =>
    state ? Object.fromEntries(state.map((v) => [v.id, v])) : undefined,
  );

export const selectPlayersMap = createSelector(
  selectUser,
  selectTeams,
  (user, teams) => {
    if (!user || !teams) return undefined;
    if (user.userRole === UserRole.Admin) {
      teams = teams.filter((t) => t.admins.some((a) => a.id === user.id));
    }
    return Object.fromEntries(
      teams.flatMap((t) => t.players.map((p) => [p.id, p])),
    );
  },
);

export const selectPlayers = createSelector(selectPlayersMap, (state) =>
  state ? Object.values(state) : undefined,
);

export const selectPlayersSorted = createSelector(selectPlayers, (state) =>
  state
    ? state.sort((a, b) =>
        `${a.firstName} ${a.lastName}`.localeCompare(
          `${b.firstName} ${b.lastName}`,
        ),
      )
    : undefined,
);

export const selectPlayer = (id: string) =>
  createSelector(selectPlayersMap, (state) => state?.[id]);
