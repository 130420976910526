import { FC } from "react";
import { Rect } from "react-konva";
import {
  FootballField,
  HEIGHT_FACTOR,
  Props as BaseProps,
} from "./FootballField";

const WIDTH = 6;
const HEIGHT = 6;

const grids = Array.from({ length: WIDTH }).map((_, x) =>
  Array.from({ length: HEIGHT }).map((_, y) => ({ x, y })),
);

export interface Grid {
  x: number;
  y: number;
}

export interface Props extends BaseProps {
  selectedGrids: Grid[];
  onChange: (_: Grid[]) => void;
}

export const FootballFieldGrid: FC<Props> = ({
  selectedGrids,
  onChange,
  ...baseProps
}) => {
  const gridsW = baseProps.width / WIDTH;
  const height = baseProps.width * HEIGHT_FACTOR;
  const gridsH = height / HEIGHT;

  return (
    <FootballField {...baseProps}>
      {selectedGrids &&
        grids.map((g) =>
          g.map((grid) => {
            const { x, y } = grid;
            const idx = selectedGrids.findIndex((s) => s.x === x && s.y === y);
            return (
              <Rect
                key={`${x}${y}`}
                x={x * gridsW}
                y={y * gridsH}
                width={gridsW}
                height={gridsH}
                fill={idx >= 0 ? "#175316" : undefined}
                stroke="black"
                strokeWidth={2}
                onMouseEnter={(e) => {
                  if (!onChange) return;
                  const stage = e.target.getStage();
                  stage && (stage.container().style.cursor = "pointer");
                }}
                onMouseLeave={(e) => {
                  if (!onChange) return;
                  const stage = e.target.getStage();
                  stage && (stage.container().style.cursor = "default");
                }}
                onClick={() => {
                  if (!onChange) return;

                  if (idx >= 0) {
                    selectedGrids.splice(idx, 1);
                  } else {
                    selectedGrids.push(grid);
                  }
                  onChange([...selectedGrids]);
                }}
              />
            );
          }),
        )}
    </FootballField>
  );
};
