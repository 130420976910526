import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";

export const getLiveStreams = createAsyncThunk(
  "liveStream/getLiveStream",
  Api.getLiveStreams,
);

export const createLiveStream = createAsyncThunk(
  "liveStream/createLiveStream",
  Api.createLiveStream,
);

export const createLiveSegment = createAsyncThunk(
  "liveStream/createLiveSegment",
  Api.createLiveSegment,
);

export const updateLiveTacticalPitch = createAsyncThunk(
  "liveStream/updateLiveTacticalPitch",
  Api.updateLiveTacticalPitch,
);

export const setLiveMode = createAsyncThunk(
  "liveStream/setLiveMode",
  Api.setLiveMode,
);

export const setLiveThumbnail = createAsyncThunk(
  "liveStream/setLiveThumbnail",
  Api.setLiveThumbnail,
);

export const updateLiveSegment = createAsyncThunk(
  "liveStream/updateLiveSegment",
  Api.updateLiveSegment,
);

export const deleteLiveSegment = createAsyncThunk(
  "liveStream/deleteLiveSegment",
  Api.deleteLiveSegment,
);

export const sendLiveSegment = createAsyncThunk(
  "liveStream/sendLiveSegment",
  Api.sendLiveSegment,
);

export const createLiveMessage = createAsyncThunk(
  "liveStream/createLiveMessage",
  Api.createLiveMessage,
);
