import { Box, useMediaQuery } from "@mui/material";
import {
  getPlayerDetection,
  getPlayerHomography,
} from "api/playerDetectionApi";
import theme from "app/theme";
import { PlayerTeam, VideoOffset } from "generated/openapi";
import {
  CSSProperties,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import screenfull from "screenfull";
import { Controls } from "./Controls";
import "./VideoPlayer.css";

export interface Props {
  url?: string;
  width?: number | string;
  style?: CSSProperties;
  playing?: boolean;
  progressInterval?: number;
  children?: ReactNode;
  augmentControls?: ReactNode;
  controlFullscreenId?: boolean;
  clickToPlay?: boolean;
  onProgress?: (_: OnProgressProps) => void;
  onSeek?: (secs: number) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onStart?: () => void;
  onEnded?: () => void;
  onDuration?: (_: number) => void;
}

export const VideoPlayer = forwardRef(
  (
    {
      url,
      width,
      style,
      playing: _playing,
      progressInterval,
      children,
      augmentControls,
      controlFullscreenId,
      clickToPlay,
      onProgress,
      onSeek,
      onPlay: _onPlay,
      onPause: _onPause,
      onStart,
      onEnded,
      onDuration,
    }: Props,
    ref,
  ) => {
    // Localhost defaults to the lowest bitrate, so hardcode a very high value.
    // Does not affect actual deployments.
    if (window.location.hostname === "localhost") {
      url = url?.replace(/\/Manifest.m3u8$/, "/10000000.m3u8");
    }

    width = width ?? "100%";
    const playerRef = useRef<ReactPlayer>(null);

    const videoPlayerRef = useMemo(
      () => (playerRef.current ? new VideoPlayerRef(playerRef.current) : null),
      [playerRef.current],
    );
    useImperativeHandle(ref, () => videoPlayerRef, [videoPlayerRef]);

    const [videoState, setVideoState] = useState({
      playing: _playing ?? false,
      muted: true,
      volume: 0.5,
      playbackRate: 1.0,
      loaded: 0,
      seeking: false,
      buffer: true,
      playerDetectionLoading: false,
    });

    const isMobile = useMediaQuery(theme.breakpoints.down(900));

    const updateVideoState = (state: Partial<typeof videoState>) =>
      setVideoState((s) => ({ ...s, ...state }));

    const endedVideoHandler = () => {
      updateVideoState({ playing: false });
      onEnded?.();
    };

    // Set to fullscreen on mobile of screen orientation changes
    useEffect(() => {
      const handleOrientationChange = (e: any) => {
        const video = document.getElementById("video-container")!;
        if (
          e.target.type === "landscape-primary" &&
          screenfull.isEnabled &&
          !screenfull.isFullscreen
        ) {
          screenfull.request(video);
        } else if (
          e.target.type === "portrait-primary" &&
          screenfull.isEnabled &&
          screenfull.isFullscreen
        ) {
          screenfull.exit();
        }
      };

      screen.orientation.addEventListener("change", handleOrientationChange);

      return () =>
        screen.orientation.removeEventListener(
          "change",
          handleOrientationChange,
        );
    }, []);

    const onPlay = () => {
      updateVideoState({ playing: true });
      videoPlayerRef?.clearCanvas();
      _onPlay?.();
    };

    const onPause = () => {
      updateVideoState({ playing: false });
      _onPause?.();
    };

    const togglePlaying = () => {
      videoState.playing ? onPause() : onPlay();
    };

    const toggleFullScreen = () => {
      const video = document.getElementById("video-container")!;
      screenfull.toggle?.(video);
    };

    const progressHandler = (state: OnProgressProps) => {
      if (!seeking) {
        updateVideoState(state);
      }
      onProgress?.(state);
    };

    useEffect(() => {
      const fn = (event: KeyboardEvent) => {
        if (event.code === "Space") {
          togglePlaying();
        } else if (event.code === "KeyM") {
          setVideoState((s) => ({ ...s, muted: !s.muted }));
        }
      };
      window.addEventListener("keydown", fn);
      return () => window.removeEventListener("keydown", fn);
    }, [videoState.playing]);

    useEffect(() => {
      if (_playing !== undefined) {
        updateVideoState({ playing: _playing });
      }
    }, [_playing]);

    const {
      playing,
      muted,
      volume,
      playbackRate,
      seeking,
      loaded,
      playerDetectionLoading,
    } = videoState;

    const currentTime = videoPlayerRef?.getCurrentSecs() ?? 0;
    const duration = videoPlayerRef?.getDuration() ?? 0;

    return (
      <Box
        id={controlFullscreenId ? "video-container" : undefined}
        sx={{
          width,
          display: "flex",
          position: controlFullscreenId ? undefined : "relative",
          height:
            screenfull.isFullscreen && isMobile
              ? "100%"
              : screenfull.isFullscreen
                ? "fit-content"
                : undefined,
        }}
      >
        <Box sx={{ width }} onClick={() => clickToPlay && togglePlaying()}>
          <ReactPlayer
            id="react-player"
            style={{
              background: "rgba(0, 0, 0, .1)",
              marginBlock: "auto",
              lineHeight: 0,
              ...style,
            }}
            ref={playerRef}
            playbackRate={playbackRate}
            url={url}
            width={width}
            height="fit-content"
            volume={volume}
            muted={muted}
            playing={playing}
            progressInterval={progressInterval}
            onProgress={progressHandler}
            onSeek={(v) => {
              videoPlayerRef?.clearCanvas();
              onSeek?.(v);
            }}
            onStart={onStart}
            onEnded={endedVideoHandler}
            onDuration={onDuration}
          />
        </Box>
        <Controls
          duration={duration}
          playing={playing}
          played={currentTime}
          loaded={loaded}
          volume={volume}
          mute={muted}
          playerDetectionLoading={playerDetectionLoading}
          augmentControls={augmentControls}
          onPlayPause={togglePlaying}
          onFullScreen={toggleFullScreen}
          onSeek={(played: number) => {
            updateVideoState({ seeking: true });
            playerRef.current!.seekTo(played, "seconds");
          }}
          onSeekEnd={() => updateVideoState({ seeking: false })}
          onSeekStart={() => updateVideoState({ seeking: true })}
          onVolumeChange={(v) =>
            updateVideoState({ volume: v / 100, muted: v === 0 })
          }
          onPlaybackRateChange={(v) => updateVideoState({ playbackRate: v })}
          onPlayerDetection={() => {
            if (videoPlayerRef) {
              updateVideoState({ playerDetectionLoading: true });
              videoPlayerRef
                .detectPlayers()
                .then(() =>
                  updateVideoState({ playerDetectionLoading: false }),
                );
            }
          }}
          onMute={() => updateVideoState({ muted: !muted })}
        />
        {children}
      </Box>
    );
  },
);

export class VideoPlayerRef {
  player: ReactPlayer;
  element: HTMLElement;
  canvas: HTMLCanvasElement;

  constructor(player: ReactPlayer) {
    this.player = player;
    this.element = document.getElementById("react-player")!;
    this.canvas = document.createElement("canvas");
    this.canvas.style.position = "absolute";
    this.canvas.style.width = "100%";
    this.canvas.style.top = "50%";
    this.canvas.style.left = "0px";
    this.canvas.style.transform = "translateY(-50%)";
    this.canvas.style.pointerEvents = "none";
    this.element.appendChild(this.canvas);
  }

  get width() {
    return this.element.offsetWidth;
  }

  get height() {
    return this.element.offsetHeight;
  }

  get marginTop() {
    const style = getComputedStyle(this.element);
    return style.marginBlockStart;
  }

  getCurrentSecs(): number {
    return this.player.getCurrentTime();
  }

  getCurrentMillis(): number {
    return Math.round(this.player.getCurrentTime() * 1_000);
  }

  getCurrentMicros(): number {
    return Math.round(this.player.getCurrentTime() * 1_000_000);
  }

  getDuration(): number {
    const duration = this.player.getDuration();
    return Number.isFinite(duration) ? duration : 0;
  }

  seekTo(micros: number) {
    this.seekToSecs(micros / 1_000_000);
  }

  seekToSecs(secs: number) {
    this.player.seekTo(secs, "seconds");
  }

  getCurrentFrame(scaledWidth?: number): string {
    const video = this.element.getElementsByTagName("video")[0];

    const canvas = document.createElement("canvas");

    if (scaledWidth === undefined) {
      canvas.width = video.offsetWidth;
      canvas.height = video.offsetHeight;
    } else {
      const scale = scaledWidth / video.offsetWidth;
      canvas.width = scaledWidth;
      canvas.height = video.offsetHeight * scale;
    }

    const ctx = canvas.getContext("2d")!;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    const res = canvas.toDataURL("image/png");
    canvas.remove();
    return res;
  }

  async getPlayerDetection() {
    const image = this.getCurrentFrame();
    return getPlayerDetection(image.substring("data:image/png;base64,".length));
  }

  async getPlayerHomography(gameId: number, videoOffset?: VideoOffset) {
    const image = this.getCurrentFrame();
    return getPlayerHomography(
      gameId,
      image.substring("data:image/png;base64,".length),
      videoOffset,
    );
  }

  async detectPlayers(): Promise<void> {
    return this.getPlayerDetection().then((rects) => {
      if (this.player.props.playing) return;

      this.canvas.width = this.width;
      this.canvas.height = this.height;

      const ctx = this.canvas.getContext("2d")!;
      ctx.lineWidth = 2;
      for (const r of rects) {
        ctx.strokeStyle = r.team === PlayerTeam.A ? "blue" : "red";
        ctx.strokeRect(r.x1, r.y1, r.x2 - r.x1, r.y2 - r.y1);
      }
    });
  }

  clearCanvas() {
    const ctx = this.canvas.getContext("2d")!;
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
}
