import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {
  Box,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
} from "@mui/material";
import theme from "app/theme";
import { PlaylistTag } from "generated/openapi";
import { FC, useState } from "react";
import {
  createPlaylistTag,
  deletePlaylistTag,
  getPlaylistTags,
  selectPlaylistTagsSorted,
  updatePlaylistTag,
  useAppDispatch,
  useAppSelector,
} from "store";

export const PlaylistTagsSettings: FC = () => {
  const tags = useAppSelector(selectPlaylistTagsSorted);
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState<number>(-1);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [tagError, setTagError] = useState<boolean>(false);

  const validateTag = (name: string, self?: PlaylistTag) => {
    if (tags?.some((t) => t.name === name && t.id !== self?.id)) {
      setTagError(true);
    } else {
      setTagError(false);
    }
  };

  const handleAddTag = (name: string) => {
    if (name !== "" && !tagError) {
      dispatch(
        createPlaylistTag({
          tag: { name },
        }),
      ).then(() => {
        dispatch(getPlaylistTags({}));
      });
    }
    setIsAdding(false);
    setTagError(false);
  };

  const handleDeleteTag = (tag: PlaylistTag) => {
    dispatch(deletePlaylistTag({ tagId: tag.id })).then(() => {
      dispatch(getPlaylistTags({}));
    });
  };

  const handleTagEdit = (name: string, tag: PlaylistTag) => {
    if (name !== tag.name && !tagError) {
      dispatch(
        updatePlaylistTag({
          tagId: tag.id,
          tag: { name },
        }),
      );
    }
    setTagError(false);
    setEdit(-1);
  };

  return (
    <Box sx={{ width: 500 }}>
      <List sx={{ py: 0 }}>
        {!!tags?.length && (
          <>
            {tags.map((tag, i) => (
              <ListItem
                key={i}
                sx={{
                  py: 1.5,
                  justifyContent: "space-between",
                  borderBottom: `1px solid ${theme.palette.fourth.dark}`,
                  "&:first-of-type": {
                    borderTop: `1px solid ${theme.palette.fourth.dark}`,
                  },
                }}
              >
                <Box sx={{ display: "flex", width: "100%" }}>
                  <ListItemIcon
                    sx={{ alignSelf: "center", minWidth: 0, pr: 3 }}
                  >
                    <LocalOfferIcon
                      fontSize="small"
                      sx={{ color: "fourth.light" }}
                    />
                  </ListItemIcon>
                  {edit === i ? (
                    <FormControl sx={{ width: "75%" }}>
                      <TextField
                        spellCheck={false}
                        inputProps={{
                          onKeyDown: (event) => {
                            return (
                              event.key === "Enter" &&
                              !tagError &&
                              event.currentTarget.blur()
                            );
                          },
                        }}
                        autoFocus
                        variant="standard"
                        error={tagError}
                        helperText={
                          tagError ? "Tag with this name already exist" : ""
                        }
                        defaultValue={tag.name}
                        onChange={(event) =>
                          validateTag(event.target.value, tag)
                        }
                        onBlur={(event) =>
                          handleTagEdit(event.target.value, tag)
                        }
                      />
                    </FormControl>
                  ) : (
                    <Typography sx={{ py: 0.5 }}>{tag.name} </Typography>
                  )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <IconButton color="warning" onClick={() => setEdit(i)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => {
                      handleDeleteTag(tag);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </ListItem>
            ))}
          </>
        )}
        {isAdding && (
          <ListItem
            sx={{
              py: 1.5,
              borderBottom: `1px solid ${theme.palette.fourth.dark}`,
              "&:first-of-type": {
                borderTop: `1px solid ${theme.palette.fourth.dark}`,
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 0, pr: 3 }}>
              <LocalOfferIcon fontSize="small" sx={{ color: "fourth.light" }} />
            </ListItemIcon>
            <FormControl sx={{ width: "75%" }}>
              <TextField
                autoFocus
                spellCheck={false}
                inputProps={{
                  onKeyDown: (event) =>
                    event.key === "Enter" &&
                    !tagError &&
                    event.currentTarget.blur(),
                }}
                error={tagError}
                helperText={tagError ? "Tag with this name already exist" : ""}
                variant="standard"
                size="small"
                onBlur={(event) => handleAddTag(event.target.value)}
                onChange={(event) => validateTag(event.target.value)}
              />
            </FormControl>
          </ListItem>
        )}
        {!tags?.length && !isAdding && <Typography>No tags listed</Typography>}
      </List>
      <IconButton
        sx={{
          mt: 2,
          bgcolor: "success.main",
          color: "white",
          float: "right",
        }}
        size="large"
        onClick={() => setIsAdding(true)}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};
