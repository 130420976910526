import EditNoteIcon from "@mui/icons-material/EditNote";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Slider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { setGamePanorama, stitchPanorama } from "api";
import { ImageUpload, VideoAssets } from "components";
import {
  VideoPlayer,
  VideoPlayerRef,
} from "components/VideoPlayer/VideoPlayer";
import { Panorama as ApiPanorama, ReferencePoint } from "generated/openapi";
import { FC, useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  getForzaGame,
  selectAccessToken,
  selectForzaGame,
  selectForzaTeamsMap,
  setAlert,
  useAppDispatch,
  useAppSelector,
} from "store";
import { dateFormat } from "utils/utils";

const MAX = 9999999999;

export const Panorama: FC = () => {
  const params = useParams();

  const gameId = params.gameId ? +params.gameId : undefined;

  if (!Number.isFinite(gameId)) {
    return <Navigate to="/" />;
  }

  return <PanoramaInner id={gameId!} />;
};

interface Props {
  id: number;
}

interface UrlTs {
  url: string;
  ts: number;
}

export const PanoramaInner: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();

  const playerRef = useRef<VideoPlayerRef>(null);

  const token = useAppSelector(selectAccessToken)!;
  const game = useAppSelector(selectForzaGame);
  const teams = useAppSelector(selectForzaTeamsMap);

  const [selectedAssetIdx, setSelectedAssetIdx] = useState(0);
  const [fromTimestamp, setFromTimestamp] = useState(0);
  const [maxToTimestamp, setMaxToTimestamp] = useState(0);
  const [toTimestamp, setToTimestamp] = useState(MAX);
  const [refresh, setRefresh] = useState(0);
  const [panorama, setPanorama] = useState<ApiPanorama | undefined>(undefined);
  const [referencePoints, seReferencePoints] = useState<ReferencePoint[]>([]);
  const [images, setImages] = useState<UrlTs[]>([]);
  const [selected, setSelected] = useState<UrlTs[]>([]);

  useEffect(() => {
    if (!game || game.id !== id) {
      dispatch(getForzaGame({ forzaGameId: id }));
    }
  }, [id]);

  const asset = game?.videoAssets[selectedAssetIdx];
  const url = asset
    ? `${asset.videoBaseUrl}/playlist.m3u8/${asset.videoAssetId}:${fromTimestamp}:${toTimestamp}/Manifest.m3u8`
    : undefined;

  useEffect(() => {
    if (!asset || toTimestamp === MAX) return;

    const images = Array.from({ length: 10 }).map(() => {
      const ts =
        Math.floor(Math.random() * (toTimestamp - fromTimestamp + 1)) +
        fromTimestamp;
      const url = `${process.env.REACT_APP_API_URL}/v1.0/panorama/${asset.videoAssetId}/options/${ts}?authorization=${token}`;
      return { url, ts };
    });

    setImages(images);
  }, [refresh]);

  if (!game || !asset) {
    // TODO: Detect if just waiting, or if game does not exist
    return <>Loading...</>;
  }

  const success = () => {
    dispatch(setAlert({ message: "Success!", severity: "success" }));
  };

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3">
            {teams[game.homeTeamId].name} vs. {teams[game.visitingTeamId].name}
          </Typography>
          {game.playlistCreators.length > 0 && (
            <Box sx={{ alignSelf: "flex-end" }}>
              <Tooltip
                sx={{ fontSize: "3rem" }}
                title={
                  <Stack>
                    {game.playlistCreators.map((c) => (
                      <Typography key={c.id} variant="caption">
                        {c.firstName} {c.lastName}
                      </Typography>
                    ))}
                  </Stack>
                }
              >
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  badgeContent={
                    <Avatar
                      sx={{
                        width: 20,
                        height: 20,
                        bgcolor: "white",
                        border: "1px solid black",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                      >
                        {game.playlistCreators.length}
                      </Typography>
                    </Avatar>
                  }
                >
                  <Avatar sx={{ bgcolor: "fourth.main" }}>
                    <EditNoteIcon sx={{ fontSize: "1.7rem", color: "white" }} />
                  </Avatar>
                </Badge>
              </Tooltip>
            </Box>
          )}
        </Stack>
        <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
          {dateFormat(game.date, "d MMMM, yyyy")}
        </Typography>
      </Box>
      <Stack
        gap={3}
        sx={{
          color: "white",
        }}
      >
        <Stack gap={2} sx={{ width: "100%" }}>
          <VideoAssets
            assets={game.videoAssets}
            selected={selectedAssetIdx}
            onSelect={setSelectedAssetIdx}
          />

          <Typography>Is Live: {`${asset.isLive}`}</Typography>

          <VideoPlayer
            playing
            controlFullscreenId
            clickToPlay
            ref={playerRef}
            url={url}
            onDuration={(duration) =>
              setMaxToTimestamp((v) => Math.max(v, duration * 1_000))
            }
          />
        </Stack>

        <Slider
          color="info"
          value={[fromTimestamp, toTimestamp]}
          min={0}
          max={maxToTimestamp}
          step={5_000}
          onChangeCommitted={(_, values) => {
            const [from, to] = values as number[];
            setFromTimestamp(from);
            setToTimestamp(to);
          }}
        />

        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setGamePanorama(id, undefined, token).then(() => success());
              }}
            >
              Delete panorama
            </Button>
          </Stack>

          <Stack direction="row" gap={2}>
            <Button
              variant="outlined"
              color="inherit"
              disabled={toTimestamp === MAX}
              onClick={() => setRefresh((v) => v + 1)}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              color="info"
              disabled={!selected.length}
              onClick={() => {
                stitchPanorama(
                  asset.videoAssetId,
                  { timestamps: selected.map((i) => i.ts) },
                  token,
                ).then((v) => setPanorama(v));
              }}
            >
              Stitch
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => document.getElementById("image-upload")?.click()}
            >
              <FileUploadIcon />
            </Button>
            <ImageUpload
              accept="image/png"
              onChange={(v, w, h) =>
                setPanorama({
                  image: v.substring("data:image/png;base64,".length),
                  width: w,
                  height: h,
                })
              }
            />
            <Button
              variant="contained"
              color="success"
              disabled={!panorama || referencePoints.length !== 4}
              onClick={() => {
                setGamePanorama(
                  id,
                  { image: panorama!.image, referencePoints },
                  token,
                ).then(() => success());
              }}
            >
              Save
            </Button>
          </Stack>
        </Stack>

        {referencePoints.length > 0 && (
          <Typography>Keypoints: {referencePoints.length}</Typography>
        )}

        {panorama && (
          <img
            src={"data:image/png;base64," + panorama.image}
            onClick={(e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              const _x = Math.max(e.clientX - rect.left, 0);
              const _y = Math.max(e.clientY - rect.top, 0);

              const x = (_x / rect.width) * panorama.width;
              const y = (_y / rect.height) * panorama.height;

              seReferencePoints((v) =>
                v.length === 4
                  ? [{ index: 0, x, y }]
                  : [...v, { index: v.length, x, y }],
              );
            }}
          />
        )}

        <Stack direction="row" gap={2}>
          <Stack width="50%">
            {images.map(({ url, ts }, i) => (
              <Button
                key={i}
                variant="text"
                onClick={() => {
                  setImages((v) => v?.filter((_, j) => j !== i));
                  setSelected((v) => [...v, { url, ts }]);
                }}
              >
                <img src={url} style={{ maxWidth: "100%" }} />
              </Button>
            ))}
          </Stack>
          <Stack width="50%">
            {selected.map(({ url }, i) => (
              <Button
                key={i}
                variant="text"
                onClick={() => setSelected((v) => v.filter((_, j) => j !== i))}
              >
                <img src={url} style={{ maxWidth: "100%" }} />
              </Button>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
