import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  alpha,
  Autocomplete,
  autocompleteClasses,
  Checkbox,
  Popper,
  styled,
  TextField,
} from "@mui/material";
import { PlaylistSegment } from "generated/openapi";
import { TagItem } from "models";
import { FC, useMemo, useState } from "react";
import { selectSegmentTagsFlattenedMap, useAppSelector } from "store";

export interface Props {
  value: TagItem[];
  segments: PlaylistSegment[];
  selectAll?: boolean;
  onChange: (_: TagItem[]) => void;
}

export const SegmentsStatsFilter: FC<Props> = ({
  value: _value,
  segments,
  selectAll,
  onChange,
}) => {
  const allTags = useAppSelector(selectSegmentTagsFlattenedMap)!;

  const [inputValue, setInputValue] = useState("");

  const [value, options] = useMemo(() => {
    const res: TagItem[] = [];

    new Set(segments.flatMap((s) => s.tags)).forEach((id) =>
      res.push(
        allTags[id].parentTagId
          ? allTags[allTags[id].parentTagId!]
          : allTags[id],
      ),
    );

    const opts = Array.from(new Set(res)).sort((a, b) =>
      a.displayName.localeCompare(b.displayName),
    );

    const diff = selectAll
      ? opts
      : _value.filter((v) => opts.some((o) => v.id === o.id));

    if (diff.length !== _value.length) {
      setTimeout(() => onChange(diff));
    }

    return [diff, opts];
  }, [segments, allTags, _value, selectAll]);

  if (selectAll) {
    return <></>;
  }

  return (
    <Autocomplete
      multiple
      disableListWrap
      size="small"
      disableCloseOnSelect
      disableClearable
      componentsProps={{ popper: { placement: "bottom-start" } }}
      value={value}
      inputValue={inputValue}
      options={options}
      renderTags={() => <></>}
      PopperComponent={StyledPopper}
      getOptionLabel={(option) => option.displayName}
      onChange={(_, value) => onChange(value)}
      onInputChange={(e, v) => {
        if (e?.target instanceof HTMLInputElement) {
          setInputValue(v);
        }
      }}
      onKeyDownCapture={(e) => {
        if (inputValue === "" && e.key === "Backspace") {
          e.stopPropagation();
        }
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={
              <CheckBoxIcon fontSize="small" sx={{ color: "white" }} />
            }
            style={{
              margin: "0px 4px 0px 4px",
            }}
            checked={selected}
          />
          {option.displayName}
        </li>
      )}
      renderInput={(params: any) => (
        <TextField
          variant="standard"
          sx={{ maxWidth: 210 }}
          {...params}
          placeholder="Select tags"
        />
      )}
    />
  );
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    "& .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: alpha(theme.palette.primary.main, 0.6),
      "&.Mui-focused": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    "& .MuiAutocomplete-option": {
      paddingLeft: 0,
      paddingRight: "4px",
    },
  },
  [`& .${autocompleteClasses.option}`]: {
    borderBottom: "1px solid",
    borderColor: theme.palette.primary.main,
    color: "white",
    "& .MuiTypography-caption": { color: "grey" },
    paddingRight: "4px",
  },
  [`& .${autocompleteClasses.paper}`]: {
    borderRadius: 0,
    marginRight: "1px",
    marginLeft: "1px",
    width: 210,
  },
}));
