import { ChipAutocomplete } from "components";
import { FC } from "react";
import {
  selectPlaylistTagsMap,
  selectPlaylistTagsSorted,
  useAppSelector,
} from "store";

interface Props {
  value?: number[] | null;
  variant?: "filled" | "outlined" | "standard";
  placeholder?: string;
  disabled?: boolean;
  disablePortal?: boolean;
  onChange?: (_: number[]) => void;
}

export const PlaylistTagsInput: FC<Props> = ({
  value: _value,
  variant,
  placeholder,
  disabled,
  disablePortal,
  onChange,
}) => {
  const options = useAppSelector(selectPlaylistTagsSorted) ?? [];
  const tags = useAppSelector(selectPlaylistTagsMap) ?? {};

  const value = _value?.length ? _value.map((t) => tags[t]) : [];

  return (
    <ChipAutocomplete
      placeholder={placeholder ?? "Add tag"}
      value={value}
      variant={variant}
      disabled={disabled}
      disablePortal={disablePortal}
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={
        onChange ? (value) => onChange?.(value.map((v) => v.id)) : undefined
      }
    />
  );
};
