import { Editor } from "@tiptap/core";
import { Dispatch, SetStateAction } from "react";

export const onEditorUpdateSetEditable = (
  editor: Editor,
  setter: Dispatch<SetStateAction<boolean>>,
) => {
  const onUpdate = ({ editor }: { editor: Editor }) =>
    setter(editor.isEditable);

  editor.on("update", onUpdate);

  return () => {
    editor.off("update", onUpdate);
  };
};
