import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, Stack, TextField } from "@mui/material";
import { Extension } from "@tiptap/core";
import { useCurrentEditor } from "@tiptap/react";
import { FC, useEffect, useState } from "react";
import { middle } from "utils/utils";

const MIN_FONT_SIZE = 8;
const MAX_FONT_SIZE = 72;

export const FontSize: FC = () => {
  const { editor } = useCurrentEditor();

  const [inputValue, setInputValue] = useState(16);

  const fontSizeStr: string =
    editor?.getAttributes("textStyle").fontSize ?? "16px";

  const fontSize = +fontSizeStr.slice(0, -2);

  useEffect(() => setInputValue(fontSize), [fontSize]);

  if (!editor) return <></>;

  const onChange = (fontSize: number) =>
    editor.chain().focus().setFontSize(`${fontSize}px`).run();

  return (
    <Stack direction="row">
      <IconButton
        size="small"
        disabled={fontSize <= MIN_FONT_SIZE}
        onClick={() => onChange(fontSize - 1)}
      >
        <RemoveIcon fontSize="inherit" />
      </IconButton>
      <TextField
        size="small"
        variant="outlined"
        value={inputValue}
        sx={{
          width: "2.5rem",
          "& .MuiInputBase-root": {
            height: 34,
          },
          "& input": {
            padding: 0,
            height: "100%",
            textAlign: "center",
          },
        }}
        onBlur={(e) => {
          const num = +e.target.value;
          if (Number.isFinite(num)) {
            onChange(middle(num, MIN_FONT_SIZE, MAX_FONT_SIZE));
          }
        }}
        onChange={(e) => {
          const v = e.target.value;
          if (v === "" || Number.isFinite(+v)) {
            setInputValue(+v);
          }
        }}
      />
      <IconButton
        size="small"
        disabled={fontSize >= MAX_FONT_SIZE}
        onClick={() => onChange(fontSize + 1)}
      >
        <AddIcon fontSize="inherit" />
      </IconButton>
    </Stack>
  );
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (fontSize: string) => ReturnType;
    };
  }
}

export const FontSizeExt = Extension.create({
  name: "fontSize",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) =>
              element.style.fontSize?.replace(/['"]+/g, ""),
            renderHTML: (attrs) => {
              if (!attrs.fontSize) {
                return {};
              }

              return {
                style: `font-size: ${attrs.fontSize}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ chain }) => {
          return chain().setMark("textStyle", { fontSize }).run();
        },
    };
  },
});
