import { FC } from "react";

export interface Props {
  accept?: string;
  onChange: (base64: string, width: number, height: number) => void;
}

export const ImageUpload: FC<Props> = ({ accept, onChange }) => {
  return (
    <input
      id="image-upload"
      type="file"
      accept={accept}
      style={{
        position: "absolute",
        width: 0,
        height: 0,
        visibility: "hidden",
      }}
      onChange={(e) => {
        const files = e.target.files;
        if (files?.length !== 1) return;

        const reader = new FileReader();
        reader.onload = () => {
          e.target.value = "";

          const image = new Image();
          image.src = reader.result as string;

          image.onload = () =>
            onChange(reader.result as string, image.width, image.height);
        };
        reader.readAsDataURL(files[0]);
      }}
    />
  );
};
