import ClearIcon from "@mui/icons-material/Clear";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { getHomography } from "api/forzaHomographyApi";
import theme from "app/theme";
import {
  CombinedPlayer,
  FootballFieldGrid,
  ForzaTeamsInput,
  LoadingScreen,
  PlayersInput,
  PlaylistVideos,
  SegmentTagsInput,
  VideoOffsetBar,
  VideoOffsetMenu,
} from "components";
import { VideoPlayerRef } from "components/VideoPlayer/VideoPlayer";
import { HomographyFrame, HomographyResult, Playlist } from "generated/openapi";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Form, useFieldArray, useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import {
  getPlaylist,
  selectAccessToken,
  selectForzaTeamsMap,
  selectPlaylistCopy,
  selectTeamsMap,
  updatePlaylist,
  useAppDispatch,
  useAppSelector,
} from "store";
import { getVideoOffset, updateHomography } from "utils";
import { ExportPlaylistModal } from "./ExportPlaylistModal";
import { ExportSportscodeModal } from "./ExportSportscodeModal";
import { GeneratePlaylistHomographyModal } from "./GeneratePlaylistHomographyModal";
import { PlaylistSegments, PlaylistSegmentsRef } from "./PlaylistSegments";

export const EditPlaylist: FC = () => {
  const params = useParams();

  const playlistId = params.playlistId ? +params.playlistId : undefined;

  if (!Number.isFinite(playlistId)) {
    return <Navigate to="/" />;
  }

  return <EditPlaylistInner id={playlistId!} />;
};

interface Props {
  id: number;
}

const EditPlaylistInner: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up(1600));
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up(600));

  const token = useAppSelector(selectAccessToken)!;
  const teams = useAppSelector(selectTeamsMap)!;
  const forzaTeams = useAppSelector(selectForzaTeamsMap);
  const playlist = useAppSelector(selectPlaylistCopy);

  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [segmentIdx, setSegmentIdx] = useState<number | undefined>(0);
  const [homography, setHomography] = useState<HomographyResult | undefined>(
    undefined,
  );

  const [exporting, setExporting] = useState(false);
  const [exportingSportscode, setExportingSportscode] = useState(false);
  const [homographyModal, setHomographyModal] = useState(false);
  const [homographyFrames, setHomographyFrames] = useState<
    Record<number, HomographyFrame> | undefined
  >(undefined);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const playerRef = useRef<VideoPlayerRef>(null);
  const segmentsRef = useRef<PlaylistSegmentsRef | null>(null);

  const {
    reset,
    control,
    watch,
    register,
    formState: { isDirty },
  } = useForm<Playlist>({ defaultValues: playlist });
  const { update, replace } = useFieldArray({ control, name: "segments" });

  useEffect(() => {
    if (playlist?.id === id) {
      reset(playlist);
    } else {
      dispatch(getPlaylist({ playlistId: id }));
    }
  }, [id, playlist?.id]);

  const segments = watch("segments");
  const segment = segmentIdx !== undefined ? segments?.[segmentIdx] : undefined;

  const exportSportscodeDisabled = useMemo(() => {
    if (!segments || segments.length === 0) return true;
    const first = segments[0].videoAssetId;
    return segments.some((s) => s.videoAssetId !== first);
  }, [segments]);

  if (!playlist || watch("id") !== id) {
    return <LoadingScreen />;
  }

  return (
    <Form
      control={control}
      onSubmit={({ data }) => {
        dispatch(updatePlaylist({ playlist: data })).then(() => reset(data));
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: mobileBreakpoint ? "row" : "column-reverse",
          gap: 2,
        }}
      >
        {editTitle ? (
          <TextField
            multiline
            spellCheck={false}
            sx={{
              width: "100%",
              "& .MuiInputBase-root": mobileBreakpoint
                ? theme.typography.h3
                : theme.typography.h2,

              "& .MuiInputBase-input": {
                p: 0,
              },
            }}
            autoFocus
            variant="standard"
            defaultValue={playlist.name}
            inputProps={{
              onKeyDown: (event) => {
                if (event.key === "Enter") {
                  event.currentTarget.blur();
                } else {
                  event.stopPropagation();
                }
              },
            }}
            {...register("name")}
            onBlur={() => setEditTitle(false)}
          />
        ) : (
          <Typography
            sx={{
              width: "100%",
              // Hacky CSS to lessen jump when switching to textfield on edit
              pt: "4px",
              borderBottom: "5px solid transparent",
            }}
            variant={mobileBreakpoint ? "h3" : "h2"}
            onClick={() => setEditTitle(true)}
          >
            {watch("name")}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            width: xlBreakpoint ? 640 : mobileBreakpoint ? 350 : "100%",
            minWidth: mobileBreakpoint ? 300 : undefined,
            justifyContent: "space-between",
            alignItems: "flex-end",
            rowGap: "8px",
          }}
        >
          <Button
            disabled={!isDirty}
            type="submit"
            color="success"
            variant="contained"
            startIcon={<SaveSharpIcon />}
            sx={{
              alignItems: "center",
              width: xlBreakpoint ? "60%" : "46%",
              fontSize: "1.0rem",
            }}
          >
            {xlBreakpoint ? "SAVE PLAYLIST" : "SAVE"}
          </Button>
          <Button
            disabled={!isDirty}
            color="primary"
            variant="contained"
            startIcon={<ClearIcon sx={{ width: 18, height: 18 }} />}
            sx={{
              width: xlBreakpoint ? "27%" : "35%",
              fontSize: "1.0rem",
              fontWeight: 500,
            }}
            onClick={() => reset(playlist)}
          >
            RESET
          </Button>
          <IconButton
            sx={{ bgcolor: "primary.light" }}
            onClick={(e) => setMenuAnchorEl(e.currentTarget)}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            disableScrollLock
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setHomographyModal(true);
                setMenuAnchorEl(null);
              }}
              disabled={isDirty}
            >
              Generate Homographys
            </MenuItem>
            <MenuItem
              onClick={() => {
                setExporting(true);
                setMenuAnchorEl(null);
              }}
              disabled={isDirty}
            >
              Export
            </MenuItem>
            <MenuItem
              onClick={() => {
                setExportingSportscode(true);
                setMenuAnchorEl(null);
              }}
              disabled={isDirty || exportSportscodeDisabled}
            >
              Export Sportscode
            </MenuItem>
          </Menu>

          {exporting && (
            <ExportPlaylistModal
              playlist={playlist}
              onClose={() => setExporting(false)}
            />
          )}
          {exportingSportscode && (
            <ExportSportscodeModal
              playlist={playlist}
              onClose={() => setExportingSportscode(false)}
            />
          )}

          {homographyModal && (
            <GeneratePlaylistHomographyModal
              playlist={playlist}
              onClose={() => setHomographyModal(false)}
            />
          )}
        </Box>
      </Box>
      <Divider
        sx={{
          pb: "1px",
          bgcolor: isDirty ? "success.main" : "primary.light",
          mt: 2,
          mb: 3,
        }}
      />
      <Stack
        direction={xlBreakpoint ? "row" : "column"}
        gap={3}
        sx={{
          color: "white",
        }}
      >
        {segment && (
          <Stack gap={3} sx={{ width: "100%" }}>
            <Stack gap={1}>
              <CombinedPlayer
                url={`${segment.videoBaseUrl}/playlist.m3u8/${segment.videoAssetId}:${segment.fromTimestamp}:${segment.toTimestamp}/Manifest.m3u8`}
                ref={playerRef}
                getVideoOffset={() =>
                  getVideoOffset(
                    segment,
                    playerRef.current!.getCurrentMillis(),
                    segment.fromTimestamp,
                  )
                }
                gameId={segment.forzaGameId}
                dynamicHomography={homography}
                staticHomography={segment?.homographyFrames ?? {}}
                drawings={segment.drawings ?? {}}
                onDrawChange={(drawings) =>
                  update(segmentIdx!, { ...segment, drawings })
                }
                onEnded={() => segmentsRef.current?.next()}
                onResetHomography={() =>
                  !homographyFrames && setHomography(undefined)
                }
                onProgress={
                  homographyFrames
                    ? ({ playedSeconds }) => {
                        setHomography(
                          updateHomography(
                            playedSeconds * 1_000,
                            undefined,
                            segment,
                            homographyFrames,
                          ) ?? {
                            players: [],
                            ballIdx: undefined,
                          },
                        );
                      }
                    : undefined
                }
                onStaticHomographyChange={(homography) =>
                  update(segmentIdx!, {
                    ...segment,
                    homographyFrames: homography,
                  })
                }
              />
              <Stack direction="row" justifyContent="space-between">
                {mobileBreakpoint ? (
                  <VideoOffsetBar
                    onClick={(v) =>
                      update(segmentIdx!, {
                        ...segment,
                        fromTimestamp: segment.fromTimestamp - v * 1000,
                      })
                    }
                  />
                ) : (
                  <VideoOffsetMenu
                    onClick={(v) =>
                      update(segmentIdx!, {
                        ...segment,
                        fromTimestamp: segment.fromTimestamp - v * 1000,
                      })
                    }
                  />
                )}
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    size={mobileBreakpoint ? "medium" : "small"}
                    color="inherit"
                    sx={{ width: 110 }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    Show Field
                  </Button>
                  <Button
                    disabled={!segment?.hasHomography}
                    size={mobileBreakpoint ? "medium" : "small"}
                    color="inherit"
                    onClick={(e) => {
                      if (!homographyFrames) {
                        getHomography({
                          // TODO: Remove after japan demo refactor
                          forzaVideoAssetId:
                            segment.videoAssetId +
                            (segment.videoBaseUrl.includes("demo-api")
                              ? 100_000_000
                              : 0),
                          startMs: segment.fromTimestamp,
                          endMs: segment.toTimestamp,
                          token,
                        }).then((_frames) => {
                          const frames = Object.fromEntries(
                            _frames.map((e) => [e.videoOffsetMs, e]),
                          );
                          const ms = playerRef.current!.getCurrentMillis();
                          setHomography(
                            updateHomography(
                              ms,
                              frames,
                              segment,
                              homographyFrames,
                            ) ?? {
                              players: [],
                              ballIdx: undefined,
                            },
                          );
                          setHomographyFrames(frames);
                        });
                      } else {
                        setHomographyFrames(undefined);
                        setHomography(undefined);
                      }
                      e.currentTarget.blur();
                    }}
                  >
                    Homography
                  </Button>
                </Box>
                {mobileBreakpoint ? (
                  <VideoOffsetBar
                    mirrored
                    onClick={(v) =>
                      update(segmentIdx!, {
                        ...segment,
                        toTimestamp: segment.toTimestamp + v * 1000,
                      })
                    }
                  />
                ) : (
                  <VideoOffsetMenu
                    mirrored
                    onClick={(v) =>
                      update(segmentIdx!, {
                        ...segment,
                        toTimestamp: segment.toTimestamp + v * 1000,
                      })
                    }
                  />
                )}
              </Stack>
            </Stack>
            <Popover
              open={Boolean(anchorEl)}
              disableScrollLock
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ bgcolor: "primary.main" }}
              >
                <ToggleButtonGroup
                  exclusive
                  orientation="vertical"
                  value={segment.footballFieldLeftTeamId}
                  onChange={(_, value) => {
                    let v;
                    if (value) {
                      const id = +value;
                      v = {
                        footballFieldLeftTeamId: id,
                        footballFieldRightTeamId:
                          id === segment.homeTeamId
                            ? segment.visitingTeamId
                            : segment.homeTeamId,
                      };
                    } else {
                      v = {
                        footballFieldLeftTeamId: undefined,
                        footballFieldRightTeamId: undefined,
                      };
                    }
                    update(segmentIdx!, { ...segment, ...v });
                  }}
                >
                  <ToggleButton value={segment.homeTeamId}>
                    {forzaTeams[segment.homeTeamId].name}
                  </ToggleButton>
                  <ToggleButton value={segment.visitingTeamId}>
                    {forzaTeams[segment.visitingTeamId].name}
                  </ToggleButton>
                </ToggleButtonGroup>
                <Stack>
                  <ToggleButton
                    value=""
                    selected={
                      segment.footballFieldRightTeamId === segment.homeTeamId
                    }
                    sx={{
                      cursor: "default",
                      "&:hover": { bgcolor: "inherit" },
                    }}
                  >
                    {forzaTeams[segment.homeTeamId].name}
                  </ToggleButton>
                  <ToggleButton
                    value=""
                    selected={
                      segment.footballFieldRightTeamId ===
                      segment.visitingTeamId
                    }
                    sx={{
                      cursor: "default",
                      "&:hover": { bgcolor: "inherit" },
                    }}
                  >
                    {forzaTeams[segment.visitingTeamId].name}
                  </ToggleButton>
                </Stack>
              </Stack>
              <FootballFieldGrid
                width={800}
                selectedGrids={segment.footballFieldGrids}
                onChange={(grids) =>
                  update(segmentIdx!, {
                    ...segment,
                    footballFieldGrids: grids,
                  })
                }
              />
            </Popover>
            <Stack gap={1} sx={{ display: "flex", flexDirection: "column" }}>
              <Box>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      fontWeight: 600,
                      fontSize: "1.4rem",
                    },
                  }}
                  spellCheck={false}
                  fullWidth
                  hiddenLabel
                  size="small"
                  variant="standard"
                  value={segment.title}
                  onChange={(event) =>
                    update(segmentIdx!, {
                      ...segment,
                      title: event.target.value,
                    })
                  }
                  onKeyDownCapture={(e) => e.stopPropagation()}
                />
                <Typography
                  sx={{ pl: 1, py: 1 }}
                  variant="subtitle2"
                  color="text.secondary"
                >
                  {forzaTeams[segment.homeTeamId].name} vs.{" "}
                  {forzaTeams[segment.visitingTeamId].name}
                </Typography>
              </Box>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    p: 1.5,
                  },
                }}
                multiline
                placeholder="Add description here..."
                rows={6}
                fullWidth
                variant="filled"
                value={segment.description ?? ""}
                onChange={(event) =>
                  update(segmentIdx!, {
                    ...segment,
                    description: event.target.value,
                  })
                }
                onKeyDownCapture={(e) => e.stopPropagation()}
              />
              <SegmentTagsInput
                value={segment.tags}
                onChange={(tags) => update(segmentIdx!, { ...segment, tags })}
              />
              <PlayersInput
                value={segment.playerTags}
                options={teams[playlist.teamId].players.map((p) => p.id)}
                onChange={(playerTags) =>
                  update(segmentIdx!, { ...segment, playerTags })
                }
              />
              <ForzaTeamsInput
                value={segment.forzaTeamTags}
                onChange={(forzaTeamTags) =>
                  update(segmentIdx!, { ...segment, forzaTeamTags })
                }
              />
            </Stack>
            <PlaylistVideos
              playlistId={playlist.id}
              videos={playlist.videos}
              expanded={xlBreakpoint}
            />
          </Stack>
        )}
        {!mobileBreakpoint && <Divider sx={{ bgcolor: "primary.light" }} />}
        <Stack sx={{ width: xlBreakpoint ? 650 : "100%" }}>
          {segments.length > 0 && (
            <PlaylistSegments
              ref={segmentsRef}
              playlistId={playlist.id}
              isPlaylistDirty={isDirty}
              segments={segments}
              selected={segmentIdx}
              onChange={replace}
              onSelect={(i) => {
                setSegmentIdx(i);
                setHomography(undefined);
                setHomographyFrames(undefined);
              }}
            />
          )}
        </Stack>
      </Stack>
    </Form>
  );
};
