import { Button, Stack } from "@mui/material";
import { FC } from "react";

interface Props {
  onClick: (value: number) => void;
  mirrored?: boolean;
}

export const VideoOffsetBar: FC<Props> = (props) => {
  const { mirrored, onClick } = props;
  const options = mirrored ? [-10, -5, -1, 1, 5, 10] : [10, 5, 1, -1, -5, -10];
  return (
    <Stack direction="row" flexWrap="wrap">
      {options.map((v, i) => (
        <Button
          key={i}
          sx={{ minWidth: 46, width: 46, height: 46 }}
          color="inherit"
          onClick={() => {
            onClick(v);
          }}
        >
          {v > 0 ? `+${v}` : v.toString()}
        </Button>
      ))}
    </Stack>
  );
};
