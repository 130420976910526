import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton } from "@mui/material";
import { FC, useState } from "react";
import { selectSegmentTagsSorted, useAppSelector } from "store";
import { CreateSegmentTagModal } from "./CreateSegmentTagModal";
import { SegmentTagItem } from "./SegmentTagItem";

export const SegmentTagsSettings: FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const tags = useAppSelector(selectSegmentTagsSorted);

  // TODO: Design "no available tags" component
  if (!tags) {
    return <></>;
  }

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{ width: 600 }}>
      {tags.map((val, i) => (
        <SegmentTagItem key={i} tag={val} />
      ))}

      <IconButton
        sx={{
          mt: 2,
          bgcolor: "success.main",
          color: "white",
          float: "right",
        }}
        size="large"
        onClick={() => setModalOpen(true)}
      >
        <AddIcon />
      </IconButton>

      <CreateSegmentTagModal open={modalOpen} onClose={handleModalClose} />
    </Box>
  );
};
