import {
  Arrow,
  ConnectedEllipses,
  Ellipse,
  Polygon,
  Rect,
  Shape,
  ShapeDiscriminants,
} from "generated/openapi";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "konva/lib/types";
import { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import { Layer, Stage } from "react-konva";
import { ArrowComponent } from "./Arrow";
import { ConnectedEllipsesComponent } from "./ConnectedEllipses";
import { EllipseComponent } from "./Ellipse";
import { PolygonComponent } from "./Polygon";
import { RectComponent } from "./Rect";
import { TextComponent } from "./Text";

export type DrawMode = "Select" | ShapeDiscriminants | undefined;

export interface Props {
  shapes: Shape[];
  fill: string;
  stroke: string;
  strokeWidth: number;
  width: number;
  height: number;
  fontSize?: number;
  drawMode?: DrawMode;
  style?: CSSProperties;
  onSelect?: (_?: Shape) => void;
  onChange?: (_: Shape[]) => void;
  onChangeDrawMode?: () => void;
}

export const KonvaEditor: FC<Props> = ({
  shapes: _shapes,
  fill,
  stroke,
  strokeWidth,
  fontSize,
  drawMode,
  style,
  width,
  height,
  onSelect,
  onChange,
  onChangeDrawMode,
}) => {
  const [selected, setSelected] = useState<number | undefined>(undefined);
  const [drawing, setDrawing] = useState(false);
  const [polygon, setPolygon] = useState(false);
  const [connectedEllipses, setConnectedEllipses] = useState(false);
  const isTouch = window.matchMedia("(pointer: coarse)").matches;

  // Convert to %
  const shapes = useMemo(() => {
    const convertedShapes = _shapes.map((shape) => {
      switch (shape.type) {
        case ShapeDiscriminants.Rect:
          const rect = shape.value;
          return {
            ...shape,
            value: {
              ...rect,
              x: rect.x * width,
              y: rect.y * height,
              width: rect.width * width,
              height: rect.height * height,
            },
          };
        case ShapeDiscriminants.Polygon:
          const poly = shape.value;
          return {
            ...shape,
            value: {
              ...poly,
              points: poly.points.map((p, i) =>
                i % 2 === 0 ? p * width : p * height,
              ),
            },
          };
        case ShapeDiscriminants.Ellipse:
          const ell = shape.value;
          return {
            ...shape,
            value: {
              ...ell,
              x: ell.x * width,
              y: ell.y * height,
              radiusX: ell.radiusX * width,
              radiusY: ell.radiusY * height,
              offsetX: ell.offsetX * width,
              offsetY: ell.offsetY * height,
            },
          };
        case ShapeDiscriminants.ConnectedEllipses:
          const ce = shape.value;
          return {
            ...shape,
            value: {
              ...ce,
              ellipses: ce.ellipses.map((e) => ({
                ...e,
                x: e.x * width,
                y: e.y * height,
                radiusX: e.radiusX * width,
                radiusY: e.radiusY * height,
                offsetX: e.offsetX * width,
                offsetY: e.offsetY * height,
              })),
            },
          };
        case ShapeDiscriminants.Arrow:
          const arr = shape.value;
          return {
            ...shape,
            value: {
              ...arr,
              x1: arr.x1 * width,
              x2: arr.x2 * width,
              y1: arr.y1 * height,
              y2: arr.y2 * height,
            },
          };
        case ShapeDiscriminants.Text:
          const text = shape.value;
          return {
            ...shape,
            value: {
              ...text,
              x: text.x * width,
              y: text.y * height,
              width: text.width * width,
              height: text.height * height,
            },
          };
        default:
          return shape;
      }
    });

    return convertedShapes;
  }, [_shapes, width, height]);

  useEffect(() => {
    if (shapes.length === 0) {
      setPolygon(false);
      setConnectedEllipses(false);
    }
  }, [shapes]);

  useEffect(() => {
    if (selected === undefined) return;

    const onKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Delete") {
        onChange?.(shapes.filter((_, i) => i !== selected));
        setSelected(undefined);
      }
    };
    document.addEventListener("keydown", onKeyPress);
    return () => document.removeEventListener("keydown", onKeyPress);
  }, [selected]);

  useEffect(() => {
    if (selected === undefined) return;
    const s = shapes[selected] as any;
    shapes[selected] = {
      ...s,
      fill: s.fill ? fill : undefined,
      stroke: s.stroke ? stroke : undefined,
      strokeWidth: s.strokeWidth !== undefined ? strokeWidth : undefined,
      fontSize: s.fontSize !== undefined ? strokeWidth : undefined,
    };
    onChange?.([...shapes]);
  }, [selected, fill, stroke, strokeWidth]);

  const handleSelect = (i?: number) => {
    setSelected(i);
    onSelect?.(i === undefined ? undefined : shapes[i]);
  };

  useEffect(() => {
    handleSelect(undefined);
    setPolygon(false);
    setConnectedEllipses(false);
  }, [drawMode]);

  const shapeToComponent = (shape: Shape, i: number) => {
    const props = {
      selected: i === selected,
      interactive: !!onChange && drawMode === "Select",
      onSelect: () => !polygon && !connectedEllipses && handleSelect(i),
      onChange: (shape: Shape) =>
        onChange?.(shapes.map((s, j) => (i === j ? shape : s))),
    };
    switch (shape.type) {
      case ShapeDiscriminants.Rect:
        return <RectComponent key={i} {...props} rect={shape.value} />;
      case ShapeDiscriminants.Polygon:
        return <PolygonComponent key={i} {...props} polygon={shape.value} />;
      case ShapeDiscriminants.Ellipse:
        return <EllipseComponent key={i} {...props} ellipse={shape.value} />;
      case ShapeDiscriminants.Arrow:
        return <ArrowComponent key={i} {...props} arrow={shape.value} />;
      case ShapeDiscriminants.Text:
        return (
          <TextComponent
            key={i}
            {...props}
            interactive
            drawMode={drawMode}
            text={shape.value}
            onFinished={onChangeDrawMode}
          />
        );
      case ShapeDiscriminants.ConnectedEllipses:
        return (
          <ConnectedEllipsesComponent
            key={i}
            {...props}
            ellipses={shape.value}
          />
        );
    }
  };

  const handlePointerDown = (
    pos: Vector2d,
    event: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>,
  ) => {
    if (!drawMode) return;

    const isStage = event.target instanceof Konva.Stage;
    if (drawMode === "Select") {
      isStage && handleSelect(undefined);
      return;
    }

    const x = pos.x;
    const y = pos.y;

    switch (drawMode) {
      case ShapeDiscriminants.Rect:
        onChange?.([
          ...shapes,
          {
            type: ShapeDiscriminants.Rect,
            value: {
              x,
              y,
              width: 0,
              height: 0,
              rotation: 0,
              fill,
              stroke,
              strokeWidth,
            },
          },
        ]);
        break;
      case ShapeDiscriminants.Polygon:
        if (polygon) {
          const poly = shapes.last()!.value as Polygon;
          shapes[shapes.length - 1].value = {
            ...poly,
            points: [...poly.points, pos.x, pos.y],
          };
          onChange?.([...shapes]);
        } else {
          setPolygon(true);
          onChange?.([
            ...shapes,
            {
              type: ShapeDiscriminants.Polygon,
              value: {
                points: [x, y, x, y],
                fill,
                stroke,
                strokeWidth,
              },
            },
          ]);
        }
        break;
      case ShapeDiscriminants.Ellipse:
        onChange?.([
          ...shapes,
          {
            type: ShapeDiscriminants.Ellipse,
            value: {
              x,
              y,
              radiusX: 0,
              radiusY: 0,
              offsetX: 0,
              offsetY: 0,
              rotation: 0,
              fill,
              stroke,
              strokeWidth,
            },
          },
        ]);
        break;
      case ShapeDiscriminants.ConnectedEllipses:
        const ellipse = {
          x,
          y,
          radiusX: 40,
          radiusY: 20,
          offsetX: 0,
          offsetY: 0,
          rotation: 0,
        };
        if (connectedEllipses) {
          const ce = shapes.last()!.value as ConnectedEllipses;
          shapes[shapes.length - 1].value = {
            ...ce,
            ellipses: [...ce.ellipses, ellipse],
          };
          onChange?.([...shapes]);
        } else {
          setConnectedEllipses(true);
          onChange?.([
            ...shapes,
            {
              type: ShapeDiscriminants.ConnectedEllipses,
              value: {
                ellipses: [ellipse],
                fill,
                stroke,
                strokeWidth,
              },
            },
          ]);
        }
        break;
      case ShapeDiscriminants.Arrow:
        onChange?.([
          ...shapes,
          {
            type: ShapeDiscriminants.Arrow,
            value: {
              x1: x,
              y1: y,
              x2: x,
              y2: y,
              stroke,
              strokeWidth,
            },
          },
        ]);
        break;
      case ShapeDiscriminants.Text:
        onChange?.([
          ...shapes,
          {
            type: ShapeDiscriminants.Text,
            value: {
              x,
              y,
              width: 400,
              height: 100,
              rotation: 0,
              text: "",
              fill,
              fontSize: fontSize ?? 40,
            },
          },
        ]);
        break;
    }
    setDrawing(true);
  };

  const handlePointerMove = (pos: Vector2d) => {
    if (!drawMode || !drawing) return;
    const x = pos.x;
    const y = pos.y;

    switch (drawMode) {
      case ShapeDiscriminants.Rect:
        const rect = shapes.last()!.value as Rect;
        shapes[shapes.length - 1].value = {
          ...rect,
          width: x - rect.x,
          height: y - rect.y,
        };
        break;
      case ShapeDiscriminants.Polygon:
        const poly = shapes.last()?.value as Polygon;
        if (poly) {
          poly.points.splice(poly.points.length - 2, 2, x, y);
          shapes[shapes.length - 1].value = {
            ...poly,
            points: [...poly.points],
          };
        }
        break;
      case ShapeDiscriminants.Ellipse:
        const ell = shapes.last()!.value as Ellipse;
        const rx = Math.abs(ell.x - x) / 2;
        const ry = Math.abs(ell.y - y) / 2;
        shapes[shapes.length - 1].value = {
          ...ell,
          radiusX: rx,
          radiusY: ry,
          offsetX: ell.x > x ? rx : -rx,
          offsetY: ell.y > y ? ry : -ry,
        };
        break;
      case ShapeDiscriminants.ConnectedEllipses:
        const ce = shapes.last()!.value as ConnectedEllipses;
        const last = ce.ellipses.length - 1;
        shapes[shapes.length - 1].value = {
          ...ce,
          ellipses: ce.ellipses.map((e, i) =>
            i === last ? { ...e, x, y } : e,
          ),
        };
        break;
      case ShapeDiscriminants.Arrow:
        const arr = shapes.last()!.value as Arrow;
        shapes[shapes.length - 1].value = { ...arr, x2: x, y2: y };
        break;
      case ShapeDiscriminants.Text:
        return;
    }
    onChange?.([...shapes]);
  };

  return (
    <Stage
      style={style}
      width={width}
      height={height}
      onContextMenu={
        onChange
          ? (e) => {
              if (!drawMode) return;

              e.evt.preventDefault();

              if (polygon) {
                setPolygon(false);
                shapes[shapes.length - 1] = { ...shapes.last()! };
                onChange?.([...shapes]);
              } else if (connectedEllipses) {
                setConnectedEllipses(false);
              }
            }
          : undefined
      }
      onMouseDown={
        onChange
          ? (e) => {
              if (e.evt.button !== 0 || isTouch) return;
              handlePointerDown({ x: e.evt.offsetX, y: e.evt.offsetY }, e);
            }
          : undefined
      }
      onTouchStart={
        onChange
          ? (e: KonvaEventObject<TouchEvent>) => {
              const pos = e.target.getStage()?.getPointerPosition();
              if (!pos) return;
              handlePointerDown(pos, e);
            }
          : undefined
      }
      onMouseMove={
        onChange
          ? (e) => {
              handlePointerMove({ x: e.evt.offsetX, y: e.evt.offsetY });
            }
          : undefined
      }
      onTouchMove={
        onChange
          ? (e: KonvaEventObject<TouchEvent>) => {
              if (!drawMode || !drawing) return;
              e.evt.preventDefault();
              const pos = e.target.getStage()?.getPointerPosition();
              if (!pos) return;

              handlePointerMove(pos);
            }
          : undefined
      }
      onMouseUp={
        onChange
          ? (_e) => {
              if (!drawing || polygon) return;
              setDrawing(false);
            }
          : undefined
      }
      onTouchEnd={
        onChange
          ? (_e) => {
              if (!drawing || polygon) return;
              setDrawing(false);
            }
          : undefined
      }
    >
      <Layer>{shapes.map(shapeToComponent)}</Layer>
    </Stage>
  );
};
