import { Box } from "@mui/material";
import { ForzaEventsParameters } from "api";
import {
  AfterSetPieceFilter,
  GoalTypeFilter,
  OnTargetFilter,
  ShotResultFilter,
  ShotTypeFilter,
} from "components";
import { ForzaEventType } from "generated/openapi";
import { FC } from "react";
import { selectSelectedEventType, useAppSelector } from "store";
import { PlayersFilter } from "../PlayersFilter";

interface Props {
  filters: ForzaEventsParameters;
  onChange: (_: ForzaEventsParameters) => void;
}

export const SecondaryHighlightsFilterBar: FC<Props> = (props) => {
  const { filters, onChange } = props;
  const selectedEventType = useAppSelector(selectSelectedEventType);

  return (
    <>
      {selectedEventType === ForzaEventType.Goal && (
        <>
          <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
            <GoalTypeFilter
              value={filters.goalType}
              onChange={(gt) => onChange({ ...filters, goalType: gt })}
            />
          </Box>
          <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
            <PlayersFilter
              teamId={filters.teamId}
              onChange={(player) =>
                onChange({ ...filters, assistBy: player?.id })
              }
            />
          </Box>
          <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
            <AfterSetPieceFilter
              value={filters.afterSetPiece}
              onChange={(val) => onChange({ ...filters, afterSetPiece: val })}
            />
          </Box>
        </>
      )}
      {selectedEventType === ForzaEventType.Shot && (
        <>
          <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
            <ShotResultFilter
              value={filters.shotResult}
              onChange={(val) => onChange({ ...filters, shotResult: val })}
            />
          </Box>
          <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
            <ShotTypeFilter
              value={filters.shotType}
              onChange={(val) => onChange({ ...filters, shotType: val })}
            />
          </Box>
          <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
            <OnTargetFilter
              value={filters.onTarget}
              onChange={(val) => onChange({ ...filters, onTarget: val })}
            />
          </Box>
        </>
      )}
    </>
  );
};
