import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";

interface Props {
  value?: boolean;
  onChange: (_?: boolean) => void;
}

export const OnTargetFilter: FC<Props> = ({ value, onChange }) => {
  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel>On Target</InputLabel>
      <Select
        MenuProps={{ disableScrollLock: true }}
        label="On Target"
        value={value === undefined ? "" : +value}
        onChange={(event) => {
          return onChange(
            typeof event.target.value === "number"
              ? !!event.target.value
              : undefined,
          );
        }}
      >
        <MenuItem value={""}>
          <em>All</em>
        </MenuItem>
        <MenuItem value={1}>Yes</MenuItem>
        <MenuItem value={0}>No</MenuItem>
      </Select>
    </FormControl>
  );
};
