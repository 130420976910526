import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import { Box, IconButton, Stack } from "@mui/material";
import { VideoPlayer } from "components";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getForzaEvent,
  selectCurrentForzaEvent,
  useAppDispatch,
  useAppSelector,
} from "store";

export const ForzaVideo: FC = () => {
  const { eventId } = useParams();
  const dispatch = useAppDispatch();

  const event = useAppSelector(selectCurrentForzaEvent);

  useEffect(() => {
    dispatch(getForzaEvent(Number(eventId)));
  }, [dispatch, eventId]);

  const [from, setFrom] = useState(event?.fromTimestamp ?? 0);
  const [to, setTo] = useState(event?.toTimestamp ?? 0);
  // TODO: Loading

  useEffect(() => {
    if (event) {
      setFrom(event.fromTimestamp);
      setTo(event.toTimestamp);
    }
  }, [event]);

  if (!event) {
    return <></>;
  }

  return (
    <Stack>
      <VideoPlayer
        playing
        url={`${event.videoBaseUrl}/playlist.m3u8/${event.videoAssetId}:${from}:${to}/Manifest.m3u8`}
        controlFullscreenId
        clickToPlay
      />
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "center",
          color: "white",
          pt: 1,
        }}
      >
        <IconButton
          color="inherit"
          onClick={() => setFrom(from - 10_000)}
          disabled={from <= 0}
        >
          <FastRewindIcon />
        </IconButton>
        <IconButton color="inherit" onClick={() => setTo(to + 10_000)}>
          <FastForwardIcon />
        </IconButton>
      </Box>
    </Stack>
  );
};
