/* eslint-disable no-extend-native */
export {};

declare global {
  interface Array<T> {
    sum(callback?: (value: T) => number): number;
    last(): T | undefined;
    move(src: number, dst: number): Array<T>;
    pushSorted(elem: T, cmpFn: (a: T, b: T) => number): Array<T>;
    findRev(callback: (_: T) => boolean): T | undefined;
    findIndexRev(callback: (_: T) => boolean): number | undefined;
  }
}

if (!(Array.prototype as any).sum)
  Object.defineProperty(Array.prototype, "sum", {
    enumerable: false,
    writable: false,
    configurable: false,
    value: function <T>(callback?: (value: T) => number): number {
      let result = 0;
      if (callback)
        for (let i = 0; i < this.length; i++) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          result += callback(this[i]);
        }
      else
        for (let i = 0; i < this.length; i++) {
          result += this[i] as number;
        }
      return result;
    },
  });

if (!(Array.prototype as any).last)
  Object.defineProperty(Array.prototype, "last", {
    enumerable: false,
    writable: false,
    configurable: false,
    value: function <T>(): T | undefined {
      return this[this.length - 1];
    },
  });

if (!(Array.prototype as any).move)
  Object.defineProperty(Array.prototype, "move", {
    enumerable: false,
    writable: false,
    configurable: false,
    value: function <T>(src: number, dst: number): Array<T> {
      const temp = this[src];
      this.splice(src, 1);
      this.splice(dst, 0, temp);
      return this;
    },
  });

if (!Array.prototype.pushSorted)
  Object.defineProperty(Array.prototype, "pushSorted", {
    enumerable: false,
    writable: false,
    configurable: false,
    value: (Array.prototype.pushSorted = function <T>(
      elem: T,
      cmpFn?: (a: T, b: T) => number,
    ): Array<T> {
      let i = 0;
      if (cmpFn) for (; i < this.length && cmpFn(elem, this[i]) > 0; i++);
      else for (; i < this.length && elem > this[i]; i++);
      this.splice(i, 0, elem);
      return this;
    }),
  });

if (!(Array.prototype as any).findRev)
  Object.defineProperty(Array.prototype, "findRev", {
    enumerable: false,
    writable: false,
    configurable: false,
    value: function <T>(callback: (_: T) => boolean): T | undefined {
      const i = this.findIndexRev(callback);
      return i === undefined ? undefined : this[i];
    },
  });

if (!(Array.prototype as any).findIndexRev)
  Object.defineProperty(Array.prototype, "findIndexRev", {
    enumerable: false,
    writable: false,
    configurable: false,
    value: function <T>(callback: (_: T) => boolean): number | undefined {
      for (let i = this.length - 1; i >= 0; i--) {
        if (callback(this[i])) {
          return i;
        }
      }
      return undefined;
    },
  });
