import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "app/theme";
import { KeybindInput, OffsetInput } from "components";
import { PlaylistSegmentSubTag, PlaylistSegmentTag } from "generated/openapi";
import { FC, useState } from "react";
import {
  deletePlaylistSegmentTag,
  selectSegmentTags,
  updatePlaylistSegmentTag,
  useAppDispatch,
  useAppSelector,
} from "store";
import { keybindEq } from "utils";

interface Props {
  tag: PlaylistSegmentTag;
}

export const SegmentTagItem: FC<Props> = (props) => {
  const { tag } = props;
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState<boolean>(false);
  const [subEdit, setSubEdit] = useState<number>();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [tagError, setTagError] = useState<boolean>(false);
  const [subtagError, setSubtagError] = useState<boolean>(false);
  const allTags = useAppSelector(selectSegmentTags)!;
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up(600));

  const validateTag = (name: string) => {
    const error = allTags.some((t) => t.name === name && t.id !== tag.id);
    setTagError(error);
    return !error;
  };

  const validateSubtag = (name: string, self?: PlaylistSegmentSubTag) => {
    const error = tag.subTags.some((t) => t.name === name && t.id !== self?.id);
    setSubtagError(error);
    return !error;
  };

  const handleTagEdit = async (edit: PlaylistSegmentTag) => {
    if (validateTag(edit.name)) {
      if (edit.keybind) {
        const conflict = allTags.find(
          (t) => t.id !== edit.id && keybindEq(t.keybind, edit.keybind),
        );
        if (conflict)
          await dispatch(
            updatePlaylistSegmentTag({
              tagId: conflict.id,
              tag: { ...conflict, keybind: undefined },
            }),
          );
      }
      dispatch(updatePlaylistSegmentTag({ tagId: tag.id, tag: edit })).then(
        () => setEdit(false),
      );
    }
  };

  const handleAddSubtag = (name: string) => {
    if (name !== "" && !subtagError) {
      dispatch(
        updatePlaylistSegmentTag({
          tagId: tag.id,
          tag: {
            ...tag,
            subTags: [...tag.subTags, { name, favourite: false }],
          },
        }),
      );
    }
    setSubEdit(-1);
    setIsAdding(false);
    setSubtagError(false);
  };

  const handleSubtagEdit = async (edit: PlaylistSegmentSubTag) => {
    if (validateSubtag(edit.name, edit)) {
      const subTags = tag.subTags.map((s) =>
        s.id === edit.id
          ? edit
          : keybindEq(edit.keybind, s.keybind)
            ? { ...s, keybind: undefined }
            : s,
      );
      dispatch(
        updatePlaylistSegmentTag({ tagId: tag.id, tag: { ...tag, subTags } }),
      );
    }
    setSubEdit(-1);
    setEdit(false);
    setSubtagError(false);
  };

  const handleDeleteSubtag = (id: number) => {
    dispatch(
      updatePlaylistSegmentTag({
        tagId: tag.id,
        tag: { ...tag, subTags: tag.subTags.filter((s) => s.id !== id) },
      }),
    );
  };

  return (
    <Accordion
      disableGutters
      square
      sx={{
        bgcolor: "primary.dark",
        boxShadow: "none",
        borderBottom: `1px solid ${theme.palette.fourth.dark}`,
        "&:first-of-type": {
          borderTop: `1px solid ${theme.palette.fourth.dark}`,
        },
        "&::before": {
          display: "none",
        },
        "& .MuiTextField-root": {
          width: "3.7rem",
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: "0.9rem", color: "fourth.light" }}
          />
        }
        sx={{
          flexDirection: "row-reverse",

          "& .MuiAccordionSummary-content": {
            justifyContent: "space-between",
            alignItems: "center",
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
        }}
      >
        {edit ? (
          <FormControl>
            <TextField
              spellCheck={false}
              sx={{ pl: 1 }}
              autoFocus
              variant="standard"
              defaultValue={tag.name}
              onKeyDown={(event) =>
                event.key === "Enter" && !tagError && event.currentTarget.blur()
              }
              error={tagError}
              helperText={tagError ? "Tag with this name already exist" : ""}
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => validateTag(event.target.value)}
              onBlur={(event) => {
                const name = event.target.value;
                if (name !== tag.name) {
                  handleTagEdit({ ...tag, name });
                }
                setEdit(false);
              }}
            />
          </FormControl>
        ) : (
          <Typography sx={{ pl: 1 }}>{tag.name}</Typography>
        )}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {mobileBreakpoint && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                pr: 1,
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "primary.light",
                },
              }}
            >
              <KeybindInput
                size="small"
                value={tag.keybind}
                onBlur={(keybind) => {
                  if (!keybindEq(keybind, tag.keybind)) {
                    handleTagEdit({ ...tag, keybind });
                  }
                }}
              />
              <OffsetInput
                size="small"
                value={tag.startOffset}
                label="Start offset"
                onBlur={(startOffset) =>
                  startOffset !== tag.startOffset &&
                  handleTagEdit({ ...tag, startOffset })
                }
              />
              <OffsetInput
                size="small"
                value={tag.endOffset}
                label="End offset"
                onBlur={(endOffset) =>
                  endOffset !== tag.endOffset &&
                  handleTagEdit({ ...tag, endOffset })
                }
              />
            </Stack>
          )}
          <IconButton
            color="inherit"
            onClick={(e) => {
              e.stopPropagation();
              handleTagEdit({ ...tag, favourite: !tag.favourite });
            }}
          >
            {tag.favourite ? (
              <StarIcon sx={{ color: "warning.main" }} />
            ) : (
              <StarOutlineIcon sx={{ color: "grey.A100" }} />
            )}
          </IconButton>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setEdit(true);
            }}
          >
            <EditIcon sx={{ color: "third.main" }} />
          </IconButton>
          <IconButton
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(deletePlaylistSegmentTag({ tagId: tag.id }));
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, bgcolor: "primary.dark" }}>
        <List component="div" disablePadding>
          {tag.subTags.map((sub, j) => (
            <ListItem
              sx={{
                pl: 5,
                borderBottom: `1px solid ${theme.palette.primary.dark}`,
              }}
              key={j}
            >
              <ListItemIcon sx={{ minWidth: 20 }}>⋅</ListItemIcon>
              <ListItemText>
                {subEdit === j ? (
                  <FormControl>
                    <TextField
                      spellCheck={false}
                      onKeyDown={(event) =>
                        event.key === "Enter" && event.currentTarget.blur()
                      }
                      autoFocus
                      variant="standard"
                      error={subtagError}
                      helperText={
                        subtagError
                          ? "Sub tag with this name already exist"
                          : ""
                      }
                      defaultValue={sub.name}
                      onChange={(event) =>
                        validateSubtag(event.target.value, sub)
                      }
                      onBlur={(event) => {
                        const name = event.target.value;
                        if (name !== sub.name) {
                          handleSubtagEdit({ ...sub, name });
                        }
                      }}
                    />
                  </FormControl>
                ) : (
                  <Typography>{sub.name} </Typography>
                )}
              </ListItemText>
              {mobileBreakpoint && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    pr: 1,
                    "& .MuiInputLabel-root": { color: "primary.light" },
                  }}
                >
                  <KeybindInput
                    size="small"
                    value={sub.keybind}
                    onBlur={(keybind) => {
                      if (!keybindEq(keybind, sub.keybind)) {
                        handleSubtagEdit({ ...sub, keybind });
                      }
                    }}
                  />
                  <OffsetInput
                    label="Start offset"
                    size="small"
                    value={sub.startOffset}
                    onBlur={(startOffset) =>
                      startOffset !== sub.startOffset &&
                      handleSubtagEdit({ ...sub, startOffset })
                    }
                  />
                  <OffsetInput
                    label="End offset"
                    size="small"
                    value={sub.endOffset}
                    onBlur={(endOffset) =>
                      endOffset !== sub.endOffset &&
                      handleSubtagEdit({ ...sub, endOffset })
                    }
                  />
                </Stack>
              )}
              <IconButton
                color="inherit"
                onClick={() =>
                  handleSubtagEdit({ ...sub, favourite: !sub.favourite })
                }
              >
                {sub.favourite ? (
                  <StarIcon sx={{ color: "warning.main" }} />
                ) : (
                  <StarOutlineIcon sx={{ color: "grey.A100" }} />
                )}
              </IconButton>
              <IconButton color="warning" onClick={() => setSubEdit(j)}>
                <EditIcon sx={{ color: "third.main" }} />
              </IconButton>
              <IconButton
                color="error"
                onClick={() => handleDeleteSubtag(sub.id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
          {isAdding && (
            <ListItem sx={{ cursor: "pointer", pl: 5 }}>
              <ListItemIcon sx={{ minWidth: 20 }}>⋅</ListItemIcon>
              <ListItemText>
                <FormControl>
                  <TextField
                    autoFocus
                    spellCheck={false}
                    onKeyDown={(event) =>
                      event.key === "Enter" &&
                      !subtagError &&
                      event.currentTarget.blur()
                    }
                    error={subtagError}
                    helperText={
                      subtagError ? "Subtag with this name already exist" : ""
                    }
                    variant="standard"
                    size="small"
                    onBlur={(event) => handleAddSubtag(event.target.value)}
                    onChange={(event) => validateSubtag(event.target.value)}
                  />
                </FormControl>
              </ListItemText>
            </ListItem>
          )}
          {!isAdding && (
            <ListItem sx={{ pl: 4 }}>
              <Button
                color="success"
                startIcon={<AddIcon />}
                onClick={() => {
                  setIsAdding(true);
                  setSubEdit(tag.subTags.length);
                }}
              >
                Add subtag
              </Button>
            </ListItem>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
