import { Tabs } from "components";
import { FC } from "react";
import { LiveStreams } from "./LiveStreams";

const TABS = [
  {
    key: "Live",
    Element: LiveStreams,
    live: true,
  },
  {
    key: "Archived",
    Element: LiveStreams,
    live: false,
  },
];

export const LiveStreamsView: FC = () => {
  return <Tabs tabs={TABS} />;
};
