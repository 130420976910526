import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";
import { PlaylistVideoInfo } from "generated/openapi";

export const getPlaylists = createAsyncThunk(
  "playlist/getPlaylists",
  Api.getPlaylists,
);

export const getPlaylistSegments = createAsyncThunk(
  "playlist/getPlaylistSegments",
  Api.getPlaylistSegments,
);

export const getPlaylist = createAsyncThunk(
  "playlist/getPlaylist",
  Api.getPlaylist,
);

export const composePlaylist = createAsyncThunk(
  "playlist/composePlaylist",
  Api.composePlaylist,
);

export const createPlaylist = createAsyncThunk(
  "playlist/createPlaylist",
  Api.createPlaylist,
);

export const updatePlaylist = createAsyncThunk(
  "playlist/updatePlaylist",
  Api.updatePlaylist,
);

export const startPlaylistExport = createAsyncThunk(
  "playlist/startPlaylistExport",
  Api.startPlaylistExport,
);

export const addPlaylistVideos = createAction<PlaylistVideoInfo[]>(
  "playlist/addPlaylistVideos",
);

export const removePlaylistVideo = createAction<number>(
  "playlist/removePlaylistVideo",
);

export const deletePlaylist = createAsyncThunk(
  "playlist/deletePlaylist",
  Api.deletePlaylist,
);

export const generatePlaylistHomography = createAsyncThunk(
  "playlist/generatePlaylistHomography",
  Api.generatePlaylistHomography,
);
