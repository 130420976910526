import { apiConfiguration, apiGet } from "api";
import {
  V1forzaApi,
  V1forzaApiForzaVideoAssetHomographyRequest,
} from "generated/openapi";

const api = new V1forzaApi(apiConfiguration);

export interface GetHomographyArgs
  extends V1forzaApiForzaVideoAssetHomographyRequest {
  token?: string;
}

export const getHomography = async ({ token, ...query }: GetHomographyArgs) =>
  apiGet(async () =>
    api.forzaVideoAssetHomography(query, {
      headers: { Authorization: `Bearer ${token}` },
    }),
  );
