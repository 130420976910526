import theme from "app/theme";
import { ChipAutocomplete } from "components";
import { FC, useMemo } from "react";
import {
  selectForzaTeamsMap,
  selectForzaTeamsSorted,
  useAppSelector,
} from "store";

interface Props {
  value?: number[];
  options?: number[];
  placeholder?: string;
  variant?: "filled" | "outlined" | "standard";
  disabled?: boolean;
  onChange?: (_: number[]) => void;
}

export const ForzaTeamsInput: FC<Props> = ({
  value: _value,
  options: _options,
  placeholder,
  variant,
  disabled,
  onChange,
}) => {
  const teams = useAppSelector(selectForzaTeamsSorted);
  const teamsMap = useAppSelector(selectForzaTeamsMap);

  const value = useMemo(
    () => _value?.map((v) => teamsMap[v]) ?? [],
    [_value, teamsMap],
  );

  const options = useMemo(
    () => _options?.map((v) => teamsMap[v]) ?? teams,
    [_options, teamsMap, teams],
  );

  return (
    <ChipAutocomplete
      color={theme.palette.grey.A400}
      placeholder={placeholder ?? "Add team"}
      value={value}
      disabled={disabled}
      variant={variant}
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={
        onChange ? (value) => onChange(value.map((v) => v.id)) : undefined
      }
    />
  );
};
