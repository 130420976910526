import { ForzaTeam } from "generated/openapi";

export interface ForzaTeamsState {
  forzaTeams: ForzaTeam[];
  forzaTeamsLoading: boolean;
}

export const initialForzaTeamsState: ForzaTeamsState = {
  forzaTeams: [],
  forzaTeamsLoading: false,
};
