import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { BubbleMenu, useCurrentEditor } from "@tiptap/react";
import { FC, useEffect, useState } from "react";

export const LinkBubbleMenu: FC = () => {
  const { editor } = useCurrentEditor();

  const [href, setHref] = useState("");
  const [editing, setEditing] = useState(false);

  const _href = editor?.getAttributes("link")?.href ?? "";

  useEffect(() => setHref(_href), [_href]);

  if (!editor) return <></>;

  const handleChange = () => {
    editor.chain().focus().extendMarkRange("link").setLink({ href }).run();
    setEditing(false);
  };

  return (
    <BubbleMenu
      editor={editor}
      pluginKey="link-bubble-menu"
      tippyOptions={{ placement: "bottom" }}
      shouldShow={({ editor }) => editor.isEditable && editor.isActive("link")}
    >
      <Stack
        direction="row"
        sx={{ padding: 1, borderRadius: 2, bgcolor: "primary.main" }}
      >
        {editing ? (
          <TextField
            autoFocus
            size="small"
            variant="outlined"
            value={href}
            onChange={(e) => setHref(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleChange()}
            onBlur={handleChange}
          />
        ) : (
          <a
            href={href}
            rel="noreferrer"
            target="_blank"
            style={{ marginBlock: "auto" }}
          >
            {href}
          </a>
        )}

        <IconButton sx={{ marginLeft: 2 }} onClick={() => setEditing(true)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => editor.chain().focus().unsetLink().run()}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </BubbleMenu>
  );
};
