import { PlaylistTagsSettings, SegmentTagsSettings, Tabs } from "components";
import { FC } from "react";

const TABS = [
  {
    key: "Playlists",
    Element: PlaylistTagsSettings,
  },
  {
    key: "Segments",
    Element: SegmentTagsSettings,
  },
];

export const SettingsView: FC = () => {
  return (
    <Tabs
      tabs={TABS}
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    />
  );
};
