import {
  ForzaGame,
  V1forzaApiForzaGamesRequest,
  ValuesTotalForzaGame,
} from "generated/openapi";

export interface ForzaGamesState {
  forzaGame?: ForzaGame;
  forzaGames?: ValuesTotalForzaGame;
  forzaGamesFilter: V1forzaApiForzaGamesRequest;
  forzaGamesLoading: boolean;
}

export const initialForzaGamesState: ForzaGamesState = {
  forzaGame: undefined,
  forzaGames: undefined,
  forzaGamesFilter: { limit: 20, offset: 0 },
  forzaGamesLoading: false,
};
