import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Chip, Popover, Stack } from "@mui/material";
import theme from "app/theme";
import { FC, ReactNode, useState } from "react";

interface Props {
  children: ReactNode;
  numActiveFilters: number;
  onReset?: () => void;
}

export const SegmentFilterMenu: FC<Props> = ({
  children,
  numActiveFilters,
  onReset,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Chip
        sx={{
          borderRadius: 1,
          width: 145,
          height: 35,
          borderColor: "primary.light",
          "& .MuiChip-deleteIcon": {
            mr: 0,
          },
          "& .MuiChip-icon": {
            ml: 0,
          },
          "& .MuiChip-label": {
            fontSize: "0.92rem",
          },
        }}
        label={
          numActiveFilters !== 0 ? `Filters (${numActiveFilters})` : "Filters"
        }
        variant="outlined"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        onDelete={onReset ?? undefined}
        icon={<FilterAltIcon fontSize="small" />}
      />

      <Popover
        open={Boolean(anchorEl)}
        disableScrollLock
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack
          sx={{
            bgcolor: "primary.main",
            p: 2,
            maxWidth: 450,
            border: `1px solid ${theme.palette.primary.light}`,
          }}
          spacing={4}
        >
          {children}
        </Stack>
      </Popover>
    </>
  );
};
