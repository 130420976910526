import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { Header } from "components";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import React, { ReactNode } from "react";

const HeaderTrack = (props: any) => (
  <Box
    sx={{
      gridColumnStart: 1,
      gridColumnEnd: 4,
      gridRowStart: 1,
      gridRowEnd: 2,
    }}
  >
    {props.children}
  </Box>
);

const MainArea = (props: any) => (
  <Box
    sx={{
      gridColumnStart: 2,
      gridColumnEnd: 3,
      gridRowStart: 2,
      py: 3,
    }}
  >
    {props.children}
  </Box>
);

interface Props {
  children: ReactNode;
}

const GridContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 70% 1fr",
  gridTemplateRows: "50px 1fr",
  position: "absolute",
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr 90% 1fr",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "1fr 80% 1fr",
  },
  [theme.breakpoints.up(1921)]: {
    gridTemplateColumns: "1fr 70% 1fr",
  },
  [theme.breakpoints.up("xxl")]: {
    gridTemplateColumns: "1fr 60% 1fr",
  },
}));

export const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <HeaderTrack>
        <Header />
      </HeaderTrack>
      <OverlayScrollbarsComponent
        className="overlayscrollbars-react"
        options={{ scrollbars: { theme: "os-theme-light" } }}
        defer
      >
        <Box sx={{ height: "100vh", width: "100%" }}>
          <GridContainer>
            <MainArea>
              <main>{children}</main>
            </MainArea>
          </GridContainer>
        </Box>
      </OverlayScrollbarsComponent>
    </>
  );
};
