import { Autocomplete, TextField, Typography } from "@mui/material";
import { TeamDetailed } from "generated/openapi";
import { FC } from "react";
import { selectTeamsMap, selectTeamsSorted, useAppSelector } from "store";

interface Props {
  label?: string;
  value?: number | null;
  defaultValue?: number;
  teams?: TeamDetailed[];
  size?: "small" | "medium";
  color?:
    | "error"
    | "primary"
    | "secondary"
    | "third"
    | "fourth"
    | "fifth"
    | "info"
    | "success"
    | "warning";
  disableClearable?: boolean;
  disabled?: boolean;
  onChange: (_?: TeamDetailed) => void;
}

export const TeamsFilter: FC<Props> = ({
  label,
  value,
  defaultValue,
  teams,
  size,
  color,
  disableClearable,
  disabled,
  onChange,
}) => {
  const _teams = useAppSelector(selectTeamsSorted) ?? [];
  const teamsMap = useAppSelector(selectTeamsMap) ?? {};

  label ??= "Team";
  teams ??= _teams;

  return (
    <Autocomplete
      disabled={disabled}
      size={size ?? "small"}
      sx={{ minWidth: "12rem" }}
      disableClearable={disableClearable}
      value={value != null ? teamsMap[value] : null}
      defaultValue={
        defaultValue !== undefined ? teamsMap[defaultValue] : undefined
      }
      options={teams}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ?? "Team"}
          color={color ?? "primary"}
        />
      )}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Typography {...props} key={option.id}>
          {option.name}
        </Typography>
      )}
      onChange={(_, v) => onChange(v ?? undefined)}
      onKeyDown={(e) => e.stopPropagation()}
    />
  );
};
