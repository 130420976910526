import theme from "app/theme";
import { ChipAutocomplete } from "components";
import { FC, useMemo } from "react";
import { selectPlayersMap, selectPlayersSorted, useAppSelector } from "store";

interface Props {
  placeholder?: string;
  value?: string[];
  options?: string[];
  variant?: "filled" | "outlined" | "standard";
  disabled?: boolean;
  onChange?: (_: string[]) => void;
}

export const PlayersInput: FC<Props> = ({
  placeholder,
  value: _value,
  options: _options,
  variant,
  disabled,
  onChange,
}) => {
  const players = useAppSelector(selectPlayersSorted) ?? [];
  const playersMap = useAppSelector(selectPlayersMap) ?? {};

  const value = _value?.length ? _value.map((v) => playersMap[v]) : [];
  const options = useMemo(() => {
    if (!_options) {
      return players;
    }
    const options = _options.map((v) => playersMap[v]);
    options.sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`,
      ),
    );
    return options;
  }, [_options, players]);

  return (
    <ChipAutocomplete
      color={theme.palette.grey.A700}
      placeholder={placeholder ?? "Add player"}
      value={value}
      disabled={disabled}
      variant={variant}
      options={options}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      onChange={
        onChange ? (value) => onChange(value.map((v) => v.id)) : undefined
      }
    />
  );
};
