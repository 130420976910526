import { getPlaylist } from "api";
import { ForzaEvent } from "generated/openapi";
import { FC } from "react";
import {
  createPlaylist,
  selectAccessToken,
  selectUser,
  updatePlaylist,
  useAppDispatch,
  useAppSelector,
} from "store";
import { AddToPlaylistModal } from "./AddToPlaylistModal";

interface Props {
  open: boolean;
  forzaEvent: ForzaEvent;
  onClose: (message?: string) => void;
}

export const AddEventToPlaylistModal: FC<Props> = ({
  open,
  forzaEvent,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const token = useAppSelector(selectAccessToken);
  const user = useAppSelector(selectUser);

  return (
    <AddToPlaylistModal
      open={open}
      numToAdd={1}
      onClose={() => onClose()}
      onCreate={(p) => {
        dispatch(
          createPlaylist({
            playlist: {
              ...p,
              thumbnailUrl: forzaEvent.thumbnailUrl,
              segments: [
                {
                  userId: user?.id,
                  forzaGameId: forzaEvent.forzaGameId,
                  title: forzaEvent.description,
                  videoAssetId: forzaEvent.videoAssetId,
                  fromTimestamp: forzaEvent.fromTimestamp - 20_000,
                  toTimestamp: forzaEvent.toTimestamp,
                  tags: [],
                  playerTags: [],
                  drawings: {},
                  footballFieldGrids: [],
                  footballFieldSequenceGrids: [],
                  favourite: false,
                },
                ...(p.segments ?? []),
              ],
            },
          }),
        ).then(() => onClose("Playlist Created"));
      }}
      onUpdate={(p) => {
        getPlaylist({ playlistId: p.id, token }).then((p) => {
          dispatch(
            updatePlaylist({
              playlist: {
                ...p,
                segments: [
                  {
                    id: -1,
                    creator: user,
                    forzaGameId: forzaEvent.forzaGameId,
                    title: forzaEvent.description,
                    videoAssetId: forzaEvent.videoAssetId,
                    homeTeamId: forzaEvent.homeTeamId,
                    visitingTeamId: forzaEvent.visitingTeamId,
                    fromTimestamp: forzaEvent.fromTimestamp - 20_000,
                    toTimestamp: forzaEvent.toTimestamp,
                    videoBaseUrl: forzaEvent.videoBaseUrl,
                    gameHasPanorama: false,
                    hasHomography: false,
                    favourite: false,
                    tags: [],
                    playerTags: [],
                    forzaTeamTags: [],
                    drawings: {},
                    homographyFrames: {},
                    footballFieldGrids: [],
                    footballFieldSequenceGrids: [],
                  },
                  ...p.segments,
                ],
              },
            }),
          ).then(() => onClose("Added To Playlist"));
        });
      }}
    />
  );
};
