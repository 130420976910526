import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Button, Modal } from "@mui/material";
import { Stack } from "@mui/system";
import { HEIGHT_FACTOR } from "components/FootballField/FootballField";
import { FootballFieldTactical } from "components/FootballField/FootballFieldTactical";
import { PlayerTeam, TacticalPitch } from "generated/openapi";
import { FC, useEffect, useRef, useState } from "react";

export const initialTacticalPitch = {
  players: [
    {
      team: PlayerTeam.A,
      xPercentage: 1 / 6,
      yPercentage: 1 / 5,
    },
    {
      team: PlayerTeam.A,
      xPercentage: 1 / 6,
      yPercentage: 2 / 5,
    },
    {
      team: PlayerTeam.A,
      xPercentage: 1 / 6,
      yPercentage: 3 / 5,
    },
    {
      team: PlayerTeam.A,
      xPercentage: 1 / 6,
      yPercentage: 4 / 5,
    },
    {
      team: PlayerTeam.A,
      xPercentage: 2 / 6,
      yPercentage: 1 / 5,
    },
    {
      team: PlayerTeam.A,
      xPercentage: 2 / 6,
      yPercentage: 2 / 5,
    },
    {
      team: PlayerTeam.A,
      xPercentage: 2 / 6,
      yPercentage: 3 / 5,
    },
    {
      team: PlayerTeam.A,
      xPercentage: 2 / 6,
      yPercentage: 4 / 5,
    },
    {
      team: PlayerTeam.A,
      xPercentage: 0.45,
      yPercentage: 1 / 3,
    },
    {
      team: PlayerTeam.A,
      xPercentage: 0.45,
      yPercentage: 2 / 3,
    },
    {
      team: PlayerTeam.B,
      xPercentage: 5 / 6,
      yPercentage: 1 / 5,
    },
    {
      team: PlayerTeam.B,
      xPercentage: 5 / 6,
      yPercentage: 2 / 5,
    },
    {
      team: PlayerTeam.B,
      xPercentage: 5 / 6,
      yPercentage: 3 / 5,
    },
    {
      team: PlayerTeam.B,
      xPercentage: 5 / 6,
      yPercentage: 4 / 5,
    },
    {
      team: PlayerTeam.B,
      xPercentage: 4 / 6,
      yPercentage: 1 / 5,
    },
    {
      team: PlayerTeam.B,
      xPercentage: 4 / 6,
      yPercentage: 2 / 5,
    },
    {
      team: PlayerTeam.B,
      xPercentage: 4 / 6,
      yPercentage: 3 / 5,
    },
    {
      team: PlayerTeam.B,
      xPercentage: 4 / 6,
      yPercentage: 4 / 5,
    },
    {
      team: PlayerTeam.B,
      xPercentage: 0.55,
      yPercentage: 1 / 3,
    },
    {
      team: PlayerTeam.B,
      xPercentage: 0.55,
      yPercentage: 2 / 3,
    },
  ],
};

export interface Props {
  open: boolean;
  value: TacticalPitch;
  disabled: boolean;
  onChange: (_: TacticalPitch) => void;
  onClose: () => void;
}

interface Size {
  width: number;
  height: number;
}

export const Tactical: FC<Props> = ({
  open,
  value,
  disabled,
  onChange,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [size, setSize] = useState<Size>({ width: 1, height: 1 });

  useEffect(() => {
    const fn = () =>
      setSize((s) =>
        ref.current
          ? {
              width: ref.current.offsetWidth,
              height: Math.max(1, ref.current.offsetHeight - 36), // Subtract for the button row,
            }
          : { ...s },
      );
    fn();
    window.addEventListener("resize", fn);
    return () => {
      window.removeEventListener("resize", fn);
    };
  }, [open, ref.current]);

  let width = size.width;
  let height = width * HEIGHT_FACTOR;

  if (height > size.height) {
    height = size.height;
    width = height / HEIGHT_FACTOR;
  }

  return (
    <Modal disableScrollLock open={open} onClose={onClose}>
      <Box
        ref={ref}
        sx={{
          display: "grid",
          width: "70vw",
          height: "100vh",
          marginInline: "auto",
        }}
        onClick={onClose}
      >
        <Stack
          sx={{ width, placeSelf: "center" }}
          onClick={(e) => e.stopPropagation()}
        >
          <Button
            color="inherit"
            disabled={disabled}
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              bgcolor: "primary.light",
              ":hover": {
                bgcolor: "primary.dark",
              },
            }}
            onClick={() => onChange(initialTacticalPitch)}
            startIcon={<ReplayIcon />}
          >
            Reset
          </Button>
          <FootballFieldTactical
            value={value}
            width={width}
            height={height}
            onChange={disabled ? undefined : onChange}
          />
        </Stack>
      </Box>
    </Modal>
  );
};
