import { apiConfiguration, apiGet } from "api";
import { CreateInvite, V1inviteApi } from "generated/openapi";

const api = new V1inviteApi(apiConfiguration);

export const getInvite = async (inviteId: string, token: string) =>
  apiGet(async () =>
    api.getInvite(
      { inviteId },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const createInvite = async (invite: CreateInvite, token: string) =>
  apiGet(async () =>
    api.createInvite(
      { createInvite: invite },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const acceptInvite = async (inviteId: string, token: string) =>
  apiGet(async () =>
    api.acceptInvite(
      { inviteId },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );
