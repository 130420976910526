import EditNoteIcon from "@mui/icons-material/EditNote";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { MAX_PLAYLIST_SEGMENTS } from "api";
import theme from "app/theme";
import { CombinedPlayer, LoadingScreen, VideoAssets } from "components";
import {
  VideoPlayer,
  VideoPlayerRef,
} from "components/VideoPlayer/VideoPlayer";
import { AddSegmentsToPlaylistModal } from "containers/Playlist/AddSegmentsToPlaylistModal";
import { PlaylistSegments } from "containers/Playlist/PlaylistSegments";
import {
  LiveStreamEventDiscriminants,
  PlaylistSegment,
  UserRole,
} from "generated/openapi";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getForzaGame,
  selectForzaGame,
  selectForzaTeamsMap,
  selectSegmentTagsFlattenedMap,
  selectUser,
  setAlert,
  useAppDispatch,
  useAppSelector,
} from "store";
import { dateFormat, getVideoOffset, minutesSeconds } from "utils/utils";
import { ForzaGameTagsMenu } from "./ForzaGameTagsMenu";

export const ForzaGame: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const _gameId = params.gameId ? +params.gameId : undefined;

  if (!Number.isFinite(_gameId)) {
    navigate("/forza/games");
  }

  const gameId = _gameId!;

  const dispatch = useAppDispatch();
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up("xl"));

  const user = useAppSelector(selectUser);
  const game = useAppSelector(selectForzaGame);
  const teams = useAppSelector(selectForzaTeamsMap);
  const tagsMap = useAppSelector(selectSegmentTagsFlattenedMap)!;

  const [playerRef, setPlayerRef] = useState<VideoPlayerRef | null>(null);

  const [thumbnail, setThumbnail] = useState<string | undefined>(undefined);
  const [segments, setSegments] = useState<PlaylistSegment[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAssetIdx, setSelectedAssetIdx] = useState(0);

  useEffect(() => {
    if (!game || game.id !== gameId) {
      dispatch(getForzaGame({ forzaGameId: gameId }));
    }
  }, [gameId]);

  if (!game) {
    // TODO: Detect if just waiting, or if game does not exist
    return <LoadingScreen />;
  }

  const asset = game.videoAssets[selectedAssetIdx];
  const url = `${asset.videoBaseUrl}/playlist.m3u8/${asset.videoAssetId}:${asset.firstHalfZeroOffsetWallClockTime ?? 0}:9999999999/Manifest.m3u8`;

  if (user?.userRole === UserRole.Player) {
    return (
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="h3">
            {teams[game.homeTeamId].name} vs. {teams[game.visitingTeamId].name}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
            {dateFormat(game.date, "d MMMM, yyyy")}
          </Typography>
        </Stack>
        <VideoPlayer playing url={url} controlFullscreenId clickToPlay />
      </Stack>
    );
  }

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3">
            {teams[game.homeTeamId].name} vs. {teams[game.visitingTeamId].name}
          </Typography>
          {game.playlistCreators.length > 0 && (
            <Box sx={{ alignSelf: "flex-end" }}>
              <Tooltip
                sx={{ fontSize: "3rem" }}
                title={
                  <Stack>
                    {game.playlistCreators.map((c) => (
                      <Typography key={c.id} variant="caption">
                        {c.firstName} {c.lastName}
                      </Typography>
                    ))}
                  </Stack>
                }
              >
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  badgeContent={
                    <Avatar
                      sx={{
                        width: 20,
                        height: 20,
                        bgcolor: "white",
                        border: "1px solid black",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                      >
                        {game.playlistCreators.length}
                      </Typography>
                    </Avatar>
                  }
                >
                  <Avatar sx={{ bgcolor: "fourth.main" }}>
                    <EditNoteIcon sx={{ fontSize: "1.7rem", color: "white" }} />
                  </Avatar>
                </Badge>
              </Tooltip>
            </Box>
          )}
        </Stack>
        <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
          {dateFormat(game.date, "d MMMM, yyyy")}
        </Typography>
      </Box>
      <Stack
        direction={xlBreakpoint ? "row" : "column"}
        gap={3}
        sx={{
          color: "white",
        }}
      >
        <Stack gap={2} sx={{ width: "100%" }}>
          <VideoAssets
            assets={game.videoAssets}
            selected={selectedAssetIdx}
            onSelect={setSelectedAssetIdx}
          />

          <CombinedPlayer
            ref={(v) => setPlayerRef(v as VideoPlayerRef | null)}
            url={url}
            gameId={gameId}
            getVideoOffset={() =>
              getVideoOffset(
                asset,
                playerRef!.getCurrentMillis(),
                asset?.firstHalfZeroOffsetWallClockTime,
              )
            }
          />

          {segments.length < MAX_PLAYLIST_SEGMENTS && (
            <ForzaGameTagsMenu
              videoPlayer={playerRef}
              onClick={(tag, timestamp) => {
                let fromTimestamp = Math.max(
                  0,
                  timestamp - (tag.startOffset ?? 30_000),
                );
                let toTimestamp = timestamp + (tag.endOffset ?? 10_000);

                if (asset.firstHalfZeroOffsetWallClockTime) {
                  fromTimestamp += asset.firstHalfZeroOffsetWallClockTime;
                  toTimestamp += asset.firstHalfZeroOffsetWallClockTime;
                }

                let wallClock;

                const firstHalf =
                  asset.firstHalfZeroOffsetWallClockTime ?? undefined;
                const secondHalf =
                  asset.secondHalfZeroOffsetWallClockTime ?? undefined;

                if (firstHalf !== undefined) {
                  if (secondHalf !== undefined && fromTimestamp > secondHalf) {
                    wallClock =
                      fromTimestamp + firstHalf - secondHalf + 60_000 * 45;
                  } else {
                    wallClock = fromTimestamp - firstHalf;
                  }
                } else if (
                  secondHalf !== undefined &&
                  fromTimestamp > secondHalf
                ) {
                  wallClock = fromTimestamp - secondHalf + 60_000 * 45;
                }

                if (wallClock !== undefined && wallClock < 0) {
                  wallClock = 0;
                }

                const segment = {
                  id: -1,
                  creator: user,
                  type: LiveStreamEventDiscriminants.NewMessage,
                  forzaGameId: game.id,
                  videoAssetId: asset.videoAssetId,
                  homeTeamId: game.homeTeamId,
                  visitingTeamId: game.visitingTeamId,
                  gameHasPanorama: game.hasPanorama,
                  videoBaseUrl: asset.videoBaseUrl,
                  hasHomography: false,
                  favourite: false,
                  description: "",
                  fromTimestamp,
                  toTimestamp,
                  tags: [tag.id],
                  playerTags: [],
                  forzaTeamTags: [],
                  title: `Clip at ${wallClock !== undefined ? minutesSeconds(wallClock) : "Unknown"}`,
                  drawings: {},
                  homographyFrames: {},
                  footballFieldGrids: [],
                  footballFieldSequenceGrids: [],
                };
                setSegments((s) => s.concat([segment]));
                if (!thumbnail) {
                  setThumbnail(playerRef?.getCurrentFrame(600));
                }
              }}
            />
          )}
        </Stack>

        {segments.length > 0 && segments.length <= MAX_PLAYLIST_SEGMENTS && (
          <>
            <Stack gap={2} sx={{ width: xlBreakpoint ? 500 : "100%" }}>
              <Button
                variant="contained"
                color="info"
                sx={{ bgcolor: "fourth.dark", borderRadius: 0 }}
                startIcon={<PlaylistAddIcon />}
                onClick={() => setModalOpen(true)}
              >
                ADD TO PLAYLIST
              </Button>
              <AddSegmentsToPlaylistModal
                open={modalOpen}
                segments={segments}
                onClose={(message) => {
                  message &&
                    dispatch(
                      setAlert({
                        message,
                        severity: "success",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }),
                    );
                  setModalOpen(false);
                }}
                thumbnailData={thumbnail}
              />
              <PlaylistSegments
                segments={segments}
                subtitle={(s) =>
                  `${minutesSeconds(s.toTimestamp - s.fromTimestamp)} - ${tagsMap[s.tags[0]].displayName}`
                }
                onChange={(v) => {
                  setSegments(v);
                  if (!v.length) {
                    setThumbnail(undefined);
                  }
                }}
              />
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};
