import { ChipAutocomplete } from "components";
import { PlaylistTag } from "generated/openapi";
import { FC } from "react";
import { selectPlaylistTagsSorted, useAppSelector } from "store";

interface Props {
  value?: PlaylistTag[];
  options?: PlaylistTag[];
  onChange: (_?: PlaylistTag[]) => void;
}

export const PlaylistTagsFilter: FC<Props> = ({ value, onChange, options }) => {
  const tags = useAppSelector(selectPlaylistTagsSorted) ?? [];

  options ??= tags;

  return (
    <ChipAutocomplete
      placeholder="Tags"
      value={value ?? []}
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={(value) => onChange(value.length ? value : undefined)}
    />
  );
};
