import {
  Autocomplete,
  autocompleteClasses,
  Popper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { ForzaEventType, ForzaPlayer } from "generated/openapi";
import { FC, useEffect } from "react";
import {
  getForzaPlayers,
  selectForzaPlayersSorted,
  selectForzaTeamPlayersSorted,
  selectSelectedEventType,
  useAppDispatch,
  useAppSelector,
} from "store";

interface Props {
  teamId?: number;
  onChange: (_?: ForzaPlayer) => void;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
  },
  [`& .${autocompleteClasses.option}`]: {
    borderBottom: "1px solid",
    borderColor: theme.palette.primary.main,
    color: "white",
    "& .MuiTypography-caption": { color: "grey" },
  },
  [`& .${autocompleteClasses.paper}`]: {
    borderRadius: 0,
    marginRight: "1px",
    marginLeft: "1px",
    width: 300,
  },
}));

export const PlayersFilter: FC<Props> = ({ teamId, onChange }) => {
  const dispatch = useAppDispatch();
  const selectedEventType = useAppSelector(selectSelectedEventType);

  const players = useAppSelector(
    teamId ? selectForzaTeamPlayersSorted(teamId) : selectForzaPlayersSorted,
  );

  useEffect(() => {
    dispatch(getForzaPlayers());
  }, [dispatch]);

  return (
    <Autocomplete
      disabled={selectedEventType === ForzaEventType.Corner}
      size="small"
      sx={{
        width: "100%",
      }}
      options={players}
      PopperComponent={StyledPopper}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Typography {...props} key={option.id}>
          {option.name}
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            selectedEventType === ForzaEventType.Freekick
              ? "Offending Player"
              : "Player"
          }
        />
      )}
      onChange={(_event, value) => onChange(value ?? undefined)}
    />
  );
};
