import { Button, TextField, Typography } from "@mui/material";
import { CreateOrg as Org } from "generated/openapi";
import { FC } from "react";
import { Form, useForm } from "react-hook-form";
import { createOrg, useAppDispatch } from "store";

export const CreateOrg: FC = () => {
  const dispatch = useAppDispatch();

  const {
    control,
    register,
    formState: { errors },
  } = useForm<Org>();

  return (
    <Form
      control={control}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginTop: "2rem",
        marginInline: "auto",
        width: 380,
      }}
      onSubmit={({ data }) => {
        dispatch(createOrg({ org: data }));
      }}
    >
      <Typography sx={{ pb: 2 }} variant="h3">
        Create Org
      </Typography>

      <TextField
        color="info"
        label="Name"
        variant="outlined"
        error={!!errors.name}
        {...register("name", { required: true })}
      />

      <Button color="info" type="submit">
        Submit
      </Button>
    </Form>
  );
};
