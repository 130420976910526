import FileUploadIcon from "@mui/icons-material/FileUpload";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Backdrop, IconButton, Stack } from "@mui/material";
import { FC, useEffect, useMemo, useRef, useState } from "react";

export interface Props {
  accept: string;
  error?: boolean;
  valid?: boolean;
  onChange: (_: string) => void;
}

export const FileUpload: FC<Props> = ({ accept, error, valid, onChange }) => {
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const cameraRef = useRef<HTMLInputElement | null>(null);

  const [dragover, setDragover] = useState(false);
  const [captureSupported, setCaptureSupported] = useState(false);

  const regex = useMemo(() => new RegExp(accept), [accept]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      for (const d of devices) {
        if (d.kind === "videoinput") {
          setCaptureSupported(true);
          return;
        }
      }
    });
  }, []);

  const handleChange = useMemo(
    () => (files: FileList | null) => {
      if (files?.length === 1) {
        const reader = new FileReader();
        reader.onload = () => onChange(reader.result as string);
        reader.readAsDataURL(files[0]);
      }
    },
    [onChange],
  );

  useEffect(() => {
    const dragoverFn = (e: DragEvent) => {
      e.preventDefault();
      setDragover(regex.test(e.dataTransfer?.items[0].type ?? ""));
    };
    const dragleaveFn = (e: DragEvent) => {
      e.preventDefault();
      setDragover(e.x !== 0 && e.y !== 0);
    };
    const dropFn = (e: DragEvent) => {
      if (regex.test(e.dataTransfer?.items[0].type ?? "")) {
        e.preventDefault();
        setDragover(false);
        handleChange(e.dataTransfer!.files);
      }
    };
    document.addEventListener("dragover", dragoverFn);
    document.addEventListener("dragleave", dragleaveFn);
    document.addEventListener("drop", dropFn);
    return () => {
      document.removeEventListener("dragover", dragoverFn);
      document.removeEventListener("dragleave", dragleaveFn);
      document.removeEventListener("drop", dropFn);
    };
  }, [regex, handleChange]);

  return (
    <Stack
      direction="row"
      gap={2}
      justifyContent="center"
      sx={{
        border: "1px solid",
        borderRadius: 1,
        borderColor: valid ? "#03dac6" : error ? "#f44336" : "primary.light",
        paddingBlock: 4,
      }}
    >
      <IconButton onClick={() => uploadRef.current!.click()}>
        <FileUploadIcon fontSize="large" />
      </IconButton>
      {captureSupported && (
        <IconButton onClick={() => cameraRef.current!.click()}>
          <PhotoCameraIcon fontSize="large" />
        </IconButton>
      )}
      <input
        hidden
        type="file"
        ref={uploadRef}
        accept={accept}
        onChange={(e) => handleChange(e.target.files)}
      />
      <input
        hidden
        type="file"
        capture="user"
        ref={cameraRef}
        accept={accept}
        onChange={(e) => handleChange(e.target.files)}
      />
      <Backdrop open={dragover} sx={{ bgcolor: "#03dac610" }}>
        <FileUploadIcon sx={{ fontSize: "4rem" }} />
      </Backdrop>
    </Stack>
  );
};
