import { useAuth0 } from "@auth0/auth0-react";
import CheckIcon from "@mui/icons-material/Check";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import theme from "app/theme";
import { TeamDetailed, UserRole } from "generated/openapi";
import { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  selectOrg,
  selectSelectedTeam,
  selectUser,
  setSelectedTeam,
  useAppDispatch,
  useAppSelector,
} from "store";

export const pathToTab = (path: string): number | boolean => {
  if (path === "/" || path.startsWith("/forza/events")) {
    return 0;
  } else if (path.startsWith("/playlists")) {
    return 1;
  } else if (path.startsWith("/journals")) {
    return 2;
  } else if (path.startsWith("/forza/games")) {
    return 3;
  } else if (path.startsWith("/live_streams")) {
    return 4;
  }
  return false;
};

export const Header: FC = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const org = useAppSelector(selectOrg);
  const selectedTeam = useAppSelector(selectSelectedTeam);

  const [tabValue, setTabValue] = useState(pathToTab(location.pathname));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: { redirect_uri: window.location.href },
      });
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleTeamChange = (_e: any, val: TeamDetailed) => {
    if (val) {
      dispatch(setSelectedTeam(val));
      // Refresh current page with new team value
      navigate(0);
    }
  };

  useEffect(
    () => setTabValue(pathToTab(location.pathname)),
    [location.pathname],
  );

  const navItems = [
    {
      label: "Highlights",
      route: "/highlights",
      disabled: false,
      hidden: false,
    },
    {
      label: "Playlists",
      route: "/playlists",
      disabled: !isAuthenticated || !org?.teams.length,
      hidden: false,
    },
    {
      label: "Journals",
      route: "/journals",
      disabled: !isAuthenticated || !org,
      hidden: false,
    },
    {
      label: "Games",
      route: "/forza/games",
      disabled: !isAuthenticated || !org,
      hidden: false,
    },
    {
      label: "Live",
      route: "/live_streams",
      disabled: !isAuthenticated || !org?.teams.length,
      hidden: user?.userRole === UserRole.Player,
    },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List component="nav">
        {navItems.map(
          (item, i) =>
            !item.hidden && (
              <ListItem key={i} component={Link} to={item.route} disablePadding>
                <ListItemButton
                  sx={{
                    textAlign: "center",
                    color: "white",
                    borderLeft:
                      pathToTab(location.pathname) === i
                        ? `4px solid ${theme.palette.secondary.main}`
                        : "",
                    bgcolor:
                      pathToTab(location.pathname) === i ? "primary.dark" : "",
                  }}
                  disabled={item.disabled}
                >
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            ),
        )}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 70% 1fr",
          [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "1fr 90% 1fr",
          },
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "1fr 80% 1fr",
          },
          [theme.breakpoints.up(1921)]: {
            gridTemplateColumns: "1fr 70% 1fr",
          },
          [theme.breakpoints.up("xxl")]: {
            gridTemplateColumns: "1fr 60% 1fr !important", // No idea why !important is necessary
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          bgcolor: "primary.main",
          color: "white",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Toolbar
          variant="dense"
          disableGutters
          sx={{
            gridColumnStart: 2,
            gridColumnEnd: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Link
            style={{ maxHeight: 50, textDecoration: "none" }}
            to="/"
            onClick={() => setTabValue(0)}
          >
            <Typography
              color="white"
              variant="h4"
              fontFamily={"'Zen Dots', sans-serif"}
            >
              Forcalytics
            </Typography>
          </Link>

          <Tabs
            value={tabValue}
            sx={{
              display: { xs: "none", md: "block" },
              maxHeight: 50,
              "& .MuiTab-root": {
                color: "text.secondary",
                "&:hover": {
                  color: "white",
                },
                "&.Mui-selected": {
                  color: "white",
                  fontWeight: theme.typography.fontWeightBold,
                },
              },
            }}
            onChange={(_, val: number) => setTabValue(val)}
            indicatorColor="secondary"
            TabIndicatorProps={{ style: { height: 3 } }}
          >
            {navItems.map(
              (item, i) =>
                !item.hidden && (
                  <Tab
                    key={i}
                    component={Link}
                    to={item.route}
                    label={item.label}
                    disabled={item.disabled}
                  />
                ),
            )}
          </Tabs>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!user && (
              <Button
                size="large"
                sx={{ color: "white" }}
                variant="outlined"
                onClick={() => {
                  loginWithRedirect({
                    authorizationParams: { redirect_uri: window.location.href },
                  });
                }}
                endIcon={
                  <PersonIcon sx={{ color: "white" }} fontSize="large" />
                }
              >
                Login
              </Button>
            )}
            {user && (
              <>
                {org && user.userRole !== UserRole.Player && (
                  <IconButton component={Link} to={"/settings"} sx={{ mr: 1 }}>
                    <SettingsIcon sx={{ color: "text.secondary" }} />
                  </IconButton>
                )}
                <Avatar
                  onClick={handleMenu}
                  sx={{
                    width: 34,
                    height: 34,
                    bgcolor: "secondary.main",
                    cursor: "pointer",
                    color: "text.secondary",
                  }}
                >
                  {user?.firstName?.charAt(0)}
                </Avatar>

                <Menu
                  disableScrollLock
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <Stack direction="row" gap={1.5} sx={{ p: 2, pt: 1 }}>
                    <Avatar>{user?.firstName?.charAt(0)}</Avatar>
                    <Stack direction="column">
                      <Typography>
                        {user?.firstName + " " + user?.lastName}
                      </Typography>
                      <Typography variant="subtitle2">{user.email}</Typography>
                    </Stack>
                  </Stack>
                  <Divider sx={{ bgcolor: "primary.main", mb: 1 }} />
                  {org?.teams && org.teams.length > 1 && (
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          px: 1,
                          py: 0.5,
                          color: "primary.dark",
                          fontStyle: "italic",
                        }}
                      >
                        Select team
                      </Typography>
                      <ToggleButtonGroup
                        sx={{ width: "100%" }}
                        orientation="vertical"
                        value={selectedTeam}
                        exclusive
                        onChange={handleTeamChange}
                      >
                        {org?.teams.map((t) => (
                          <ToggleButton
                            key={t.id}
                            value={t}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              px: 2,
                              py: 1.5,
                              border: 0,
                              borderRadius: 0,
                              ":hover": {
                                bgcolor: "primary.dark",
                              },
                            }}
                          >
                            {t.name}
                            {t === selectedTeam && (
                              <CheckIcon sx={{ alignSelf: "flex-end" }} />
                            )}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                      <Divider sx={{ bgcolor: "primary.main", my: 1 }} />
                    </Box>
                  )}

                  {(org ?? user.userRole === UserRole.Admin) && (
                    <Link
                      style={{ color: "white", textDecoration: "none" }}
                      to={"/org"}
                    >
                      <MenuItem onClick={() => setAnchorEl(null)}>
                        <ListItemIcon>
                          <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        {org ? "Settings" : "Create Org"}
                      </MenuItem>
                    </Link>
                  )}
                  <MenuItem
                    onClick={() => {
                      logout({
                        logoutParams: { returnTo: window.location.origin },
                      });
                    }}
                  >
                    <ListItemIcon>
                      <LogoutSharpIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          disableScrollLock
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 200,
              bgcolor: "primary.main",
            },
          }}
        >
          <Toolbar />
          {drawer}
        </Drawer>
      </AppBar>
    </>
  );
};
