import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { fontStyle } from "app/theme";
import { Confirm, ConfirmModal, LoadingScreen } from "components";
import { UserRole } from "generated/openapi";
import { FC, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { selectOrg, selectOrgLoading, selectUser, useAppSelector } from "store";
import { CreateOrg } from "./CreateOrg";
import { Overview } from "./Overview";
import { Teams } from "./Teams";
import { Users } from "./Users";

export enum SettingsSubRoute {
  Overview,
  Teams,
  Users,
}

interface Props {
  subRoute?: SettingsSubRoute;
}

const pathToTab = (path: string): number | boolean => {
  if (path === "/org") {
    return 0;
  } else if (path.includes("/teams")) {
    return 1;
  } else if (path.includes("/users")) {
    return 2;
  }
  return false;
};

export const Org: FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useAppSelector(selectUser)!;
  const _org = useAppSelector(selectOrg);
  const loading = useAppSelector(selectOrgLoading);

  const [confirm, setConfirm] = useState<Confirm | undefined>(undefined);
  const [tabValue, setTabValue] = useState(pathToTab(location.pathname));

  if (loading) return <LoadingScreen />;

  if (!_org) {
    if (user.userRole === UserRole.Admin) {
      return <CreateOrg />;
    } else {
      navigate("/");
    }
  }

  const org = _org!;

  const navItems = [
    {
      label: "Overview",
      route: "/org",
    },
    {
      label: "Teams",
      route: "/org/teams",
    },
    {
      label: "Users",
      route: "/org/users",
    },
  ];

  return (
    <>
      <Stack gap={1}>
        <Typography sx={{ pb: 1 }} variant="h3">
          {org.name}
        </Typography>
        <Divider sx={{ bgcolor: "primary.light", mb: 2 }} />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
        >
          <Tabs
            orientation="vertical"
            indicatorColor="secondary"
            sx={{
              borderRight: 1,
              width: 240,
              borderColor: "primary.main",
              "& .MuiTab-root": {
                color: "text.secondary",
                "&:hover": {
                  color: "white",
                },
                "&.Mui-selected": {
                  color: "white",
                  fontWeight: fontStyle.fontWeightSemiBold,
                  bgcolor: "primary.main",
                },
              },
            }}
            value={tabValue}
            onChange={(_, val: number) => setTabValue(val)}
          >
            {navItems.map((item, i) => (
              <Tab
                key={i}
                label={item.label}
                component={Link}
                to={item.route}
              />
            ))}
          </Tabs>
          <Box sx={{ pl: 5, width: "100%" }}>
            {tabValue === SettingsSubRoute.Overview && <Overview />}
            {tabValue === SettingsSubRoute.Teams && <Teams />}
            {tabValue === SettingsSubRoute.Users && <Users />}
          </Box>
        </Box>

        {confirm && (
          <ConfirmModal
            {...confirm}
            open
            onClose={() => setConfirm(undefined)}
          />
        )}
      </Stack>
    </>
  );
};
