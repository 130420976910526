import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppState } from "store/state";
import {
  createTeam,
  deleteTeam,
  kickFromTeam,
  setSelectedTeam,
  updateTeam,
} from "./actions";

export const teamBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(createTeam.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(createTeam.fulfilled, (state, action) => {
      state.org!.teams.unshift({
        ...action.payload,
        coaches: [],
        players: [],
        admins: [],
        groups: [],
      });
    })
    .addCase(updateTeam.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updateTeam.fulfilled, (state, action) => {
      const idx = state.org!.teams.findIndex(
        (v) => v.id === action.meta.arg.team.id,
      );
      if (idx >= 0) {
        state.org!.teams[idx] = action.meta.arg.team;
      }
    })
    .addCase(deleteTeam.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(deleteTeam.fulfilled, (state, action) => {
      const idx = state.org!.teams.findIndex(
        (v) => v.id === action.meta.arg.teamId,
      );
      if (idx >= 0) {
        state.org!.teams.splice(idx, 1);
      }
    })
    .addCase(kickFromTeam.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(kickFromTeam.fulfilled, (state, action) => {
      const team = state.org!.teams.find(
        (v) => v.id === action.meta.arg.teamId,
      );
      if (team) {
        team.coaches = team.coaches.filter(
          (c) => c.id !== action.meta.arg.userId,
        );
        team.players = team.players.filter(
          (c) => c.id !== action.meta.arg.userId,
        );
      }
    })
    .addCase(setSelectedTeam, (state, action) => {
      localStorage.setItem("selectedTeamId", action.payload.id.toString());
      state.selectedTeam = action.payload;
    });
