import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppState } from "store/state";
import {
  addPlaylistVideos,
  composePlaylist,
  createPlaylist,
  deletePlaylist,
  generatePlaylistHomography,
  getPlaylist,
  getPlaylists,
  getPlaylistSegments,
  removePlaylistVideo,
  startPlaylistExport,
  updatePlaylist,
} from "./actions";

export const playlistBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(getPlaylist.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
      state.playlist = undefined;
    })
    .addCase(getPlaylist.fulfilled, (state, action) => {
      state.playlist = action.payload;
    })
    .addCase(getPlaylists.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
      state.playlists = undefined;
      state.playlistsLoading = true;
    })
    .addCase(getPlaylists.fulfilled, (state, action) => {
      state.playlists = action.payload;
      state.playlistsLoading = false;
    })
    .addCase(getPlaylists.rejected, (state, _action) => {
      state.playlistsLoading = false;
    })
    .addCase(getPlaylistSegments.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
      state.segments = undefined;
      state.segmentsLoading = true;
    })
    .addCase(getPlaylistSegments.fulfilled, (state, action) => {
      state.segments = action.payload;
      state.segmentsLoading = false;
    })
    .addCase(getPlaylistSegments.rejected, (state, _action) => {
      state.segmentsLoading = false;
    })
    .addCase(composePlaylist.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(composePlaylist.fulfilled, (_state, action) => {
      (action as any).asyncDispatch(
        getPlaylist({ playlistId: action.payload }),
      );
    })
    .addCase(composePlaylist.rejected, (_state, action) => {
      console.warn(action);
    })
    .addCase(createPlaylist.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(createPlaylist.fulfilled, (_state, action) => {
      (action as any).asyncDispatch(
        getPlaylist({ playlistId: action.payload }),
      );
    })
    .addCase(updatePlaylist.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updatePlaylist.fulfilled, (state, action) => {
      const updated = action.meta.arg.playlist;
      state.playlist = action.meta.arg.playlist;
      const minimal = state.playlists?.values.find(
        (pl) => pl.id === action.meta.arg.playlist.id,
      );
      if (minimal) {
        minimal.playerTags = updated.playerTags;
        minimal.tags = updated.tags;
        minimal.forzaTeamTags = updated.forzaTeamTags;
      }
    })
    .addCase(startPlaylistExport.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(addPlaylistVideos, (state, action) => {
      if (state.playlist) {
        state.playlist.videos = state.playlist.videos.concat(action.payload);
      }
    })
    .addCase(removePlaylistVideo, (state, action) => {
      if (state.playlist) {
        state.playlist.videos = state.playlist.videos.filter(
          (v) => v.id !== action.payload,
        );
      }
    })
    .addCase(deletePlaylist.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(deletePlaylist.fulfilled, (state, action) => {
      const idx = state.playlists!.values.findIndex(
        (v) => v.id === action.meta.arg.playlistId,
      );
      if (idx >= 0) {
        state.playlists!.total -= 1;
        state.playlists!.values.splice(idx, 1);
      }
    })
    .addCase(generatePlaylistHomography.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    });
