import { Box, Typography } from "@mui/material";
import { Editor } from "@tiptap/core";
import Color from "@tiptap/extension-color";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorProvider, FloatingMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { FC, useEffect, useState } from "react";
import "./RichText.css";
import { filterOptions, SuggestionMenu } from "./SuggestionMenu";
import { FontSizeExt } from "./Toolbar/FontSize";
import { ImageExt } from "./Toolbar/ImageExt";
import { LinkBubbleMenu } from "./Toolbar/LinkBubbleMenu";
import { ToolbarBubbleMenu } from "./Toolbar/ToolbarBubbleMenu";

const extensions = [
  Link.configure({ autolink: true, openOnClick: false }),
  StarterKit,
  Underline,
  TextStyle,
  FontSizeExt,
  Color,
  TextAlign.configure({ types: ["paragraph", "heading"] }),
  TaskItem,
  TaskList,
  Table.configure({ resizable: true }),
  TableRow,
  TableHeader,
  TableCell,
  Image.configure({ inline: false, allowBase64: true }),
  ImageExt,
];

export interface Props {
  value: string;
  editable?: boolean;
  onChange?: (_: string) => void;
}

export const RichText: FC<Props> = ({ value, editable, onChange }) => {
  const [editor, setEditor] = useState<Editor | undefined>(undefined);

  useEffect(() => {
    if (!editor) return;
    editor.setEditable(editable ?? false);
    editable && editor.commands.focus();
  }, [editor, editable]);

  useEffect(() => {
    if (editor && editor.getHTML() !== value) {
      setTimeout(() => editor.commands.setContent(value));
    }
  }, [editor, value]);

  return (
    <Box>
      <EditorProvider
        autofocus={!!editable}
        editable={!!editable}
        extensions={extensions}
        content={value}
        editorProps={{
          handleDOMEvents: {
            keydown: (view, event) => {
              if (event.key === "Enter") {
                const node = view.state.selection.$head.parent;
                if (node.type.name !== "paragraph") return;

                const text = node.textContent;
                if (!text.startsWith("/")) return;

                const search = text.substring(1);
                const opts = filterOptions(search);
                if (opts.length) event.preventDefault();
              }
            },
          },
        }}
        onCreate={({ editor }) => setEditor(editor)}
        onUpdate={({ editor }) =>
          onChange?.(editor.isEmpty ? "" : editor.getHTML())
        }
      >
        <ToolbarBubbleMenu />
        <LinkBubbleMenu />
        <SuggestionMenu />
      </EditorProvider>
      {editor && (
        <FloatingMenu
          editor={editor}
          pluginKey="type-helper"
          tippyOptions={{ interactive: false }}
          shouldShow={({ editor, state }) => {
            if (!editor.isActive("paragraph")) return false;

            const $anchor = state.selection.$anchor;
            return $anchor.depth === 1 && $anchor.parent.textContent === "";
          }}
        >
          <Typography sx={{ opacity: 0.6 }}>
            Type / to browse options
          </Typography>
        </FloatingMenu>
      )}
    </Box>
  );
};
