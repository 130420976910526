import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppState } from "store/state";
import { getForzaPlayers } from "./actions";

export const forzaPlayersBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(getForzaPlayers.pending, (state, _action) => {
      state.forzaPlayersLoading = true;
    })
    .addCase(getForzaPlayers.fulfilled, (state, action) => {
      state.forzaPlayers = action.payload;
      state.forzaPlayersLoading = false;
    })
    .addCase(getForzaPlayers.rejected, (state, _action) => {
      state.forzaPlayersLoading = false;
    });
