import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Popover,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { GetPlaylistSegmentsArgs, MAX_PLAYLIST_SEGMENTS } from "api";
import theme from "app/theme";
import {
  FilterHeader,
  FootballFieldGrid,
  ForzaTeamsFilter,
  ForzaTeamsInput,
  PlayersInput,
  SegmentItem,
  SegmentTagsFilter,
} from "components";
import { FC, useEffect, useMemo, useState } from "react";
import {
  getPlaylistSegments,
  selectForzaTeamsSorted,
  selectPlaylistSegments,
  selectSelectedTeam,
  setAlert,
  useAppDispatch,
  useAppSelector,
} from "store";
import { AddSegmentsQueryToPlaylistModal } from "./AddSegmentsQueryToPlaylistModal";

export const Segments: FC = () => {
  const dispatch = useAppDispatch();

  const forzaTeams = useAppSelector(selectForzaTeamsSorted);
  const segments = useAppSelector(selectPlaylistSegments);
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up(600));
  const selectedTeam = useAppSelector(selectSelectedTeam)!;

  const [filters, setFilters] = useState<GetPlaylistSegmentsArgs>({
    teamId: selectedTeam.id,
    limit: 25,
    offset: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [teamsLeft, teamsRight] = useMemo(() => {
    const teamsL = forzaTeams.filter((t) => t.id !== filters.rightTeamId);
    const teamsR = forzaTeams.filter((t) => t.id !== filters.leftTeamId);
    return [teamsL, teamsR];
  }, [forzaTeams, filters.leftTeamId, filters.rightTeamId]);

  useEffect(() => {
    dispatch(getPlaylistSegments(filters));
  }, [filters]);

  const AddToPlaylistButton = () => (
    <>
      {!!segments?.total && segments.total <= MAX_PLAYLIST_SEGMENTS && (
        <Box sx={{ p: mobileBreakpoint ? 1 : 0 }}>
          <Button
            variant="contained"
            color="info"
            sx={{
              bgcolor: "fourth.dark",
              height: 40,
              width: mobileBreakpoint ? 240 : "100%",
            }}
            startIcon={<PlaylistAddIcon />}
            onClick={() => setModalOpen(true)}
          >
            ADD TO PLAYLIST ({segments.total})
          </Button>
          <AddSegmentsQueryToPlaylistModal
            open={modalOpen}
            total={segments.total}
            query={filters}
            onClose={(message) => {
              message &&
                dispatch(
                  setAlert({
                    message,
                    severity: "success",
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }),
                );
              setModalOpen(false);
            }}
          />
        </Box>
      )}
    </>
  );

  return (
    <Stack gap={2}>
      <FilterHeader>
        <Stack direction="row" justifyContent="space-between">
          <Stack
            direction="row"
            gap={2}
            flexWrap="wrap"
            sx={{
              padding: 1,
              "& > div": { minWidth: 120, width: 220 },
              "& > *": mobileBreakpoint
                ? {}
                : {
                    width: "100% !important",
                  },
            }}
          >
            <SegmentTagsFilter
              value={filters.tags ?? []}
              onChange={(tags) => setFilters({ ...filters, tags, offset: 0 })}
            />
            <PlayersInput
              placeholder="Players"
              value={filters.players ?? []}
              onChange={(players) =>
                setFilters({ ...filters, players, offset: 0 })
              }
            />
            <ForzaTeamsInput
              placeholder="Teams"
              value={filters.teams}
              onChange={(teams) => setFilters({ ...filters, teams, offset: 0 })}
            />
            <Button
              color="inherit"
              sx={{ width: 220, height: 40 }}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              Show Field
            </Button>
          </Stack>
          {mobileBreakpoint && <AddToPlaylistButton />}
        </Stack>
      </FilterHeader>
      {!mobileBreakpoint && <AddToPlaylistButton />}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        disableScrollLock
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            bgcolor: "primary.main",
            "& .MuiFormLabel-root": {
              color: `${theme.palette.text.secondary}`,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: `${theme.palette.primary.light}`,
            },
          }}
        >
          <Box sx={{ m: 1, minWidth: 120, width: 180 }}>
            <ForzaTeamsFilter
              value={filters.leftTeamId}
              teams={teamsLeft}
              onChange={(team) =>
                setFilters({ ...filters, leftTeamId: team?.id })
              }
            />
          </Box>
          <Box sx={{ m: 1, minWidth: 120, width: 180 }}>
            <ForzaTeamsFilter
              value={filters.rightTeamId}
              teams={teamsRight}
              onChange={(team) =>
                setFilters({ ...filters, rightTeamId: team?.id })
              }
            />
          </Box>
        </Stack>
        <FootballFieldGrid
          width={800}
          selectedGrids={filters.grids ?? []}
          onChange={(grids) =>
            setFilters({
              ...filters,
              grids: grids.length ? grids : undefined,
            })
          }
        />
      </Popover>

      {segments ? (
        <>
          <Grid container spacing={2}>
            {segments.values.map((s, i) => (
              <Grid item xs={12} md={6} lg={6} xl={4} xxl={3} key={i}>
                <SegmentItem segment={s} />
              </Grid>
            ))}
          </Grid>
          <Pagination
            sx={{ display: "flex", my: 2, alignSelf: "center" }}
            color="secondary"
            size={mobileBreakpoint ? "medium" : "small"}
            page={filters.offset! / filters.limit! + 1}
            count={Math.ceil(segments.total / filters.limit!)}
            onChange={(_event, page: number) =>
              setFilters({ ...filters, offset: (page - 1) * filters.limit! })
            }
          />
        </>
      ) : (
        <Stack alignItems="center">
          <CircularProgress color="secondary" size={80} />
        </Stack>
      )}
    </Stack>
  );
};
