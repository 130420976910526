import { User } from "@auth0/auth0-react";
import { createAction } from "@reduxjs/toolkit";
import { SnackAlert } from "models";

export const setError = createAction<boolean>("base/setError");

export const setAuthState = createAction<{ user: User; token: string }>(
  "base/setAuthState",
);

export const setAlert = createAction<SnackAlert | undefined>("/base/setAlert");
