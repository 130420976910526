import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ForzaEventType } from "generated/openapi";
import { FC } from "react";

interface Props {
  value?: ForzaEventType;
  onChange: (_?: ForzaEventType) => void;
}

export const EventTypeFilter: FC<Props> = ({ value, onChange }) => {
  return (
    <FormControl
      sx={{ width: "100%", "& .MuiSvgIcon-root": { mx: "2px" } }}
      size="small"
    >
      <InputLabel>Event</InputLabel>
      <Select
        MenuProps={{ disableScrollLock: true }}
        label="Event"
        value={value ?? ""}
        onChange={(event) =>
          onChange(
            event.target.value === ""
              ? undefined
              : (event.target.value as ForzaEventType),
          )
        }
      >
        <MenuItem value={""}>
          <em>All</em>
        </MenuItem>
        {Object.values(ForzaEventType).map((t, i) => (
          <MenuItem key={i} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
