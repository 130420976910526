import { User } from "@auth0/auth0-react";
import { SnackAlert } from "models";
import { ForzaEventsState, initialForzaEventsState } from "./forzaEvents";
import { ForzaGamesState, initialForzaGamesState } from "./forzaGame";
import { ForzaPlayersState, initialForzaPlayersState } from "./forzaPlayer";
import { ForzaTeamsState, initialForzaTeamsState } from "./forzaTeam";
import { initialJournalState, JournalState } from "./journal";
import { initialLiveStreamState, LiveStreamState } from "./liveStream";
import { initialOrgState, OrgState } from "./org";
import { initialPlaylistState, PlaylistState } from "./playlist";
import { initialPlaylistTagState, PlaylistTagState } from "./playlistTag";
import { initialSegmentTagState, SegmentTagState } from "./segmentTag";
import { initialTeamState, TeamState } from "./team";
import { initialUserState, UserState } from "./user";

export interface BaseState {
  error: boolean;
  auth0User?: User;
  accessToken?: string;
  alert?: SnackAlert;
}

const initialBaseState: BaseState = {
  error: false,
  auth0User: undefined,
  accessToken: undefined,
  alert: undefined,
};

export interface AppState
  extends BaseState,
    PlaylistTagState,
    SegmentTagState,
    UserState,
    OrgState,
    TeamState,
    PlaylistState,
    JournalState,
    LiveStreamState,
    ForzaPlayersState,
    ForzaTeamsState,
    ForzaGamesState,
    ForzaEventsState {}

export const initialAppState: AppState = {
  ...initialBaseState,
  ...initialSegmentTagState,
  ...initialPlaylistTagState,
  ...initialUserState,
  ...initialOrgState,
  ...initialTeamState,
  ...initialPlaylistState,
  ...initialJournalState,
  ...initialLiveStreamState,
  ...initialForzaPlayersState,
  ...initialForzaTeamsState,
  ...initialForzaGamesState,
  ...initialForzaEventsState,
};
