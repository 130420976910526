import {
  Box,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  MenuProps,
} from "@mui/material";
import { TacticalPitch, TacticalTeam } from "generated/openapi";
import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "konva/lib/types";
import { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { Circle, Rect } from "react-konva";
import { Html } from "react-konva-utils";
import { useImmer } from "use-immer";
import { middle } from "utils";
import { FootballField } from "./FootballField";

export interface Props {
  value: TacticalPitch;
  width: number;
  height: number;
  onChange?: (_: TacticalPitch) => void;
}

export const FootballFieldTactical: FC<Props> = ({
  value: _value,
  width,
  height,
  onChange,
}) => {
  const [value, setValue] = useImmer(_value);

  useEffect(() => setValue(_value), [_value]);

  const players = useMemo(
    () =>
      value.players?.map((p) => ({
        x: p.xPercentage * width,
        y: p.yPercentage * height,
        team: p.team ?? undefined,
      })) ?? [],
    [value.players, width, height],
  );

  const onPlayersChange = useMemo(() => {
    const convertPlayers = (players: Player[]) =>
      players.map((p) => ({
        ...p,
        xPercentage: middle(0, p.x / width, 1),
        yPercentage: middle(0, p.y / height, 1),
      }));

    return onChange
      ? (_players: Player[]): TacticalPitch => {
          const players = convertPlayers(_players);
          setValue((draft) => {
            draft.players = players;
          });
          return { ...value, players };
        }
      : undefined;
  }, [width, height]);

  return (
    <Box sx={{ width, height }}>
      <FootballFieldPlayersInner
        width={width}
        height={height}
        players={players}
        onChange={onPlayersChange}
        onChangeCommitted={
          onChange
            ? (_players) => onChange(onPlayersChange!(_players))
            : undefined
        }
      />
    </Box>
  );
};

interface AnchorState {
  x: number;
  y: number;
  playerIdx?: number;
}

interface Player {
  x: number;
  y: number;
  team: TacticalTeam;
}

interface PropsInner {
  width: number;
  height: number;
  players: Player[];
  onChange?: (_: Player[]) => void;
  onChangeCommitted?: (_: Player[]) => void;
}

const FootballFieldPlayersInner = memo(
  ({ width, height, players, onChange, onChangeCommitted }: PropsInner) => {
    const anchorRef = useRef(null);

    const [anchor, setAnchor] = useState<AnchorState | undefined>(undefined);

    const movePlayer = (idx: number, position: Vector2d) => {
      Object.assign(players[idx], position);
      onChange?.(players);
    };

    const updatePlayer = (
      idx: number,
      update: Partial<Player> | ((_: Player) => Partial<Player>),
    ) => {
      Object.assign(
        players[idx],
        typeof update === "function" ? update(players[idx]) : update,
      );
      onChangeCommitted?.(players);
    };

    const addPlayer = (player: Player) =>
      onChangeCommitted?.([...players, player]);

    const deletePlayer = (idx: number) => {
      players.splice(idx, 1);
      onChangeCommitted?.(players);
    };

    const onMenuClose = () => setAnchor(undefined);

    const Menu = ({ children, ...props }: Partial<MenuProps>) => (
      <MuiMenu
        open
        disableScrollLock
        anchorEl={anchorRef.current}
        onClose={onMenuClose}
        {...props}
      >
        {children}
      </MuiMenu>
    );

    const MenuItem = ({ onClick, children, ...props }: MenuItemProps) => (
      <MuiMenuItem
        onClick={(e) => {
          onClick?.(e);
          onMenuClose();
        }}
        {...props}
      >
        {children}
      </MuiMenuItem>
    );

    return (
      <FootballField backgroundColor="#000000" width={width} height={height}>
        <Rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill="#00000088"
          onContextMenu={(e) => {
            e.evt.preventDefault();
            setAnchor({ x: e.evt.offsetX, y: e.evt.offsetY });
          }}
          onDblTap={(e: KonvaEventObject<TouchEvent>) => {
            e.evt.preventDefault();
            const pos = e.target.getStage()?.getPointerPosition();
            if (pos) {
              setAnchor(pos);
            }
          }}
        />
        {players.map((p, i) => (
          <Circle
            draggable={!!onChange}
            key={i}
            x={p.x}
            y={p.y}
            radius={width * 0.01 + 2}
            fill={p.team === TacticalTeam.A ? "#00f" : "#0f0"}
            onDragMove={(e) => movePlayer(i, e.target.position())}
            onDragEnd={(e) => updatePlayer(i, e.target.position())}
            onContextMenu={(e) => {
              e.evt.preventDefault();
              setAnchor({ ...e.target.position(), playerIdx: i });
            }}
            onDblTap={(e: KonvaEventObject<TouchEvent>) => {
              e.evt.preventDefault();
              setAnchor({ ...e.target.position(), playerIdx: i });
            }}
          />
        ))}
        <Html>
          <Box
            ref={anchorRef}
            sx={{
              position: "absolute",
              visibility: "hidden",
              top: anchor?.y ?? 0,
              left: anchor?.x ?? 0,
            }}
          />

          {!!onChange &&
            !!anchorRef.current &&
            !!anchor &&
            (anchor.playerIdx !== undefined ? (
              <Menu>
                <MenuItem
                  onClick={() =>
                    updatePlayer(anchor.playerIdx!, (p) => ({
                      team:
                        p.team === TacticalTeam.A
                          ? TacticalTeam.B
                          : TacticalTeam.A,
                    }))
                  }
                >
                  Switch Team
                </MenuItem>
                <MenuItem onClick={() => deletePlayer(anchor.playerIdx!)}>
                  Delete Player
                </MenuItem>
              </Menu>
            ) : (
              <Menu>
                <MenuItem
                  onClick={() => addPlayer({ ...anchor, team: TacticalTeam.A })}
                >
                  Add Player A
                </MenuItem>
                <MenuItem
                  onClick={() => addPlayer({ ...anchor, team: TacticalTeam.B })}
                >
                  Add Player B
                </MenuItem>
              </Menu>
            ))}
        </Html>
      </FootballField>
    );
  },
  (prev, next) =>
    prev.width === next.width &&
    prev.height === next.height &&
    prev.players === next.players,
);
