import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ForzaShotResult } from "generated/openapi";
import { FC } from "react";

interface Props {
  value?: ForzaShotResult;
  onChange: (_?: ForzaShotResult) => void;
}

export const ShotResultFilter: FC<Props> = ({ value, onChange }) => {
  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel>Shot result</InputLabel>
      <Select
        MenuProps={{ disableScrollLock: true }}
        label="Shot result"
        value={value ?? ""}
        onChange={(event) =>
          onChange(
            event.target.value === ""
              ? undefined
              : (event.target.value as ForzaShotResult),
          )
        }
      >
        <MenuItem value={""}>
          <em>All</em>
        </MenuItem>
        {Object.values(ForzaShotResult).map((t, i) => (
          <MenuItem key={i} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
