import { format, formatDuration, intervalToDuration } from "date-fns";
import {
  HomographyFrame,
  JournalEntryComment,
  Keybind,
  PlaylistSegment,
  VideoOffset,
} from "generated/openapi";

type IntoDate = Date | number | string;

export const dateFormat = (d: IntoDate | undefined | null, f: string) =>
  d ? format(new Date(d), f) : "";

export const twoDigits = (value?: number): string =>
  value === undefined ? "00" : value < 10 ? `0${value}` : value.toString();

export const middle = (a: number, b: number, c: number) =>
  a + b + c - Math.max(a, b, c) - Math.min(a, b, c);

export const minutesSeconds = (millis: number): string => {
  const secs = millis / 1_000;
  const minutes = Math.floor(secs / 60);
  const seconds = Math.round(secs % 60);
  return `${twoDigits(minutes)}:${twoDigits(seconds)}`;
};

export const hms = (secs: number): string => {
  const hours = Math.floor(secs / (60 * 60));
  const minutes = Math.floor((secs / 60) % 60);
  const seconds = Math.round(secs % 60);
  return hours
    ? `${hours}:${twoDigits(minutes)}:${twoDigits(seconds)}`
    : `${twoDigits(minutes)}:${twoDigits(seconds)}`;
};

export const countComments = (comments: JournalEntryComment[]): number =>
  comments.length + comments.map((c) => countComments(c.comments)).sum();

// TODO: Test this more, not sure if it works on 60min +
export const formatWallClockTime = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  const zeroPad = (value: number | undefined) => String(value).padStart(2, "0");

  if (duration.hours) {
    duration.minutes = duration.minutes
      ? (duration.minutes += 60)
      : duration.minutes;
    duration.hours = 0;
  }

  const formatted = formatDuration(duration, {
    format: ["minutes", "seconds"],
    zero: true,
    delimiter: ":",
    locale: {
      formatDistance: (_token, count: number) => zeroPad(count),
    },
  });

  return formatted;
};

export const keybindEq = (a?: Keybind | null, b?: Keybind | null) =>
  a?.char === b?.char && a?.ctrl === b?.ctrl;

export const keybindStr = ({ char, ctrl }: Keybind) =>
  `${ctrl ? "Ctrl+" : ""}${char !== char.toLowerCase() ? "Shift+" : ""}${char}`;

export const updateHomography = (
  ms: number,
  frames?: Record<number, HomographyFrame>,
  segment?: PlaylistSegment | undefined,
  homographyFrames?: Record<number, HomographyFrame> | undefined,
): HomographyFrame | undefined => {
  const start = segment?.fromTimestamp ?? 0;
  const aligned = start - (start % 2000);
  ms += aligned;
  // Round timestamp to 40 ms (assumes video is 25 fps)
  ms = Math.round(ms - (ms % 40));
  return (frames ?? homographyFrames)?.[ms];
};

export interface Asset {
  videoAssetId: number;
  videoBaseUrl: string;
}

export const getVideoOffset = (
  asset: Asset,
  currentMs: number,
  fromTimestamp?: number | null,
): VideoOffset => {
  const start = fromTimestamp ?? 0;
  const aligned = start - (start % 2000);

  // Round timestamp to 40 ms (assumes video is 25 fps)
  currentMs = Math.round(currentMs - (currentMs % 40));

  return {
    // TODO: Remove after japan demo refactor
    forzaVideoAssetId:
      asset.videoAssetId +
      (asset.videoBaseUrl.includes("demo-api") ? 100_000_000 : 0),
    videoOffsetMs: currentMs + aligned,
  };
};
