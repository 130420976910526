import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";

export const createGroup = createAsyncThunk(
  "group/createGroup",
  Api.createGroup,
);

export const updateGroup = createAsyncThunk(
  "group/updateGroup",
  Api.updateGroup,
);

export const deleteGroup = createAsyncThunk(
  "group/deleteGroup",
  Api.deleteGroup,
);
