import { PlaylistTag } from "generated/openapi";

export interface PlaylistTagState {
  playlistTags?: PlaylistTag[];
  playlistTagsLoading: boolean;
}

export const initialPlaylistTagState: PlaylistTagState = {
  playlistTags: undefined,
  playlistTagsLoading: false,
};
