import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import {
  Popper,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { mergeAttributes, Node } from "@tiptap/core";
import {
  NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import { useEffect, useRef, useState } from "react";
import { onEditorUpdateSetEditable } from "../utils";

export const ImageComponent = ({ editor, node, selected }: NodeViewProps) => {
  const ref = useRef(null);

  const attrs = node.attrs;

  const src: string = attrs.src ?? "";
  const align: string = attrs.align ?? "marginInline";
  const width: string = attrs.width ?? "100%";

  const [editable, setEditable] = useState(editor.isEditable);
  useEffect(() => onEditorUpdateSetEditable(editor, setEditable), []);

  return (
    <NodeViewWrapper className="image-ext">
      <Popper
        open={(editable && selected) ?? false}
        anchorEl={ref.current}
        placement="top"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{ bgcolor: "primary.main", padding: 1, borderRadius: 2 }}
        >
          <ToggleButtonGroup
            size="small"
            exclusive
            value={align}
            onChange={(_, align) =>
              editor.commands.updateAttributes("imageExt", { align })
            }
          >
            <ToggleButton value="marginRight">
              <AlignHorizontalLeftIcon />
            </ToggleButton>
            <ToggleButton value="marginInline">
              <AlignHorizontalCenterIcon />
            </ToggleButton>
            <ToggleButton value="marginLeft">
              <AlignHorizontalRightIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <Box sx={{ width: "10rem" }}>
            <Slider
              color="info"
              value={+width.slice(0, -1)}
              step={10}
              min={10}
              max={100}
              onChange={(_, v) =>
                editor.commands.updateAttributes("imageExt", {
                  width: `${v as number}%`,
                })
              }
            />
          </Box>
          <Typography>{width}</Typography>
        </Stack>
      </Popper>
      <Box ref={ref} width="100%">
        <Box width={width} sx={{ [align]: "auto" }}>
          <img
            src={src}
            style={{
              width: "100%",
              border: editable && selected ? "2px solid white" : "none",
              borderRadius: 8,
            }}
          />
        </Box>
      </Box>
    </NodeViewWrapper>
  );
};

export const ImageExt = Node.create({
  name: "imageExt",
  group: "block",
  atom: true,

  addAttributes() {
    return {
      src: { default: "" },
      align: { default: "marginInline" },
      width: { default: "100%" },
    };
  },
  parseHTML() {
    return [{ tag: "image-ext" }];
  },
  renderHTML({ HTMLAttributes }) {
    return ["image-ext", mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(ImageComponent);
  },
});
