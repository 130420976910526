import { apiConfiguration, apiGet } from "api";
import {
  CreateLiveMessage,
  CreateLiveStream,
  CreatePlaylistSegment,
  LiveStreamMode,
  PlaylistSegment,
  TacticalPitch,
  V1liveStreamApi,
} from "generated/openapi";

const api = new V1liveStreamApi(apiConfiguration);

export interface GetLiveStreamsArgs {
  token?: string;
  live: boolean;
}

export interface CreateLiveStreamArgs {
  token?: string;
  liveStream: CreateLiveStream;
}

export interface CreateLiveSegmentArgs {
  token?: string;
  liveStreamId: number;
  segment: CreatePlaylistSegment;
}

export interface SetLiveModeArgs {
  token?: string;
  liveStreamId: number;
  channelId: number;
  mode: LiveStreamMode;
}

export interface SetLiveThumbnailArgs {
  token?: string;
  liveStreamId: number;
  thumbnail: string;
}

export interface UpdateLiveSegmentArgs {
  token?: string;
  liveStreamId: number;
  segment: PlaylistSegment;
}

export interface UpdateLiveTacticalPitchArgs {
  token?: string;
  liveStreamId: number;
  tacticalPitch: TacticalPitch;
}

export interface DeleteLiveSegmentArgs {
  token?: string;
  liveStreamId: number;
  segmentId: number;
}

export interface SendLiveSegmentArgs {
  token?: string;
  liveStreamId: number;
  segmentId: number;
  userId: string;
}

export interface CreateLiveMessageArgs {
  token?: string;
  liveStreamId: number;
  message: CreateLiveMessage;
}

export const getLiveStreams = async ({ token, ...query }: GetLiveStreamsArgs) =>
  apiGet(async () =>
    api.getLiveStreams(query, {
      headers: { Authorization: `Bearer ${token}` },
    }),
  );

export const createLiveStream = async ({
  token,
  liveStream,
}: CreateLiveStreamArgs) =>
  apiGet(async () =>
    api.createLiveStream(
      { createLiveStream: liveStream },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const createLiveSegment = async ({
  token,
  liveStreamId,
  segment,
}: CreateLiveSegmentArgs) =>
  apiGet(async () =>
    api.createLiveSegment(
      { liveStreamId, createPlaylistSegment: segment },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const updateLiveTacticalPitch = async ({
  token,
  liveStreamId,
  tacticalPitch,
}: UpdateLiveTacticalPitchArgs) =>
  apiGet(async () =>
    api.updateLiveStreamTacticalPitch(
      {
        liveStreamId,
        tacticalPitch,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const setLiveMode = async ({
  token,
  liveStreamId,
  ...mode
}: SetLiveModeArgs) =>
  apiGet(async () =>
    api.setLiveStreamMode(
      {
        liveStreamId,
        setLiveStreamMode: mode,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const setLiveThumbnail = async ({
  token,
  liveStreamId,
  thumbnail,
}: SetLiveThumbnailArgs) =>
  apiGet(async () =>
    api.setLiveStreamThumbnail(
      {
        liveStreamId,
        setLiveStreamThumbnail: { thumbnail },
      },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const updateLiveSegment = async ({
  token,
  liveStreamId,
  segment,
}: UpdateLiveSegmentArgs) =>
  apiGet(async () =>
    api.updateLiveSegment(
      {
        liveStreamId,
        createPlaylistSegment: { ...segment, userId: segment.creator?.id },
      },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const deleteLiveSegment = async ({
  token,
  liveStreamId,
  segmentId,
}: DeleteLiveSegmentArgs) =>
  apiGet(async () =>
    api.deleteLiveSegment(
      { liveStreamId, playlistSegmentId: segmentId },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const sendLiveSegment = async ({
  token,
  liveStreamId,
  segmentId,
  userId,
}: SendLiveSegmentArgs) =>
  apiGet(async () =>
    api.sendLiveSegment(
      { liveStreamId, playlistSegmentId: segmentId, userId },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );

export const createLiveMessage = async ({
  token,
  liveStreamId,
  message,
}: CreateLiveMessageArgs) =>
  apiGet(async () =>
    api.createLiveMessage(
      { liveStreamId, createLiveMessage: message },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
  );
