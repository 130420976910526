import { Button, Stack, Typography } from "@mui/material";
import { VideoAssets, VideoPlayer } from "components";
import { VideoPlayerRef } from "components/VideoPlayer/VideoPlayer";
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  getForzaGame,
  selectForzaGame,
  setForzaGameKickoff,
  useAppDispatch,
  useAppSelector,
} from "store";

export const SelectKickoff = () => {
  const [query, _] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();

  const id = params.gameId ? +params.gameId : undefined;
  const redirect = query.get("redirectUrl");

  if (!Number.isFinite(id) || !redirect) {
    navigate("/");
  }

  return <SelectKickoffInner id={id!} redirectUrl={redirect!} />;
};

interface Props {
  id: number;
  redirectUrl: string;
}

const SelectKickoffInner: FC<Props> = ({ id, redirectUrl }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ref = useRef<VideoPlayerRef | null>(null);

  const game = useAppSelector(selectForzaGame);

  const [selectedAssetIdx, setSelectedAssetIdx] = useState(0);

  useEffect(() => {
    if (!game || game.id !== id) {
      dispatch(getForzaGame({ forzaGameId: id }));
    }
  }, [id]);

  if (!game) {
    // TODO: Detect if just waiting, or if game does not exist
    return <>Loading...</>;
  }

  const asset = game.videoAssets[selectedAssetIdx];
  const url = `${asset.videoBaseUrl}/playlist.m3u8/${asset.videoAssetId}:${asset.firstHalfZeroOffsetWallClockTime ?? 0}:9999999999/Manifest.m3u8`;

  return (
    <Stack gap={2}>
      <Typography sx={{ pb: 2 }} variant="h3">
        Select Kickoff
      </Typography>

      <VideoAssets
        assets={game.videoAssets}
        selected={selectedAssetIdx}
        onSelect={setSelectedAssetIdx}
      />

      <VideoPlayer
        playing
        clickToPlay
        controlFullscreenId
        ref={ref}
        url={url}
      />

      <Button
        color="info"
        size="large"
        variant="contained"
        sx={{ marginInline: "auto" }}
        onClick={() => {
          dispatch(
            setForzaGameKickoff({
              forzaGameId: id,
              setGameKickoff: {
                manualKickoffTimeMs: ref.current!.getCurrentMillis(),
              },
            }),
          ).then(() => navigate(redirectUrl));
        }}
      >
        Submit
      </Button>
    </Stack>
  );
};
