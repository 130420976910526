import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { nb } from "date-fns/locale";
import { FC } from "react";

interface Props {
  label: string;
  size?: "small" | "medium";
  color?: string;
  value?: Date | string | null;
  onChange: (_?: Date) => void;
}

export const DateFilter: FC<Props> = ({
  label,
  size,
  color,
  value,
  onChange,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        "& .MuiIconButton-sizeMedium": {
          color: color ?? "secondary.main",
        },
        "& .MuiFormControl-root": {
          width: "100%",
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nb}>
        <DatePicker
          slotProps={{ textField: { size: size ?? "small" } }}
          label={label}
          value={
            value ? (value instanceof Date ? value : new Date(value)) : null
          }
          onChange={(value: Date | null) =>
            onChange(value && !isNaN(value.getTime()) ? value : undefined)
          }
        />
      </LocalizationProvider>
    </Box>
  );
};
