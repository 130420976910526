import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";

export const getPlaylistTags = createAsyncThunk(
  "tag/getPlaylistTags",
  Api.getPlaylistTags,
);
export const createPlaylistTag = createAsyncThunk(
  "tag/createPlaylistTags",
  Api.createPlaylistTag,
);
export const updatePlaylistTag = createAsyncThunk(
  "tag/updatePlaylistTags",
  Api.updatePlaylistTag,
);
export const deletePlaylistTag = createAsyncThunk(
  "tag/deletePlaylistTag",
  Api.deletePlaylistTag,
);
