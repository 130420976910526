import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { FC } from "react";

interface Props {
  value: string[];
  color?: string;
}

export const ChipList: FC<Props> = (props) => {
  const { value, color } = props;

  if (!value?.length) {
    return (
      <Typography
        fontStyle="italic"
        variant="subtitle2"
        color="text.secondary"
        sx={{ lineHeight: 0 }}
      >
        None
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "& .MuiInputBase-root": {
          cursor: "pointer",
          input: { minWidth: 0, p: 0 },
        },
        "& .MuiInputBase-input": {
          cursor: "pointer",
        },
        "& .MuiChip-filled": {
          color: "white",
          bgcolor: color ?? "third.dark",
        },
        "& .MuiAutocomplete-inputRoot": {
          "& .MuiAutocomplete-input": {
            minWidth: 0,
            p: 0,
          },
        },
      }}
    >
      <Autocomplete
        sx={{
          "& .MuiOutlinedInput-root": {
            "&.MuiInputBase-sizeSmall": {
              p: 0,
              "& .MuiAutocomplete-input": { p: 0 },
            },
          },
          "& .MuiAutocomplete-inputRoot": {
            "& .MuiAutocomplete-input": {
              minWidth: 0,
              p: 0,
            },
          },
        }}
        multiple
        freeSolo
        disablePortal
        disableListWrap
        disableCloseOnSelect
        readOnly
        size="small"
        limitTags={3}
        value={value}
        options={[]}
        onKeyDown={(e) => e.stopPropagation()}
        renderInput={(params: any) => <TextField sx={{ p: 0 }} {...params} />}
      />
    </Box>
  );
};
