import { Dialog, Stack, SxProps, Theme } from "@mui/material";
import { FC, ReactNode } from "react";

export interface Props {
  open: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
  onClose: () => void;
}

export const Modal: FC<Props> = ({ open, onClose, children, sx }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: 2 } }}
      disableScrollLock
    >
      <Stack
        gap={1}
        sx={{
          bgcolor: "primary.main",
          padding: 4,
          ...sx,
        }}
      >
        {children}
      </Stack>
    </Dialog>
  );
};
