import { ChipAutocomplete } from "components";
import { FC } from "react";
import {
  selectSegmentTagsFlattened,
  selectSegmentTagsFlattenedMap,
  useAppSelector,
} from "store";

interface Props {
  value?: number[];
  placeholder?: string;
  onChange?: (_: number[]) => void;
}

export const SegmentTagsInput: FC<Props> = ({
  value,
  placeholder,
  onChange,
}) => {
  const tagsFlat = useAppSelector(selectSegmentTagsFlattened) ?? [];
  const tagsMap = useAppSelector(selectSegmentTagsFlattenedMap) ?? {};

  const val = value?.length ? value.map((t) => tagsMap[t]) : [];

  return (
    <ChipAutocomplete
      placeholder={placeholder ?? "Add tag"}
      value={val}
      options={tagsFlat}
      onChange={
        onChange ? (value) => onChange(value?.map((t) => t.id)) : undefined
      }
      getOptionLabel={(option) => option?.displayName}
      getOptionDisabled={(option) =>
        val.some((t) => t.parentTagId === option.id) ||
        val.some(
          (t) => t.subTags && t.subTags.some((st) => st.id === option.id),
        )
      }
    />
  );
};
