import { Button, TextField, Typography } from "@mui/material";
import { Modal } from "components";
import { CreateGroup, Group } from "generated/openapi";
import { FC } from "react";
import { Form, useForm } from "react-hook-form";
import { updateGroup, useAppDispatch } from "store";

export interface Props {
  teamId: number;
  group: Group;
  onClose: () => void;
}

export const EditGroupModal: FC<Props> = ({ teamId, group, onClose }) => {
  const dispatch = useAppDispatch();
  const {
    control,
    register,
    formState: { errors },
  } = useForm<CreateGroup>({ defaultValues: { name: group.name } });

  return (
    <Modal open={true} onClose={onClose} sx={{ p: 3 }}>
      <Form
        control={control}
        style={{ display: "flex", flexDirection: "column", gap: 8 }}
        onSubmit={({ data }) => {
          dispatch(
            updateGroup({ teamId, group: { ...group, name: data.name } }),
          );
          onClose();
        }}
      >
        <Typography sx={{ pb: 2 }} variant="h6">
          Edit group
        </Typography>

        <TextField
          sx={{ width: 300 }}
          spellCheck={false}
          autoFocus
          color="third"
          label="Name"
          variant="outlined"
          error={!!errors.name}
          {...register("name", { required: true })}
        />

        <Button color="third" type="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
