import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { createInvite } from "api";
import { Modal, TeamsFilter } from "components";
import { CreateInvite, UserRole } from "generated/openapi";
import { FC, useEffect } from "react";
import { Form, useForm } from "react-hook-form";
import { selectAccessToken, selectUser, useAppSelector } from "store";

export interface Props {
  invite: CreateInvite;
  onClose: () => void;
}

export const InviteUser: FC<Props> = ({ invite, onClose }) => {
  const token = useAppSelector(selectAccessToken);
  const user = useAppSelector(selectUser)!;

  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateInvite>({ defaultValues: invite });

  // Reset team if admin role is selected
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === "change" &&
        name === "userRole" &&
        value.userRole === UserRole.Admin
      ) {
        setValue("teamId", undefined);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Modal open={true} onClose={onClose}>
      <Form
        control={control}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 18,
          width: 330,
        }}
        onSubmit={({ data }) => {
          createInvite(data, token!).then(() => onClose());
        }}
      >
        <Typography sx={{ pb: 1 }} variant="h6">
          Invite User
        </Typography>

        <TextField
          autoFocus
          color="third"
          spellCheck={false}
          label="E-mail"
          variant="outlined"
          error={!!errors.email}
          {...register("email", { required: true })}
        />

        <FormControl error={!!errors.userRole} color="third">
          <FormLabel>User Role</FormLabel>
          <RadioGroup defaultValue={invite.userRole}>
            {user.userRole === UserRole.Admin && (
              <FormControlLabel
                value={UserRole.Admin}
                control={<Radio color="third" />}
                label={UserRole.Admin}
                {...register("userRole", { required: true })}
              />
            )}
            <FormControlLabel
              value={UserRole.Coach}
              control={<Radio color="third" />}
              label={UserRole.Coach}
              {...register("userRole", { required: true })}
            />
            <FormControlLabel
              value={UserRole.Player}
              control={<Radio color="third" />}
              label={UserRole.Player}
              {...register("userRole", { required: true })}
            />
          </RadioGroup>
        </FormControl>

        <TeamsFilter
          disabled={watch("userRole") === UserRole.Admin}
          label="Select team"
          size="medium"
          color="third"
          value={watch("teamId")}
          defaultValue={invite.teamId ?? undefined}
          onChange={(value) => setValue("teamId", value?.id)}
        />

        <Button color="third" type="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
