import { createSelector } from "@reduxjs/toolkit";
import { selectAppState } from "store/selectAppState";

export const selectGoals = createSelector(
  selectAppState,
  (state) => state.goals,
);

export const selectPenalties = createSelector(
  selectAppState,
  (state) => state.penalties,
);

export const selectRedCards = createSelector(
  selectAppState,
  (state) => state.redCards,
);

export const selectYellowCards = createSelector(
  selectAppState,
  (state) => state.yellowCards,
);

export const selectShotsOnGoal = createSelector(
  selectAppState,
  (state) => state.shotsOnGoal,
);

export const selectCorners = createSelector(
  selectAppState,
  (state) => state.corners,
);

export const selectOffsides = createSelector(
  selectAppState,
  (state) => state.offsides,
);

export const selectFreekicks = createSelector(
  selectAppState,
  (state) => state.freekicks,
);

export const selectEventsFilters = createSelector(
  selectAppState,
  (state) => state.eventsFilters,
);

export const selectSelectedEventType = createSelector(
  selectAppState,
  (state) => state.selectedEventType,
);

export const selectCurrentForzaEvent = createSelector(
  selectAppState,
  (state) => state.currentEvent,
);

export const selectForzaEventsLoading = createSelector(
  selectAppState,
  (state) => state.eventsLoading,
);
