import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DownloadIcon from "@mui/icons-material/Download";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import theme from "app/theme";
import {
  PlaylistVideoInfo,
  VideoExportEvent,
  VideoExportEventDiscriminants,
} from "generated/openapi";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  addPlaylistVideos,
  removePlaylistVideo,
  selectAccessToken,
  setAlert,
  useAppDispatch,
  useAppSelector,
} from "store";

interface Props {
  playlistId: number;
  videos: PlaylistVideoInfo[];
  expanded?: boolean;
}

export const PlaylistVideos: FC<Props> = ({ playlistId, videos, expanded }) => {
  const dispatch = useAppDispatch();

  const token = useAppSelector(selectAccessToken)!;

  const [progress, setProgress] = useState<Record<number, number>>({});

  useEffect(() => {
    const source = new EventSource(
      `${process.env.REACT_APP_API_URL!}/v1.0/playlists/${playlistId}/export?authorization=${token}`,
    );

    source.onmessage = (e) => {
      const msg: VideoExportEvent = JSON.parse(e.data);
      if (!msg) {
        console.warn("received invalid message", e);
        return;
      }

      switch (msg.type) {
        case VideoExportEventDiscriminants.Initial:
        case VideoExportEventDiscriminants.NewVideos:
          setProgress((v) => ({
            ...v,
            ...Object.fromEntries(msg.value.map((v) => [v.id, 0])),
          }));
          dispatch(addPlaylistVideos(msg.value));
          return;
        case VideoExportEventDiscriminants.Progress:
          setProgress((v) => ({ ...v, [msg.value.id]: msg.value.progress }));
          return;
        case VideoExportEventDiscriminants.StartingUpload:
          setProgress((v) => ({ ...v, [msg.value.id]: 100 }));
          return;
        case VideoExportEventDiscriminants.Finished:
          setProgress((_v) => {
            const { [msg.value.id]: _, ...v } = _v;
            return v;
          });
          return;
        case VideoExportEventDiscriminants.Error:
          setProgress((_v) => {
            const { [msg.value.id]: _, ...v } = _v;
            return v;
          });
          dispatch(removePlaylistVideo(msg.value.id));
          dispatch(
            setAlert({
              message: `Failed to export '${msg.value.name}', please try again`,
              severity: "error",
            }),
          );
      }
    };

    source.onerror = (e) => console.warn("event source error", e);

    return () => source.close();
  }, []);

  if (!videos.length) {
    return <></>;
  }

  return (
    <Accordion
      defaultExpanded={expanded}
      sx={{
        bgcolor: "primary.dark",
        pt: 3,
        boxShadow: "none",
        ":before": { bgcolor: "primary.dark" },
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ArrowForwardIosIcon sx={{ fontSize: "0.9rem" }} />}
        sx={{
          borderBottom: `1px solid ${theme.palette.primary.light}`,
          px: 0,
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
        }}
      >
        <Typography sx={{ pl: 1 }} variant="h4">
          Exported Videos ({videos.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 2, px: 0 }}>
        <Grid container spacing={2}>
          {videos.map((v) => (
            <Grid item xs={12} md={6} lg={6} xl={4} xxl={4} key={v.id}>
              <Box
                key={v.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  p: 1,
                  height: "100%",
                  border: `1px solid ${theme.palette.primary.light}`,
                  borderRadius: 2,
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Stack direction="row" gap={1} sx={{ flexGrow: 1 }}>
                  <VideoFileIcon
                    sx={{
                      width: 60,
                      height: 60,
                      color: "primary.light",
                    }}
                  />
                  <Stack sx={{ flexGrow: 1 }}>
                    <Typography
                      sx={{
                        p: 1,
                        fontWeight: "bold",
                      }}
                    >
                      {v.name}
                    </Typography>
                    {v.id in progress && (
                      <Box sx={{ width: "100%" }}>
                        {progress[v.id] === 0 || progress[v.id] >= 100 ? (
                          <LinearProgress color="secondary" />
                        ) : (
                          <Box
                            sx={{
                              height: "4px",
                              width: "100%",
                              borderRadius: 2,
                              bgcolor: "primary.dark",
                            }}
                          >
                            <Box
                              sx={{
                                width: `${progress[v.id]}%`,
                                height: "4px",
                                borderRadius: 2,
                                bgcolor: "secondary.main",
                                transition: "width 50ms",
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </Stack>
                </Stack>
                {!(v.id in progress) && (
                  <IconButton
                    title="Download"
                    color="info"
                    component={Link}
                    to={`${process.env.REACT_APP_API_URL}/v1.0/playlists/videos/${v.id}?authorization=${token}`}
                    target="_blank"
                  >
                    <DownloadIcon sx={{ width: 35, height: 35 }} />
                  </IconButton>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
