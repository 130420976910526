import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import NotesIcon from "@mui/icons-material/Notes";
import { FC, ReactElement, useState } from "react";
// import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import ChecklistIcon from "@mui/icons-material/Checklist";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Menu,
  MenuItem,
  Stack,
  styled,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { Level } from "@tiptap/extension-heading";
import {
  BubbleMenu,
  Editor,
  isTextSelection,
  useCurrentEditor,
} from "@tiptap/react";
import { FontSize } from "./FontSize";
// import { ColorPicker } from "components";

const ToggleButton = styled(MuiToggleButton)({
  paddingInline: 6,
});

export const ToolbarBubbleMenu: FC = () => {
  const { editor } = useCurrentEditor();

  const [formatAnchor, setFormatAnchor] = useState<HTMLElement | null>(null);
  const [alignAnchor, setAlignAnchor] = useState<HTMLElement | null>(null);

  if (!editor) return <></>;

  const format =
    BLOCK_FORMAT_OPTIONS.findRev((v) => v.isActive(editor)) ??
    BLOCK_FORMAT_OPTIONS[0];
  const align =
    ALIGNMENT_FORMAT_OPTIONS.findRev((v) => v.isActive(editor)) ??
    ALIGNMENT_FORMAT_OPTIONS[0];

  return (
    <BubbleMenu
      editor={editor}
      pluginKey="toolbar-bubble-menu"
      tippyOptions={{ maxWidth: "unset", interactive: true }}
      shouldShow={({ editor, view, state, from, to }) => {
        // Reimplementation of the default from:
        // https://github.com/ueberdosis/tiptap/blob/48935a2f26e3da2fa13c265773dfe259893829cc/packages/extension-bubble-menu/src/bubble-menu-plugin.ts#L47
        // With additional checks

        if (
          !editor.isEditable ||
          !view.hasFocus() ||
          editor.isActive("imageExt")
        )
          return false;

        const { doc, selection } = state;
        const { empty } = selection;

        if (empty) return false;

        // Sometime check for `empty` is not enough.
        // Doubleclick an empty paragraph returns a node size of 2.
        // So we check also for an empty text size.
        const isEmptyTextBlock =
          !doc.textBetween(from, to).length && isTextSelection(state.selection);

        if (isEmptyTextBlock) return false;

        return true;
      }}
    >
      <Stack
        direction="row"
        sx={{
          gap: 1,
          height: 42,
          padding: 0.5,
          borderRadius: 2,
          bgcolor: "primary.main",
        }}
      >
        <Button
          size="small"
          color="inherit"
          endIcon={<KeyboardArrowDownIcon fontSize="inherit" />}
          onClick={(e) =>
            setFormatAnchor(formatAnchor ? null : e.currentTarget)
          }
        >
          {format.Icon}
        </Button>
        <Menu open={!!formatAnchor} anchorEl={formatAnchor}>
          {BLOCK_FORMAT_OPTIONS.map((v, i) => (
            <MenuItem
              key={i}
              value={i}
              onClick={() => {
                setFormatAnchor(null);
                v.onSelect(editor);
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {v.Icon} {v.name}
              </Box>
            </MenuItem>
          ))}
        </Menu>
        <Divider orientation="vertical" />
        {editor.isActive("paragraph") && (
          <>
            <FontSize />
            <Divider orientation="vertical" />
          </>
        )}
        <ToggleButtonGroup size="small">
          <ToggleButton
            value="bold"
            title="Bold (Ctrl+B)"
            selected={editor.isActive("bold")}
            onClick={() => editor.chain().focus().toggleBold().run()}
          >
            <FormatBoldIcon fontSize="small" />
          </ToggleButton>
          <ToggleButton
            value="italic"
            title="Italic (Ctrl+I)"
            selected={editor.isActive("italic")}
            onClick={() => editor.chain().focus().toggleItalic().run()}
          >
            <FormatItalicIcon fontSize="small" />
          </ToggleButton>
          <ToggleButton
            value="underlined"
            title="Underline (Ctrl+U)"
            selected={editor.isActive("underline")}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
          >
            <FormatUnderlinedIcon fontSize="small" />
          </ToggleButton>
          <ToggleButton
            value="strikethrough"
            selected={editor.isActive("strike")}
            onClick={() => editor.chain().focus().toggleStrike().run()}
          >
            <FormatStrikethroughIcon fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
        {/* TODO
        <ColorPicker
          value={editor.getAttributes("textStyle").color ?? "#ffffff"}
          icon={<FormatColorFillIcon fontSize="small" />}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          transformOrigin={{ vertical: "bottom", horizontal: "center" }}
          onChange={(v) => editor.chain().focus().setColor(v).run()}
        /> */}
        <ToggleButton
          size="small"
          title="Insert Link"
          value=""
          disabled={editor.isActive("link")}
          onClick={() =>
            editor.chain().focus().setLink({ href: "https://" }).run()
          }
        >
          <InsertLinkIcon fontSize="small" />
        </ToggleButton>
        <Divider orientation="vertical" />
        <Button
          size="small"
          color="inherit"
          endIcon={<KeyboardArrowDownIcon fontSize="inherit" />}
          onClick={(e) => setAlignAnchor(alignAnchor ? null : e.currentTarget)}
        >
          {align.Icon}
        </Button>
        <Menu open={!!alignAnchor} anchorEl={alignAnchor}>
          {ALIGNMENT_FORMAT_OPTIONS.map((v, i) => (
            <MenuItem
              key={i}
              value={i}
              onClick={() => {
                setAlignAnchor(null);
                v.onSelect(editor);
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {v.Icon} {v.name}
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    </BubbleMenu>
  );
};

const formatHeading = (editor: Editor, level: Level) =>
  editor.chain().focus().toggleHeading({ level }).run();

interface FormatOption {
  Icon: ReactElement;
  name: string;
  isActive: (editor: Editor) => boolean;
  onSelect: (editor: Editor) => void;
}

export const BLOCK_FORMAT_OPTIONS: FormatOption[] = [
  {
    Icon: <NotesIcon />,
    name: "Normal",
    isActive: (editor: Editor) => editor.isActive("paragraph"),
    onSelect: (editor: Editor) => editor.chain().focus().setParagraph().run(),
  },
  {
    Icon: <span>H1</span>,
    name: "Heading 1",
    isActive: (editor: Editor) => editor.isActive("heading", { level: 1 }),
    onSelect: (editor: Editor) => formatHeading(editor, 1),
  },
  {
    Icon: <span>H2</span>,
    name: "Heading 2",
    isActive: (editor: Editor) => editor.isActive("heading", { level: 2 }),
    onSelect: (editor: Editor) => formatHeading(editor, 2),
  },
  {
    Icon: <span>H3</span>,
    name: "Heading 3",
    isActive: (editor: Editor) => editor.isActive("heading", { level: 3 }),
    onSelect: (editor: Editor) => formatHeading(editor, 3),
  },
  {
    Icon: <span>H4</span>,
    name: "Heading 4",
    isActive: (editor: Editor) => editor.isActive("heading", { level: 4 }),
    onSelect: (editor: Editor) => formatHeading(editor, 4),
  },
  {
    Icon: <FormatListNumberedIcon fontSize="small" />,
    name: "Numbered List",
    isActive: (editor: Editor) => editor.isActive("orderedList"),
    onSelect: (editor: Editor) =>
      editor.chain().focus().toggleOrderedList().run(),
  },
  {
    Icon: <FormatListBulletedIcon fontSize="small" />,
    name: "Bulleted List",
    isActive: (editor: Editor) => editor.isActive("bulletList"),
    onSelect: (editor: Editor) =>
      editor.chain().focus().toggleBulletList().run(),
  },
  {
    Icon: <ChecklistIcon fontSize="small" />,
    name: "Task List",
    isActive: (editor: Editor) => editor.isActive("taskList"),
    onSelect: (editor: Editor) => editor.chain().focus().toggleTaskList().run(),
  },
  {
    Icon: <FormatQuoteIcon fontSize="small" />,
    name: "Quote",
    isActive: (editor: Editor) => editor.isActive("blockquote"),
    onSelect: (editor: Editor) =>
      editor.chain().focus().toggleBlockquote().run(),
  },
];

export const ALIGNMENT_FORMAT_OPTIONS: FormatOption[] = [
  {
    Icon: <FormatAlignLeftIcon fontSize="small" />,
    name: "Left",
    isActive: (editor: Editor) => editor.isActive({ textAlign: "left" }),
    onSelect: (editor: Editor) =>
      editor.chain().focus().setTextAlign("left").run(),
  },
  {
    Icon: <FormatAlignCenterIcon fontSize="small" />,
    name: "Center",
    isActive: (editor: Editor) => editor.isActive({ textAlign: "center" }),
    onSelect: (editor: Editor) =>
      editor.chain().focus().setTextAlign("center").run(),
  },
  {
    Icon: <FormatAlignRightIcon fontSize="small" />,
    name: "Right",
    isActive: (editor: Editor) => editor.isActive({ textAlign: "right" }),
    onSelect: (editor: Editor) =>
      editor.chain().focus().setTextAlign("right").run(),
  },
  {
    Icon: <FormatAlignJustifyIcon fontSize="small" />,
    name: "Justify",
    isActive: (editor: Editor) => editor.isActive({ textAlign: "justify" }),
    onSelect: (editor: Editor) =>
      editor.chain().focus().setTextAlign("justify").run(),
  },
];

const Divider = ({
  orientation,
}: {
  orientation?: "horizontal" | "vertical";
}) => (
  <MuiDivider
    flexItem
    orientation={orientation}
    sx={{ borderColor: "primary.light" }}
  />
);
