import { apiConfiguration, apiGet } from "api";
import { CreateGroup, Group, V1groupApi } from "generated/openapi";

const api = new V1groupApi(apiConfiguration);

export interface CreateGroupArgs {
  teamId: number;
  group: CreateGroup;
  token?: string;
}

export interface UpdateGroupArgs {
  teamId: number;
  group: Group;
  token?: string;
}

export interface DeleteGroupArgs {
  teamId: number;
  groupId: number;
  token?: string;
}

export const createGroup = async (query: CreateGroupArgs) =>
  apiGet(async () =>
    api.createGroup(
      { teamId: query.teamId, createGroup: query.group },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const updateGroup = async (query: UpdateGroupArgs) =>
  apiGet(async () =>
    api.updateGroup(
      {
        groupId: query.group.id,
        createGroup: {
          name: query.group.name,
          users: query.group.users.map((v) => v.id),
        },
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const deleteGroup = async (query: DeleteGroupArgs) =>
  apiGet(async () =>
    api.deleteGroup(
      { groupId: query.groupId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );
