import { createSelector } from "@reduxjs/toolkit";
import { selectAppState } from "store/selectAppState";

export const selectPlaylist = createSelector(
  selectAppState,
  (state) => state.playlist,
);

export const selectPlaylists = createSelector(
  selectAppState,
  (state) => state.playlists,
);

export const selectPlaylistSegments = createSelector(
  selectAppState,
  (state) => state.segments,
);

export const selectPlaylistCopy = createSelector(selectPlaylist, (state) =>
  state
    ? { ...state, segments: state.segments.map((s) => ({ ...s })) }
    : undefined,
);

export const selectPlaylistsLoading = createSelector(
  selectAppState,
  (state) => state.playlistsLoading,
);

export const selectPlaylistSegmentsLoading = createSelector(
  selectAppState,
  (state) => state.segmentsLoading,
);
