import CheckIcon from "@mui/icons-material/Check";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Chip, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { LoadingScreen, RichText } from "components";
import { formatDistanceToNow } from "date-fns";
import { CreateJournalEntryComment } from "generated/openapi";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createJournalEntryComment,
  getJournalEntry,
  selectJournalEntry,
  selectUser,
  updateJournalEntry,
  useAppDispatch,
  useAppSelector,
} from "store";
import { countComments } from "utils/utils";
import { JournalEntryComment } from "./JournalEntryComment";

export const JournalEntry: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const entryId = params.entryId ? +params.entryId : undefined;

  if (!Number.isFinite(entryId)) {
    navigate("/journal");
  }

  const id = entryId!;

  const dispatch = useAppDispatch();

  const journal = useAppSelector(selectJournalEntry(id));
  const user = useAppSelector(selectUser);

  const [editing, setEditing] = useState<string | undefined>(undefined);
  const [comment, setComment] = useState<CreateJournalEntryComment | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!journal) {
      dispatch(getJournalEntry({ entryId: id }));
    }
  }, [id]);

  if (!journal || !user) return <LoadingScreen />;

  return (
    <Stack gap={2} sx={{ maxWidth: "46rem", marginInline: "auto" }}>
      <Typography variant="h3" fontWeight="normal">
        {journal.player.firstName} {journal.player.lastName}
      </Typography>
      <Stack
        direction="row"
        gap={2}
        sx={{ marginBlock: 1 }}
        justifyContent="space-between"
      >
        <Stack direction="row" gap={2}>
          <Typography>
            {journal.creator
              ? `${journal.creator.firstName} ${journal.creator.lastName}`
              : "Deleted User"}
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
            }}
          >
            {formatDistanceToNow(new Date(journal.created)) + " ago"}
          </Typography>
        </Stack>
        {user.id === journal.creator?.id &&
          (editing === undefined ? (
            <Button
              color="warning"
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={() => setEditing(journal.entry)}
            >
              Edit
            </Button>
          ) : (
            <Stack direction="row" gap={2}>
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => setEditing(undefined)}
              >
                Cancel
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={editing === "" || journal.entry === editing}
                startIcon={<CheckIcon />}
                onClick={() => {
                  setEditing(undefined);
                  dispatch(
                    updateJournalEntry({
                      entry: { ...journal, entry: editing },
                    }),
                  );
                }}
              >
                Save
              </Button>
            </Stack>
          ))}
      </Stack>

      <Box>
        <RichText
          value={editing ?? journal.entry}
          editable={editing !== undefined}
          onChange={(v) => editing !== undefined && setEditing(v)}
        />
      </Box>

      <Stack direction="row" spacing={1}>
        <Chip icon={<CommentIcon />} label={countComments(journal.comments)} />
      </Stack>

      <Stack>
        {comment && comment.replyTo === undefined ? (
          <>
            <RichText
              editable
              value=""
              onChange={(comment) => setComment({ comment })}
            />
            <Stack direction="row" justifyContent="right">
              <Button color="inherit" onClick={() => setComment(undefined)}>
                Cancel
              </Button>
              <Button
                color="info"
                disabled={comment.comment === ""}
                onClick={() => {
                  dispatch(
                    createJournalEntryComment({ entryId: journal.id, comment }),
                  ).then(() => setComment(undefined));
                }}
              >
                Comment
              </Button>
            </Stack>
          </>
        ) : (
          <TextField
            color="info"
            value=""
            placeholder="Add a comment"
            onFocus={() => setComment({ comment: "" })}
          />
        )}
      </Stack>

      {journal.comments.map((c, i) => (
        <JournalEntryComment key={i} journal={journal} comment={c} indent={0} />
      ))}
    </Stack>
  );
};
