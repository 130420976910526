import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import ClearIcon from "@mui/icons-material/Clear";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  SxProps,
  useMediaQuery,
} from "@mui/material";
import theme from "app/theme";
import {
  FootballFieldHomography,
  KonvaVideoPlayer,
  VideoPlayer,
} from "components";
import { HomographyResult, Shape, VideoOffset } from "generated/openapi";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { OnProgressProps } from "react-player/base";
import { setAlert, useAppDispatch } from "store";
import { AugmentControls } from "./AugmentControls";
import { VideoPlayerRef } from "./VideoPlayer";

interface Props {
  url?: string;
  gameId?: number;
  dynamicHomography?: HomographyResult;
  staticHomography?: Record<string, HomographyResult>;
  drawings?: Record<string, Shape[]>;
  getVideoOffset: () => VideoOffset | undefined;
  onDrawChange?: (_: Record<string, Shape[]>) => void;
  onEnded?: () => void;
  onResetHomography?: () => void;
  onProgress?: (_: OnProgressProps) => void;
  onStaticHomographyChange?: (_: Record<string, HomographyResult>) => void;
}

const positionMenuOptions: Record<string, any> = {
  "Top-Right": { top: 0, right: 0 },
  "Top-Left": { top: 0, left: 0 },
  "Bottom-Right": { bottom: 40, right: 0 },
  "Bottom-Left": { bottom: 40, left: 0 },
};

const windowSizeOptions: Record<string, string> = {
  Small: "30%",
  Medium: "40%",
  Large: "60%",
};

export const CombinedPlayer = forwardRef(
  (
    {
      url,
      gameId,
      dynamicHomography,
      getVideoOffset,
      drawings,
      staticHomography,
      onDrawChange,
      onEnded,
      onResetHomography,
      onProgress,
      onStaticHomographyChange,
    }: Props,
    ref,
  ) => {
    const dispatch = useAppDispatch();

    const [videoMainWindow, setVideoMainWindow] = useState<boolean>(true);
    const [forceReflow, setForceReflow] = useState<number>(0);
    const [posAnchorEl, setPosAnchorEl] = useState<null | HTMLElement>(null);
    const [sizeAnchorEl, setSizeAnchorEl] = useState<null | HTMLElement>(null);
    const [windowProps, setWindowProps] = useState<string>("Top-Right");
    const [windowSize, setWindowSize] = useState<string>("40%");
    const [disableDrawing, setDisableDrawing] = useState<boolean>(false);
    const [pausedTs, setPausedTs] = useState<number | undefined>(0);
    const [_homography, _setHomography] = useState<
      HomographyResult | undefined
    >(undefined);
    const [homographyLoading, setHomographyLoading] = useState<boolean>(false);
    const videoPlayerId = "video-container";
    const isMobile = useMediaQuery(theme.breakpoints.down(900));

    const [playerRef, setPlayerRef] = useState<VideoPlayerRef | null>(null);

    useImperativeHandle(ref, () => playerRef, [playerRef]);

    const homography =
      pausedTs && staticHomography?.[pausedTs]
        ? staticHomography[pausedTs]
        : (dynamicHomography ?? _homography);

    const handlePositionMenuClose = (val: string) => {
      setPosAnchorEl(null);
      setWindowProps(val);
    };

    const handleSizeChange = (val: string) => {
      setSizeAnchorEl(null);
      setWindowSize(val);
      setForceReflow(forceReflow + 1);
    };

    const handleToggle = () => {
      setVideoMainWindow((prevState) => !prevState);
      setForceReflow(forceReflow + 1);
    };

    const handleResetHomography = () => {
      _setHomography(undefined);
      onResetHomography?.();
      setVideoMainWindow(true);
    };

    const handleHomographyClick = () => {
      setHomographyLoading(true);
      gameId &&
        playerRef
          ?.getPlayerHomography(gameId, getVideoOffset())
          .then((res) => {
            onStaticHomographyChange &&
              pausedTs &&
              res &&
              onStaticHomographyChange({
                ...staticHomography,
                [pausedTs]: res,
              });
            _setHomography(res);
          })
          .catch(() => {
            dispatch(
              setAlert({
                message: "Unable to retrieve homography for this frame",
                severity: "error",
              }),
            );
          })
          .finally(() => setHomographyLoading(false));
    };

    const handleStaticHomographyChange = (homography: HomographyResult) => {
      if (staticHomography) {
        onStaticHomographyChange?.({
          ...staticHomography,
          [pausedTs!]: homography,
        });
      }
    };

    useEffect(() => {
      setDisableDrawing(!videoMainWindow);
    }, [videoMainWindow]);

    useEffect(() => {
      setPausedTs(0);
    }, [url]);

    // Reset when playlsit reset button is pressed
    useEffect(() => {
      _setHomography(undefined);
    }, [staticHomography]);

    const pushBottom =
      !videoMainWindow &&
      (windowProps === "Bottom-Left" || windowProps === "Bottom-Right")
        ? { bottom: 0 }
        : undefined;

    const flipButtonSide =
      windowProps === "Top-Left" || windowProps === "Bottom-Left";

    const mainSx: SxProps = {
      width: "100%",
      margin:
        isMobile && screen.orientation.type === "landscape-primary"
          ? ""
          : "auto",
    };
    const secondarySx: SxProps = {
      display: "flex",
      flexDirection: flipButtonSide ? "row" : "row-reverse",
      gap: 0.5,
      position: "absolute",
      zIndex: 1000,
      width: windowSize,
      ...positionMenuOptions[windowProps],
      ...pushBottom,
    };

    const buttonSx = {
      width: 35,
      height: 35,
      bgcolor: "primary.main",
      opacity: 0.7,
      ":hover": { bgcolor: "primary.dark" },
    };

    const homographyTimestamps = Object.keys(staticHomography ?? {}).map(
      Number,
    );
    const drawingTimestamps = Object.keys(drawings ?? {}).map(Number);
    const getCurrentTime = () => playerRef!.getCurrentMicros();

    const findStaticHomographiesTs = () => {
      const ts = getCurrentTime();
      return homographyTimestamps.find((t) => t <= ts && ts - t < 100_000);
    };

    const findDrawingTs = () => {
      const ts = getCurrentTime();
      return drawingTimestamps.find((t) => t <= ts && ts - t < 100_000);
    };

    const homographyControls = () => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column-reverse", gap: 0.5 }}
          >
            <IconButton
              sx={buttonSx}
              onClick={() => {
                handleToggle();
              }}
            >
              <OpenInNewIcon fontSize="small" />
            </IconButton>
            <IconButton
              sx={buttonSx}
              onClick={(e) => setPosAnchorEl(e.currentTarget)}
            >
              <ControlCameraIcon fontSize="small" />
            </IconButton>
            <IconButton
              sx={buttonSx}
              onClick={(e) => setSizeAnchorEl(e.currentTarget)}
            >
              <AspectRatioIcon fontSize="small" />
            </IconButton>
          </Box>
          <IconButton
            sx={{ ...buttonSx }}
            onClick={() => {
              const res = { ...staticHomography };
              // eslint-disable-next-line
              delete res[pausedTs!];
              onStaticHomographyChange?.(res);
            }}
          >
            <ClearIcon fontSize="small" sx={{ color: "red" }} />
          </IconButton>
          <Menu
            disableScrollLock
            disablePortal
            MenuListProps={{ disablePadding: true }}
            anchorEl={posAnchorEl}
            open={Boolean(posAnchorEl)}
            onClose={() => setPosAnchorEl(null)}
          >
            {Object.keys(positionMenuOptions).map((opt, i) => (
              <MenuItem
                sx={{
                  "&.Mui-selected": {
                    bgcolor: "primary.main",
                  },
                }}
                dense
                key={i}
                selected={opt === windowProps}
                onClick={() => {
                  handlePositionMenuClose(opt);
                }}
              >
                {opt}
              </MenuItem>
            ))}
          </Menu>
          <Menu
            disableScrollLock
            disablePortal
            MenuListProps={{ disablePadding: true }}
            anchorEl={sizeAnchorEl}
            open={Boolean(sizeAnchorEl)}
            onClose={() => setSizeAnchorEl(null)}
          >
            {Object.keys(windowSizeOptions).map((opt, i) => (
              <MenuItem
                sx={{
                  "&.Mui-selected": {
                    bgcolor: "primary.main",
                  },
                }}
                dense
                key={i}
                selected={windowSizeOptions[opt] === windowSize}
                onClick={() => {
                  handleSizeChange(windowSizeOptions[opt]);
                }}
              >
                {opt}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      );
    };

    return (
      <Box id={videoPlayerId}>
        <Box sx={videoMainWindow ? mainSx : secondarySx}>
          {drawings ? (
            <KonvaVideoPlayer
              ref={(v) => setPlayerRef(v as VideoPlayerRef | null)}
              url={url}
              pausedTs={pausedTs}
              playing={pausedTs === undefined || pausedTs === 0}
              drawings={drawings}
              augmentControls={
                <AugmentControls
                  onHomographyClick={handleHomographyClick}
                  disabled={
                    pausedTs === undefined ||
                    pausedTs === 0 ||
                    !!staticHomography?.[pausedTs]
                  }
                  loading={homographyLoading}
                />
              }
              disableDrawing={disableDrawing}
              onChange={onDrawChange}
              onEnded={onEnded}
              onPlay={() => {
                if (
                  pausedTs !== undefined &&
                  ((drawings && pausedTs in drawings) ||
                    (staticHomography && pausedTs in staticHomography))
                ) {
                  playerRef!.seekTo(pausedTs + 100_000);
                }
                setPausedTs(undefined);
              }}
              onPause={() => setPausedTs(pausedTs ?? getCurrentTime())}
              onSeek={(secs) => {
                setPausedTs(
                  pausedTs === undefined ? undefined : secs * 1_000_000,
                );
                handleResetHomography();
              }}
              onProgress={(props) => {
                if (staticHomography) {
                  const k = findStaticHomographiesTs();
                  if (k !== undefined) {
                    setPausedTs(k);
                    playerRef!.seekTo(k);
                  }
                }
                if (drawings) {
                  const k = findDrawingTs();
                  if (k !== undefined) {
                    setPausedTs(k);
                    playerRef!.seekTo(k);
                  }
                }
                onProgress && onProgress(props);
              }}
            />
          ) : (
            <VideoPlayer
              playing
              ref={(v) => setPlayerRef(v as VideoPlayerRef | null)}
              url={url}
              augmentControls={
                <AugmentControls
                  onHomographyClick={handleHomographyClick}
                  disabled={
                    pausedTs === undefined || pausedTs === 0 || !!_homography
                  }
                  loading={homographyLoading}
                />
              }
              onPlay={() => {
                setPausedTs(undefined);
                handleResetHomography();
              }}
              onPause={() => setPausedTs(pausedTs ?? getCurrentTime())}
              onSeek={handleResetHomography}
              progressInterval={onProgress ? 100 : undefined}
              onProgress={onProgress}
            />
          )}
          {!videoMainWindow && homography && homographyControls()}
        </Box>
        <Box key={forceReflow} sx={videoMainWindow ? secondarySx : mainSx}>
          {homography && (
            <>
              <FootballFieldHomography
                homography={homography}
                onChange={handleStaticHomographyChange}
              />
              {videoMainWindow && homographyControls()}
            </>
          )}
        </Box>
      </Box>
    );
  },
);
