import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppState } from "store/state";
import {
  createLiveMessage,
  createLiveSegment,
  createLiveStream,
  deleteLiveSegment,
  getLiveStreams,
  sendLiveSegment,
  setLiveMode,
  setLiveThumbnail,
  updateLiveSegment,
  updateLiveTacticalPitch,
} from "./actions";

export const liveStreamBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(getLiveStreams.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
      state.liveStreams = [];
      state.liveStreamsLoading = true;
    })
    .addCase(getLiveStreams.fulfilled, (state, action) => {
      state.liveStreams = action.payload;
      state.liveStreamsLoading = false;
    })
    .addCase(getLiveStreams.rejected, (state, _action) => {
      state.liveStreamsLoading = false;
    })
    .addCase(createLiveStream.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(createLiveSegment.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updateLiveTacticalPitch.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(setLiveMode.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(setLiveThumbnail.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updateLiveSegment.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(deleteLiveSegment.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(sendLiveSegment.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(createLiveMessage.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    });
