import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Modal } from "components";
import { Playlist } from "generated/openapi";
import { FC, useMemo, useState } from "react";
import { generatePlaylistHomography, useAppDispatch } from "store";

interface Props {
  playlist: Playlist;
  onClose: () => void;
}

export const GeneratePlaylistHomographyModal: FC<Props> = ({
  playlist,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const allSegments = useMemo(
    () =>
      playlist.segments.filter((s) => !s.hasHomography && s.gameHasPanorama),
    [playlist.segments],
  );

  const [segments, setSegments] = useState<number[]>([]);

  const handleSegmentClick = (id: number) => {
    const i = segments.findIndex((v) => v === id);
    if (i < 0) {
      setSegments([...segments, id]);
    } else {
      segments.splice(i, 1);
      setSegments([...segments]);
    }
  };

  return (
    <Modal open onClose={onClose} sx={{ p: 3 }}>
      <Typography sx={{ pb: 2 }} variant="h3">
        Generate Playlist Homography
      </Typography>

      <List sx={{ maxHeight: "60vh", overflowY: "auto" }}>
        <ListItem
          secondaryAction={
            <Checkbox
              edge="end"
              color="info"
              checked={segments.length === allSegments.length}
              onClick={() =>
                setSegments(
                  segments.length === allSegments.length
                    ? []
                    : allSegments.map((s) => s.id),
                )
              }
            />
          }
        >
          <ListItemText sx={{ py: 1.5 }} primary="" />
        </ListItem>
        {allSegments.map((s) => (
          <ListItem
            key={s.id}
            disablePadding
            secondaryAction={
              <Checkbox
                edge="end"
                color="info"
                checked={segments.includes(s.id)}
                onClick={() => handleSegmentClick(s.id)}
              />
            }
          >
            <ListItemButton onClick={() => handleSegmentClick(s.id)}>
              <ListItemText primary={s.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Button
        color="info"
        onClick={() => {
          if (segments.length) {
            dispatch(
              generatePlaylistHomography({ playlistId: playlist.id, segments }),
            ).then(() => onClose());
          } else {
            onClose();
          }
        }}
      >
        Submit
      </Button>
    </Modal>
  );
};
