import { Box, Typography } from "@mui/material";
import { FC, ReactElement } from "react";

export interface Props {
  url?: string | null;
  title?: string;
  subtitle?: string;
  duration?: string;
  width?: number | string;
  height?: number | string;
  children?: false | ReactElement | ReactElement[];
}

export const Thumbnail: FC<Props> = ({
  url,
  title,
  subtitle,
  duration,
  width,
  height,
  children,
}) => {
  return (
    <Box
      sx={{
        color: "white",
        position: "relative",
        width: width ?? 380,
        height: height ?? 214,
        backgroundSize: "cover",
        backgroundImage: `url(${url})`,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          background:
            "linear-gradient(180deg, transparent, transparent 40%, rgba(10, 24, 35, 0.85) 85%)",
        }}
      ></Box>

      {duration && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            padding: 0.75,
            backgroundColor: "#000000c0",
            borderBottomRightRadius: 6,
          }}
        >
          {duration}
        </Box>
      )}

      <Box
        sx={{
          position: "absolute",
          left: 4,
          bottom: 4,
          fontWeight: "bold",
        }}
      >
        {title && <Typography fontWeight="bold">{title}</Typography>}
        {subtitle && (
          <Typography color="#2fcaff" variant="caption" fontWeight="bold">
            {subtitle}
          </Typography>
        )}
      </Box>

      <Box sx={{ position: "absolute", inset: 0 }}>{children}</Box>
    </Box>
  );
};
