import { Ellipse, Shape, ShapeDiscriminants } from "generated/openapi";
import Konva from "konva";
import { FC, useEffect, useRef } from "react";
import * as ReactKonva from "react-konva";

export interface Props {
  ellipse: Ellipse;
  selected: boolean;
  interactive: boolean;
  onSelect: () => void;
  onChange: (_: Shape) => void;
  onDrag?: (_: Ellipse) => void;
  onTransform?: (_: Ellipse) => void;
}

export const EllipseComponent: FC<Props> = ({
  ellipse,
  selected,
  interactive,
  onSelect,
  onChange: _onChange,
  onDrag,
  onTransform,
}) => {
  const shapeRef = useRef<Konva.Ellipse>(null);
  const trRef = useRef<Konva.Transformer>(null);

  useEffect(() => {
    if (selected && shapeRef.current && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [selected]);

  const onChange = (value: Partial<Ellipse>) =>
    _onChange({
      type: ShapeDiscriminants.Ellipse,
      value: { ...ellipse, ...value },
    });

  const getTransform = () => {
    const node = shapeRef.current!;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    node.scaleX(1);
    node.scaleY(1);

    return {
      ...ellipse,
      ...node.position(),
      radiusX: (node.width() * scaleX) / 2,
      radiusY: (node.height() * scaleY) / 2,
      offsetX: node.offsetX() * scaleX,
      offsetY: node.offsetY() * scaleY,
      rotation: node.rotation(),
    };
  };

  return (
    <>
      <ReactKonva.Ellipse
        {...ellipse}
        stroke={ellipse.stroke ?? undefined}
        strokeWidth={ellipse.strokeWidth ?? undefined}
        draggable={interactive}
        strokeScaleEnabled={false}
        ref={shapeRef}
        onClick={() => interactive && onSelect()}
        onDragMove={(e) => onDrag?.({ ...ellipse, ...e.target.position() })}
        onDragEnd={(e) => onChange({ ...e.target.position() })}
        onTransform={(_e) => onTransform?.(getTransform())}
        onTransformEnd={(_e) => onChange(getTransform())}
      />
      {selected && (
        <ReactKonva.Transformer ref={trRef} ignoreStroke keepRatio={false} />
      )}
    </>
  );
};
