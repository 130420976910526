import { Polygon, Shape, ShapeDiscriminants } from "generated/openapi";
import Konva from "konva";
import { FC, useMemo, useRef } from "react";
import * as ReactKonva from "react-konva";

export interface Props {
  polygon: Polygon;
  selected: boolean;
  interactive: boolean;
  onSelect: () => void;
  onChange: (_: Shape) => void;
}

export const PolygonComponent: FC<Props> = ({
  polygon,
  selected,
  interactive,
  onSelect,
  onChange: _onChange,
}) => {
  const shapeRef = useRef<Konva.Line>(null);
  const trRef = useRef<(Konva.Rect | null)[]>([]);

  const changeCursor = (cursor: string) => {
    const stage = shapeRef.current!.getStage();
    if (stage) {
      stage.container().style.cursor = cursor;
    }
  };

  const onChange = (value: Partial<Polygon>) =>
    _onChange({
      type: ShapeDiscriminants.Polygon,
      value: { ...polygon, ...value },
    });

  const Anchor = ({ x, y, i }: { x: number; y: number; i: number }) => (
    <ReactKonva.Rect
      draggable={interactive}
      visible={selected}
      ref={(r) => (trRef.current[i] = r)}
      x={x}
      y={y}
      width={10}
      height={10}
      offsetX={5}
      offsetY={5}
      fill={"white"}
      stroke={"blue"}
      strokeWidth={1}
      onMouseEnter={() => changeCursor("move")}
      onMouseLeave={() => changeCursor("default")}
      onDragMove={(_e) => {
        const points = trRef.current.flatMap((r) => {
          const p = r!.getPosition();
          return [p.x, p.y];
        });
        shapeRef.current!.points(points);
      }}
    />
  );

  const points = useMemo(() => {
    const length = polygon.points.length;
    const points: { x: number; y: number }[] = Array.from({
      length: length / 2,
    });
    for (let i = 0; i < length; i += 2) {
      points[i / 2] = { x: polygon.points[i], y: polygon.points[i + 1] };
    }
    return points;
  }, [polygon]);

  return (
    <ReactKonva.Group
      draggable={interactive}
      onDragEnd={(e) => {
        const points = trRef.current.flatMap((r) => {
          const p = r!.getAbsolutePosition();
          return [p.x, p.y];
        });
        shapeRef.current!.points(points);
        e.target.setAttrs({ x: 0, y: 0 });
        onChange({ points });
      }}
    >
      <ReactKonva.Line
        {...polygon}
        stroke={polygon.stroke ?? undefined}
        strokeWidth={polygon.strokeWidth ?? undefined}
        closed
        strokeScaleEnabled={false}
        ref={shapeRef}
        onClick={() => interactive && onSelect()}
      />
      {points.map(({ x, y }, i) => (
        <Anchor key={i} x={x} y={y} i={i} />
      ))}
    </ReactKonva.Group>
  );
};
