import EditIcon from "@mui/icons-material/Edit";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { fontStyle } from "app/theme";
import { UserRole } from "generated/openapi";
import { FC, useState } from "react";
import {
  selectOrg,
  selectTeam,
  selectUser,
  updateTeam,
  useAppDispatch,
  useAppSelector,
} from "store";
import { Groups } from "./Groups";
import { TeamMembersTable } from "./TeamMembersTable";

interface Props {
  teamId: number;
}

export const Team: FC<Props> = ({ teamId }) => {
  const team = useAppSelector(selectTeam(teamId));
  const user = useAppSelector(selectUser)!;
  const org = useAppSelector(selectOrg)!;
  const dispatch = useAppDispatch();

  const [editing, setEditing] = useState<string | undefined>(undefined);

  const isPlayer = user.userRole === UserRole.Player;

  const handleSave = () => {
    if (editing && editing !== org.name) {
      dispatch(updateTeam({ team: { ...team!, name: editing } }));
      setEditing(undefined);
    }
  };

  if (!team) {
    return <></>;
  }

  return (
    <Stack spacing={3}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        {editing !== undefined ? (
          <>
            <TextField
              autoFocus
              color="third"
              variant="standard"
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  fontWeight: fontStyle.fontWeightSemiBold,
                  fontSize: "2.25rem",
                  lineHeight: 1.167,
                },
                "& .MuiInputBase-input": {
                  p: 0,
                },
              }}
              value={editing}
              onKeyDown={(e) => e.key === "Enter" && handleSave()}
              onChange={(e) => setEditing(e.target.value)}
            />
            <Stack direction="row" gap={1}>
              <Button
                sx={{ textTransform: "capitalize" }}
                size="small"
                color="inherit"
                onClick={() => setEditing(undefined)}
              >
                Cancel
              </Button>
              <Button
                size="small"
                color="success"
                variant="contained"
                disabled={!editing || editing === team.name}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Typography
              variant="h3"
              sx={{ pt: "5px", borderBottom: "5px solid transparent" }}
            >
              {team.name}
            </Typography>
            {!isPlayer && (
              <Button
                size="small"
                variant="outlined"
                color="warning"
                sx={{ textTransform: "inherit" }}
                onClick={() => setEditing(team.name)}
                startIcon={<EditIcon />}
              >
                Edit name
              </Button>
            )}
          </>
        )}
      </Stack>
      <TeamMembersTable teamId={teamId} />
      <Groups groups={team.groups} teamId={teamId} />
    </Stack>
  );
};
