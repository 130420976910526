import { JournalEntry } from "generated/openapi";

export interface JournalState {
  journalEntries?: JournalEntry[];
  journalEntriesLoading: boolean;
}

export const initialJournalState: JournalState = {
  journalEntries: undefined,
  journalEntriesLoading: false,
};
