import { Box, Divider, useMediaQuery } from "@mui/material";
import { ForzaEventsParameters } from "api";
import theme from "app/theme";
import { FilterHeader } from "components/Common/FilterHeader";
import { ForzaEventType } from "generated/openapi";
import { FC } from "react";
import {
  selectEventsFilters,
  selectSelectedEventType,
  setSelectedForzaEventType,
  useAppDispatch,
  useAppSelector,
} from "store";
import { DateFilter } from "./DateFilter";
import { EventTypeFilter } from "./EventTypeFilter";
import { ForzaTeamsFilter } from "./ForzaTeamsFilter";
import { PlayersFilter } from "./PlayersFilter";
import { SecondaryHighlightsFilterBar } from "./SecondaryHighlightsFilterBar/SecondaryHighlightsFilterBar";

interface Props {
  filters: ForzaEventsParameters;
  onChange: (_: ForzaEventsParameters) => void;
}

export const HighlightsFilterBar: FC<Props> = (props) => {
  const { onChange } = props;
  const filters = useAppSelector(selectEventsFilters);
  const dispatch = useAppDispatch();
  const selectedEventType = useAppSelector(selectSelectedEventType);
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up(600));

  const showSecondaryFilters =
    selectedEventType === ForzaEventType.Goal ||
    selectedEventType === ForzaEventType.Shot;

  return (
    <FilterHeader>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          width: "100%",
          "& > *": mobileBreakpoint
            ? {}
            : {
                width: "100% !important",
              },
        }}
      >
        <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
          <ForzaTeamsFilter
            label="Team"
            value={filters.teamId}
            onChange={(team) => onChange({ ...filters, teamId: team?.id })}
          />
        </Box>

        <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
          <EventTypeFilter
            value={selectedEventType}
            onChange={(eventType) =>
              dispatch(setSelectedForzaEventType(eventType))
            }
          />
        </Box>

        <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
          <ForzaTeamsFilter
            label="Opponent"
            value={filters.oppositionTeamId}
            onChange={(team) =>
              onChange({ ...filters, oppositionTeamId: team?.id })
            }
          />
        </Box>

        <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
          <PlayersFilter
            teamId={filters.teamId}
            onChange={(player) =>
              onChange({ ...filters, playerId: player?.id })
            }
          />
        </Box>

        <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
          <DateFilter
            label="Start Date"
            value={filters.from}
            onChange={(date) =>
              onChange({ ...filters, from: date?.toISOString() })
            }
          />
        </Box>

        <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
          <DateFilter
            label="End Date"
            value={filters.to}
            onChange={(date) =>
              onChange({ ...filters, to: date?.toISOString() })
            }
          />
        </Box>
      </Box>
      {showSecondaryFilters && (
        <>
          <Divider sx={{ width: "100%", my: 1, bgcolor: "grey" }} />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              flexDirection: mobileBreakpoint ? "row" : "column",
              alignItems: "center",
              "& > *": mobileBreakpoint
                ? {}
                : {
                    width: "100% !important",
                  },
            }}
          >
            <SecondaryHighlightsFilterBar
              filters={filters}
              onChange={onChange}
            />
          </Box>
        </>
      )}
    </FilterHeader>
  );
};
