import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";

interface Props {
  value: number | undefined;
  years: number[];
  onChange: (_?: number) => void;
}

export const YearFilter: FC<Props> = ({ value, years, onChange }) => {
  return (
    <FormControl size="small" sx={{ width: "100%" }}>
      <InputLabel>Year</InputLabel>
      <Select
        MenuProps={{ disableScrollLock: true }}
        label="Year"
        defaultValue={value ?? ""}
        onChange={(e) => onChange(e.target.value ? +e.target.value : undefined)}
      >
        <MenuItem value="">---</MenuItem>
        {years.map((y) => (
          <MenuItem key={y} value={y}>
            {y}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
