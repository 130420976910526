import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { createUser } from "api";
import { CreateUser as User, Invite, UserRole } from "generated/openapi";
import { FC } from "react";
import { Form, useForm } from "react-hook-form";
import { selectAccessToken, useAppSelector } from "store";

export interface Props {
  invite?: Invite;
}

export const CreateUser: FC<Props> = ({ invite }) => {
  const {
    control,
    register,
    formState: { errors },
  } = useForm<User>({
    defaultValues: invite ? { inviteId: invite.id } : {},
  });

  const { getAccessTokenSilently } = useAuth0();
  const token = useAppSelector(selectAccessToken)!;

  return (
    <Form
      control={control}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginTop: "2rem",
        marginInline: "auto",
        width: 380,
      }}
      onSubmit={async ({ data }) => {
        await createUser(data, token);
        await getAccessTokenSilently({ cacheMode: "off" });
        window.location.assign(window.location.origin);
      }}
    >
      <Typography sx={{ pb: 2 }} variant="h3">
        Create Profile
      </Typography>
      {!invite && (
        <FormControl error={!!errors.userRole} color="info">
          <FormLabel>User Role</FormLabel>
          <RadioGroup>
            <FormControlLabel
              value={UserRole.Admin}
              control={<Radio color="info" />}
              label={UserRole.Admin}
              {...register("userRole", { required: true })}
            />
            <FormControlLabel
              value={UserRole.Coach}
              control={<Radio color="info" />}
              label={UserRole.Coach}
              {...register("userRole", { required: true })}
            />
            <FormControlLabel
              value={UserRole.Player}
              control={<Radio color="info" />}
              label={UserRole.Player}
              {...register("userRole", { required: true })}
            />
          </RadioGroup>
        </FormControl>
      )}

      <TextField
        color="info"
        label="First Name"
        variant="outlined"
        error={!!errors.firstName}
        {...register("firstName", { required: true })}
      />
      <TextField
        color="info"
        label="Last Name"
        variant="outlined"
        error={!!errors.lastName}
        {...register("lastName", { required: true })}
      />

      <Button color="info" type="submit">
        Submit
      </Button>
    </Form>
  );
};
