import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "app/theme";
import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export const FilterHeader: FC<Props> = ({ children }) => {
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up(600));

  return (
    <Card
      variant="elevation"
      sx={{
        bgcolor: "primary.main",
        borderRadius: 2,
        alignItems: "center",
        justifyContent: "center",
        p: 1,
        "& .MuiFormLabel-root": {
          color: `${theme.palette.text.secondary} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.primary.light} !important`,
        },
      }}
    >
      {mobileBreakpoint ? (
        children
      ) : (
        <>
          <Accordion
            sx={{ bgcolor: "primary.main", boxShadow: "none" }}
            disableGutters
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <MenuIcon sx={{ mr: 2 }} />
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
          </Accordion>
        </>
      )}
    </Card>
  );
};
