import {
  ConnectedEllipses,
  Ellipse,
  EllipseMinimal,
  Shape,
  ShapeDiscriminants,
} from "generated/openapi";
import { FC, Fragment, useEffect, useState } from "react";
import * as ReactKonva from "react-konva";
import { EllipseComponent } from "./Ellipse";

export interface Props {
  ellipses: ConnectedEllipses;
  selected: boolean;
  interactive: boolean;
  onSelect: () => void;
  onChange: (_: Shape) => void;
}

export const ConnectedEllipsesComponent: FC<Props> = ({
  ellipses,
  selected,
  interactive,
  onSelect,
  onChange: _onChange,
}) => {
  const [shape, setShape] = useState(ellipses);
  const [selectedIdx, setSelectedIdx] = useState(0);

  useEffect(() => setShape(ellipses), [ellipses]);

  const onChange = (value: Partial<ConnectedEllipses>) =>
    _onChange({
      type: ShapeDiscriminants.ConnectedEllipses,
      value: { ...ellipses, ...value },
    });

  const getChanged = (ellipse: EllipseMinimal, i: number) => ({
    ...shape,
    ellipses: shape.ellipses.map((e, j) => (i === j ? ellipse : e)),
  });

  return (
    <>
      {shape.ellipses.map((e, i, arr) => {
        let line = <></>;
        const n = arr[i + 1];
        if (n) {
          const a1 = Math.atan((n.y - e.y) / (n.x - e.x));
          const a2 = Math.atan((e.y - n.y) / (e.x - n.x));

          const rot1 = degreesToRadians(e.rotation);
          const rot2 = degreesToRadians(n.rotation);

          const r1 = ellipseRadius(e.radiusX, Math.abs(e.radiusY), rot1 + a1);
          const r2 = ellipseRadius(n.radiusX, Math.abs(n.radiusY), rot2 + a2);

          const [x1, y1] = movePoint(e.x, e.y, a1, e.x < n.x ? r1 : -r1);
          const [x2, y2] = movePoint(n.x, n.y, a2, e.x < n.x ? -r2 : r2);

          line = (
            <ReactKonva.Line
              points={[x1, y1, x2, y2]}
              stroke={shape.stroke}
              strokeWidth={shape.strokeWidth}
            />
          );
        }
        return (
          <Fragment key={i}>
            {line}
            <EllipseComponent
              key={i}
              ellipse={{
                ...e,
                fill: shape.fill,
                stroke: shape.stroke,
                strokeWidth: shape.strokeWidth,
              }}
              selected={selected && i === selectedIdx}
              interactive={interactive}
              onSelect={() => {
                if (interactive) {
                  onSelect();
                  setSelectedIdx(i);
                }
              }}
              onDrag={(v) => setShape(getChanged(v, i))}
              onTransform={(v) => setShape(getChanged(v, i))}
              onChange={(v) => onChange(getChanged(v.value as Ellipse, i))}
            />
          </Fragment>
        );
      })}
    </>
  );
};

const degreesToRadians = (degrees: number) => (degrees * Math.PI) / 180;

// https://en.wikipedia.org/wiki/Ellipse#Polar_form_relative_to_center
const ellipseRadius = (x: number, y: number, angle: number) =>
  (x * y) /
  Math.sqrt(
    Math.pow(y * Math.cos(angle), 2) + Math.pow(x * Math.sin(angle), 2),
  );

const movePoint = (x: number, y: number, angle: number, distance: number) => [
  x + distance * Math.cos(angle),
  y + distance * Math.sin(angle),
];
