import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppState } from "store/state";
import {
  getForzaGame,
  getForzaGames,
  setForzaGameKickoff,
  setForzaGamesFilters,
} from "./actions";

export const forzaGamesBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(getForzaGame.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
      state.forzaGame = undefined;
    })
    .addCase(getForzaGame.fulfilled, (state, action) => {
      state.forzaGame = action.payload;
    })
    .addCase(getForzaGames.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
      state.forzaGames = undefined;
      state.forzaGamesLoading = true;
    })
    .addCase(getForzaGames.fulfilled, (state, action) => {
      state.forzaGames = action.payload;
      state.forzaGamesLoading = false;
    })
    .addCase(getForzaGames.rejected, (state, _action) => {
      state.forzaGamesLoading = false;
    })
    .addCase(setForzaGameKickoff.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(setForzaGameKickoff.fulfilled, (state, action) => {
      if (state.playlist) {
        for (const s of state.playlist.segments)
          if (s.forzaGameId === action.meta.arg.forzaGameId)
            s.manualKickoffTimeMs =
              action.meta.arg.setGameKickoff.manualKickoffTimeMs;
      }
    })
    .addCase(setForzaGamesFilters, (state, action) => {
      state.forzaGamesFilter = action.payload;
      (action as any).asyncDispatch(getForzaGames({ ...action.payload }));
    });
