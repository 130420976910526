import {
  CircularProgress,
  Grid,
  Pagination,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { GetPlaylistsArgs } from "api";
import theme from "app/theme";
import {
  FilterHeader,
  ForzaTeamsInput,
  PlayersInput,
  PlaylistItem,
  PlaylistTagsFilter,
} from "components";
import { FC, useEffect, useState } from "react";
import {
  getPlaylists,
  selectPlaylists,
  selectSelectedTeam,
  useAppDispatch,
  useAppSelector,
} from "store";

export const Playlists: FC = () => {
  const dispatch = useAppDispatch();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up(600));

  const playlists = useAppSelector(selectPlaylists);
  const selectedTeam = useAppSelector(selectSelectedTeam)!;

  const [filters, setFilters] = useState<GetPlaylistsArgs>({
    teamId: selectedTeam.id,
    limit: 12,
    offset: 0,
  });

  useEffect(() => {
    dispatch(getPlaylists(filters));
  }, [filters]);

  return (
    <Stack gap={2}>
      <FilterHeader>
        <Stack
          direction="row"
          gap={2}
          flexWrap="wrap"
          sx={{
            padding: 1,
            "& > div": { minWidth: 120, width: 220 },
            "& > *": mobileBreakpoint
              ? {}
              : {
                  width: "100% !important",
                },
          }}
        >
          <PlaylistTagsFilter
            value={filters.tags ?? []}
            onChange={(tags) => setFilters({ ...filters, tags })}
          />
          <PlayersInput
            placeholder="Players"
            value={filters.players ?? []}
            onChange={(players) => setFilters({ ...filters, players })}
          />
          <ForzaTeamsInput
            placeholder="Teams"
            value={filters.teams ?? []}
            onChange={(teams) => setFilters({ ...filters, teams })}
          />
        </Stack>
      </FilterHeader>
      {playlists ? (
        <>
          <Grid container spacing={2}>
            {playlists.values.map((v) => (
              <Grid item xs={12} md={6} lg={6} xl={4} xxl={3} key={v.id}>
                <PlaylistItem playlist={v} />
              </Grid>
            ))}
          </Grid>
          <Pagination
            sx={{ display: "flex", my: 2, alignSelf: "center" }}
            color="secondary"
            size={mobileBreakpoint ? "medium" : "small"}
            page={filters.offset! / filters.limit! + 1}
            count={Math.ceil(playlists.total / filters.limit!)}
            onChange={(_event, page: number) =>
              setFilters({ ...filters, offset: (page - 1) * filters.limit! })
            }
          />
        </>
      ) : (
        <Stack alignItems="center">
          <CircularProgress color="secondary" size={80} />
        </Stack>
      )}
    </Stack>
  );
};
