import CircleIcon from "@mui/icons-material/Circle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Replay10Icon from "@mui/icons-material/Replay10";
import Replay30Icon from "@mui/icons-material/Replay30";
import Replay5Icon from "@mui/icons-material/Replay5";
import TableChartIcon from "@mui/icons-material/TableChart";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { VideoPlayer } from "components";
import { SegmentsStatsModal } from "components/SegmentsStatsTable/SegmentsStatsModal";
import { VideoPlayerRef } from "components/VideoPlayer/VideoPlayer";
import { PlaylistSegment } from "generated/openapi";
import { FC, useEffect, useRef, useState } from "react";

interface Props {
  liveUrl: string;
  segment?: PlaylistSegment;
  segments: PlaylistSegment[];
  onExitFocusMode: () => void;
  onResetSegment: () => void;
}

interface State {
  playing: boolean;
  url?: string;
}

export const FocusModeView: FC<Props> = ({
  liveUrl,
  segment,
  segments,
  onExitFocusMode,
  onResetSegment,
}) => {
  const playerRef = useRef<VideoPlayerRef>(null);

  const [state, setState] = useState<State>({ playing: true, url: liveUrl });
  const [statsModalOpen, setStatsModalOpen] = useState(false);

  useEffect(() => {
    // A bug occurs when simultaneously changing the url and pausing the video where the video won't play afterwards.
    // To work around this; when pausing the video, also set the url to `undefined` which will 'reset' the video state.
    // Then set the correct url through the `setTimeout`.

    setState({ playing: !segment, url: undefined });

    const url = segment
      ? `${segment.videoBaseUrl}/playlist.m3u8/${segment.videoAssetId}:${segment.fromTimestamp}:${segment.toTimestamp}/Manifest.m3u8`
      : liveUrl;
    setTimeout(() => setState((s) => ({ ...s, url })));
  }, [segment]);

  const { playing, url } = state;

  return (
    <Stack
      sx={{
        position: "absolute",
        top: 60,
        left: 1,
        right: 1,
        bottom: 0,
      }}
    >
      <Stack
        gap={2}
        sx={{
          width: "clamp(0px, 100%, calc(100vh * 16 / 9 - 280px))",
          marginInline: "auto",
        }}
      >
        <VideoPlayer
          clickToPlay
          controlFullscreenId
          ref={playerRef}
          playing={playing}
          url={url}
          onPlay={() => setState((s) => ({ ...s, playing: true }))}
          onPause={() => setState((s) => ({ ...s, playing: false }))}
        />
        <Stack direction="row" justifyContent="space-between" sx={{ px: 1 }}>
          <Stack direction="row">
            {REPLAY_BUTTONS.map(({ Icon, value }) => (
              <IconButton
                key={value}
                onClick={() =>
                  playerRef.current!.seekTo(
                    playerRef.current!.getCurrentMicros() - value,
                  )
                }
              >
                <Icon sx={{ fontSize: "3rem" }} />
              </IconButton>
            ))}
            <IconButton
              sx={{ marginLeft: "2rem" }}
              onClick={() => setStatsModalOpen(true)}
            >
              <TableChartIcon sx={{ fontSize: "3rem" }} />
            </IconButton>
            {statsModalOpen && (
              <SegmentsStatsModal
                segments={segments}
                onClose={() => setStatsModalOpen(false)}
              />
            )}
          </Stack>
          <Button
            size="large"
            sx={{
              width: 140,
              height: 50,
              color: "grey.A200",
              alignSelf: "center",
              borderColor: "grey.A200",
              ":hover": {
                bgcolor: "black",
                borderColor: "grey.A200",
              },
            }}
            variant="outlined"
            startIcon={
              <CircleIcon
                sx={{
                  width: 18,
                  height: 18,
                  color: segment ? "primary.light" : "grey.A200",
                }}
              />
            }
            onClick={() => onResetSegment()}
          >
            <Typography sx={{ color: "text.secondary", fontSize: "1.3rem" }}>
              LIVE
            </Typography>
          </Button>
          <Box sx={{ display: "flex", width: 192, justifyContent: "flex-end" }}>
            <IconButton
              title="Exit focus mode"
              onClick={() => onExitFocusMode()}
            >
              <ExitToAppIcon color="error" sx={{ fontSize: "3rem" }} />
            </IconButton>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

const REPLAY_BUTTONS = [
  {
    Icon: Replay30Icon,
    value: 30_000_000,
  },
  {
    Icon: Replay10Icon,
    value: 10_000_000,
  },
  {
    Icon: Replay5Icon,
    value: 5_000_000,
  },
];
