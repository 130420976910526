import { OrgDetailed } from "generated/openapi";

export interface OrgState {
  org?: OrgDetailed;
  orgLoading: boolean;
}

export const initialOrgState: OrgState = {
  org: undefined,
  orgLoading: false,
};
