import AddIcon from "@mui/icons-material/Add";
import CommentIcon from "@mui/icons-material/Comment";
import {
  Autocomplete,
  Box,
  Card,
  Chip,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import theme from "app/theme";
import { LoadingScreen, RichText } from "components";
import { formatDistanceToNow } from "date-fns";
import { UserRole } from "generated/openapi";
import { FC, useEffect } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import {
  getJournalEntries,
  selectJournalEntries,
  selectJournalEntriesLoading,
  selectOrgLoading,
  selectPlayer,
  selectPlayersSorted,
  selectUser,
  useAppDispatch,
  useAppSelector,
} from "store";
import { countComments } from "utils/utils";

export const Journal: FC = () => {
  const players = useAppSelector(selectPlayersSorted);
  const user = useAppSelector(selectUser)!;
  const loading = useAppSelector(selectOrgLoading);

  if (loading) return <LoadingScreen />;

  // This should only happen if they are not part of an org,
  // in which case they should not have access to this page.
  if (!players) return <Navigate to="/" />;

  const player = user.userRole === UserRole.Player ? user : players[0];

  return player ? <Navigate to={player.id} /> : <>No players</>;
};

export const PlayerJournal: FC = () => {
  const { playerId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);
  const isPlayer = user?.userRole === UserRole.Player;

  const player = isPlayer ? user : useAppSelector(selectPlayer(playerId!));
  const players = useAppSelector(selectPlayersSorted);
  const journals = useAppSelector(selectJournalEntries);
  const loading = useAppSelector(selectJournalEntriesLoading);

  useEffect(() => {
    if (!player) return;
    dispatch(getJournalEntries({ playerId: player.id }));
  }, [player?.id]);

  if (loading) return <LoadingScreen />;
  if (!player) return <Navigate to="/" />;

  return (
    <Box sx={{ width: "fit-content", marginInline: "auto" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        {isPlayer ? (
          <Typography variant="h3" fontWeight="normal">
            {user.firstName} {user.lastName}
          </Typography>
        ) : (
          <Autocomplete
            disableClearable
            options={players ?? []}
            sx={{
              width: 260,
              bgcolor: "primary.main",
              "& .MuiFormLabel-root": {
                color: `${theme.palette.text.secondary}`,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme.palette.primary.light}`,
              },
            }}
            value={player}
            renderInput={(params) => <TextField {...params} label="Player" />}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
            renderOption={(props, option) => (
              <Typography {...props} key={option.id}>
                {option.firstName} {option.lastName}
              </Typography>
            )}
            onChange={(_e, value) => navigate(`/journals/${value.id}`)}
          />
        )}
        <IconButton
          sx={{ bgcolor: "success.main", ml: 2 }}
          size="large"
          onClick={() => navigate("create")}
        >
          <AddIcon />
        </IconButton>
      </Stack>

      <Stack gap={2} sx={{ marginTop: 4 }}>
        {journals?.map((j) => (
          <Card
            key={j.id}
            variant="elevation"
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              bgcolor: "primary.main",
              borderRadius: 2,
              padding: 3,
              maxWidth: "46rem",
            }}
          >
            <Stack direction="row" gap={2}>
              <Typography>
                {j.creator
                  ? `${j.creator.firstName} ${j.creator.lastName}`
                  : "Deleted User"}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {formatDistanceToNow(new Date(j.created)) + " ago"}
              </Typography>
            </Stack>

            <RichText value={j.entry} />

            <Stack direction="row" spacing={1}>
              <Chip icon={<CommentIcon />} label={countComments(j.comments)} />
            </Stack>
            <Link
              to={`/journals/entry/${j.id}`}
              style={{ position: "absolute", inset: 0, textDecoration: "none" }}
            />
          </Card>
        ))}
      </Stack>
    </Box>
  );
};
