import { createSelector } from "@reduxjs/toolkit";
import { selectAppState } from "store/selectAppState";

export const selectForzaTeams = createSelector(
  selectAppState,
  (state) => state.forzaTeams,
);

export const selectForzaTeamsSorted = createSelector(
  selectForzaTeams,
  (state) => [...state].sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectForzaTeamsMap = createSelector(selectForzaTeams, (state) =>
  Object.fromEntries(state.map((s) => [s.id, s])),
);

export const selectForzaTeamsLoading = createSelector(
  selectAppState,
  (state) => state.forzaTeamsLoading,
);

export const selectForzaTeamPlayers = (teamId: number) =>
  createSelector(selectForzaTeamsMap, (state) => state[teamId]?.players);

export const selectForzaTeamPlayersSorted = (teamId: number) =>
  createSelector(selectForzaTeamPlayers(teamId), (state) =>
    [...state].sort((a, b) => a.name.localeCompare(b.name)),
  );
