import { Button, Stack, Typography } from "@mui/material";
import { RichText } from "components";
import {
  CreateJournalEntry as NewJournalEntry,
  UserRole,
} from "generated/openapi";
import { FC } from "react";
import { Form, useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  createJournalEntry,
  selectPlayer,
  selectUser,
  useAppDispatch,
  useAppSelector,
} from "store";

export const CreateJournalEntry: FC = () => {
  const { playerId } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const isPlayer = user?.userRole === UserRole.Player;

  const player = isPlayer ? user : useAppSelector(selectPlayer(playerId!));

  const { control, setValue } = useForm<NewJournalEntry>({ defaultValues: {} });

  if (!player) return <Navigate to="/journals" />;

  return (
    <Form
      control={control}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 12,
      }}
      onSubmit={({ data }) => {
        dispatch(createJournalEntry({ playerId: player.id, entry: data })).then(
          (x) =>
            // TODO: Handle error case
            navigate(`/journals/entry/${(x as any).payload.id}`),
        );
      }}
    >
      <Typography sx={{ pb: 2 }} variant="h3">
        Create Journal Entry
      </Typography>

      <RichText editable value="" onChange={(v) => setValue("entry", v)} />

      <Stack direction="row" gap={2} justifyContent="end">
        <Button
          autoFocus
          sx={{ color: "white", borderColor: "primary.light" }}
          variant="outlined"
          onClick={() => navigate(`/journals/${player.id}`)}
        >
          Cancel
        </Button>
        <Button color="success" variant="contained" type="submit">
          Submit
        </Button>
      </Stack>
    </Form>
  );
};
