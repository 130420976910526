import { Box } from "@mui/material";
import { MinimalPlaylist } from "generated/openapi";
import { FC, ReactElement, useMemo } from "react";
import { hms } from "utils/utils";
import { Thumbnail } from "./Thumbnail";

export interface Props {
  playlist: MinimalPlaylist;
  children?: false | ReactElement | ReactElement[];
  width?: number | string;
  height?: number | string;
}

export const PlaylistThumbnail: FC<Props> = ({
  playlist,
  children,
  width,
  height,
}) => {
  const duration = useMemo(() => hms(playlist.durationMs / 1_000), [playlist]);

  return (
    <Thumbnail
      url={playlist.thumbnailUrl}
      duration={duration}
      width={width}
      height={height}
    >
      <>
        {playlist && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: 0.75,
              backgroundColor: "#000000c0",
              borderBottomLeftRadius: 6,
            }}
          >
            {playlist.numSegments} videos
          </Box>
        )}
        {children}
      </>
    </Thumbnail>
  );
};
