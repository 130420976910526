import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Confirm, ConfirmModal } from "components";
import { Group, UserRole } from "generated/openapi";
import { FC, useEffect, useState } from "react";
import {
  deleteGroup,
  selectAccessToken,
  selectOrg,
  selectTeam,
  selectUser,
  updateGroup,
  useAppDispatch,
  useAppSelector,
} from "store";
import { AddUsersToGroupModal } from "./AddUsersToGroupModal";
import { CreateGroup } from "./CreateGroup";
import { EditGroupModal } from "./EditGroupModal";

interface Props {
  teamId: number;
  groups: Group[];
}

export const Groups: FC<Props> = (props) => {
  const { teamId, groups } = props;
  const dispatch = useAppDispatch();
  const org = useAppSelector(selectOrg)!;
  const team = useAppSelector(selectTeam(teamId));
  const user = useAppSelector(selectUser)!;
  const token = useAppSelector(selectAccessToken);

  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(
    undefined,
  );
  const [createGroup, setCreateGroup] = useState(false);
  const [addUsers, setAddUsers] = useState(false);
  const [editGroupName, setEditGroupName] = useState(false);
  const [confirm, setConfirm] = useState<Confirm | undefined>(undefined);

  const isPlayer = user.userRole === UserRole.Player;

  const handleSelectGroup = (group: Group) => {
    if (group.id === selectedGroup?.id) {
      setSelectedGroup(undefined);
    } else {
      setSelectedGroup(group);
    }
  };

  // Refresh selected group on edit
  useEffect(() => {
    const group = groups.find((g) => g.id === selectedGroup?.id);
    if (group) {
      setSelectedGroup(group);
    }
  }, [groups]);

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Groups</Typography>
      {!isPlayer && (
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            color="third"
            disabled={org.teams.length >= org.maxTeams}
            sx={{ color: "text.secondary" }}
            startIcon={<AddIcon sx={{ color: "third.main" }} />}
            onClick={() => setCreateGroup(true)}
          >
            Create group
          </Button>
          {selectedGroup && (
            <>
              <Button
                variant="outlined"
                color="third"
                disabled={org.teams.length >= org.maxTeams}
                sx={{ color: "text.secondary" }}
                startIcon={<GroupAddIcon sx={{ color: "third.main" }} />}
                onClick={() => setAddUsers(true)}
              >
                Add player to group
              </Button>
              <Button
                variant="outlined"
                color="third"
                disabled={org.teams.length >= org.maxTeams}
                sx={{ color: "text.secondary" }}
                startIcon={<EditIcon sx={{ color: "third.main" }} />}
                onClick={() => setEditGroupName(true)}
              >
                Edit name
              </Button>
              <Button
                variant="outlined"
                color="third"
                disabled={org.teams.length >= org.maxTeams}
                sx={{ color: "text.secondary" }}
                startIcon={<DeleteIcon sx={{ color: "third.main" }} />}
                onClick={() =>
                  setConfirm({
                    message: `Are you sure you want to delete the group '${selectedGroup.name}'`,
                    onConfirm: () => {
                      dispatch(
                        deleteGroup({ teamId, groupId: selectedGroup.id }),
                      );
                    },
                  })
                }
              >
                Delete group
              </Button>
              {addUsers && (
                <AddUsersToGroupModal
                  teamId={teamId}
                  group={selectedGroup}
                  onClose={() => setAddUsers(false)}
                />
              )}
              {confirm && (
                <ConfirmModal
                  {...confirm}
                  open
                  onClose={() => setConfirm(undefined)}
                />
              )}
              {editGroupName && (
                <EditGroupModal
                  group={selectedGroup}
                  teamId={team!.id}
                  onClose={() => setEditGroupName(false)}
                />
              )}
            </>
          )}
        </Stack>
      )}
      {groups.length ? (
        <Stack direction="row" sx={{ border: "3px solid #2f2f2f" }}>
          <List
            sx={{
              width: 500,
              borderRight: "3px solid #2f2f2f",
            }}
            disablePadding
          >
            {groups.map((g, i) => (
              <ListItemButton
                sx={{
                  "&.Mui-selected": {
                    bgcolor: "primary.main",
                    ":hover": { bgcolor: "primary.main" },
                  },
                  ":hover": { bgcolor: "primary.light" },
                }}
                key={i}
                onClick={() => handleSelectGroup(g)}
                selected={selectedGroup?.id === g.id}
              >
                <ListItemText
                  primary={g.name}
                  secondary={`${g.users.length} members`}
                />
                <KeyboardArrowRightIcon />
              </ListItemButton>
            ))}
          </List>
          <Box sx={{ width: "100%" }}>
            {selectedGroup && (
              <>
                {selectedGroup.users.length === 0 ? (
                  <Typography
                    sx={{ p: 2 }}
                    fontStyle="italic"
                    color="text.secondary"
                  >
                    No members in group
                  </Typography>
                ) : (
                  <List>
                    {selectedGroup.users.map((u, j) => (
                      <ListItem
                        sx={{
                          borderBottom: "1px solid #2f2f2f",
                          ":last-of-type": { borderBottom: 0 },
                        }}
                        key={j}
                        secondaryAction={
                          isPlayer ? undefined : (
                            <IconButton
                              color="error"
                              onClick={() => {
                                setConfirm({
                                  message: `Are you sure you want to remove '${u.firstName} ${u.lastName}' from the group '${selectedGroup.name}'?`,
                                  onConfirm: () => {
                                    dispatch(
                                      updateGroup({
                                        teamId,
                                        group: {
                                          ...selectedGroup,
                                          users: selectedGroup.users.filter(
                                            (v) => v.id !== u.id,
                                          ),
                                        },
                                      }),
                                    );
                                  },
                                });
                              }}
                            >
                              <GroupRemoveIcon />
                            </IconButton>
                          )
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{ width: 26, height: 26 }}
                            src={`${process.env.REACT_APP_API_URL}/v1.0/users/${u.id}/profile_image?authorization=${token}`}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          {u.firstName + " " + u.lastName}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                )}
              </>
            )}
          </Box>
        </Stack>
      ) : (
        <Typography fontStyle="italic"> No groups </Typography>
      )}
      {createGroup && (
        <CreateGroup teamId={team!.id} onClose={() => setCreateGroup(false)} />
      )}
    </Stack>
  );
};
