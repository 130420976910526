import BorderColorIcon from "@mui/icons-material/BorderColor";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import Crop75SharpIcon from "@mui/icons-material/Crop75Sharp";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import HexagonOutlinedIcon from "@mui/icons-material/HexagonOutlined";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import PolylineRoundedIcon from "@mui/icons-material/PolylineRounded";
import TitleSharpIcon from "@mui/icons-material/TitleSharp";
import {
  Box,
  Button,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { ColorPicker, DrawMode, KonvaEditor, VideoPlayer } from "components";
import { Shape, ShapeDiscriminants } from "generated/openapi";
import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { OnProgressProps } from "react-player/base";
import screenfull from "screenfull";
import { VideoPlayerRef } from "./VideoPlayer";

export interface Props {
  url?: string;
  playing?: boolean;
  width?: number | string;
  pausedTs?: number;
  drawings?: Record<string, Shape[]>;
  augmentControls?: ReactNode;
  disableDrawing?: boolean;
  onChange?: (_: Record<string, Shape[]>) => void;
  onEnded?: () => void;
  onPlay?: () => void;
  onSeek?: (secs: number) => void;
  onProgress?: (_: OnProgressProps) => void;
  onPause?: () => void;
}

export const KonvaVideoPlayer = forwardRef(
  (
    {
      url,
      playing,
      width,
      pausedTs,
      drawings,
      augmentControls,
      disableDrawing,
      onChange,
      onPlay,
      onEnded,
      onSeek,
      onProgress,
      onPause,
    }: Props,
    ref,
  ) => {
    width = width ?? "100%";

    const [playerRef, setPlayerRef] = useState<VideoPlayerRef | null>(null);

    useImperativeHandle(ref, () => playerRef, [playerRef]);

    const [started, setStarted] = useState<boolean>(false);
    const [fill, setFill] = useState({ text: "#ffff00", other: "#ffff0020" });
    const [stroke, setStroke] = useState("#ffff00");
    const [strokeWidth, setStrokeWidth] = useState({
      text: 40,
      arrow: 15,
      other: 2,
    });
    const [drawMode, setDrawMode] = useState<DrawMode>(
      onChange ? "Select" : undefined,
    );
    const [selectedShape, setSelectedShape] = useState<Shape | undefined>(
      undefined,
    );
    const [canvasDimensions, setCanvasDimensions] = useState({
      top: "0px",
      width: 0,
      height: 0,
    });
    const [triggerResize, setTriggerResize] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

    // Trigger component re-render on fullscreen change
    useEffect(() => {
      const detectFullscreenChange = () => {
        setIsFullscreen(screenfull.isFullscreen);
      };

      screenfull.on?.("change", detectFullscreenChange);
      return () => {
        screenfull.off?.("change", detectFullscreenChange);
      };
    });

    // Event listener for setting canvas dimensions on screen resize
    useEffect(() => {
      const handleWindowSizeChange = () => setTriggerResize((v) => v + 1);
      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }, []);

    // Set canvas dimensions to same as parent. Triggered when screen resizes
    useEffect(() => {
      if (playerRef?.width && playerRef?.height) {
        setCanvasDimensions({
          top: playerRef.marginTop,
          width: playerRef.width,
          height: playerRef.height,
        });
      }
    }, [triggerResize, pausedTs, playerRef?.marginTop, isFullscreen]);

    const handleChange = (_shapes: Shape[]) => {
      const shapes = _shapes.map((shape) => {
        switch (shape.type) {
          case ShapeDiscriminants.Rect:
            const rect = shape.value;
            return {
              ...shape,
              value: {
                ...rect,
                x: rect.x / canvasDimensions.width,
                y: rect.y / canvasDimensions.height,
                width: rect.width / canvasDimensions.width,
                height: rect.height / canvasDimensions.height,
              },
            };
          case ShapeDiscriminants.Polygon:
            const poly = shape.value;
            return {
              ...shape,
              value: {
                ...poly,
                points: poly.points.map((p, i) =>
                  i % 2 === 0
                    ? p / canvasDimensions.width
                    : p / canvasDimensions.height,
                ),
              },
            };
          case ShapeDiscriminants.Ellipse:
            const ell = shape.value;
            return {
              ...shape,
              value: {
                ...ell,
                x: ell.x / canvasDimensions.width,
                y: ell.y / canvasDimensions.height,
                radiusX: ell.radiusX / canvasDimensions.width,
                radiusY: ell.radiusY / canvasDimensions.height,
                offsetX: ell.offsetX / canvasDimensions.width,
                offsetY: ell.offsetY / canvasDimensions.height,
              },
            };
          case ShapeDiscriminants.ConnectedEllipses:
            const ce = shape.value;
            return {
              ...shape,
              value: {
                ...ce,
                ellipses: ce.ellipses.map((e) => ({
                  ...e,
                  x: e.x / canvasDimensions.width,
                  y: e.y / canvasDimensions.height,
                  radiusX: e.radiusX / canvasDimensions.width,
                  radiusY: e.radiusY / canvasDimensions.height,
                  offsetX: e.offsetX / canvasDimensions.width,
                  offsetY: e.offsetY / canvasDimensions.height,
                })),
              },
            };
          case ShapeDiscriminants.Arrow:
            const arr = shape.value;
            return {
              ...shape,
              value: {
                ...arr,
                x1: arr.x1 / canvasDimensions.width,
                x2: arr.x2 / canvasDimensions.width,
                y1: arr.y1 / canvasDimensions.height,
                y2: arr.y2 / canvasDimensions.height,
              },
            };
          case ShapeDiscriminants.Text:
            const text = shape.value;
            return {
              ...shape,
              value: {
                ...text,
                x: text.x / canvasDimensions.width,
                y: text.y / canvasDimensions.height,
                width: text.width / canvasDimensions.width,
                height: text.height / canvasDimensions.height,
              },
            };
          default:
            return shape;
        }
      });
      const res = { ...drawings };
      if (!shapes.length) {
        // eslint-disable-next-line
        delete res[pausedTs!];
      } else {
        res[pausedTs!] = shapes;
      }

      onChange?.(res);
    };

    const mode = selectedShape?.type ?? drawMode;

    const fillKey: keyof typeof fill =
      mode === ShapeDiscriminants.Text ? "text" : "other";
    const strokeWidthKey: keyof typeof strokeWidth =
      mode === ShapeDiscriminants.Text
        ? "text"
        : mode === ShapeDiscriminants.Arrow
          ? "arrow"
          : "other";

    return (
      <>
        <VideoPlayer
          ref={(v) => setPlayerRef(v as VideoPlayerRef | null)}
          url={url}
          width={width}
          playing={playing && started}
          progressInterval={(drawings ?? onProgress) ? 100 : undefined}
          augmentControls={augmentControls}
          onProgress={onProgress}
          onSeek={onSeek}
          onPlay={onPlay}
          onPause={onPause}
          onStart={() => setStarted(true)}
          onEnded={onEnded}
        >
          {started &&
            drawings &&
            pausedTs !== undefined &&
            disableDrawing !== true && (
              <KonvaEditor
                shapes={drawings[pausedTs] ?? []}
                fill={fill[fillKey]}
                stroke={stroke}
                strokeWidth={strokeWidth[strokeWidthKey]}
                drawMode={drawMode}
                style={{
                  position: "absolute",
                  top: canvasDimensions.top,
                  left: 0,
                }}
                width={canvasDimensions.width}
                height={canvasDimensions.height}
                onSelect={(shape: any) => {
                  setSelectedShape(shape);
                  if (!shape) return;

                  const mode = shape?.type ?? drawMode;

                  if (shape.fill) {
                    const key: keyof typeof fill =
                      mode === ShapeDiscriminants.Text ? "text" : "other";
                    setFill({ ...fill, [key]: shape.fill });
                  }
                  if ((shape.strokeWidth ?? shape.fontSize) !== undefined) {
                    const key: keyof typeof strokeWidth =
                      mode === ShapeDiscriminants.Text
                        ? "text"
                        : mode === ShapeDiscriminants.Arrow
                          ? "arrow"
                          : "other";
                    setStrokeWidth({
                      ...strokeWidth,
                      [key]: shape.strokeWidth ?? shape.fontSize,
                    });
                  }

                  shape.stroke && setStroke(shape.stroke as string);
                }}
                onChange={isFullscreen ? undefined : handleChange}
                onChangeDrawMode={() => setDrawMode("Select")}
              />
            )}
        </VideoPlayer>
        {onChange &&
          started &&
          pausedTs !== undefined &&
          disableDrawing !== true && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <ToggleButtonGroup
                  sx={{
                    border: 0,
                    "& .MuiToggleButtonGroup-grouped": {
                      border: 0,
                      width: 46,
                      height: 46,
                    },
                    "& .MuiToggleButtonGroup-firstButton, & .MuiToggleButtonGroup-lastButton":
                      { borderRadius: 0 },
                  }}
                  value={drawMode}
                  exclusive
                  onChange={(_event, value: DrawMode) => setDrawMode(value)}
                >
                  <ToggleButton value={"Select"}>
                    <PanToolAltIcon />
                  </ToggleButton>
                  <ToggleButton value={ShapeDiscriminants.Rect}>
                    <Crop75SharpIcon />
                  </ToggleButton>
                  <ToggleButton value={ShapeDiscriminants.Ellipse}>
                    <CircleOutlinedIcon />
                  </ToggleButton>
                  <ToggleButton value={ShapeDiscriminants.Polygon}>
                    <HexagonOutlinedIcon />
                  </ToggleButton>
                  <ToggleButton value={ShapeDiscriminants.ConnectedEllipses}>
                    <PolylineRoundedIcon />
                  </ToggleButton>
                  <ToggleButton value={ShapeDiscriminants.Arrow}>
                    <CallMadeOutlinedIcon />
                  </ToggleButton>
                  <ToggleButton value={ShapeDiscriminants.Text}>
                    <TitleSharpIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Box
                  sx={{
                    display: "flex",
                    "& .MuiOutlinedInput-input": { height: "14px" },
                    "& .MuiButtonBase-root": {
                      borderRadius: 0,
                      width: 46,
                      height: 46,
                    },
                  }}
                >
                  <ColorPicker
                    value={stroke}
                    icon={<BorderColorIcon sx={{ color: stroke }} />}
                    onChange={setStroke}
                  />
                  {mode !== ShapeDiscriminants.Text &&
                    mode !== ShapeDiscriminants.Arrow && (
                      <ColorPicker
                        value={fill[fillKey]}
                        icon={
                          <FormatColorFillIcon sx={{ color: fill[fillKey] }} />
                        }
                        onChange={(v) => setFill({ ...fill, [fillKey]: v })}
                      />
                    )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: 150,
                    mx: 3,
                  }}
                >
                  <Slider
                    sx={{
                      marginBlock: "auto",
                      "& .MuiSlider-valueLabel": {
                        bgcolor: "primary.main",
                      },
                      "& .MuiSlider-thumb": {
                        height: 15,
                        width: 15,
                      },
                    }}
                    color="secondary"
                    step={1}
                    min={mode === ShapeDiscriminants.Text ? 12 : 0}
                    max={mode === ShapeDiscriminants.Text ? 60 : 20}
                    value={strokeWidth[strokeWidthKey]}
                    valueLabelFormat={(v) => v + " pt"}
                    valueLabelDisplay="auto"
                    onChange={(_e, v) =>
                      setStrokeWidth({ ...strokeWidth, [strokeWidthKey]: v })
                    }
                  />
                </Box>
              </Box>
              <Button
                sx={{ borderRadius: 0 }}
                color="inherit"
                onClick={() => handleChange([])}
                startIcon={<ClearIcon sx={{ color: "error.main" }} />}
              >
                Clear
              </Button>
            </Box>
          )}
      </>
    );
  },
);
