import { Button, CircularProgress, useMediaQuery } from "@mui/material";
import theme from "app/theme";
import { FC } from "react";

interface Props {
  onHomographyClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export const AugmentControls: FC<Props> = (props) => {
  const { onHomographyClick, disabled, loading } = props;
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up(600));

  return (
    <>
      <Button
        disabled={disabled}
        disableTouchRipple
        title="2D Field Map"
        sx={{
          minWidth: 0,
          width: mobileBreakpoint ? 40 : 30,
          height: mobileBreakpoint ? 40 : 30,
          borderRadius: "50%",
          ":hover": {
            bgcolor: "rgba(255, 255, 255, 0.04)",
          },
        }}
        onClick={() => {
          onHomographyClick();
        }}
      >
        {loading === true ? (
          <CircularProgress size={18} color="inherit" />
        ) : (
          "2D"
        )}
      </Button>
    </>
  );
};
