import { createSelector } from "@reduxjs/toolkit";
import { selectAppState } from "store/selectAppState";

export const selectLiveStreams = createSelector(
  selectAppState,
  (state) => state.liveStreams,
);

export const selectLiveStreamsSorted = createSelector(
  selectLiveStreams,
  (state) => (state ? [...state].sort((a, b) => b.id - a.id) : undefined),
);

export const selectLiveStreamsLoading = createSelector(
  selectAppState,
  (state) => state.liveStreamsLoading,
);
