import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";

export const getJournalEntry = createAsyncThunk(
  "journal/getJournalEntry",
  Api.getJournalEntry,
);

export const getJournalEntries = createAsyncThunk(
  "journal/getJournalEntries",
  Api.getJournalEntries,
);

export const createJournalEntry = createAsyncThunk(
  "journal/createJournalEntry",
  Api.createJournalEntry,
);

export const createJournalEntryComment = createAsyncThunk(
  "journal/createJournalEntryComment",
  Api.createJournalEntryComment,
);

export const updateJournalEntry = createAsyncThunk(
  "journal/updateJournalEntry",
  Api.updateJournalEntry,
);

export const updateJournalEntryComment = createAsyncThunk(
  "journal/updateJournalEntryComment",
  Api.updateJournalEntryComment,
);

export const deleteJournalEntry = createAsyncThunk(
  "journal/deleteJournalEntry",
  Api.deleteJournalEntry,
);

export const deleteJournalEntryComment = createAsyncThunk(
  "journal/deleteJournalEntryComment",
  Api.deleteJournalEntryComment,
);
