import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "app/theme";
import {
  FilterHeader,
  ForzaTeamsFilter,
  GameThumbnail,
  YearFilter,
} from "components";
import { endOfYear, startOfYear } from "date-fns";
import { V1forzaApiForzaGamesRequest } from "generated/openapi";
import { FC, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  getForzaGames,
  selectForzaGames,
  selectForzaGamesFilter,
  selectForzaGamesLoading,
  selectForzaTeamsSorted,
  setForzaGamesFilters,
  useAppDispatch,
  useAppSelector,
} from "store";

export const ForzaGames: FC = () => {
  const dispatch = useAppDispatch();

  const teams = useAppSelector(selectForzaTeamsSorted);

  const filters = useAppSelector(selectForzaGamesFilter);
  const loading = useAppSelector(selectForzaGamesLoading);
  const games = useAppSelector(selectForzaGames);

  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up(600));

  useEffect(() => {
    dispatch(getForzaGames({ ...filters }));
  }, []);

  const [teams1, teams2] = useMemo(() => {
    const teams1 = teams.filter((t) => t.id !== filters.teamId2);
    const teams2 = teams.filter((t) => t.id !== filters.teamId1);
    return [teams1, teams2];
  }, [teams, filters.teamId1, filters.teamId2]);

  const years = useMemo(() => {
    const start = 2016;
    const now = new Date().getFullYear();
    return Array.from({ length: now - start + 1 }).map((_, i) => now - i);
  }, []);

  const onChange = (change: V1forzaApiForzaGamesRequest) => {
    dispatch(setForzaGamesFilters({ ...filters, ...change }));
  };

  if (!loading && games === undefined) {
    // TODO: Handle error!
    return <>Error!</>;
  }

  return (
    <>
      {!games ? (
        <Box sx={{ mt: "2rem", marginInline: "auto", width: "fit-content" }}>
          <CircularProgress color="info" />
        </Box>
      ) : (
        <>
          <FilterHeader>
            <Box
              sx={{
                display: "flex",
                flexDirection: mobileBreakpoint ? "row" : "column",
                alignItems: "center",
                justifyContent: "center",
                "& > *": mobileBreakpoint
                  ? {}
                  : {
                      width: "100% !important",
                    },
              }}
            >
              <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
                <ForzaTeamsFilter
                  value={filters.teamId1}
                  teams={teams1}
                  onChange={(team) => onChange({ teamId1: team?.id })}
                />
              </Box>
              {filters.teamId1 && (
                <>
                  <Typography sx={{ px: 1 }}>vs.</Typography>
                  <Box sx={{ p: 1, minWidth: 120, width: 180 }}>
                    <ForzaTeamsFilter
                      value={filters.teamId2}
                      teams={teams2}
                      onChange={(team) => onChange({ teamId2: team?.id })}
                    />
                  </Box>
                </>
              )}
              <Box sx={{ p: 1, minWidth: 100, width: 100 }}>
                <YearFilter
                  value={
                    filters.startDate
                      ? new Date(filters.startDate).getFullYear()
                      : undefined
                  }
                  years={years}
                  onChange={(year) => {
                    const date = year ? new Date(year, 0) : undefined;
                    onChange(
                      date
                        ? {
                            startDate: startOfYear(date).toISOString(),
                            stopDate: endOfYear(date).toISOString(),
                          }
                        : { startDate: undefined, stopDate: undefined },
                    );
                  }}
                />
              </Box>
            </Box>
          </FilterHeader>

          <Card
            variant="elevation"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: "primary.main",
              color: "white",
              borderRadius: 2,
              p: 2,
              px: mobileBreakpoint ? 8 : 1,
              mt: 2,
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    py: 1,
                  }}
                >
                  <Typography sx={{ textAlign: "center" }} variant="h4">
                    Games [{games.total}]
                  </Typography>
                </Box>
              </Grid>
              {games.values.map((g, i) => (
                <Grid item xs={12} md={6} lg={4} xl={3} xxl={3} key={i}>
                  <Box
                    sx={{
                      width: "100%",
                      position: "relative",
                      paddingTop: "56.25%",
                    }}
                  >
                    <Box sx={{ position: "absolute", inset: 0 }}>
                      <Link key={g.id} to={`/forza/games/${g.id}`}>
                        <GameThumbnail game={g} width="100%" height="100%" />
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            {filters.limit && (
              <Pagination
                sx={{ mt: 2 }}
                color="secondary"
                size={mobileBreakpoint ? "medium" : "small"}
                page={(filters.offset ?? 0) / filters.limit + 1}
                count={Math.ceil(games.total / filters.limit)}
                onChange={(_event, page: number) =>
                  onChange({ ...filters, offset: (page - 1) * filters.limit! })
                }
              />
            )}
          </Card>
        </>
      )}
    </>
  );
};
