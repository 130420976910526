import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";
import { V1forzaApiForzaGamesRequest } from "generated/openapi";

export const getForzaGame = createAsyncThunk(
  "forzaGame/getForzaGame",
  Api.getForzaGame,
);

export const getForzaGames = createAsyncThunk(
  "forzaGame/getForzaGames",
  Api.getForzaGames,
);

export const setForzaGameKickoff = createAsyncThunk(
  "forzaGame/setForzaGameKickoff",
  Api.setForzaGameKickoff,
);

export const setForzaGamesFilters = createAction<V1forzaApiForzaGamesRequest>(
  "forzaGame/setForzaGamesFilters",
);
