import { Chip, Stack, Typography } from "@mui/material";
import theme from "app/theme";
import { MinimalUser } from "generated/openapi";
import { FC } from "react";

interface Props {
  value: MinimalUser[];
  options: MinimalUser[];
  onChange: (_: MinimalUser[]) => void;
}

export const CreatorFilter: FC<Props> = ({ value, options, onChange }) => {
  const handleToggle = (user: MinimalUser) => {
    if (value.includes(user)) {
      onChange(value.filter((u) => u.id !== user.id));
    } else {
      onChange([...value, user]);
    }
  };

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">Creator</Typography>
      <Stack direction="row" gap={1} flexWrap="wrap">
        {options.map((user, i) => (
          <Chip
            key={i}
            sx={{
              color: value.includes(user) ? "white" : theme.palette.grey[300],
            }}
            label={user.firstName + " " + user.lastName}
            onClick={() => handleToggle(user)}
            color="third"
            variant={value.includes(user) ? "filled" : "outlined"}
          />
        ))}
      </Stack>
    </Stack>
  );
};
