import { apiConfiguration, apiGet } from "api";
import {
  V1forzaApi,
  V1forzaApiForzaGameRequest,
  V1forzaApiForzaGamesRequest,
  V1forzaApiSetForzaGameKickoffRequest,
} from "generated/openapi";

const api = new V1forzaApi(apiConfiguration);

export interface GetForzaGameArgs extends V1forzaApiForzaGameRequest {
  token?: string;
}

export interface GetForzaGamesArgs extends V1forzaApiForzaGamesRequest {
  token?: string;
}

export interface SetForzaGameKickoffArgs
  extends V1forzaApiSetForzaGameKickoffRequest {
  token?: string;
}

export const getForzaGame = async ({ token, ...query }: GetForzaGameArgs) =>
  apiGet(async () =>
    api.forzaGame(query, { headers: { Authorization: `Bearer ${token}` } }),
  );

export const getForzaGames = async ({ token, ...query }: GetForzaGamesArgs) =>
  apiGet(async () =>
    api.forzaGames(query, { headers: { Authorization: `Bearer ${token}` } }),
  );

export const setForzaGameKickoff = async ({
  token,
  ...query
}: SetForzaGameKickoffArgs) =>
  apiGet(async () =>
    api.setForzaGameKickoff(query, {
      headers: { Authorization: `Bearer ${token}` },
    }),
  );
