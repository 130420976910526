import { apiConfiguration, apiGet } from "api";
import { CreateOrg, OrgDetailed, V1orgApi } from "generated/openapi";

const api = new V1orgApi(apiConfiguration);

export interface CreateOrgArgs {
  org: CreateOrg;
  token?: string;
}

export interface UpdateOrgArgs {
  org: OrgDetailed;
  token?: string;
}

export interface KickFromOrgArgs {
  userId: string;
  token?: string;
}

export const getOrg = async ({
  token,
}: {
  token?: string;
}): Promise<OrgDetailed | null> =>
  apiGet(async () =>
    api.getOrg({ headers: { Authorization: `Bearer ${token}` } }),
  );

export const createOrg = async (query: CreateOrgArgs) =>
  apiGet(async () =>
    api.createOrg(
      { createOrg: query.org },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const updateOrg = async (query: UpdateOrgArgs) =>
  apiGet(async () =>
    api.updateOrg(
      { createOrg: { name: query.org.name } },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const deleteOrg = async ({ token }: { token?: string }) =>
  apiGet(async () =>
    api.deleteOrg({ headers: { Authorization: `Bearer ${token}` } }),
  );

export const leaveOrg = async ({ token }: { token?: string }) =>
  apiGet(async () =>
    api.leaveOrg({ headers: { Authorization: `Bearer ${token}` } }),
  );

export const kickFromOrg = async (query: KickFromOrgArgs) =>
  apiGet(async () =>
    api.kickUserFromOrg(
      { userId: query.userId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );
