import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";

export const getPlaylistSegmentTags = createAsyncThunk(
  "tag/getPlaylistSegmentTags",
  Api.getPlaylistSegmentTags,
);
export const createPlaylistSegmentTag = createAsyncThunk(
  "tag/createPlaylistSegmentTag",
  Api.createPlaylistSegmentTag,
);
export const updatePlaylistSegmentTag = createAsyncThunk(
  "tag/updatePlaylistSegmentTag",
  Api.updatePlaylistSegmentTag,
);
export const deletePlaylistSegmentTag = createAsyncThunk(
  "tag/deletePlaylistSegmentTag",
  Api.deletePlaylistSegmentTag,
);
