import SensorsIcon from "@mui/icons-material/Sensors";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { initialTacticalPitch } from "containers/Tactical/Tactical";
import { ForzaGameMinimal } from "generated/openapi";
import { FC, useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import {
  createLiveStream,
  getForzaGames,
  getLiveStreams,
  selectForzaGames,
  selectForzaTeamsMap,
  selectLiveStreamsSorted,
  selectSelectedTeam,
  useAppDispatch,
  useAppSelector,
} from "store";
import { dateFormat } from "utils";

export interface Props {
  live: boolean;
}

export const LiveStreams: FC<Props> = ({ live }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const team = useAppSelector(selectSelectedTeam);
  const teams = useAppSelector(selectForzaTeamsMap);
  const _games = useAppSelector(selectForzaGames);
  const liveStreams = useAppSelector(selectLiveStreamsSorted);

  useEffect(() => {
    dispatch(getLiveStreams({ live }));
    if (live) {
      dispatch(getForzaGames({ isLive: true }));
    }
  }, [live]);

  const games = useMemo(
    () =>
      _games?.values.filter(
        (v) => !liveStreams?.some((l) => v.id === l.game.id),
      ),
    [_games, liveStreams],
  );

  if (!games && !liveStreams) {
    return (
      <Stack alignItems="center">
        <CircularProgress color="secondary" size={80} />
      </Stack>
    );
  }

  const teamName = (id: number) => teams[id]?.name ?? "Unknown";

  return (
    <Grid container spacing={2}>
      {liveStreams?.map((v) => (
        <LiveStreamItem
          key={v.id}
          live={live}
          name={v.name}
          game={v.game}
          onClick={() => navigate(`/live_streams/${v.id}`)}
        />
      ))}
      {live &&
        games?.map((v) => (
          <LiveStreamItem
            key={v.id}
            live={live}
            name={`${teamName(v.homeTeamId)} vs. ${teamName(v.visitingTeamId)}`}
            game={v}
            onClick={() => {
              dispatch(
                createLiveStream({
                  liveStream: {
                    teamId: team!.id,
                    gameId: v.id,
                    name: `${teamName(v.homeTeamId)} vs. ${teamName(v.visitingTeamId)}`,
                    tacticalPitch: initialTacticalPitch,
                  },
                }),
              ).then((v) => navigate(`/live_streams/${(v as any).payload}`));
            }}
          />
        ))}
    </Grid>
  );
};

interface ItemProps {
  live: boolean;
  name: string;
  game: ForzaGameMinimal;
  onClick: () => void;
}

const LiveStreamItem: FC<ItemProps> = ({ live, name, game, onClick }) => {
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(false);

  return (
    <Grid item xs={12} md={6} lg={6} xl={4} xxl={3}>
      <Box
        sx={{
          position: "relative",
          cursor: "pointer",
          paddingTop: "56.25%",
        }}
        onClick={onClick}
      >
        <Box sx={{ position: "absolute", inset: 0 }}>
          <ReactPlayer
            playing={false}
            controls={false}
            width="100%"
            height="100%"
            light={showPlaceholder}
            config={{
              // Since this is just a thumbnail, prevent buffering the video.
              file: { hlsOptions: { maxBufferLength: 1, maxBufferSize: 1 } },
            }}
            url={`${game.videoAssets[0].videoBaseUrl}/live/event/${game.videoAssets[0].videoAssetId}/Manifest.m3u8`}
            onError={(_) => {
              setShowPlaceholder(true);
            }}
            // TODO: Uncomment this when we can test with real live games
            // to see if it looks better to show last frame instead of first
            // onReady={(v) => v.seekTo(1_000_000)}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            inset: "0px -1px -1px 0px",
            background:
              "linear-gradient(180deg, transparent, transparent 60%, rgba(10, 24, 35, 0.85) 85%)",
          }}
        />
        <Box sx={{ position: "absolute", bottom: 14, left: 14 }}>
          <Typography
            fontWeight="bold"
            sx={{ color: "white", fontSize: "1.2rem" }}
          >
            {name}
          </Typography>
          {!live && (
            <Typography>{dateFormat(game.date, "dd.MM.yyyy")}</Typography>
          )}
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{
            px: 0.5,
            position: "absolute",
            bottom: 14,
            right: 14,
            bgcolor: live ? "#B30F0F" : "primary.light",
            borderRadius: 1,
          }}
        >
          <SensorsIcon fontSize="small" sx={{ color: "white" }} />
          <Typography
            fontWeight="bold"
            sx={{
              color: "white",
              fontSize: "0.8rem",
              alignSelf: "center",
              lineHeight: 1.2,
            }}
          >
            {live ? "LIVE" : "ARCHIVED"}
          </Typography>
        </Stack>
      </Box>
    </Grid>
  );
};
