import { createSelector } from "@reduxjs/toolkit";
import { TagItem } from "models";
import { selectAppState } from "store/selectAppState";

export const selectSegmentTags = createSelector(
  selectAppState,
  (state) => state.segmentTags,
);

export const selectSegmentTagsSorted = createSelector(
  selectSegmentTags,
  (state) =>
    state ? [...state].sort((a, b) => a.name.localeCompare(b.name)) : undefined,
);

export const selectSegmentTagsLoading = createSelector(
  selectAppState,
  (state) => state.segmentTagsLoading || state.segmentTags === undefined,
);

export const selectSegmentTagsFlattened = createSelector(
  selectSegmentTags,
  (state) =>
    state
      ?.flatMap((t) => {
        const tags: TagItem[] = [{ ...t, displayName: t.name }];
        const subs: TagItem[] = t.subTags.map((s) => ({
          ...s,
          displayName: `${t.name} : ${s.name}`,
        }));
        return tags.concat(subs);
      })
      .sort((a, b) => a.displayName.localeCompare(b.displayName)),
);

export const selectSegmentTagsFlattenedMap = createSelector(
  selectSegmentTagsFlattened,
  (state) =>
    state ? Object.fromEntries(state.map((t) => [t.id, t])) : undefined,
);
