import { ForzaPlayer } from "generated/openapi";

export interface ForzaPlayersState {
  forzaPlayers: ForzaPlayer[];
  forzaPlayersLoading: boolean;
}

export const initialForzaPlayersState: ForzaPlayersState = {
  forzaPlayers: [],
  forzaPlayersLoading: false,
};
