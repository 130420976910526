import {
  Box,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PlaylistSegment } from "generated/openapi";
import { TagItem } from "models";
import { FC, useMemo, useState } from "react";
import { selectSegmentTagsFlattenedMap, useAppSelector } from "store";
import { SegmentsStatsFilter } from "./SegmentsStatsFilter";

interface Props {
  segments: PlaylistSegment[];
  selectAllTags?: boolean;
  onFilterChange?: (tagId: number) => void;
}

export const SegmentsStatsTable: FC<Props> = ({
  segments,
  selectAllTags,
  onFilterChange,
}) => {
  const allTags = useAppSelector(selectSegmentTagsFlattenedMap)!;
  const [selectedTags, setSelectedTags] = useState<TagItem[]>([]);

  const [usedSubTags, uniqueSubTags] = useMemo(() => {
    const res: TagItem[] = [];
    const unique: TagItem[] = [];

    new Set(segments.flatMap((s) => s.tags)).forEach((id) => {
      if (
        allTags[id].parentTagId &&
        selectedTags.some((v) => v.id === allTags[id].parentTagId)
      ) {
        const tag = allTags[id];
        res.push(tag);
        if (!unique.some((t) => t.name === tag.name)) {
          unique.push(tag);
        }
      }
    });

    return [res, unique];
  }, [segments, allTags, selectedTags]);

  const subTagCount = (mainTag: TagItem, name: string) => {
    const count = segments
      .flatMap((s) => s.tags)
      .reduce(
        (tot, id) =>
          allTags[id].name === name && mainTag.id === allTags[id].parentTagId
            ? tot + 1
            : tot,
        0,
      );
    return count === 0 ? undefined : count;
  };

  const totalTagCount = (mainTag: TagItem) =>
    segments
      .flatMap((s) => s.tags)
      .reduce(
        (tot, id) =>
          mainTag.id === id || mainTag.id === allTags[id].parentTagId
            ? tot + 1
            : tot,
        0,
      );

  return (
    <Stack spacing={1} sx={{ pt: 2 }}>
      <Typography variant="h6">Statistics</Typography>
      <TableContainer>
        <Table size="small" sx={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={
                  selectedTags.length
                    ? {}
                    : {
                        bgcolor: "transparent",
                        border: "1px solid transparent !important",
                      }
                }
              >
                <SegmentsStatsFilter
                  value={selectedTags}
                  segments={segments}
                  selectAll={selectAllTags}
                  onChange={setSelectedTags}
                />
              </StyledTableCell>
              {uniqueSubTags.map((st, i) => (
                <StyledTableCell align="center" key={i}>
                  {st.name}
                </StyledTableCell>
              ))}
              {selectedTags.length > 0 && (
                <StyledTableCell align="center">Total</StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedTags.map((t, i) => (
              <TableRow
                key={i}
                sx={{
                  "&:last-of-type td": {
                    borderBottom: "2px solid #414141",
                  },
                }}
              >
                <StyledTableCell>{t.displayName}</StyledTableCell>
                {uniqueSubTags.map((st, j) => (
                  <StyledTableCell key={j} align="center">
                    <Box
                      sx={{ cursor: onFilterChange ? "pointer" : undefined }}
                      onClick={() =>
                        onFilterChange?.(
                          usedSubTags.find(
                            (find) =>
                              find.name === st.name &&
                              find.parentTagId === t.id,
                          )!.id,
                        )
                      }
                    >
                      {subTagCount(t, st.name)}
                    </Box>
                  </StyledTableCell>
                ))}
                <StyledTableCell align="center">
                  <Box
                    sx={{ cursor: onFilterChange ? "pointer" : undefined }}
                    onClick={() => onFilterChange?.(t.id)}
                  >
                    {totalTagCount(t)}
                  </Box>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.primary.light}`,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "white",
    fontSize: "0.97rem",
    border: `1px solid ${theme.palette.primary.light}`,
    width: 150,
    "&:first-of-type": {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.secondary,
      backgroundColor: "#292929",
    },
  },
}));
