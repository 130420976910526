import { IconButton, Popover, PopoverOrigin } from "@mui/material";
import theme from "app/theme";
import { FC, ReactNode, useState } from "react";
import { RGBColor, SketchPicker } from "react-color";

export interface Props {
  value: string;
  icon: ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  onChange: (_: string) => void;
}

export const ColorPicker: FC<Props> = ({
  value,
  icon,
  anchorOrigin,
  transformOrigin,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        {icon}
      </IconButton>
      <Popover
        id="color-picker-popper"
        disableScrollLock
        sx={{
          zIndex: 999999,
          "& *": {
            color: `${theme.palette.text.primary} !important`,
          },
          "& .sketch-picker": {
            backgroundColor: `${theme.palette.primary.main} !important`,
          },
          "& input": {
            backgroundColor: `${theme.palette.primary.light} !important`,
          },
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={
          anchorOrigin ?? { vertical: "bottom", horizontal: "center" }
        }
        transformOrigin={
          transformOrigin ?? { vertical: "top", horizontal: "center" }
        }
        onClose={() => setAnchorEl(null)}
      >
        <SketchPicker
          color={value}
          onChange={({ rgb }) => onChange(rgbToHex(rgb))}
        />
      </Popover>
    </>
  );
};

const toHex = (v: number) => v.toString(16).padStart(2, "0");

export const rgbToHex = ({ r, g, b, a }: RGBColor): string =>
  `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(Math.round((a ?? 1) * 255))}`;
