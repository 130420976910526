import { apiConfiguration, apiGet } from "api";
import {
  ForzaEventType,
  ForzaGoalType,
  ForzaRedCardType,
  ForzaSetPiece,
  ForzaShotResult,
  ForzaShotType,
  V1forzaApi,
} from "generated/openapi";

const api = new V1forzaApi(apiConfiguration);

export interface ForzaEventsParameters {
  from?: string;
  limit?: number;
  offset?: number;
  oppositionTeamId?: number;
  playerId?: number;
  teamId?: number;
  to?: string;
  assistBy?: number;
  goalType?: ForzaGoalType;
  afterSetPiece?: ForzaSetPiece;
  onTarget?: boolean;
  shotType?: ForzaShotType;
  shotResult?: ForzaShotResult;
  playerAwarded?: number;
  redCardType?: ForzaRedCardType;
}

export interface ForzaEventArgs {
  eventType: ForzaEventType;
  params: ForzaEventsParameters;
}

export const getForzaEvents = async (query: ForzaEventArgs) =>
  apiGet(async () =>
    api.forzaEvents({
      forzaEventTypeId: query.eventType,
      from: query.params.from ?? undefined,
      to: query.params.to ?? undefined,
      limit: query.params.limit ?? undefined,
      offset: query.params.offset ?? undefined,
      oppositionTeamId: query.params.oppositionTeamId ?? undefined,
      playerId: query.params.playerId ?? undefined,
      teamId: query.params.teamId ?? undefined,
      goalType: query.params.goalType ?? undefined,
      afterSetPiece: query.params.afterSetPiece ?? undefined,
      onTarget: query.params.onTarget ?? undefined,
      shotType: query.params.shotType ?? undefined,
      shotResult: query.params.shotResult ?? undefined,
      playerAwarded: query.params.playerAwarded ?? undefined,
      redCardType: query.params.redCardType ?? undefined,
    }),
  );

export const getForzaEvent = async (eventId: number) =>
  apiGet(async () => api.forzaEvent({ forzaEventId: eventId }));
