import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ForzaGoalType } from "generated/openapi";
import { FC } from "react";

interface Props {
  value?: ForzaGoalType;
  onChange: (_?: ForzaGoalType) => void;
}

export const GoalTypeFilter: FC<Props> = ({ value, onChange }) => {
  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel>Goal type</InputLabel>
      <Select
        MenuProps={{ disableScrollLock: true }}
        label="Goal type"
        value={value ?? ""}
        onChange={(event) =>
          onChange(
            event.target.value === ""
              ? undefined
              : (event.target.value as ForzaGoalType),
          )
        }
      >
        <MenuItem value={""}>
          <em>All</em>
        </MenuItem>
        {Object.values(ForzaGoalType).map((t, i) => (
          <MenuItem key={i} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
