import GridOnIcon from "@mui/icons-material/GridOn";
import ImageIcon from "@mui/icons-material/Image";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import { Editor, FloatingMenu, useCurrentEditor } from "@tiptap/react";
import { ImageUpload } from "components";
import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { CreateTableModal } from "./Toolbar/CreateTableModal";
import { BLOCK_FORMAT_OPTIONS } from "./Toolbar/ToolbarBubbleMenu";

// Using this implementation of modulo to remove negative numbers.
const modulo = (n: number, m: number): number => ((n % m) + m) % m;

export const SuggestionMenu: FC = () => {
  const { editor } = useCurrentEditor();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [hover, setHover] = useState(0);
  const [tableModalOpen, setTableModalOpen] = useState(false);

  const options = useMemo(() => filterOptions(search), [search]);

  const open = options.length > 0;

  const onSelect = (idx?: number) => {
    if (editor) {
      const option = options[idx ?? hover];
      option.onSelect(editor);
      if (option.name === "Table") {
        setTableModalOpen(true);
      }
    }
    setSearch(undefined);
  };

  useEffect(() => setHover(0), [search]);

  useEffect(() => {
    if (!open) return;

    const fn = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        onSelect();
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setHover((h) => modulo(h + 1, options.length));
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setHover((h) => modulo(h - 1, options.length));
      }
    };

    window.addEventListener("keydown", fn);
    return () => {
      window.removeEventListener("keydown", fn);
    };
  }, [options, open, hover]);

  if (!editor) return <></>;

  return (
    <>
      <FloatingMenu
        editor={editor}
        pluginKey="suggestion-menu"
        shouldShow={({ editor, state }) => {
          if (!editor.isActive("paragraph")) return false;

          const $anchor = state.selection.$anchor;
          if ($anchor.depth !== 1) return false;

          const text = $anchor.parent.textContent;
          if (!text.startsWith("/")) return false;

          const search = text.substring(1);
          const opts = filterOptions(search);
          if (!opts.length) return false;

          setSearch(search);
          return true;
        }}
      >
        <MenuList
          sx={{
            bgcolor: "primary.main",
            padding: 1,
            borderRadius: 2,
            transform: "translateY(55%)",
          }}
        >
          {options.map((v, i, arr) => [
            v.group !== arr[i - 1]?.group && (
              <Divider
                textAlign="left"
                sx={{
                  "&:before": { borderColor: "primary.light" },
                  "&:after": { borderColor: "primary.light" },
                }}
              >
                {v.group}
              </Divider>
            ),
            <MenuItem
              key={i}
              onClick={() => onSelect(i)}
              sx={{ bgcolor: i === hover ? "primary.dark" : "primary.main" }}
            >
              <ListItemIcon>{v.Icon}</ListItemIcon>
              <ListItemText>{v.name}</ListItemText>
            </MenuItem>,
          ])}
        </MenuList>
      </FloatingMenu>
      <ImageUpload
        accept="image/*"
        onChange={(v) =>
          editor.commands.insertContent(`<image-ext src="${v}" />`)
        }
      />
      {tableModalOpen && (
        <CreateTableModal onClose={() => setTableModalOpen(false)} />
      )}
    </>
  );
};

interface Option {
  group: string;
  name: string;
  Icon: ReactElement;
  onSelect: (_: Editor) => void;
}

export const filterOptions = (search?: string) =>
  search === undefined
    ? []
    : OPTIONS.filter((v) => v.name.toLowerCase().includes(search));

const clearCurrentNode = (editor: Editor) => {
  const selection = editor.view.state.selection;
  const to = selection.to;
  const from = to - selection.$head.parentOffset;
  editor.commands.deleteRange({ from, to });
};

const OPTIONS: Option[] = BLOCK_FORMAT_OPTIONS.map((v) => ({
  group: "Format",
  name: v.name,
  Icon: v.Icon,
  onSelect: (editor: Editor) => {
    clearCurrentNode(editor);
    v.onSelect(editor);
  },
})).concat([
  {
    group: "Insert",
    name: "Image",
    Icon: <ImageIcon />,
    onSelect: (editor: Editor) => {
      clearCurrentNode(editor);
      document.getElementById("image-upload")?.click();
    },
  },
  {
    group: "Insert",
    name: "Table",
    Icon: <GridOnIcon />,
    onSelect: (editor: Editor) => clearCurrentNode(editor),
  },
]);
