import { createSelector } from "@reduxjs/toolkit";
import { selectAppState } from "store/selectAppState";

export const selectForzaGame = createSelector(
  selectAppState,
  (state) => state.forzaGame,
);

export const selectForzaGames = createSelector(
  selectAppState,
  (state) => state.forzaGames,
);

export const selectForzaGamesFilter = createSelector(
  selectAppState,
  (state) => state.forzaGamesFilter,
);

export const selectForzaGamesLoading = createSelector(
  selectAppState,
  (state) => state.forzaGamesLoading,
);
