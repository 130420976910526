import { Button, Typography } from "@mui/material";
import { Modal, PlayersInput } from "components";
import { Group } from "generated/openapi";
import { FC, useMemo, useState } from "react";
import {
  selectTeamPlayers,
  selectTeamPlayersMap,
  updateGroup,
  useAppDispatch,
  useAppSelector,
} from "store";

export interface Props {
  teamId: number;
  group: Group;
  onClose: () => void;
}

export const AddUsersToGroupModal: FC<Props> = ({ teamId, group, onClose }) => {
  const dispatch = useAppDispatch();

  const _players = useAppSelector(selectTeamPlayers(teamId))!;
  const playersMap = useAppSelector(selectTeamPlayersMap(teamId))!;

  const [users, setUsers] = useState<string[]>([]);

  const players = useMemo(
    () =>
      _players
        .filter((p) => !group.users?.some((u) => u.id === p.id))
        .map((p) => p.id),
    [_players, group],
  );

  const onSubmit = () => {
    dispatch(
      updateGroup({
        teamId,
        group: { ...group, users: users.map((v) => playersMap[v]) },
      }),
    );
    onClose();
  };

  return (
    <Modal open={true} onClose={onClose} sx={{ p: 3 }}>
      <Typography sx={{ pb: 2, width: 350 }} variant="h6">
        Add players to group
      </Typography>

      <PlayersInput
        value={users}
        options={players}
        onChange={setUsers}
        placeholder="Select players"
      />

      <Button color="third" onClick={onSubmit}>
        Submit
      </Button>
    </Modal>
  );
};
