import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ForzaSetPiece } from "generated/openapi";
import { FC } from "react";

interface Props {
  value?: ForzaSetPiece;
  onChange: (_?: ForzaSetPiece) => void;
}

export const AfterSetPieceFilter: FC<Props> = ({ value, onChange }) => {
  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel>After</InputLabel>
      <Select
        MenuProps={{ disableScrollLock: true }}
        label="After"
        value={value ?? ""}
        onChange={(event) =>
          onChange(
            event.target.value === ""
              ? undefined
              : (event.target.value as ForzaSetPiece),
          )
        }
      >
        <MenuItem value={""}>
          <em>All</em>
        </MenuItem>
        {Object.values(ForzaSetPiece).map((t, i) => (
          <MenuItem key={i} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
