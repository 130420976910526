import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppState } from "store/state";
import {
  createPlaylistTag,
  deletePlaylistTag,
  getPlaylistTags,
  updatePlaylistTag,
} from "./actions";

export const playlistTagBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(getPlaylistTags.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
      state.playlistTagsLoading = true;
    })
    .addCase(getPlaylistTags.fulfilled, (state, action) => {
      state.playlistTags = action.payload;
      state.playlistTagsLoading = false;
    })
    .addCase(getPlaylistTags.rejected, (state, action) => {
      console.warn(action);
      state.playlistTags = [];
      state.playlistTagsLoading = false;
    })
    .addCase(createPlaylistTag.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(createPlaylistTag.fulfilled, (state, action) => {
      (action as any).asyncDispatch(
        getPlaylistTags({ token: state.accessToken }),
      );
    })
    .addCase(updatePlaylistTag.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updatePlaylistTag.fulfilled, (state, action) => {
      const idx = state.playlistTags!.findIndex(
        (v) => v.id === action.meta.arg.tagId,
      );
      if (idx >= 0) {
        state.playlistTags![idx] = {
          id: action.meta.arg.tagId,
          name: action.meta.arg.tag.name,
        };
      }
    })
    .addCase(deletePlaylistTag.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(deletePlaylistTag.fulfilled, (state, action) => {
      const idx = state.playlistTags!.findIndex(
        (v) => v.id === action.meta.arg.tagId,
      );
      if (idx >= 0) {
        state.playlistTags!.splice(idx, 1);
      }
    });
