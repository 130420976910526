import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ForzaEventType } from "generated/openapi";
import { AppState } from "store/state";
import {
  getForzaEvent,
  getForzaEvents,
  setForzaEventsFilters,
  setSelectedForzaEventType,
} from "./actions";

export const forzaEventsBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(getForzaEvents.pending, (state, _) => {
      state.eventsLoading = true;
    })
    .addCase(getForzaEvents.fulfilled, (state, action) => {
      const eventType = action.meta.arg.eventType;

      state.eventsLoading = false;

      switch (eventType) {
        case ForzaEventType.Goal:
          state.goals = action.payload;
          break;
        case ForzaEventType.Penalty:
          state.penalties = action.payload;
          break;
        case ForzaEventType.RedCard:
          state.redCards = action.payload;
          break;
        case ForzaEventType.YellowCard:
          state.yellowCards = action.payload;
          break;
        case ForzaEventType.Shot:
          state.shotsOnGoal = action.payload;
          break;
        case ForzaEventType.Offside:
          state.offsides = action.payload;
          break;
        case ForzaEventType.Corner:
          state.corners = action.payload;
          break;
        case ForzaEventType.Freekick:
          state.freekicks = action.payload;
          break;
      }
    })
    .addCase(getForzaEvents.rejected, (state, _) => {
      state.eventsLoading = false;
      state.error = true;
    })
    .addCase(getForzaEvent.pending, (state, _) => {
      state.currentEvent = undefined;
      state.eventsLoading = true;
    })
    .addCase(getForzaEvent.fulfilled, (state, action) => {
      state.currentEvent = action.payload;
      state.eventsLoading = false;
    })
    .addCase(getForzaEvent.rejected, (state, _) => {
      state.eventsLoading = false;

      state.error = true;
    })
    .addCase(setForzaEventsFilters, (state, action) => {
      return {
        ...state,
        eventsFilters: action.payload,
      };
    })
    .addCase(setSelectedForzaEventType, (state, action) => {
      // Reset secondary filters
      return {
        ...state,
        selectedEventType: action.payload,
        eventsFilters: {
          ...state.eventsFilters,
          assistBy: undefined,
          goalType: undefined,
          afterSetPiece: undefined,
          onTarget: undefined,
          shotType: undefined,
          shotResult: undefined,
          playerAwarded: undefined,
          redCardType: undefined,
        },
      };
    });
