import { nbNO } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    third: Palette["primary"];
    fourth: Palette["primary"];
    fifth: Palette["primary"];
  }
  interface PaletteOptions {
    third: PaletteOptions["primary"];
    fourth: PaletteOptions["primary"];
    fifth: PaletteOptions["primary"];
  }
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    third: true;
    fourth: true;
    fifth: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    third: true;
    fourth: true;
    fifth: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    third: true;
    fourth: true;
    fifth: true;
  }
}

declare module "@mui/material/FormControl" {
  interface FormControlPropsColorOverrides {
    third: true;
    fourth: true;
    fifth: true;
  }
}

declare module "@mui/material/Radio" {
  interface RadioPropsColorOverrides {
    third: true;
    fourth: true;
    fifth: true;
  }
}

export const fontStyle: any = {
  fontFamily: "'Poppins', sans-serif",
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
};
// Create a theme instance.
const theme = createTheme(
  {
    typography: {
      ...fontStyle,
      h1: {
        fontWeight: fontStyle.fontWeightBold,
        fontSize: "3.25rem",
      },
      h2: {
        fontWeight: fontStyle.fontWeightSemiBold,
        fontSize: "1.6rem",
      },
      h3: {
        fontWeight: fontStyle.fontWeightSemiBold,
        fontSize: "2.25rem",
        lineHeight: 1.167,
      },
      h4: {
        fontWeight: fontStyle.fontWeightMedium,
        lineHeight: 1,
        fontSize: "1.5rem",
      },
      h5: {
        fontWeight: fontStyle.fontWeightSemiBold,
        fontSize: "1.125rem",
      },
      h6: {
        fontWeight: fontStyle.fontWeightSemiBold,
        fontSize: "1.45rem",
      },
    },
    palette: {
      primary: {
        main: "#2f2f2f",
        light: "#5B5B5B",
        dark: "#212121",
      },
      secondary: {
        main: "#4442D0",
        light: "#8886E1",
        dark: "#8886E1",
        contrastText: "#ffffff",
      },
      third: {
        main: "#147D76",
        light: "#5EB3AE",
        dark: "#226C67",
        contrastText: "#ffffff",
      },
      fourth: {
        main: "#123957",
        light: "#80b8e7",
        dark: "#233D52",
        contrastText: "#ffffff",
      },
      fifth: {
        main: "#f9a976",
        light: "#fcd4bb",
      },
      warning: {
        main: "#B68F59",
      },
      error: {
        main: "#893B3B",
        light: "#C95A5A",
      },
      success: {
        main: "#326A38",
      },
      background: {
        default: "#212121",
        paper: "#5B5B5B",
      },
      text: {
        primary: "#ffffff",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(255, 255, 255, 0.5)",
      },
      grey: {
        50: "#F5F7FA",
        100: "#F1F3F8",
        200: "#E7EAF1",
        300: "#E0E3EB",
        400: "#CFD4E0",
        500: "#CFD4E0",
        600: "#BCC1CF",
        700: "#9EA4B5",
        A100: "#80888C",
        A200: "#D96866", // Live stream red
        A400: "#2A5570", // Teams tag color
        A700: "#5B3E87", // Players tag color
      },
      action: {
        active: "#ffffff",
        hover: "#212121",
        disabled: "rgba(255, 255, 255, 0.3)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        selected: "rgba(255, 255, 255, 0.16)",
      },
      divider: "rgba(0, 0, 0, 0.12)",
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1920,
        xxl: 2560,
      },
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          outlined: {
            "&:focused": {
              borderColor: "#ffffff",
            },
            "&:before": {
              borderColor: "#ffffff",
            },
            "&:after": {
              borderColor: "#ffffff",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {},
          outlined: {
            textTransform: "unset",
            fontWeight: 500,
          },
          contained: {
            boxShadow: "none",
            textTransform: "unset",
            fontWeight: 600,
          },
          containedPrimary: {
            color: "white",
          },
          containedSizeLarge: {
            fontSize: "0.875rem",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            borderBottom: "none",
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          grouped: {
            width: "100%",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 0,
            backgroundColor: "#5B5B5B",
          },
        },
      },

      // MuiTextField: {
      //   styleOverrides: {
      //     root: {
      //       "--TextField-brandBorderColor": "rgba(128, 136, 140, 1)",
      //       "--TextField-brandBorderHoverColor": "#4F9D95",
      //       // "--TextField-brandBorderFocusedColor": "#0288d1",
      //       "& label.Mui-focused": {
      //         color: "var(--TextField-brandBorderFocusedColor)",
      //       },
      //     },
      //   },
      // },
      // MuiInput: {
      //   styleOverrides: {
      //     root: {
      //       "&:before": {
      //         borderBottom: "1px solid var(--TextField-brandBorderColor)",
      //       },
      //       "&:hover:not(.Mui-disabled, .Mui-error):before": {
      //         borderBottom: "1px solid var(--TextField-brandBorderHoverColor)",
      //       },
      //       "&.Mui-focused:after": {
      //         borderBottom:
      //           "1px solid var(--TextField-brandBorderFocusedColor)",
      //       },
      //     },
      //   },
      // },
    },
  },
  nbNO,
);

export default theme;
