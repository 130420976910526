import { styled } from "@mui/system";
import { Shape, ShapeDiscriminants, Text } from "generated/openapi";
import Konva from "konva";
import { FC, useEffect, useRef, useState } from "react";
import * as ReactKonva from "react-konva";
import { Html } from "react-konva-utils";
import { DrawMode } from "./KonvaEditor";

export interface Props {
  text: Text;
  selected: boolean;
  interactive: boolean;
  drawMode?: DrawMode;
  onSelect: () => void;
  onChange: (_: Shape) => void;
  onFinished?: () => void;
}

export const TextComponent: FC<Props> = ({
  text,
  selected,
  interactive,
  drawMode,
  onSelect,
  onChange: _onChange,
  onFinished,
}) => {
  const shapeRef = useRef<Konva.Text>(null);
  const trRef = useRef<Konva.Transformer>(null);

  const [typing, setTyping] = useState(
    interactive && drawMode === ShapeDiscriminants.Text,
  );

  useEffect(() => {
    setTimeout(
      () => setTyping(interactive && drawMode === ShapeDiscriminants.Text),
      10,
    );
  }, []);

  useEffect(() => {
    if (selected && shapeRef.current && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [selected]);

  const onChange = (value: Partial<Text>) =>
    _onChange({
      type: ShapeDiscriminants.Text,
      value: { ...text, ...value },
    });

  return (
    <>
      {typing && (
        <Html groupProps={{ x: text.x, y: text.y }}>
          <Textarea
            autoFocus={interactive}
            text={text}
            defaultValue={text.text}
            onBlur={() => {
              setTyping(false);
              onFinished && onFinished();
            }}
            onChange={(e) => onChange({ text: e.target.value })}
            onKeyDownCapture={(e) => e.stopPropagation()}
          />
        </Html>
      )}
      <ReactKonva.Text
        {...text}
        fontFamily="sans-serif"
        draggable={interactive}
        ref={shapeRef}
        onClick={() => interactive && onSelect()}
        onDblClick={() => interactive && setTyping(true)}
        onTransform={(_e) => {
          const node = shapeRef.current!;
          const width = Math.max(node.width() * node.scaleX(), 10);
          const height = Math.max(node.height() * node.scaleY(), 10);
          node.setAttrs({ width, height, scaleX: 1, scaleY: 1 });
        }}
        onTransformEnd={(_e) => {
          const node = shapeRef.current!;
          onChange({
            ...node.position(),
            ...node.size(),
            rotation: node.rotation(),
          });
        }}
        onDragEnd={(e) => onChange({ ...e.target.position() })}
      />
      {selected && (
        <ReactKonva.Transformer
          ref={trRef}
          keepRatio={false}
          boundBoxFunc={(oldBox, newBox) =>
            newBox.width < 10 || newBox.height < 10 ? oldBox : newBox
          }
        />
      )}
    </>
  );
};

const Textarea = styled("textarea", { shouldForwardProp: (_) => true })<{
  text: Text;
}>(({ text }) => ({
  fontSize: text.fontSize,
  width: text.width,
  height: text.height,
  color: "transparent",
  resize: "none",
  caretColor: "black",
  backgroundColor: "transparent",
  fontFamily: "sans-serif",
  margin: 0,
  padding: 0,
  marginLeft: 1,
  lineHeight: 0.9,
  outline: "none",
  border: "none",
  "& :focus": {
    border: "none",
  },
}));
