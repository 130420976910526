import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Modal } from "components";
import {
  CreatePlaylistExport,
  ImagePlacement,
  Playlist,
} from "generated/openapi";
import { FC, useState } from "react";
import { Form, useForm } from "react-hook-form";
import { startPlaylistExport, useAppDispatch } from "store";

enum ExportMode {
  Combined = "Combined",
  Split = "Split",
}

interface Props {
  playlist: Playlist;
  onClose: () => void;
}

export const ExportPlaylistModal: FC<Props> = ({ playlist, onClose }) => {
  const dispatch = useAppDispatch();

  const [exportMode, setExportMode] = useState(ExportMode.Combined);

  const {
    watch,
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePlaylistExport>({
    defaultValues: {
      name: "",
      segments: [],
      placement: ImagePlacement.TopLeft,
      invert: false,
    },
  });

  const handleSegmentClick = (id: number) => {
    const segments = getValues("segments");
    const i = segments.findIndex((v) => v === id);
    if (i < 0) {
      setValue("segments", [...segments, id]);
    } else {
      segments.splice(i, 1);
      setValue("segments", [...segments]);
    }
  };

  const segments = watch("segments");
  const allSegments = playlist.segments;

  return (
    <Modal open onClose={() => onClose()} sx={{ p: 3 }}>
      <Form
        control={control}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
        }}
      >
        <Typography sx={{ pb: 2 }} variant="h3">
          Export Playlist
        </Typography>

        <ToggleButtonGroup
          exclusive
          value={exportMode}
          onChange={(_, v) => v && setExportMode(v)}
        >
          <ToggleButton value={ExportMode.Combined}>
            {ExportMode.Combined}
          </ToggleButton>
          <ToggleButton value={ExportMode.Split}>
            {ExportMode.Split}
          </ToggleButton>
        </ToggleButtonGroup>

        {exportMode === ExportMode.Combined && (
          <TextField
            autoFocus
            color="info"
            label="Name"
            variant="outlined"
            error={!!errors.name}
            onKeyDown={(e) => e.stopPropagation()}
            {...register("name", {
              required: exportMode === ExportMode.Combined,
            })}
          />
        )}

        <FormControl error={!!errors.placement} color="info">
          <FormLabel>Placement</FormLabel>
          <RadioGroup defaultValue={ImagePlacement.TopLeft}>
            <FormControlLabel
              value={ImagePlacement.TopLeft}
              control={<Radio color="info" />}
              label={ImagePlacement.TopLeft}
              {...register("placement", { required: true })}
            />
            <FormControlLabel
              value={ImagePlacement.TopRight}
              control={<Radio color="info" />}
              label={ImagePlacement.TopRight}
              {...register("placement", { required: true })}
            />
            <FormControlLabel
              value={ImagePlacement.BottomLeft}
              control={<Radio color="info" />}
              label={ImagePlacement.BottomLeft}
              {...register("placement", { required: true })}
            />
            <FormControlLabel
              value={ImagePlacement.BottomRight}
              control={<Radio color="info" />}
              label={ImagePlacement.BottomRight}
              {...register("placement", { required: true })}
            />
          </RadioGroup>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              color="info"
              checked={watch("invert")}
              onClick={() => setValue("invert", !getValues("invert"))}
            />
          }
          label="Invert"
        />

        <List sx={{ maxHeight: "60vh", overflowY: "auto" }}>
          <ListItem
            secondaryAction={
              <Checkbox
                edge="end"
                color="info"
                checked={segments.length === allSegments.length}
                onClick={() =>
                  setValue(
                    "segments",
                    segments.length === allSegments.length
                      ? []
                      : allSegments.map((s) => s.id),
                  )
                }
              />
            }
          >
            <ListItemText sx={{ py: 1.5 }} primary="" />
          </ListItem>
          {allSegments.map((s) => (
            <ListItem
              key={s.id}
              disablePadding
              secondaryAction={
                <Checkbox
                  edge="end"
                  color="info"
                  checked={segments.includes(s.id)}
                  onClick={() => handleSegmentClick(s.id)}
                />
              }
            >
              <ListItemButton onClick={() => handleSegmentClick(s.id)}>
                <ListItemText primary={s.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Button
          color="info"
          onClick={() => {
            handleSubmit((data) => {
              dispatch(
                startPlaylistExport({
                  playlistId: playlist.id,
                  ...data,
                  name:
                    exportMode === ExportMode.Combined ? data.name : undefined,
                }),
              ).then(() => onClose());
            })();
          }}
        >
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
