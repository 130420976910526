import { ForzaEvent } from "generated/openapi";
import { FC, ReactElement, useMemo } from "react";
import { selectForzaTeamsMap, useAppSelector } from "store";
import { dateFormat, minutesSeconds } from "utils/utils";
import { Thumbnail } from "./Thumbnail";

export interface Props {
  event: ForzaEvent;
  children?: false | ReactElement | ReactElement[];
  width?: number | string;
  height?: number | string;
}

export const EventThumbnail: FC<Props> = ({
  event,
  children,
  width,
  height,
}) => {
  const teams = useAppSelector(selectForzaTeamsMap);

  const [team1, team2, duration] = useMemo(() => {
    const team1 = teams[event.homeTeamId];
    const team2 = teams[event.visitingTeamId];
    const duration = minutesSeconds(event.toTimestamp - event.fromTimestamp);

    return [team1, team2, duration];
  }, [event]);

  return (
    <Thumbnail
      url={event.thumbnailUrl}
      title={event.description}
      subtitle={`${team1.name} VS. ${team2.name} - ${dateFormat(event.recordingTimestamp, "dd.MM.yyyy")}`}
      duration={duration}
      width={width}
      height={height}
    >
      {children}
    </Thumbnail>
  );
};
