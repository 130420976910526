import { createSelector } from "@reduxjs/toolkit";
import { selectAppState } from "store/selectAppState";

export const selectForzaPlayers = createSelector(
  selectAppState,
  (state) => state.forzaPlayers,
);

export const selectForzaPlayersSorted = createSelector(
  selectForzaPlayers,
  (state) => [...state].sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectForzaPlayersLoading = createSelector(
  selectAppState,
  (state) => state.forzaPlayersLoading,
);
