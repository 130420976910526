import { Box, Dialog } from "@mui/material";
import { PlaylistSegment } from "generated/openapi";
import { FC } from "react";
import { SegmentsStatsTable } from "./SegmentsStatsTable";

interface Props {
  segments: PlaylistSegment[];
  onClose: () => void;
}

export const SegmentsStatsModal: FC<Props> = ({ onClose, ...props }) => {
  return (
    <Dialog open maxWidth="xl" onClose={onClose}>
      <Box sx={{ bgcolor: "primary.main", padding: 4 }}>
        <SegmentsStatsTable selectAllTags {...props} />
      </Box>
    </Dialog>
  );
};
