import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppState } from "store/state";
import { createGroup, deleteGroup, updateGroup } from "./actions";

export const groupBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(createGroup.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(createGroup.fulfilled, (state, action) => {
      const team = state.org!.teams.find(
        (t) => t.id === action.meta.arg.teamId,
      )!;
      team.groups.push(action.payload);
    })
    .addCase(updateGroup.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updateGroup.fulfilled, (state, action) => {
      const team = state.org!.teams.find(
        (t) => t.id === action.meta.arg.teamId,
      )!;
      const idx = team.groups.findIndex(
        (v) => v.id === action.meta.arg.group.id,
      );
      if (idx >= 0) {
        team.groups[idx] = action.meta.arg.group;
      }
    })
    .addCase(deleteGroup.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(deleteGroup.fulfilled, (state, action) => {
      const team = state.org!.teams.find(
        (t) => t.id === action.meta.arg.teamId,
      )!;
      const idx = team.groups.findIndex(
        (v) => v.id === action.meta.arg.groupId,
      );
      if (idx >= 0) {
        team.groups.splice(idx, 1);
      }
    });
