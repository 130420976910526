import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";
import { ForzaEventsParameters } from "api";
import { ForzaEventType } from "generated/openapi";

export const getForzaEvents = createAsyncThunk(
  "events/getForzaEvents",
  Api.getForzaEvents,
);

export const getForzaEvent = createAsyncThunk(
  "events/getForzaEvent",
  Api.getForzaEvent,
);

export const getGoals = (query: ForzaEventsParameters) =>
  getForzaEvents({
    eventType: ForzaEventType.Goal,
    params: query,
  });
export const getPenalties = (query: ForzaEventsParameters) =>
  getForzaEvents({
    eventType: ForzaEventType.Penalty,
    params: query,
  });
export const getRedCards = (query: ForzaEventsParameters) =>
  getForzaEvents({
    eventType: ForzaEventType.RedCard,
    params: query,
  });
export const getYellowCards = (query: ForzaEventsParameters) =>
  getForzaEvents({
    eventType: ForzaEventType.YellowCard,
    params: query,
  });
export const getShots = (query: ForzaEventsParameters) =>
  getForzaEvents({
    eventType: ForzaEventType.Shot,
    params: query,
  });
export const getCorners = (query: ForzaEventsParameters) =>
  getForzaEvents({
    eventType: ForzaEventType.Corner,
    params: query,
  });
export const getOffsides = (query: ForzaEventsParameters) =>
  getForzaEvents({
    eventType: ForzaEventType.Offside,
    params: query,
  });
export const getFreekicks = (query: ForzaEventsParameters) =>
  getForzaEvents({
    eventType: ForzaEventType.Freekick,
    params: query,
  });

export const setForzaEventsFilters = createAction<ForzaEventsParameters>(
  "events/setForzaEventsFilters",
);

export const setSelectedForzaEventType = createAction<
  ForzaEventType | undefined
>("events/setSelectedForzaEventType");
