import { KonvaEventObject } from "konva/lib/Node";
import { FC, ReactNode } from "react";
import { Arc, Circle, Layer, Line, Rect, Stage } from "react-konva";

export const HEIGHT_FACTOR = 9 / 16; // 0.5625

export interface Grid {
  x: number;
  y: number;
}

export interface Props {
  width: number;
  height?: number;
  color?: string;
  backgroundColor?: string;
  children?: ReactNode;
  onClick?: (_: KonvaEventObject<MouseEvent>) => void;
  onContextMenu?: (_: KonvaEventObject<PointerEvent>) => void;
  onMouseMove?: (_: KonvaEventObject<MouseEvent>) => void;
}

export const FootballField: FC<Props> = ({
  width,
  height: _height,
  color: _color,
  backgroundColor,
  children,
  onClick,
  onContextMenu,
  onMouseMove,
}) => {
  // All values are found by overlaying this on the image found in `public/football-field.png`
  // and lining up all the lines.

  const height = _height ?? width * HEIGHT_FACTOR;
  const fiverWidth = width * 0.06;
  const fiverHeight = height * 0.255;
  const sixteenWidth = width * 0.123;
  const sixteenHeight = height * 0.515;

  const color = _color ?? "white";
  const lineWidth = 4;

  const Corner = (props: { x: number; y: number; r: number }) => (
    <Arc
      x={props.x}
      y={props.y}
      innerRadius={width / 50}
      outerRadius={width / 50}
      rotation={props.r}
      angle={90}
      stroke={color}
      strokeWidth={lineWidth}
    />
  );

  const GoalBox = ({ left }: { left?: boolean }) => {
    const x = left ? 0 : width;
    const r = left ? 0 : 180;

    const _penaltyX = (fiverWidth + sixteenWidth) / 2;
    const penaltyX = left ? _penaltyX : width - _penaltyX;

    return (
      <>
        <Rect
          x={x}
          y={height / 2}
          width={fiverWidth}
          height={fiverHeight}
          rotation={r}
          offsetY={fiverHeight / 2}
          stroke={color}
          strokeWidth={lineWidth}
        />
        <Rect
          x={x}
          y={height / 2}
          width={sixteenWidth}
          height={sixteenHeight}
          rotation={r}
          offsetY={sixteenHeight / 2}
          stroke={color}
          strokeWidth={lineWidth}
        />
        <Arc
          x={penaltyX}
          y={height / 2}
          innerRadius={width / 14.5}
          outerRadius={width / 14.5}
          rotation={r - 62}
          angle={125}
          stroke={color}
          strokeWidth={lineWidth}
        />
        <Circle x={penaltyX} y={height / 2} radius={lineWidth} fill={color} />
      </>
    );
  };

  return (
    <Stage
      width={width}
      height={height}
      onClick={onClick}
      onContextMenu={onContextMenu}
      onMouseMove={onMouseMove}
    >
      <Layer>
        <Rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={backgroundColor ?? "#238222"}
          stroke={color}
          strokeWidth={lineWidth * 2}
        />
        <Line
          points={[width / 2, 0, width / 2, height]}
          stroke={color}
          strokeWidth={lineWidth}
        />
        <Circle
          x={width / 2}
          y={height / 2}
          radius={lineWidth * 1.5}
          fill={color}
        />
        <Circle
          x={width / 2}
          y={height / 2 - 1}
          radius={width / 13.25}
          stroke={color}
          strokeWidth={lineWidth}
        />
        <Corner x={0} y={0} r={0} />
        <Corner x={width} y={0} r={90} />
        <Corner x={0} y={height} r={-90} />
        <Corner x={width} y={height} r={180} />
        <GoalBox left />
        <GoalBox />
        {children}
      </Layer>
    </Stage>
  );
};
