import { Rect, Shape, ShapeDiscriminants } from "generated/openapi";
import Konva from "konva";
import { FC, useEffect, useRef } from "react";
import * as ReactKonva from "react-konva";

export interface Props {
  rect: Rect;
  selected: boolean;
  interactive: boolean;
  onSelect: () => void;
  onChange: (_: Shape) => void;
}

export const RectComponent: FC<Props> = ({
  rect,
  selected,
  interactive,
  onSelect,
  onChange: _onChange,
}) => {
  const shapeRef = useRef<Konva.Rect>(null);
  const trRef = useRef<Konva.Transformer>(null);

  useEffect(() => {
    if (selected && shapeRef.current && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [selected]);

  const onChange = (value: Partial<Rect>) =>
    _onChange({
      type: ShapeDiscriminants.Rect,
      value: { ...rect, ...value },
    });

  return (
    <>
      <ReactKonva.Rect
        {...rect}
        stroke={rect.stroke ?? undefined}
        strokeWidth={rect.strokeWidth ?? undefined}
        draggable={interactive}
        strokeScaleEnabled={false}
        ref={shapeRef}
        onClick={() => interactive && onSelect()}
        onDragEnd={(e) => onChange(e.target.position())}
        onTransformEnd={(_e) => {
          const node = shapeRef.current!;
          const width = node.width() * node.scaleX();
          const height = node.height() * node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          onChange({
            ...node.position(),
            width,
            height,
            rotation: node.rotation(),
          });
        }}
      />
      {selected && (
        <ReactKonva.Transformer ref={trRef} ignoreStroke keepRatio={false} />
      )}
    </>
  );
};
