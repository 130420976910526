import { createSelector } from "@reduxjs/toolkit";
import { selectAppState } from "store/selectAppState";

export const selectPlaylistTags = createSelector(
  selectAppState,
  (state) => state.playlistTags,
);

export const selectPlaylistTagsLoading = createSelector(
  selectAppState,
  (state) => state.playlistTagsLoading || state.playlistTags === undefined,
);

export const selectPlaylistTagsSorted = createSelector(
  selectPlaylistTags,
  (state) =>
    state ? [...state].sort((a, b) => a.name.localeCompare(b.name)) : undefined,
);

export const selectPlaylistTagsMap = createSelector(
  selectPlaylistTags,
  (state) =>
    state ? Object.fromEntries(state.map((t) => [t.id, t])) : undefined,
);
