import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Button,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import theme, { fontStyle } from "app/theme";
import { Confirm, ConfirmModal } from "components";
import { UserRole } from "generated/openapi";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  leaveOrg,
  selectOrg,
  selectUser,
  updateOrg,
  useAppDispatch,
  useAppSelector,
} from "store";
import { CreateOrg } from "./CreateOrg";

const StyledTableCell = styled(TableCell)(() => ({
  border: 0,
  color: theme.palette.text.secondary,

  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    padding: "6px 0px 6px 0px",
    textAlign: "center",
    "&:first-of-type": {
      textAlign: "left",
    },
    "&:last-of-type": {
      fontSize: " 1.1rem",
      textAlign: "right",
    },
  },
}));

export const Overview: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser)!;
  const _org = useAppSelector(selectOrg);

  const [confirm, setConfirm] = useState<Confirm | undefined>(undefined);
  const [editing, setEditing] = useState<string | undefined>(undefined);

  if (!_org) {
    if (user.userRole === UserRole.Admin) {
      return <CreateOrg />;
    } else {
      navigate("/");
    }
  }

  const org = _org!;
  const isAdmin = user.userRole === UserRole.Admin;

  const [admins, coaches, players] = useMemo(() => {
    let admins = 0;
    let coaches = 0;
    let players = 0;

    for (const t of org.teams) {
      admins += t.admins.length;
      coaches += t.coaches.length;
      players += t.players.length;
    }
    for (const u of org.otherUsers) {
      if (u.userRole === UserRole.Admin) admins += 1;
      else if (u.userRole === UserRole.Coach) coaches += 1;
      else players += 1;
    }

    return [admins, coaches, players];
  }, [org]);

  const handleSave = () => {
    if (editing && editing !== org.name) {
      dispatch(updateOrg({ org: { ...org, name: editing } }));
      setEditing(undefined);
    }
  };

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          bgcolor: "primary.dark",
          borderColor: "primary.main",
          borderRadius: 2,
          mb: 3,
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          {editing !== undefined ? (
            <>
              <TextField
                autoFocus
                color="third"
                variant="standard"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    fontWeight: fontStyle.fontWeightSemiBold,
                    fontSize: "1.45rem",
                    lineHeight: 1.6,
                  },
                  "& .MuiInputBase-input": {
                    p: 0,
                    pt: "2px",
                    pb: "1.5px",
                  },
                }}
                value={editing}
                onKeyDown={(e) => e.key === "Enter" && handleSave()}
                onChange={(e) => setEditing(e.target.value)}
              />
              <Stack direction="row" gap={1}>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  size="small"
                  color="inherit"
                  onClick={() => setEditing(undefined)}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  color="success"
                  variant="contained"
                  disabled={!editing || editing === org.name}
                  onClick={() => handleSave()}
                >
                  Save
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Typography
                variant="h6"
                sx={{ pt: "0px", borderBottom: "0px solid transparent" }}
              >
                {org.name}
              </Typography>
              {isAdmin && (
                <Button
                  size="small"
                  variant="outlined"
                  color="warning"
                  sx={{ textTransform: "inherit" }}
                  onClick={() => setEditing(org.name)}
                  startIcon={<EditIcon />}
                >
                  Edit name
                </Button>
              )}
            </>
          )}
        </Stack>

        <Table sx={{ maxWidth: 320 }}>
          <TableBody>
            <TableRow>
              <StyledTableCell>Teams: </StyledTableCell>
              <StyledTableCell>
                {org.teams.length} / {org.maxTeams}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Admins: </StyledTableCell>
              <StyledTableCell>
                {admins} / {org.maxAdmins}{" "}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Coaches: </StyledTableCell>
              <StyledTableCell>
                {coaches} / {org.maxCoaches}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Players: </StyledTableCell>
              <StyledTableCell>
                {players} / {org.maxPlayers}{" "}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>

      {confirm && (
        <ConfirmModal {...confirm} open onClose={() => setConfirm(undefined)} />
      )}
      <Button
        color="error"
        variant="contained"
        startIcon={<LogoutIcon />}
        onClick={() =>
          setConfirm({
            message: `Are you sure you want to leave the org '${org.name}'?`,
            onConfirm: () => {
              dispatch(leaveOrg({}));
            },
          })
        }
      >
        Leave {org.name}
      </Button>
    </>
  );
};
