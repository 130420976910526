import { createSelector } from "@reduxjs/toolkit";
import { selectAppState } from "store/selectAppState";

export const selectJournalEntries = createSelector(
  selectAppState,
  (state) => state.journalEntries,
);

export const selectJournalEntriesLoading = createSelector(
  selectAppState,
  (state) => state.journalEntriesLoading,
);

export const selectJournalEntriesMap = createSelector(
  selectJournalEntries,
  (state) =>
    state ? Object.fromEntries(state.map((v) => [v.id, v])) : undefined,
);

export const selectJournalEntry = (id: number) =>
  createSelector(selectJournalEntriesMap, (state) => state?.[id]);
