import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { UpdatePlayer, UpdateUser, User, UserRole } from "generated/openapi";
import { AppState } from "store/state";
import { deleteUser, getUser, updatePlayer, updateUser } from "./actions";

const findAndUpdate = (
  users: User[],
  userId: string,
  user: UpdateUser | UpdatePlayer,
) => {
  for (let i = 0; i < users.length; i++) {
    if (users[i].id === userId) {
      users[i] = { ...users[i], ...user };
      return;
    }
  }
};

export const userBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(getUser.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;
    })
    .addCase(getUser.rejected, (_state, action) => {
      console.warn(action);
    })
    .addCase(updateUser.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      const user = action.meta.arg.user;
      state.user = { ...state.user!, ...user };
      if (state.org) {
        findAndUpdate(state.org.otherUsers, state.user.id, user);
        for (const t of state.org.teams) {
          findAndUpdate(
            state.user.userRole === UserRole.Admin
              ? t.admins
              : state.user.userRole === UserRole.Coach
                ? t.coaches
                : t.players,
            state.user.id,
            user,
          );
        }
      }
    })
    .addCase(updatePlayer.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updatePlayer.fulfilled, (state, action) => {
      const { userId, user } = action.meta.arg;
      if (userId === state.user?.id) {
        state.user = { ...state.user, ...user };
      }
      findAndUpdate(state.org!.otherUsers, userId, user);
      for (const t of state.org!.teams) {
        findAndUpdate(t.players, userId, user);
      }
    })
    .addCase(deleteUser.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(deleteUser.fulfilled, (_state, _action) => {
      window.location.assign(window.location.origin);
    });
