import { Button, TextField, Typography } from "@mui/material";
import { Modal, PlayersInput } from "components";
import { CreateGroup as Group } from "generated/openapi";
import { FC, useMemo } from "react";
import { Form, useForm } from "react-hook-form";
import {
  createGroup,
  selectTeamPlayers,
  useAppDispatch,
  useAppSelector,
} from "store";

export interface Props {
  teamId: number;
  onClose: () => void;
}

export const CreateGroup: FC<Props> = ({ teamId, onClose }) => {
  const dispatch = useAppDispatch();

  const _players = useAppSelector(selectTeamPlayers(teamId));

  const players = useMemo(() => _players?.map((p) => p.id) ?? [], [_players]);

  const {
    watch,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<Group>();

  return (
    <Modal open={true} onClose={onClose} sx={{ p: 3 }}>
      <Form
        control={control}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          width: 350,
        }}
        onSubmit={({ data }) => {
          dispatch(createGroup({ teamId, group: data }));
          onClose();
        }}
      >
        <Typography sx={{ pb: 2 }} variant="h6">
          Create group
        </Typography>

        <TextField
          spellCheck={false}
          autoFocus
          color="third"
          label="Name"
          variant="outlined"
          error={!!errors.name}
          {...register("name", { required: true })}
        />

        <PlayersInput
          value={watch("users") ?? []}
          options={players}
          onChange={(users) => setValue("users", users)}
        />

        <Button color="third" type="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
