import { apiConfiguration, apiGet } from "api";
import { CreateTeam, TeamDetailed, V1teamApi } from "generated/openapi";

const api = new V1teamApi(apiConfiguration);

export interface CreateTeamArgs {
  team: CreateTeam;
  token?: string;
}

export interface UpdateTeamArgs {
  team: TeamDetailed;
  token?: string;
}

export interface DeleteTeamArgs {
  teamId: number;
  token?: string;
}

export interface KickFromTeamArgs {
  teamId: number;
  userId: string;
  token?: string;
}

export const createTeam = async (query: CreateTeamArgs) =>
  apiGet(async () =>
    api.createTeam(
      { createTeam: query.team },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const updateTeam = async (query: UpdateTeamArgs) =>
  apiGet(async () =>
    api.updateTeam(
      { teamId: query.team.id, createTeam: query.team },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const deleteTeam = async (query: DeleteTeamArgs) =>
  apiGet(async () =>
    api.deleteTeam(
      { teamId: query.teamId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const kickFromTeam = async (query: KickFromTeamArgs) =>
  apiGet(async () =>
    api.kickUserFromTeam(
      { teamId: query.teamId, userId: query.userId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );
