import { Box, SxProps, Tab as MuiTab, Tabs as MuiTabs } from "@mui/material";
import { fontStyle } from "app/theme";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";

export interface Tab {
  key: string;
  Element: FC<any>;
  [k: string]: any;
}

export interface Props {
  tabs: Tab[];
  sx?: SxProps;
}

export const Tabs: FC<Props> = ({ tabs, sx }) => {
  const [params, setParams] = useSearchParams();

  const tabKey = params.get("tab");

  const { key, Element, ...props } =
    tabs.find(({ key }) => key === tabKey) ?? tabs[0];

  return (
    <Box>
      <MuiTabs
        centered
        indicatorColor="secondary"
        sx={{
          "& .MuiTab-root": {
            color: "text.secondary",
            "&:hover": {
              color: "white",
            },
            "&.Mui-selected": {
              color: "white",
              fontWeight: fontStyle.fontWeightSemiBold,
            },
          },
        }}
        value={key}
        onChange={(_, tab: string) => setParams({ tab })}
      >
        {tabs.map(({ key }) => (
          <MuiTab key={key} label={key} value={key} />
        ))}
      </MuiTabs>
      <Box sx={{ pt: 2, ...sx }}>{<Element {...props} />}</Box>
    </Box>
  );
};
