import { getPlaylist } from "api";
import { PlaylistSegment } from "generated/openapi";
import { FC } from "react";
import {
  createPlaylist,
  selectAccessToken,
  updatePlaylist,
  useAppDispatch,
  useAppSelector,
} from "store";
import { AddToPlaylistModal } from "./AddToPlaylistModal";

interface Props {
  open: boolean;
  segments: PlaylistSegment[];
  thumbnailData?: string;
  onClose: (message?: string) => void;
}

export const AddSegmentsToPlaylistModal: FC<Props> = ({
  open,
  segments,
  onClose,
  thumbnailData,
}) => {
  const dispatch = useAppDispatch();

  const token = useAppSelector(selectAccessToken);

  return (
    <AddToPlaylistModal
      open={open}
      numToAdd={segments.length}
      onClose={onClose}
      onCreate={(p) => {
        dispatch(
          createPlaylist({
            playlist: {
              ...p,
              thumbnailUrl: thumbnailData,
              segments: p.segments?.concat(
                segments.map((s) => ({
                  ...s,
                  tags: s.tags,
                })),
              ),
            },
          }),
        ).then(() => onClose("Playlist Created"));
      }}
      onUpdate={(p) => {
        getPlaylist({ playlistId: p.id, token }).then((p) => {
          dispatch(
            updatePlaylist({
              playlist: {
                ...p,
                segments: segments.concat(p.segments),
              },
            }),
          ).then(() => onClose("Added To Playlist"));
        });
      }}
    />
  );
};
