import { LiveStream } from "generated/openapi";

export interface LiveStreamState {
  liveStreams?: LiveStream[];
  liveStreamsLoading: boolean;
}

export const initialLiveStreamState: LiveStreamState = {
  liveStreams: undefined,
  liveStreamsLoading: false,
};
