import { ForzaEventArgs, getForzaEvents, getPlaylist } from "api";
import { CreatePlaylistSegment, PlaylistSegment } from "generated/openapi";
import { FC } from "react";
import {
  createPlaylist,
  selectAccessToken,
  selectUser,
  updatePlaylist,
  useAppDispatch,
  useAppSelector,
} from "store";
import { AddToPlaylistModal } from "./AddToPlaylistModal";

export interface ForzaEventsQuery extends ForzaEventArgs {
  total: number;
}

interface Props {
  open: boolean;
  eventsQuery: ForzaEventsQuery;
  onClose: (message?: string) => void;
}

export const AddEventsToPlaylistModal: FC<Props> = ({
  open,
  eventsQuery,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const token = useAppSelector(selectAccessToken);
  const user = useAppSelector(selectUser);

  const getEvents = async () =>
    getForzaEvents({
      eventType: eventsQuery.eventType,
      params: eventsQuery.params,
    });

  return (
    <AddToPlaylistModal
      open={open}
      numToAdd={eventsQuery.total}
      onClose={() => onClose()}
      onCreate={(p) => {
        getEvents().then(({ values }) => {
          dispatch(
            createPlaylist({
              playlist: {
                ...p,
                thumbnailUrl: values[0].thumbnailUrl,
                segments: values
                  .map(
                    (e): CreatePlaylistSegment => ({
                      userId: user?.id,
                      forzaGameId: e.forzaGameId,
                      title: e.description,
                      videoAssetId: e.videoAssetId,
                      fromTimestamp: e.fromTimestamp - 20_000,
                      toTimestamp: e.toTimestamp,
                      tags: [],
                      playerTags: [],
                      drawings: {},
                      footballFieldGrids: [],
                      footballFieldSequenceGrids: [],
                      favourite: false,
                    }),
                  )
                  .concat(p.segments ?? []),
              },
            }),
          ).then(() => onClose("Playlist Created"));
        });
      }}
      onUpdate={(p) => {
        Promise.all([
          getPlaylist({ playlistId: p.id, token }),
          getEvents(),
        ]).then(([p, { values }]) => {
          dispatch(
            updatePlaylist({
              playlist: {
                ...p,
                segments: values
                  .map(
                    (e): PlaylistSegment => ({
                      id: -1,
                      creator: user,
                      forzaGameId: e.forzaGameId,
                      title: e.description,
                      videoAssetId: e.videoAssetId,
                      homeTeamId: e.homeTeamId,
                      visitingTeamId: e.visitingTeamId,
                      fromTimestamp: e.fromTimestamp - 20_000,
                      toTimestamp: e.toTimestamp,
                      videoBaseUrl: e.videoBaseUrl,
                      gameHasPanorama: false,
                      hasHomography: false,
                      favourite: false,
                      tags: [],
                      playerTags: [],
                      forzaTeamTags: [],
                      drawings: {},
                      homographyFrames: {},
                      footballFieldGrids: [],
                      footballFieldSequenceGrids: [],
                    }),
                  )
                  .concat(p.segments),
              },
            }),
          ).then(() => onClose("Added To Playlist"));
        });
      }}
    />
  );
};
