import { SxProps, Theme } from "@mui/material";
import { ChipAutocomplete } from "components";
import { TagItem } from "models";
import { FC, useMemo } from "react";
import {
  selectSegmentTagsFlattened,
  selectSegmentTagsFlattenedMap,
  useAppSelector,
} from "store";

interface Props {
  value?: TagItem[];
  onChange: (_?: TagItem[]) => void;
  options?: number[];
  sx?: SxProps<Theme>;
}

export const SegmentTagsFilter: FC<Props> = ({
  value,
  options: _options,
  sx,
  onChange,
}) => {
  const tags = useAppSelector(selectSegmentTagsFlattened)!;
  const tagsMap = useAppSelector(selectSegmentTagsFlattenedMap)!;

  const options = useMemo(
    () =>
      _options
        ? _options
            .map((o) => tagsMap[o])
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
        : tags,
    [_options, tags, tagsMap],
  );

  return (
    <ChipAutocomplete
      sx={sx}
      placeholder="Tags"
      value={value ?? []}
      options={options}
      getOptionLabel={(option) => option.displayName}
      onChange={(value) => onChange(value?.length ? value : undefined)}
    />
  );
};
