import { apiConfiguration, apiGet } from "api";
import {
  CreatePlaylistSegmentTag,
  UpdatePlaylistSegmentTag,
  V1playlistSegmentTagApi,
} from "generated/openapi";

const api = new V1playlistSegmentTagApi(apiConfiguration);

export interface UpdatePlaylistSegmentTagArgs {
  tagId: number;
  tag: UpdatePlaylistSegmentTag;
  token?: string;
}

export interface CreatePlaylistSegmentTagArgs {
  tag: CreatePlaylistSegmentTag;
  token?: string;
}

export interface DeletePlaylistSegmentTagArgs {
  tagId: number;
  token?: string;
}

export const getPlaylistSegmentTags = async ({ token }: { token?: string }) =>
  apiGet(async () =>
    api.getPlaylistSegmentTags({
      headers: { Authorization: `Bearer ${token}` },
    }),
  );

export const createPlaylistSegmentTag = async (
  query: CreatePlaylistSegmentTagArgs,
) =>
  apiGet(async () =>
    api.createPlaylistSegmentTag(
      { createPlaylistSegmentTag: query.tag },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const updatePlaylistSegmentTag = async (
  query: UpdatePlaylistSegmentTagArgs,
) =>
  apiGet(async () =>
    api.updatePlaylistSegmentTag(
      {
        tagId: query.tagId,
        updatePlaylistSegmentTag: query.tag,
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const deletePlaylistSegmentTag = async (
  query: DeletePlaylistSegmentTagArgs,
) =>
  apiGet(async () =>
    api.deletePlaylistSegmentTag(
      { tagId: query.tagId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );
