import { createSelector } from "@reduxjs/toolkit";
import { selectSegmentTagsLoading } from "store/segmentTag";
import { selectOrg, selectOrgLoading } from "./org";
import { selectPlaylistTagsLoading } from "./playlistTag";
import { selectAppState } from "./selectAppState";

export const selectError = createSelector(
  selectAppState,
  (state) => state.error,
);

export const selectAccessToken = createSelector(
  selectAppState,
  (state) => state.accessToken,
);

export const selectAccessTokenObj = createSelector(
  selectAccessToken,
  (state) =>
    state ? JSON.parse(atob(state.split(".")[1]).toString()) : undefined,
);

export const selectIsOrcaAdmin = createSelector(selectAccessTokenObj, (state) =>
  state?.user_roles.includes("Gengar:Admin"),
);

export const selectLoggedInResourcesLoading = createSelector(
  selectSegmentTagsLoading,
  selectPlaylistTagsLoading,
  selectOrgLoading,
  selectOrg,
  (tagsLoading, playlistTagsLoading, orgLoading, org) => {
    if (!orgLoading && !org) {
      return false;
    }
    return tagsLoading || playlistTagsLoading || orgLoading;
  },
);

export const selectAlert = createSelector(
  selectAppState,
  (state) => state.alert,
);
