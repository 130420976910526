import { ForzaEventsParameters } from "api";
import { startOfYear, subYears } from "date-fns";
import {
  ForzaEvent,
  ForzaEventType,
  ValuesTotalForzaEvent,
} from "generated/openapi";

export interface ForzaEventsState {
  goals: ValuesTotalForzaEvent;
  penalties: ValuesTotalForzaEvent;
  redCards: ValuesTotalForzaEvent;
  yellowCards: ValuesTotalForzaEvent;
  corners: ValuesTotalForzaEvent;
  shotsOnGoal: ValuesTotalForzaEvent;
  offsides: ValuesTotalForzaEvent;
  freekicks: ValuesTotalForzaEvent;
  eventsFilters: ForzaEventsParameters;
  selectedEventType?: ForzaEventType;
  eventsLoading: boolean;
  currentEvent?: ForzaEvent;
}

export const initialForzaEventsState: ForzaEventsState = {
  goals: { values: [], total: 0 },
  penalties: { values: [], total: 0 },
  redCards: { values: [], total: 0 },
  yellowCards: { values: [], total: 0 },
  corners: { values: [], total: 0 },
  shotsOnGoal: { values: [], total: 0 },
  offsides: { values: [], total: 0 },
  freekicks: { values: [], total: 0 },
  eventsLoading: false,
  selectedEventType: undefined,
  eventsFilters: {
    from: startOfYear(subYears(new Date(), 1)).toISOString(),
    limit: 6,
    offset: 0,
  },
  currentEvent: undefined,
};
