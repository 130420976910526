import { useAuth0 } from "@auth0/auth0-react";
import { acceptInvite, getInvite } from "api";
import { AxiosError } from "axios";
import { LoadingScreen } from "components";
import { CreateUser } from "containers/CreateUser/CreateUser";
import { Invite } from "generated/openapi";
import { FC, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { selectAccessToken, selectUser, useAppSelector } from "store";

export const AcceptInvite: FC = () => {
  const { loginWithRedirect } = useAuth0();

  const navigate = useNavigate();
  const [params, _] = useSearchParams();
  const inviteId = params.get("inviteId");

  const token = useAppSelector(selectAccessToken);
  const user = useAppSelector(selectUser);

  const [invite, setInvite] = useState<Invite | undefined>(undefined);

  useEffect(() => {
    if (inviteId && token) {
      getInvite(inviteId, token)
        .then(setInvite)
        .catch((e: AxiosError) => {
          if (e.response?.status === 404) {
            navigate("/");
          }
        });
    }
  }, [inviteId, token]);

  if (!inviteId) return <Navigate to={"/"} />;

  if (!token) {
    loginWithRedirect({
      authorizationParams: { redirect_uri: window.location.href },
    });
    return <></>;
  }

  if (!user) {
    return invite ? <CreateUser invite={invite} /> : <LoadingScreen />;
  }

  acceptInvite(inviteId, token).then(() =>
    window.location.assign(window.location.origin),
  );

  return <></>;
};
