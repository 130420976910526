import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppState } from "store/state";
import {
  createPlaylistSegmentTag,
  deletePlaylistSegmentTag,
  getPlaylistSegmentTags,
  updatePlaylistSegmentTag,
} from "./actions";

export const segmentTagBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(getPlaylistSegmentTags.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
      state.segmentTagsLoading = true;
    })
    .addCase(getPlaylistSegmentTags.fulfilled, (state, action) => {
      state.segmentTags = action.payload;
      state.segmentTagsLoading = false;
    })
    .addCase(getPlaylistSegmentTags.rejected, (state, action) => {
      console.warn(action);
      state.segmentTags = [];
      state.segmentTagsLoading = false;
    })
    .addCase(createPlaylistSegmentTag.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(createPlaylistSegmentTag.fulfilled, (state, action) => {
      if (state.segmentTags) {
        state.segmentTags.push(action.payload);
      } else {
        state.segmentTags = [action.payload];
      }
    })
    .addCase(updatePlaylistSegmentTag.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updatePlaylistSegmentTag.fulfilled, (state, action) => {
      const idx = state.segmentTags!.findIndex(
        (v) => v.id === action.meta.arg.tagId,
      );
      if (idx >= 0) {
        state.segmentTags![idx] = { ...action.payload };
      }
    })
    .addCase(deletePlaylistSegmentTag.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(deletePlaylistSegmentTag.fulfilled, (state, action) => {
      const idx = state.segmentTags!.findIndex(
        (v) => v.id === action.meta.arg.tagId,
      );
      if (idx >= 0) {
        state.segmentTags!.splice(idx, 1);
      }
    });
