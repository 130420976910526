import { apiConfiguration, apiGet } from "api";
import { CreatePlaylistTag, V1playlistTagApi } from "generated/openapi";

const api = new V1playlistTagApi(apiConfiguration);

export interface UpdatePlaylistTagArgs {
  tag: CreatePlaylistTag;
  tagId: number;
  token?: string;
}

export interface CreatePlaylistTagArgs {
  tag: CreatePlaylistTag;
  token?: string;
}

export interface DeletePlaylistTagArgs {
  tagId: number;
  token?: string;
}

export const getPlaylistTags = async ({ token }: { token?: string }) =>
  apiGet(async () =>
    api.getPlaylistTags({
      headers: { Authorization: `Bearer ${token}` },
    }),
  );

export const createPlaylistTag = async (query: CreatePlaylistTagArgs) =>
  apiGet(async () =>
    api.createPlaylistTag(
      { createPlaylistTag: query.tag },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const updatePlaylistTag = async (query: UpdatePlaylistTagArgs) =>
  apiGet(async () =>
    api.updatePlaylistTag(
      {
        tagId: query.tagId,
        createPlaylistTag: query.tag,
      },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );

export const deletePlaylistTag = async (query: DeletePlaylistTagArgs) =>
  apiGet(async () =>
    api.deletePlaylistTag(
      { tagId: query.tagId },
      { headers: { Authorization: `Bearer ${query.token}` } },
    ),
  );
