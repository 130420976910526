import { Button, Stack, TextField, Typography } from "@mui/material";
import theme from "app/theme";
import { PlaylistTagsInput, TeamsFilter } from "components";
import { CreatePlaylist } from "generated/openapi";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { selectSelectedTeam, selectTeams, useAppSelector } from "store";

interface Props {
  onCreate: (_: CreatePlaylist) => void;
}

export const ComposePlaylist: FC<Props> = ({ onCreate }) => {
  const teams = useAppSelector(selectTeams)!;
  const selectedTeam = useAppSelector(selectSelectedTeam)!;

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePlaylist>({
    defaultValues: {
      teamId: selectedTeam.id,
      segments: [],
      tags: [],
      playerTags: [],
      sharedWith: [],
    },
  });

  const onSubmit: SubmitHandler<CreatePlaylist> = async (form) => {
    onCreate(form);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={2}
        sx={{
          p: "2px",
          pb: 0,
          width: 350,
          "& .MuiFormLabel-root": {
            color: `${theme.palette.text.secondary} !important`,
          },
        }}
      >
        <Typography variant="h6" sx={{ pb: 1.5 }}>
          Create playlist
        </Typography>

        <TextField
          autoFocus
          autoComplete="off"
          spellCheck={false}
          size="small"
          label="Name"
          variant="outlined"
          error={!!errors.name}
          onKeyDown={(e) => e.stopPropagation()}
          {...register("name", { required: true })}
        />

        {teams.length > 1 && (
          <TeamsFilter
            disableClearable
            value={watch("teamId")}
            onChange={(value) =>
              value !== undefined && setValue("teamId", value.id)
            }
          />
        )}

        <PlaylistTagsInput
          value={watch("tags")}
          disablePortal={false}
          onChange={(tags) => setValue("tags", tags)}
        />

        <Button color="info" type="submit">
          Create
        </Button>
      </Stack>
    </form>
  );
};
