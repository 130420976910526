import { Box } from "@mui/material";
import { ForzaEventsParameters } from "api";
import { EventsContainer, HighlightsFilterBar } from "components";
import { ForzaEventType } from "generated/openapi";
import { FC } from "react";
import {
  getCorners,
  getFreekicks,
  getGoals,
  getOffsides,
  getPenalties,
  getRedCards,
  getShots,
  getYellowCards,
  selectCorners,
  selectEventsFilters,
  selectFreekicks,
  selectGoals,
  selectOffsides,
  selectPenalties,
  selectRedCards,
  selectSelectedEventType,
  selectShotsOnGoal,
  selectYellowCards,
  setForzaEventsFilters,
  useAppDispatch,
  useAppSelector,
} from "store";

const eventTypes = [
  { type: ForzaEventType.Goal, action: getGoals, selector: selectGoals },
  {
    type: ForzaEventType.Penalty,
    action: getPenalties,
    selector: selectPenalties,
  },
  {
    type: ForzaEventType.RedCard,
    action: getRedCards,
    selector: selectRedCards,
  },
  {
    type: ForzaEventType.YellowCard,
    action: getYellowCards,
    selector: selectYellowCards,
  },
  { type: ForzaEventType.Shot, action: getShots, selector: selectShotsOnGoal },
  { type: ForzaEventType.Corner, action: getCorners, selector: selectCorners },
  {
    type: ForzaEventType.Offside,
    action: getOffsides,
    selector: selectOffsides,
  },
  {
    type: ForzaEventType.Freekick,
    action: getFreekicks,
    selector: selectFreekicks,
  },
];

export const HighlightsView: FC = () => {
  const dispatch = useAppDispatch();
  const eventFilters = useAppSelector(selectEventsFilters);
  const selectedEventType = useAppSelector(selectSelectedEventType);

  const handleChange = (filters: ForzaEventsParameters) => {
    dispatch(setForzaEventsFilters(filters));
  };

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <HighlightsFilterBar filters={eventFilters} onChange={handleChange} />
      </Box>
      {eventTypes.map(
        ({ type, action, selector }) =>
          (!selectedEventType || selectedEventType === type) && (
            <EventsContainer
              key={type}
              action={action}
              selector={selector}
              eventType={type}
            />
          ),
      )}
    </>
  );
};
