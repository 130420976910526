import { Button, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useCurrentEditor } from "@tiptap/react";
import { Modal } from "components";
import { Dispatch, FC, SetStateAction, useState } from "react";

export interface Props {
  onClose: () => void;
}

export const CreateTableModal: FC<Props> = ({ onClose }) => {
  const { editor } = useCurrentEditor();

  const [rows, setRows] = useState(3);
  const [cols, setCols] = useState(3);

  if (!editor) return <></>;

  const handleChange = (
    value: string,
    setter: Dispatch<SetStateAction<number>>,
  ) => {
    const v = +value;
    if (Number.isFinite(v)) {
      setter(v);
    }
  };

  return (
    <Modal open onClose={onClose}>
      <Stack gap={4}>
        <Typography variant="h3" fontWeight="normal">
          Insert Table
        </Typography>
        <TextField
          autoFocus
          label="Rows"
          variant="outlined"
          type="number"
          value={rows}
          onChange={(e) => handleChange(e.target.value, setRows)}
        />
        <TextField
          label="Columns"
          variant="outlined"
          type="number"
          value={cols}
          onChange={(e) => handleChange(e.target.value, setCols)}
        />
        <Button
          variant="outlined"
          color="inherit"
          sx={{ width: "50%", marginLeft: "auto" }}
          onClick={() => {
            editor
              .chain()
              .focus()
              .insertTable({ rows, cols, withHeaderRow: true })
              .run();
            onClose();
          }}
        >
          Confirm
        </Button>
      </Stack>
    </Modal>
  );
};
