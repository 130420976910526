import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import {
  Avatar,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import theme from "app/theme";
import { Confirm, ConfirmModal } from "components";
import { CreateInvite, User, UserRole } from "generated/openapi";
import { FC, useState } from "react";
import {
  kickFromTeam,
  selectAccessToken,
  selectOrg,
  selectTeam,
  selectUser,
  useAppDispatch,
  useAppSelector,
} from "store";
import { InviteUser } from "./InviteUser";
import { UpdatePlayer } from "./UpdatePlayer";
import { UpdateUser } from "./UpdateUser";
import { StyledTableCell, StyledTableCellSub } from "./Users";

interface RowMenuProps {
  user: User | undefined;
  canEdit: boolean;
  canRemove: boolean;
  anchorEl: HTMLButtonElement | null;
}

interface Props {
  teamId: number;
}

export const TeamMembersTable: FC<Props> = (props) => {
  const { teamId } = props;
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser)!;
  const user = useAppSelector(selectUser)!;
  const team = useAppSelector(selectTeam(teamId));
  const org = useAppSelector(selectOrg)!;
  const token = useAppSelector(selectAccessToken);

  const [confirm, setConfirm] = useState<Confirm | undefined>(undefined);
  const [editUser, setEditUser] = useState<User | undefined>(undefined);
  const [inviteUser, setInviteUser] = useState<CreateInvite | undefined>(
    undefined,
  );
  const [rowMenuProps, setRowMenuProps] = useState<RowMenuProps>({
    user: undefined,
    canEdit: false,
    canRemove: false,
    anchorEl: null,
  });

  const isPlayer = user.userRole === UserRole.Player;

  const canEdit = (user: User) =>
    currentUser.id === user.id ||
    currentUser.userRole === UserRole.Admin ||
    (user.userRole === UserRole.Player &&
      currentUser.userRole === UserRole.Coach);

  const canKick = (user: User) =>
    currentUser.id !== user.id && currentUser.userRole !== UserRole.Player;

  const handleOpenActionMenu = (
    user: User,
    anchorTarget: HTMLButtonElement,
  ) => {
    setRowMenuProps({
      user,
      canEdit: canEdit(user),
      canRemove: canKick(user),
      anchorEl: anchorTarget,
    });
  };

  const handleCloseMenu = () => {
    setRowMenuProps({
      user: undefined,
      canEdit: false,
      canRemove: false,
      anchorEl: null,
    });
  };

  const handleEditUser = () => {
    setEditUser(rowMenuProps.user);
    handleCloseMenu();
  };

  const handleRemoveUser = () => {
    setConfirm({
      message: `Are you sure you want to remove '${rowMenuProps.user!.firstName} ${rowMenuProps.user!.lastName}' from the team?`,
      onConfirm: () => {
        dispatch(kickFromTeam({ teamId, userId: rowMenuProps.user!.id }));
      },
    });
    handleCloseMenu();
  };

  if (!team) {
    return <></>;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Members</Typography>
      {!isPlayer && (
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            color="third"
            disabled={org.teams.length >= org.maxTeams}
            sx={{ color: "text.secondary" }}
            startIcon={<PersonAddIcon sx={{ color: "third.main" }} />}
            onClick={() =>
              setInviteUser({ email: "", userRole: UserRole.Player, teamId })
            }
          >
            Add member
          </Button>
        </Stack>
      )}
      <TableContainer
        sx={{ border: `1px solid ${theme.palette.primary.main}` }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>First name</StyledTableCell>
              <StyledTableCell>Last name</StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell>Position</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          {team.coaches.length && (
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCellSub colSpan={6}>Coaches</StyledTableCellSub>
                </TableRow>
              </TableHead>
              <TableBody>
                {team.coaches.map((u, i) => (
                  <TableRow key={i}>
                    <StyledTableCell>
                      <Avatar
                        sx={{ width: 26, height: 26 }}
                        src={`${process.env.REACT_APP_API_URL}/v1.0/users/${u.id}/profile_image?authorization=${token}`}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{u.firstName}</StyledTableCell>
                    <StyledTableCell>{u.lastName}</StyledTableCell>
                    <StyledTableCell>{u.userRole}</StyledTableCell>
                    <StyledTableCell>{u.position}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        onClick={(e) =>
                          handleOpenActionMenu(u, e.currentTarget)
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
          {team.players.length && (
            <>
              <TableHead>
                <TableRow>
                  <StyledTableCellSub colSpan={6}>Players</StyledTableCellSub>
                </TableRow>
              </TableHead>
              <TableBody>
                {team.players.map((u, i) => (
                  <TableRow key={i}>
                    <StyledTableCell>
                      <Avatar
                        sx={{ width: 26, height: 26 }}
                        src={`${process.env.REACT_APP_API_URL}/v1.0/users/${u.id}/profile_image?authorization=${token}`}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{u.firstName}</StyledTableCell>
                    <StyledTableCell>{u.lastName}</StyledTableCell>
                    <StyledTableCell>{u.userRole}</StyledTableCell>
                    <StyledTableCell>{u.position}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        onClick={(e) =>
                          handleOpenActionMenu(u, e.currentTarget)
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
      {confirm && (
        <ConfirmModal {...confirm} open onClose={() => setConfirm(undefined)} />
      )}
      {editUser &&
        (currentUser.id !== editUser.id &&
        editUser.userRole === UserRole.Player ? (
          <UpdatePlayer
            user={editUser}
            onClose={() => setEditUser(undefined)}
          />
        ) : (
          <UpdateUser user={editUser} onClose={() => setEditUser(undefined)} />
        ))}
      {inviteUser && (
        <InviteUser
          invite={inviteUser}
          onClose={() => setInviteUser(undefined)}
        />
      )}
      <Menu
        disableScrollLock
        anchorEl={rowMenuProps.anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(rowMenuProps.anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleEditUser} disabled={!rowMenuProps.canEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit user
        </MenuItem>
        <MenuItem onClick={handleRemoveUser} disabled={!rowMenuProps.canRemove}>
          <ListItemIcon>
            <PersonRemoveIcon fontSize="small" />
          </ListItemIcon>
          Remove from team
        </MenuItem>
      </Menu>
    </Stack>
  );
};
