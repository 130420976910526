import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FC } from "react";

export interface Confirm {
  message: string;
  onConfirm: () => void;
}

export interface Props {
  message: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmModal: FC<Props> = ({
  message,
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableScrollLock
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "primary.main",
          padding: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="outlined"
          sx={{
            color: "white",
            borderColor: "primary.light",
            "&:hover": { borderColor: "primary.dark" },
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
