import { Chip, Stack, Typography } from "@mui/material";
import theme from "app/theme";
import { TagItem } from "models";
import { FC, useMemo } from "react";
import {
  selectSegmentTagsFlattened,
  selectSegmentTagsFlattenedMap,
  useAppSelector,
} from "store";

interface Props {
  value: TagItem[];
  onChange: (_?: TagItem[]) => void;
  options?: number[];
}

export const TagsFilter: FC<Props> = ({
  value,
  options: _options,
  onChange,
}) => {
  const tags = useAppSelector(selectSegmentTagsFlattened)!;
  const tagsMap = useAppSelector(selectSegmentTagsFlattenedMap)!;

  const options = useMemo(
    () =>
      _options
        ? _options
            .map((o) => tagsMap[o])
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
        : tags,
    [_options, tags, tagsMap],
  );

  const handleToggle = (tag: TagItem) => {
    if (value.includes(tag)) {
      onChange(value.filter((t) => t.id !== tag.id));
    } else {
      onChange([...value, tag]);
    }
  };

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">Tags</Typography>
      <Stack direction="row" gap={1} flexWrap="wrap">
        {options.map((tag, i) => (
          <Chip
            sx={{
              color: value.includes(tag) ? "white" : theme.palette.grey[300],
            }}
            key={i}
            label={tag.displayName}
            onClick={() => handleToggle(tag)}
            color="third"
            variant={value.includes(tag) ? "filled" : "outlined"}
          />
        ))}
      </Stack>
    </Stack>
  );
};
