import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import { Button, Divider, IconButton, Popover, Stack } from "@mui/material";
import { FC, useState } from "react";

interface Props {
  onClick: (value: number) => void;
  mirrored?: boolean;
}

export const VideoOffsetMenu: FC<Props> = (props) => {
  const { mirrored, onClick } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const options = mirrored ? [-10, -5, -1, 1, 5, 10] : [10, 5, 1, -1, -5, -10];
  return (
    <>
      <IconButton
        size="small"
        sx={{ bgcolor: "primary.dark", ":hover": { bgcolor: "primary.main" } }}
        onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
      >
        {mirrored ? <FastForwardIcon /> : <FastRewindIcon />}
      </IconButton>
      <Popover
        disableScrollLock
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: mirrored ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: mirrored ? "right" : "left",
        }}
      >
        <Stack
          direction="row"
          sx={{ bgcolor: "primary.light", width: "100%" }}
          divider={
            <Divider
              sx={{ bgcolor: "primary.dark" }}
              orientation="vertical"
              flexItem
            />
          }
        >
          {options.map((v, i) => (
            <Button
              key={i}
              sx={{ minWidth: 45 }}
              color="inherit"
              onClick={() => {
                onClick(v);
                setAnchorEl(null);
              }}
            >
              {v > 0 ? `+${v}` : v.toString()}
            </Button>
          ))}
        </Stack>
      </Popover>
    </>
  );
};
