import AddIcon from "@mui/icons-material/Add";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { Box, Button, Typography } from "@mui/material";
import { MAX_PLAYLIST_SEGMENTS } from "api";
import { ComposePlaylist, Modal } from "components";
import { CreatePlaylist, MinimalPlaylist } from "generated/openapi";
import { FC, useEffect, useState } from "react";
import {
  getPlaylists,
  selectPlaylists,
  selectSelectedTeam,
  selectTeams,
  useAppDispatch,
  useAppSelector,
} from "store";

interface Props {
  open: boolean;
  numToAdd: number;
  onClose: () => void;
  onCreate: (_: CreatePlaylist) => void;
  onUpdate: (_: MinimalPlaylist) => void;
}

export const AddToPlaylistModal: FC<Props> = ({
  open,
  numToAdd,
  onClose,
  onCreate,
  onUpdate,
}) => {
  const dispatch = useAppDispatch();

  const teams = useAppSelector(selectTeams)!;
  const playlists = useAppSelector(selectPlaylists);
  const selectedTeam = useAppSelector(selectSelectedTeam)!;

  const [create, setCreate] = useState(false);

  useEffect(() => {
    dispatch(getPlaylists({ teamId: selectedTeam.id }));
  }, []);

  const close = () => {
    setCreate(false);
    onClose();
  };

  if (!playlists) {
    return <></>;
  }

  return (
    <Modal open={open} onClose={close} sx={{ p: 3 }}>
      {create ? (
        <ComposePlaylist onCreate={onCreate} />
      ) : (
        <>
          <Typography fontWeight="semiBold">Add to...</Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {playlists.values.map(
              (p) =>
                p.numSegments + numToAdd <= MAX_PLAYLIST_SEGMENTS && (
                  <Button
                    key={p.id}
                    color="inherit"
                    sx={{
                      maxWidth: 450,
                      textTransform: "none",
                      justifyContent: "left",
                    }}
                    onClick={() => onUpdate(p)}
                    startIcon={<PlaylistAddIcon />}
                  >
                    <Box
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      title={p.name.length >= 58 ? p.name : undefined}
                    >
                      {p.name}
                    </Box>
                  </Button>
                ),
            )}
          </Box>
          <Button
            sx={{ width: "100%" }}
            color="info"
            disabled={teams.length === 0}
            startIcon={<AddIcon />}
            onClick={() => setCreate(true)}
          >
            Create New Playlist
          </Button>
        </>
      )}
    </Modal>
  );
};
