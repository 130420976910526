import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getPlaylistSegmentTags, getPlaylistTags } from "store";
import { AppState } from "store/state";
import {
  createOrg,
  deleteOrg,
  getOrg,
  kickFromOrg,
  leaveOrg,
  updateOrg,
} from "./actions";

export const orgBuilder = (
  builder: ActionReducerMapBuilder<AppState>,
): ActionReducerMapBuilder<AppState> =>
  builder
    .addCase(getOrg.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
      state.orgLoading = true;
    })
    .addCase(getOrg.fulfilled, (state, action) => {
      state.orgLoading = false;
      const org = action.payload;

      if (!org) {
        return;
      }

      state.org = org;

      if (org.teams.length > 1) {
        const selectedTeamId = localStorage.getItem("selectedTeamId");
        if (selectedTeamId) {
          const team = org.teams.find((v) => v.id === Number(selectedTeamId));
          if (team) {
            state.selectedTeam = team;
          } else {
            state.selectedTeam = org.teams[0];
          }
        } else {
          state.selectedTeam = org.teams[0];
        }
      } else {
        state.selectedTeam = org.teams[0];
      }
      (action as any).asyncDispatch(
        getPlaylistSegmentTags({ token: state.accessToken }),
      );
      (action as any).asyncDispatch(
        getPlaylistTags({ token: state.accessToken }),
      );
    })
    .addCase(getOrg.rejected, (state, _action) => {
      state.orgLoading = false;
    })
    .addCase(createOrg.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(createOrg.fulfilled, (state, action) => {
      state.org = {
        ...action.payload,
        teams: [],
        otherUsers: [state.user!],
      };
    })
    .addCase(updateOrg.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(updateOrg.fulfilled, (state, action) => {
      state.org = action.meta.arg.org;
    })
    .addCase(deleteOrg.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(deleteOrg.fulfilled, (_state, _action) => {
      window.location.assign(window.location.origin);
    })
    .addCase(leaveOrg.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(leaveOrg.fulfilled, (_state, _action) => {
      window.location.assign(window.location.origin);
    })
    .addCase(kickFromOrg.pending, (state, action) => {
      action.meta.arg.token = state.accessToken;
    })
    .addCase(kickFromOrg.fulfilled, (state, action) => {
      const org = state.org!;
      org.otherUsers = org.otherUsers.filter(
        (c) => c.id !== action.meta.arg.userId,
      );
      for (const team of org.teams) {
        team.coaches = team.coaches.filter(
          (c) => c.id !== action.meta.arg.userId,
        );
        team.players = team.players.filter(
          (c) => c.id !== action.meta.arg.userId,
        );
      }
    });
