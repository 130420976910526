import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";
import PersonIcon from "@mui/icons-material/Person";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import {
  Badge,
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme from "app/theme";
import { CreatorFilter, SegmentFilterMenu, TagsFilter } from "components";
import { customBadge } from "containers/Playlist/PlaylistSegments";
import {
  LiveStreamUser,
  MinimalUser,
  PlaylistSegment,
} from "generated/openapi";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { selectSegmentTagsFlattenedMap, useAppSelector } from "store";
import { minutesSeconds } from "utils";

export interface Props {
  segments: PlaylistSegment[];
  colorMap: Record<string, string>;
  focusModeUsers?: LiveStreamUser[];
  selected?: PlaylistSegment;
  tagFilter?: number;
  onChange?: (segment: PlaylistSegment) => void;
  onDelete?: (segment: PlaylistSegment) => void;
  onSelect?: (segment?: PlaylistSegment) => void;
  onSendToFocusedUser?: (
    segment: PlaylistSegment,
    user: LiveStreamUser,
  ) => void;
  // Used to reset state of externally set tag filter
  onFilterChange?: () => void;
}

interface SegmentFilters {
  tags?: number[];
  creators?: MinimalUser[];
  favorite: boolean;
}

export const LiveSegments: FC<Props> = ({
  segments: _segments,
  colorMap,
  focusModeUsers,
  selected,
  tagFilter,
  onChange,
  onDelete,
  onSelect,
  onSendToFocusedUser,
  onFilterChange,
}) => {
  const [filters, setFilters] = useState<SegmentFilters>({
    tags: [],
    creators: [],
    favorite: false,
  });
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up("xl"));
  const allTags = useAppSelector(selectSegmentTagsFlattenedMap)!;
  const segmentTags = useAppSelector(selectSegmentTagsFlattenedMap);
  const segments = useMemo(() => {
    const { tags, creators, favorite } = filters;
    let s: PlaylistSegment[] = _segments;

    if (tags?.length)
      s = s.filter((segment) => tags?.some((t) => segment.tags.includes(t)));
    if (creators?.length)
      s = s.filter((segment) =>
        creators?.some((c) => segment.creator?.id === c.id),
      );
    if (favorite) s = s.filter((segment) => segment.favourite);

    return s;
  }, [_segments, filters]);

  useEffect(() => {
    if (tagFilter) {
      const tag = allTags[tagFilter];

      if (!("parentTagId" in tag)) {
        const ids = Array.from(
          new Set(_segments.flatMap((s) => s.tags)),
        ).filter((id) => allTags[id].parentTagId === tagFilter);
        ids.push(tagFilter);

        setFilters({ creators: [], favorite: false, tags: ids });
      } else {
        setFilters({
          creators: [],
          favorite: false,
          tags: [tagFilter],
        });
      }
    }
  }, [tagFilter, _segments]);

  const creatorsOptions = useMemo(
    () =>
      _segments.reduce((acc: MinimalUser[], cur) => {
        if (cur.creator && !acc.some((u) => u.id === cur.creator!.id)) {
          acc.push(cur.creator);
        }
        return acc;
      }, []),
    [_segments],
  );

  const handleResetFilters = () => {
    setFilters({ ...filters, tags: [], creators: [] });
    onFilterChange?.();
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          "& .MuiAutocomplete-paper": {
            width: 330,
          },
          "& .MuiFormLabel-root": {
            color: `${theme.palette.text.secondary} !important`,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.light} !important`,
            borderRadius: 0,
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 1 }}
        >
          <SegmentFilterMenu
            numActiveFilters={
              (filters.tags?.length ?? 0) + (filters.creators?.length ?? 0)
            }
            onReset={
              filters.tags?.length !== 0 || filters.creators?.length !== 0
                ? handleResetFilters
                : undefined
            }
          >
            <TagsFilter
              value={filters.tags?.map((t) => segmentTags![t]) ?? []}
              options={Array.from(new Set(_segments.flatMap((s) => s.tags)))}
              onChange={(tags) => {
                setFilters({ ...filters, tags: tags?.map((t) => t.id) });
                onFilterChange?.();
              }}
            />
            <CreatorFilter
              value={filters.creators ?? []}
              options={creatorsOptions}
              onChange={(creators) => {
                setFilters({ ...filters, creators });
                onFilterChange?.();
              }}
            />
          </SegmentFilterMenu>
          <Checkbox
            checked={filters.favorite!}
            icon={<StarOutlineIcon />}
            checkedIcon={<StarIcon sx={{ color: "warning.main" }} />}
            onChange={() => {
              setFilters({ ...filters, favorite: !filters.favorite });
              onFilterChange?.();
            }}
          />
        </Stack>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          height: xlBreakpoint ? "72vh" : "100%",
        }}
      >
        {!!_segments.length && !segments.length && (
          <Typography fontStyle="italic">
            No results for specified filter
          </Typography>
        )}
        {segments.map((s, i) => (
          <Segment
            key={i}
            segment={s}
            colorMap={colorMap}
            focusModeUsers={focusModeUsers}
            selected={s.id === selected?.id}
            onSelect={() => onSelect?.(s)}
            onChange={onChange}
            onDelete={onDelete}
            onSendToFocusedUser={onSendToFocusedUser}
          />
        ))}
      </Box>
    </>
  );
};

interface SProps {
  segment: PlaylistSegment;
  colorMap: Record<string, string>;
  selected: boolean;
  focusModeUsers?: LiveStreamUser[];
  onSelect: (segment: PlaylistSegment) => void;
  onChange?: (segment: PlaylistSegment) => void;
  onDelete?: (segment: PlaylistSegment) => void;
  onSendToFocusedUser?: (
    segment: PlaylistSegment,
    user: LiveStreamUser,
  ) => void;
}

const Segment: FC<SProps> = memo(
  ({
    segment,
    colorMap,
    focusModeUsers,
    selected,
    onSelect,
    onChange,
    onDelete,
    onSendToFocusedUser,
  }: SProps) => {
    const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);

    return (
      <Box
        sx={{
          mb: 1,
          "&:last-child": {
            mb: 0,
          },
          p: 2,
          pb: 0,
          ":after": {},
          userSelect: "none",
          bgcolor: selected ? "primary.light" : "primary.main",
          cursor: "pointer",
          borderLeft: "4px solid",
          borderColor: segment.creator
            ? colorMap[segment.creator.id]
            : "primary.main",
        }}
        onClick={() => onSelect(segment)}
      >
        <Stack direction="row">
          <Stack>
            <Typography fontWeight="bold">{segment.title}</Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ py: 1 }}
            >
              {segment.creator
                ? segment.creator?.firstName + " " + segment.creator?.lastName
                : "Unknown"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                py: 1,
                alignItems: "center",
              }}
            >
              <Typography variant="caption" sx={{ lineHeight: 0, mr: 0.5 }}>
                {minutesSeconds(segment.toTimestamp - segment.fromTimestamp)}
              </Typography>
              {customBadge(Object.values(segment.drawings).length, ImageIcon)}
              {!!Object.keys(segment.homographyFrames) && (
                <Box sx={{ display: "flex" }}>
                  <Divider
                    sx={{ bgcolor: "primary.light", mr: 1.5 }}
                    orientation="vertical"
                    flexItem
                  />
                  <Badge
                    sx={{
                      mr: Object.keys(segment.homographyFrames).length
                        ? 1
                        : 0.5,
                      "& .MuiBadge-badge": {
                        right: -6,
                        top: 11,
                        color: "white",
                        border: `2px solid ${theme.palette.primary.main}`,
                        padding: "0 4px",
                        bgcolor: "primary.dark",
                      },
                    }}
                    badgeContent={Object.keys(segment.homographyFrames).length}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.9rem",
                        color: "text.secondary",
                        fontWeight: "bold",
                      }}
                    >
                      2D
                    </Typography>
                  </Badge>
                </Box>
              )}
            </Box>
          </Stack>
          <Box sx={{ marginLeft: "auto", display: "flex" }}>
            {onSendToFocusedUser && (
              <IconButton
                sx={{ width: 40, height: 40 }}
                disabled={!focusModeUsers}
                color="inherit"
                onClick={(e) => {
                  e.stopPropagation();
                  if (focusModeUsers) {
                    focusModeUsers.length > 1
                      ? setShareDialogOpen(true)
                      : onSendToFocusedUser(segment, focusModeUsers[0]);
                  }
                }}
              >
                <ScreenShareIcon
                  sx={{
                    color: focusModeUsers ? "fourth.light" : "grey.A100",
                  }}
                />
              </IconButton>
            )}
            <IconButton
              sx={{ width: 40, height: 40 }}
              color="inherit"
              disabled={!onChange}
              onClick={(e) => {
                e.stopPropagation();
                onChange?.({ ...segment, favourite: !segment.favourite });
              }}
            >
              {segment.favourite ? (
                <StarIcon sx={{ color: "warning.main" }} />
              ) : (
                <StarOutlineIcon sx={{ color: "grey.A100" }} />
              )}
            </IconButton>
            {onDelete && (
              <IconButton
                sx={{ width: 40, height: 40 }}
                color="inherit"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(segment);
                }}
              >
                <DeleteIcon sx={{ color: "error.light" }} />
              </IconButton>
            )}
          </Box>
        </Stack>
        {focusModeUsers && focusModeUsers?.length > 1 && (
          <Dialog
            sx={{
              "& .MuiDialog-paper": {
                width: 300,
                bgcolor: "primary.main",
              },
            }}
            open={shareDialogOpen}
            disableScrollLock
            onClose={() => setShareDialogOpen(false)}
            maxWidth="xs"
          >
            <DialogTitle>Send to...</DialogTitle>
            <List sx={{ pt: 0 }}>
              {focusModeUsers.map((user, i) => (
                <ListItem disablePadding key={i}>
                  <ListItemButton
                    onClick={() => {
                      onSendToFocusedUser?.(segment, user);
                      setShareDialogOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <PersonIcon sx={{ color: colorMap[user.id] }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={user.firstName + " " + user.lastName}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Dialog>
        )}
      </Box>
    );
  },
);
