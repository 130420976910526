import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import theme from "app/theme";
import { Confirm, ConfirmModal } from "components";
import { UserRole } from "generated/openapi";
import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  deleteTeam,
  selectOrg,
  selectTeamsSorted,
  selectUser,
  useAppDispatch,
  useAppSelector,
} from "store";
import { CreateTeam } from "./CreateTeam";
import { Team } from "./Team";

interface Props {}

const StyledTableCell = styled(TableCell)(() => ({
  borderLeft: "none",
  borderRight: "none",

  [`&.${tableCellClasses.head}`]: {
    border: "none",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontSize: "0.8rem",
    backgroundColor: theme.palette.primary.dark,
    textAlign: "center",
    "&:first-of-type": { textAlign: "left" },
    "&:last-of-type": { textAlign: "right" },
  },
  [`&.${tableCellClasses.body}`]: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.text.secondary,
    width: 150,
    fontSize: "0.9rem",
    textAlign: "center",
    "&:first-of-type": {
      fontSize: " 1.1rem",
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.third.light,
      textAlign: "left",
    },
    "&:last-of-type": {
      textAlign: "right",
    },
  },
}));

export const Teams: FC<Props> = () => {
  const teams = useAppSelector(selectTeamsSorted)!;
  const user = useAppSelector(selectUser)!;
  const org = useAppSelector(selectOrg)!;

  const [params, setParams] = useSearchParams();
  const [createTeam, setCreateTeam] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<Confirm | undefined>(
    undefined,
  );
  const dispatch = useAppDispatch();

  const tabKey = params.get("id");

  const isAdmin = user.userRole === UserRole.Admin;

  return (
    <>
      {tabKey ? (
        <Team teamId={Number(tabKey)} />
      ) : (
        <Stack spacing={2}>
          {isAdmin && (
            <Button
              variant="outlined"
              color="third"
              disabled={org.teams.length >= org.maxTeams}
              sx={{ width: 170, color: "white" }}
              startIcon={<AddIcon sx={{ color: "third.main" }} />}
              onClick={() => setCreateTeam(true)}
            >
              Create Team
            </Button>
          )}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell># Players</StyledTableCell>
                  <StyledTableCell># Coaches</StyledTableCell>
                  <StyledTableCell># Groups</StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {teams.map((t, i) => (
                  <TableRow
                    key={i}
                    onClick={() => setParams({ id: t.id.toString() })}
                    sx={{
                      ":hover": { bgcolor: "primary.main", cursor: "pointer" },
                    }}
                  >
                    <StyledTableCell>{t.name}</StyledTableCell>
                    <StyledTableCell>{t.players.length}</StyledTableCell>
                    <StyledTableCell>{t.coaches.length}</StyledTableCell>
                    <StyledTableCell>{t.groups.length}</StyledTableCell>
                    <StyledTableCell>
                      {isAdmin && (
                        <Button
                          sx={{ color: "white" }}
                          startIcon={<DeleteIcon color="error" />}
                          variant="outlined"
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation();
                            setConfirmDelete({
                              message: `Are you sure you want to delete the team '${t.name}'`,
                              onConfirm: () => {
                                dispatch(deleteTeam({ teamId: t.id }));
                              },
                            });
                          }}
                        >
                          Delete team
                        </Button>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {createTeam && <CreateTeam onClose={() => setCreateTeam(false)} />}
          {confirmDelete && (
            <ConfirmModal
              {...confirmDelete}
              open
              onClose={() => setConfirmDelete(undefined)}
            />
          )}
        </Stack>
      )}
    </>
  );
};
