import { Button, Stack, TextField } from "@mui/material";
import { Modal } from "components";
import { CreatePlaylistSegmentTag } from "generated/openapi";
import { MuiChipsInput } from "mui-chips-input";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { createPlaylistSegmentTag, useAppDispatch } from "store";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CreateSegmentTagModal: FC<Props> = (props) => {
  const { open, onClose } = props;
  const dispatch = useAppDispatch();

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<CreatePlaylistSegmentTag>({ defaultValues: { subTags: [] } });

  const onSubmit: SubmitHandler<CreatePlaylistSegmentTag> = async (form) => {
    dispatch(
      createPlaylistSegmentTag({ tag: { ...form, favourite: false } }),
    ).then(() => {
      reset();
      onClose();
    });
  };
  const subTags = watch("subTags");
  return (
    <Modal open={open} onClose={onClose} sx={{ p: 3 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          gap={1}
          sx={{
            bgcolor: "primary.main",
          }}
        >
          <TextField
            spellCheck={false}
            autoFocus
            label="Name"
            variant="outlined"
            error={!!errors.name}
            {...register("name", { required: true })}
          />

          <MuiChipsInput
            value={subTags?.map((st) => st.name)}
            onChange={(value) =>
              setValue(
                "subTags",
                value.map((v) => {
                  return { name: v, favourite: false };
                }),
              )
            }
          />

          <Button color="success" type="submit">
            Create
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};
