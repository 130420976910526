import UpdateIcon from "@mui/icons-material/Update";
import { Button, Stack, Typography } from "@mui/material";
import { Modal } from "components";
import { Playlist } from "generated/openapi";
import { SportscodeExport, SportscodeRowInstance, TagItem } from "models";
import { FC, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { selectSegmentTagsFlattenedMap, useAppSelector } from "store";
import { v4 as uuidv4 } from "uuid";

interface Props {
  playlist: Playlist;
  onClose: () => void;
}

export const ExportSportscodeModal: FC<Props> = ({ playlist, onClose }) => {
  const navigate = useNavigate();

  const allTags = useAppSelector(selectSegmentTagsFlattenedMap);

  const setKickoffUrl = `/forza/games/${playlist.segments[0].forzaGameId}/kickoff?redirectUrl=${window.location.pathname}`;

  if (!playlist.segments[0]?.manualKickoffTimeMs) {
    return <Navigate to={setKickoffUrl} />;
  }

  const manualKickoffTimeMs = playlist.segments[0]?.manualKickoffTimeMs;

  const segmentsWithoutTags = useMemo(
    () => playlist.segments.filter((s) => !s.tags.length),
    [playlist.segments],
  );

  return (
    <Modal open onClose={() => onClose()} sx={{ p: 3 }}>
      <Stack gap={2}>
        <Typography sx={{ pb: 2 }} variant="h3">
          Export To Sportscode
        </Typography>

        <Button
          color="inherit"
          sx={{ marginLeft: "auto" }}
          onClick={() => navigate(setKickoffUrl)}
        >
          <UpdateIcon />
        </Button>

        {!!segmentsWithoutTags.length && (
          <Stack marginBlock="1rem">
            <Typography>
              NB! These clips don&apos;t have tags and will not be exported.
            </Typography>
            <Stack>
              {segmentsWithoutTags.map((s) => (
                <Typography key={s.id}>{s.title}</Typography>
              ))}
            </Stack>
          </Stack>
        )}

        <Button
          color="info"
          type="submit"
          onClick={() => {
            let modifyCount = 1;

            const rows: Record<string, SportscodeRowInstance[]> = {};
            const labels = new Set<string>();

            for (const s of playlist.segments) {
              const tags = s.tags.map((id) => allTags![id]);

              const mainTags = tags.reduce((tot: TagItem[], cur) => {
                const id = cur.parentTagId ?? cur.id;
                if (!tot.some((t) => t.id === id)) {
                  tot.push(allTags![id]);
                }
                return tot;
              }, []);

              for (const tag of mainTags) {
                const subTags = tags.filter((t) => t.parentTagId === tag.id);

                const instance = {
                  uniqueId: uuidv4().toUpperCase(),
                  startTime: s.fromTimestamp / 1_000,
                  endTime: s.toTimestamp / 1_000,
                  modifyCount: modifyCount++,
                  sharing: true,
                  notes: "",
                  instanceNum: (rows[tag.displayName]?.length ?? 0) + 1,
                  labels: subTags.map((t) => {
                    labels.add(t.name);
                    return { name: t.name, group: "" };
                  }),
                };

                (rows[tag.displayName] ??= []).push(instance);
              }
            }

            const kickoff: [string, SportscodeRowInstance[]] = [
              "Kickoff",
              [
                {
                  startTime: manualKickoffTimeMs / 1_000,
                  endTime: manualKickoffTimeMs / 1_000 + 10,
                  modifyCount: modifyCount++,
                  sharing: true,
                  notes: "",
                  instanceNum: 1,
                  labels: [],
                  uniqueId: uuidv4().toUpperCase(),
                },
              ],
            ];

            download("x.SCTimeline", {
              timeline: {
                uniqueId: uuidv4().toUpperCase(),
                labels: Array.from(labels).map((name) => ({ name, group: "" })),
                rows: [kickoff]
                  .concat(Object.entries(rows))
                  .map(([name, instances], i) => ({
                    name,
                    modifyCount: modifyCount++,
                    color: "#3890E0",
                    uniqueId: uuidv4().toUpperCase(),
                    rowNum: i + 1,
                    instances,
                  })),
                currentModifyCount: --modifyCount,
              },
              currentPlaybackTime: 0,
            });

            onClose();
          }}
        >
          Export
        </Button>
      </Stack>
    </Modal>
  );
};

function download(filename: string, body: SportscodeExport) {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(JSON.stringify(body)),
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
