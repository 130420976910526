import VideocamIcon from "@mui/icons-material/Videocam";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ForzaVideoAsset } from "generated/openapi";
import { FC } from "react";

export interface Props {
  assets: ForzaVideoAsset[];
  selected: number;
  onSelect: (_: number) => void;
}

export const VideoAssets: FC<Props> = ({ assets, selected, onSelect }) => {
  const handleCameraChange = (
    _event: React.MouseEvent<HTMLElement>,
    cameraNum: number | null,
  ) => {
    if (cameraNum !== null) {
      onSelect(cameraNum);
    }
  };

  if (assets.length <= 1) {
    return <></>;
  }

  return (
    <ToggleButtonGroup
      value={selected}
      exclusive
      onChange={handleCameraChange}
      size="small"
      sx={{
        "& .MuiToggleButtonGroup-grouped": {
          color: "text.secondary",
          height: 36,
          width: 65,
          "&.Mui-selected": {
            color: "fourth.light",
          },
        },
      }}
    >
      <ToggleButton value={0}>
        <VideocamIcon />
        <Typography sx={{ pl: 0.5 }}>1</Typography>
      </ToggleButton>
      <ToggleButton value={1}>
        <VideocamIcon />
        <Typography sx={{ pl: 0.5 }}>2</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
